/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Typography, ETypography, ETypographyColor, ENotificationType } from '@bondsports/utils';
import { Fragment, useState } from 'react';
import { TranslationEn } from '@assets/i18n/en';
import { AlertDialog, AlertDialogPrimaryLayout, CheckMark, Conflict, DeleteOutline } from "ui";
import { paymentApi } from '@app/react/lib/api/paymentApi';
import { useErrorModal } from '@app/react/hooks/useErrorModal';
import { MailTo } from '@app/react/components/shared/MailTo';
import { useNotification } from '@app/react/hooks/useNotification';

interface RemovePaymentMethodModalProps {
	paymentMethodId: number;
	userId: number;
	isShowing: boolean;
	onSuccess: () => void;
	onChangePaymentMethod: (pmId: number) => void;
	toggle: () => void;
}

export const RemovePaymentMethodModal = ({
	paymentMethodId,
	userId,
	onSuccess,
	onChangePaymentMethod,
	toggle,
	isShowing,
}: RemovePaymentMethodModalProps ) => {
	const { setToastNotification } = useNotification();
	const { setErrorModal } = useErrorModal();

	const PAYMENT_PLAN_ERROR_MODAL = 'paymentPlanError'
	const MULTIPLE_ORG_ERROR_MODAL = 'multipleOrgError'

	const [modalStates, setModalStates] = useState({
		[PAYMENT_PLAN_ERROR_MODAL]: false,
		[MULTIPLE_ORG_ERROR_MODAL]: false,
	});

	const toggleModal = (key: keyof typeof modalStates) =>
		setModalStates((prev) => ({ ...prev, [key]: !prev[key] }));


	const togglePaymentPlanErrorModal = () => {
		toggleModal(PAYMENT_PLAN_ERROR_MODAL);
	};

	const toggleMultipleOrgErrorModal = () => {
		toggleModal(MULTIPLE_ORG_ERROR_MODAL);
	};

	const success = () => {
		 setToastNotification(labels.successMessage, ENotificationType.success);
		onSuccess();
	};

	const handleChangePaymentMethod = () => {
		onChangePaymentMethod(paymentMethodId);
		togglePaymentPlanErrorModal();
	};

	const handleApprove = () => {
		paymentApi	
			.deleteUserPaymentMethod(userId, paymentMethodId)
			.then(res => {
				if (res.err) {
					handleError(res.err);
				} else {
					success();
				}
			})
			.catch(error => {
				setErrorModal({ message: error});
			})
			.finally(toggle);
	};

	enum PaymentMethodError {
		PaymentPlan = 'Cannot remove due to payment plan',
		MultipleOrganizations = 'paymentMethodUserBelongsToMultipleOrgs',
	}

	const handleError = (err: string) => {
		switch (err) {
			case PaymentMethodError.PaymentPlan:
				togglePaymentPlanErrorModal();
				break;
			case PaymentMethodError.MultipleOrganizations:
				toggleMultipleOrgErrorModal();
				break;
			default:
				setErrorModal({ message: err });
		}
	};

	const labels = TranslationEn.payments.removePaymentMethod.dialog;

	const containerCss = css`
		display: flex;
		justify-content: center;
		align-items: center;
	`;

	return (
		<Fragment>
			<AlertDialog open={isShowing}>
				<AlertDialogPrimaryLayout
					icon={<DeleteOutline className="text-error" />}
					title={labels.confirmation.title}
					primaryButton={labels.confirmation.mainButton}
					onPrimaryAction={handleApprove}
					secondaryButton={labels.confirmation.subButton}
					onSecondaryAction={toggle}
				>
					<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
						{labels.confirmation.description}
					</Typography>
				</AlertDialogPrimaryLayout>
			</AlertDialog>
			<AlertDialog open={modalStates['paymentPlanError']}>
				<AlertDialogPrimaryLayout
					icon={<Conflict className="text-error"/>}
					title={labels.paymentPlanError.title}
					secondaryButton={labels.paymentPlanError.cancel}
					onSecondaryAction={togglePaymentPlanErrorModal}
					onPrimaryAction={handleChangePaymentMethod}
					primaryButton={labels.paymentPlanError.button}
				>
					<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
						{labels.paymentPlanError.description}
					</Typography>
				</AlertDialogPrimaryLayout>
			</AlertDialog>
			<AlertDialog open={modalStates['multipleOrgError']}>
				<AlertDialogPrimaryLayout
					icon={<Conflict className="text-error"/>}
					title={labels.multipleOrganizationsError.title}
					onPrimaryAction={toggleMultipleOrgErrorModal}
					primaryButton={labels.multipleOrganizationsError.button}
				>
					<div css={containerCss} >
						<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
							{labels.multipleOrganizationsError.cannotBeRemoved}
						</Typography>
					</div>
					<div css={containerCss} >
						<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
							{labels.multipleOrganizationsError.pleaseContact}
						</Typography>
						<MailTo
							value={labels.multipleOrganizationsError.supportEmail}
							typographyType={ETypography.captionLink}
							TypographyColor={ETypographyColor.primary}
						/>
						<Typography color={ETypographyColor.secondary} type={ETypography.caption}>
							{labels.multipleOrganizationsError.forAssistance}
						</Typography>
					</div>
				</AlertDialogPrimaryLayout>
			</AlertDialog>
		</Fragment>
	);
};
