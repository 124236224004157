/** @jsx jsx */

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { css, jsx } from '@emotion/react';
import { BnIcon, Breadcrumbs, Button, Icons, Pricify, useModal } from '@bondsports/utils';
import { ISingleTab } from '../../../shared/Tabs';
import { HeaderContainer, HeaderTitle, SideContainer } from '../../../shared/Header';
import { AccountActivity } from '../../../shared/Header/ClockLabel';
import { CustomerTagMapper } from '../../utils/CustomerTag';
import { Tabs } from '../Tabs';
import { setBreadCrumbsPaths } from '../../utils/setBreadcrumbs';
import { colors } from '../../../../styles/theme';
import { flex, topLineHeader, topLineHeaderBread } from '../../../../styles/utils';
import { CustomerTypeEnum, ICustomer } from '../../../../types/customers';
import { MoreMenuWrapper } from './MoreMenuWrapper';
import { TranslationEn } from '../../../../../../assets/i18n/en';
import { generateMoreMenuProps } from '../../../../pages/customers/CustomerList/lib';
import { NotesPopupCustomerWrapper } from '../../NotesPopupCustomerWrapper';
import { AUTO_CHECK_IN_TOKEN, NOTE_POPUP_LABELS } from '../../consts';
import { CustomerCheckInModal } from '@app/react/components/customers/customerPage/Header/CustomerCheckInModal';
import { useAttendance } from '@app/react/hooks/useAttendance';

const leftPartContainer = css`
	display: flex;
	flex-direction: column;
`;

const balanceContainer = css`
	background: white;
	padding: 1rem;
	text-align: left;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 1.6rem;
	line-height: 20px;
	color: ${colors.brandPrimary};
	span {
		color: ${colors.statusGreen};
	}
`;
const checkInButtonCss = css`
	margin-left: 20px;
	margin-right: 20px;
	justify-content: space-between !important;
	font-size: 14px !important;
	svg {
		margin-right: 8px;
		width: 16px;
		height: 16px;
	}
`;

interface IProps {
	id: string;
	customerState: ICustomer;
	tabs: ISingleTab[];
}

export const CustomerHeader = ({ customerState, tabs, id }: IProps) => {
	const [breadCrumbSteps, setBreadCrumbSteps] = useState([]);
	const { isShowing: showCheckInModal, toggle: toggleCheckin } = useModal();
	const location = useLocation();
	const labels = TranslationEn.customers;

	const moreMenuProps = generateMoreMenuProps(customerState);
	const { fetchAttendance, attendance } = useAttendance(customerState.id, customerState.organizationId);

	const lastCheckinDate = attendance?.[0]?.checkinDate.toString();

	useEffect(() => {
		fetchAttendance(customerState.entityId);
	}, [customerState.organizationId, customerState.entityId]);

	useEffect(() => {
		setBreadCrumbsPaths(location as any, setBreadCrumbSteps);
	}, [location]);

	useEffect(() => {
		const token = AUTO_CHECK_IN_TOKEN(customerState.id);
		if (location.search.includes(token)) {
			toggleCheckin();
			//remove token from url so a page refresh doesn't trigger the check in again
			const url = new URL(window.location.href.replace(token, ''));
			window.history.replaceState({}, '', url.toString());
		}
	}, [location, location.search, customerState.id]);

	return (
		<HeaderContainer isColumn>
			<div data-aid="customerPageHeader-bread" css={topLineHeaderBread}>
				<Breadcrumbs steps={breadCrumbSteps} />
			</div>
			<div data-aid="customerPageHeader-top" css={topLineHeader}>
				<SideContainer>
					<div css={leftPartContainer}>
						<div data-aid="customerPageHeader-title" css={flex}>
							<HeaderTitle>
								{customerState.firstName} {customerState.lastName}
							</HeaderTitle>
							<CustomerTagMapper entityType={customerState.entityType} familyStatus={customerState.familyStatus} />
							{customerState.isEmployee ? <CustomerTagMapper entityType={CustomerTypeEnum.EMPLOYEE} familyStatus={customerState.familyStatus} /> : null}
						</div>
						<AccountActivity
							id={customerState.id}
							lastActivityDate={customerState.updatedAt}
							lastCheckinDate={lastCheckinDate}
						/>
					</div>
				</SideContainer>
				<SideContainer>
					<div data-aid="customerPageHeader-side" css={flex}>
						<div data-aid="customerPageHeader-balance" css={balanceContainer}>
							{labels.listColumns.creditBalance}: <span>{Pricify(Number(customerState.storedCredit))}</span>
						</div>
						<Button
							data-aid="button-customerHeader-check-in"
							sizer="L"
							theme="primary"
							css={checkInButtonCss}
							onClick={toggleCheckin}
						>
							<BnIcon icon={Icons.check_in} />
							{labels.customerHeader.checkInButtonText}
						</Button>
						<NotesPopupCustomerWrapper labels={NOTE_POPUP_LABELS} customerState={customerState} />
						<MoreMenuWrapper {...moreMenuProps} />
					</div>
				</SideContainer>
			</div>
			<Tabs tabs={tabs} id={id} />
			{showCheckInModal && (
				<CustomerCheckInModal
					customerState={customerState}
					userId={customerState.entityId}
					organizationId={customerState.organizationId}
					toggle={toggleCheckin}
				/>
			)}
		</HeaderContainer>
	);
};
