import React, { FC, useEffect, useMemo } from 'react';
import { useColors } from '../../hooks/useColors';
import { ThemeProvider, Global } from '@emotion/react';

import { IColors, IBnProviderSettings } from '../../types';
import { RecoilRoot, useRecoilState } from 'recoil';
import { usePermissions } from '../../hooks/usePermissions';
import { Store } from '../../store';

export const BnThemeProvider: FC<IBnProviderSettings> = ({
	children,
	colors,
	fetchPermissios,
	origin = '',
	isWhiteLabeld = false,
}) => {
	const { colors: ThemeColors, setColors } = useColors();
	const { getPermissions } = usePermissions();
	const [isWhite, setWhiteLabeled] = useRecoilState(Store.isWhiteLabeld);

	useEffect(() => {
		setWhiteLabeled(isWhiteLabeld);
	}, [isWhiteLabeld]);

	useEffect(() => {
		if (fetchPermissios) {
			getPermissions(fetchPermissios, origin);
			window.addEventListener('focus', () => getPermissions(fetchPermissios, origin));

			return () => {
				window.removeEventListener('focus', () => getPermissions(fetchPermissios, origin));
			};
		}
		return;
	}, []);

	return <ThemeProvider theme={ThemeColors as IColors}>{children}</ThemeProvider>;
};

const whiteLabeledPalette = {
	bg_system: '#503ee9',
	bg_text_primary: '#2e19dc',
	bg_text_secondary: '#503ee9',
	bg_text_teriaty: '#7a6cee',
	bg_white: '#FFFFFF',
	bg_text_disabled: '#a49af4',
	bg_border_primary: '#c8c9cb',
	bg_border_seperator: '#dadcdf',
	bg_background_primary: '#F3F7FA',
	bg_background_secondary: '#FAFCFD',
	bg_background_overlay: '#7F7F7F',
	bg_background_fields: 'rgba(13,71,116,0.04)',
	bg_error: '#e02020',
	tx_system: '#F7B500',
	tx_text_primary: '#3d4043',
	tx_text_secondary: '#606367',
	tx_text_teriaty: '#81868a',
	tx_white: '#FFFFFF',
	tx_text_disabled: '#c8c9cb',
	tx_border_primary: '#c8c9cb',
	tx_border_seperator: '#dadcdf',
	tx_background_primary: '#f1f3f4',
	tx_background_secondary: '#fafafa',
	tx_background_overlay: '#7F7F7F',
	tx_background_fields: 'rgba(13,71,116,0.04)',
	tx_error: '#e02020',
	bg_system_gradient: '#503ee9',
	t_green: '#24C875',
	b_green: '#E5F8EE',
	opacityBackground: '#503ee9',
	bg_background_disabled: '#3D40430A',
};

const globalCss = `
  .skeleton {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    background: #e4e4e4;
    border-radius: 6px;
  }
  @-webkit-keyframes pulse {
    50% {
      opacity: 0.5;
    }
  }

  @keyframes pulse {
    50% {
      opacity: 0.5;
    }
  }
`;

export const BnProvider: FC<IBnProviderSettings> = ({
	children,
	colors,
	fetchPermissios,
	origin,
	isWhiteLabeld = false,
}) => {
	const newColors = useMemo(() => {
		if (isWhiteLabeld) {
			return { ...whiteLabeledPalette, ...colors };
		} else {
			return colors;
		}
	}, [colors, isWhiteLabeld]);

	return (
		<RecoilRoot override={false}>
			<BnThemeProvider
				origin={origin}
				fetchPermissios={fetchPermissios}
				colors={newColors}
				isWhiteLabeld={isWhiteLabeld}
			>
				<Global styles={globalCss} />
				{children}
			</BnThemeProvider>
		</RecoilRoot>
	);
};
