/** @jsxRuntime classic */
/** @jsx jsx */

import { useEffect, useMemo, useState } from 'react';
import { useTheme, jsx } from '@emotion/react';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';

import { Droppable } from '../Droppable';
import { useMiddlewareContext } from '../../hooks/useMiddlewareContext';
import { WeeklyDraggable } from '../weekly/WeeklyDraggable';
import { MoreSlotsButton } from '../MoreSlotsButton';
import { MoreSlotsList } from '../MoreSlotsList';
import { LoadingContainer } from '../LoadingContainer';
import { groupEventsByDate } from '../../utils';
import { IWeekToDropProps } from '../../types';
import {
	monthlyDateContainer,
	monthlyViewContainer,
	spaceWidth,
	weekDayContainer,
	weekDayEventContainer,
} from '../styles';
import { ETimes } from '../../../types/enums/times';

dayjs.extend(isoWeek);

export const MonthlyView = ({ spaceId, EventComponent, isLoading }: IWeekToDropProps) => {
	const colors = useTheme();
	const { options, eventsToSpaces } = useMiddlewareContext();
	const { date: optionsDate, isSundayFirstDay } = options;
	const dayShift = isSundayFirstDay ? 1 : 0;
	const today = dayjs.utc(optionsDate);
	const currentMonth = today.month();

	const { dates, withSixth } = useMemo(() => {
		let result = [] as dayjs.Dayjs[];
		let withSixth = true;

		for (let i = 1; i < 7 * 6 + 1; i++) {
			const date = today.set(ETimes.DATE, 1).isoWeekday(i - dayShift);

			// dont render other days if 1st day of 6th week is next month
			if (i === 36 && currentMonth !== date.month()) {
				withSixth = false;
				break;
			}

			result.push(date);
		}

		return { dates: result, withSixth };
	}, [dayShift, optionsDate]);

	const weekDaysWithEvents = useMemo(() => {
		return groupEventsByDate(dates, eventsToSpaces, +spaceId);
	}, [dates, eventsToSpaces, spaceId]);

	const [modals, setModals] = useState<boolean[]>([]);

	useEffect(() => {
		setModals(Array(dates.length).fill(false));
	}, [dates.length]);

	const handleToggleModal = (index: number) => {
		let newModals = modals.slice();
		newModals[index] = !newModals[index];
		setModals(newModals);
	};

	let maxVisibleSlotsPerDay = 1;
	const todayContainer = document.getElementById('today-container');
	if (todayContainer) {
		const height = todayContainer.clientHeight - 20;
		maxVisibleSlotsPerDay = Math.floor(height / 18) + 1;
	}

	return (
		<div css={monthlyViewContainer} data-aid="MonthlyView-monthly">
			{weekDaysWithEvents.map((weekDay, index) => {
				const { date: weekDayDate, events: weekDayEvents } = weekDay;
				const droppableId = `${spaceId}_${weekDayDate.toISOString()}`;
				const withModal = weekDayEvents.length > maxVisibleSlotsPerDay;
				const items = withModal ? weekDayEvents.slice(0, maxVisibleSlotsPerDay - 1) : weekDayEvents;
				const moreItemsCount = weekDayEvents.length - (maxVisibleSlotsPerDay - 1);
				const isCurrentMonth = today.isSame(weekDayDate, ETimes.MONTH);
				const currentDay = dayjs().isSame(weekDayDate, ETimes.DATE);
				const isSeventh = (index + 1) % 7 === 0;

				return (
					<Droppable
						weekly
						dragging={false}
						id={droppableId}
						key={droppableId + index}
						height={`calc(100% / ${withSixth ? 6 : 5})`}
						maxWidth={`max(100% / 7, ${spaceWidth}px )`}
					>
						<div css={monthlyDateContainer(colors, isCurrentMonth, currentDay, isSeventh)}>
							<div className="date">{weekDayDate.format('DD')}</div>
							<div
								id={currentDay ? 'today-container' : undefined}
								css={weekDayContainer(colors, undefined, 'calc(100% - 16px)')}
							>
								{isLoading ? (
									<LoadingContainer />
								) : (
									items.map(event => (
										<WeeklyDraggable parentId={droppableId} state={event} key={event.id} id={event.id}>
											<div css={weekDayEventContainer} key={event.id}>
												<EventComponent event={event} key={`EventComponent-${event.id}`} />
											</div>
										</WeeklyDraggable>
									))
								)}
								{withModal && (
									<MoreSlotsButton
										isWeekly
										colors={colors}
										handleToggleModal={() => handleToggleModal(index)}
										moreItemsCount={moreItemsCount}
									/>
								)}
								{withModal && modals[index] && (
									<MoreSlotsList
										colors={colors}
										EventComponent={EventComponent}
										group={{ items: weekDayEvents }}
										handleToggleModal={handleToggleModal}
										index={index}
									/>
								)}
							</div>
						</div>
					</Droppable>
				);
			})}
		</div>
	);
};
