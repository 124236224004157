import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '../services/auth/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(
		private router: Router,
		private auth: AuthenticationService
	) {}

	canActivate(): boolean {
		const isLoggedIn: boolean = this.auth.isSignedInAndUserDetailsSubmitted();

		if (!isLoggedIn) {
			window.location.assign('/login');
			return false;
		}

		return true;
	}
}
