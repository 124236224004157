import { IPayment, PaginationResultDto } from "@bondsports/types";
import { Pricify } from "@bondsports/utils";
import { PaymentColumnDef } from "./columns";

export const paymentsParser = (data: PaginationResultDto<IPayment>): PaymentColumnDef[] => {
  return data.data.map((payment) => ({
    createdAt: payment.createdAt,
    id: payment.id,
    total: Pricify(payment.total),
    paymentMethod: payment.paymentMethod,
    status: payment.status,
    invoices: payment.invoices,
  }));
}