/** @jsxRuntime classic */
/** @jsx jsx */

import { Fragment, useMemo } from 'react';
import { jsx } from '@emotion/react';
import { spaceColumnContainer, spaceRowContainer } from '../../styles';
import { ECalendarView, IWeeklyViewProps } from '../../types';
import { WeekToDrop } from './WeekToDrop';
import { useMiddlewareContext } from '../../hooks/useMiddlewareContext';
import { sumBy } from 'lodash';

export const WeeklyView = ({ spaces, EventComponent, isLoading }: IWeeklyViewProps) => {
	const { options } = useMiddlewareContext();
	const { view = ECalendarView.VERTICAL } = options || {};
	const horizontal = view === ECalendarView.HORIZONTAL;
	const spacesLength = spaces?.length;
	const columnsCount = useMemo(() => {
		return sumBy(spaces, (space) => space.children?.length || 1);
	}, [spaces]);

	return (
		<Fragment>
			{spaces?.map(space => {
				const currentChildrenLength = space.children?.length;
				const weeklyContainerCss = horizontal ? spaceRowContainer(spacesLength) : spaceColumnContainer(columnsCount, currentChildrenLength || 1);
					return (
						<div
							key={space.id}
							css={weeklyContainerCss}
							data-aid="WeeklyView-weekly"
						>
							{currentChildrenLength === 0 ?
								<WeekToDrop
									key={space.id}
									spaceId={String(space.id)}
									EventComponent={EventComponent}
									isLoading={isLoading}
								/>
								: space.children.map(item =>
									(
										<WeekToDrop
											key={item.id}
											spaceId={String(item.id)}
											EventComponent={EventComponent}
											isLoading={isLoading}
										/>
									)
								)
							}
						</div>
					);
			})}
		</Fragment>
	);
};
