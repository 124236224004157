/** @jsx jsx */
import React from 'react';
import { CognitoProvider, Login } from 'auth';
import { TranslationEn } from '@assets/i18n/en';
import { useNavigation } from '@app/react/hooks/useNavigation';
import { CookiesWorker } from '@app/shared/services/auth/CookiesWorker';
import { LoginRoutes } from '../lib/routes';
import { environment } from '../../../../../environments/environment';
import { LogoSvg } from '@app/react/components/shared/Logo';
import { css, jsx } from '@emotion/react';
import { mobileOnly } from '@app/react/styles/utils';
import { useMediaQuery } from '@material-ui/core';
import { colors_v2 } from '@app/react/styles/theme';

const LABELS = {
	login: TranslationEn.authentication.login,
	resetPassword: TranslationEn.authentication.resetPassword,
	chooseEmail: TranslationEn.authentication.chooseEmail,
	changePassword: TranslationEn.authentication.changePassword,
	confirm: TranslationEn.authentication.confirm,
};

const containerCss = css`
  	display: grid;
  	grid-template-columns: 0.45fr 1fr;
		background: linear-gradient(to right, black, ${colors_v2.bg_text_primary});
    height: 100%;
		
		${mobileOnly} {
      	display: flex;
				flex-direction: row;
		}
		
		@media screen and (max-height: 500px) {
        height: max-content;
    }
		
		width: 100dvw;
`;

const formContainerCss = css`
    border-color: ${colors_v2.bg_border_primary};
    border-width: 2px;
    padding: 5rem;
    border-radius: 0 15px 15px 0;
		
		${mobileOnly} {
				padding: 3rem;
				border-radius: 0;
				border-width: 0;
				flex-grow: 1;
				width: 100%;
    }
		
	grid-column: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: white;

`;

const logoContainerCss = css`
		display: flex;
		justify-content: center;
		align-items: center;
		grid-column: 2;
`;

const ERRORS = {
	generic: TranslationEn.genericError,
};

export const LoginPage: React.FC = () => {
	const { ngNavigate } = useNavigation();
	const isMobile: boolean = useMediaQuery(mobileOnly);

	const onSuccess = (data: any) => {
		if (CookiesWorker.hasBondUserDetailsSubmitted()) {
			ngNavigate(...LoginRoutes.calendar);
		} else {
			ngNavigate(...LoginRoutes.editEmployee(data.user.id));
		}
	};

	return (
		<CognitoProvider host={environment.CS_URLS.AUTH_ROOT} errors={ERRORS}>
			<main css={containerCss}>
				<section css={formContainerCss}>
					<Login labels={LABELS} onSuccess={onSuccess} />
				</section>
				{
					!isMobile && <section css={logoContainerCss}>
						<LogoSvg />
					</section>
				}
			</main>
		</CognitoProvider>
	);
};
