import * as Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { USER_DETAILS_COOKIE_NAME, BACKOFFICE_DATA_ADDED, VerificationResult } from './types';

const SECONDS_TO_MILLISECONDS_MULTIPLIER = 1000;

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class CookiesWorker {
	private static bondUserDetails: VerificationResult;

	public static hasBondUserDetails(): boolean {
		return !!CookiesWorker.getBondUserDetails();
	}

	public static getBondUserEmail(): string | undefined {
		const userDetails = CookiesWorker.getBondUserDetails();
		return userDetails?.email;
	}

	public static getBondUserDetails(): VerificationResult | undefined {
		if (CookiesWorker.bondUserDetails && CookiesWorker.validateUserDetails(CookiesWorker.bondUserDetails)) {
			return CookiesWorker.bondUserDetails;
		}

		const token: string = CookiesWorker.getBondUserDetailsCookie();

		if (!token) {
			return;
		}

		try {
			const decodedToken: VerificationResult = jwtDecode(token);

			if (!CookiesWorker.validateUserDetails(decodedToken)) {
				return;
			}

			CookiesWorker.bondUserDetails = decodedToken;
			return decodedToken;
		} catch (e) {
			console.info('Error while decoding token', e);
			return;
		}
	}

	private static validateUserDetails(userDetails: VerificationResult): boolean {
		const now = new Date();
		const tokenExpirationDate = new Date(userDetails.exp * SECONDS_TO_MILLISECONDS_MULTIPLIER);

		return tokenExpirationDate >= now;
	}

	public static hasBondUserDetailsSubmitted(): boolean {
		const userDetails: VerificationResult = CookiesWorker.getBondUserDetails();
		return !!userDetails?.[BACKOFFICE_DATA_ADDED];
	}

	private static getBondUserDetailsCookie(): string | undefined {
		return Cookies.get(USER_DETAILS_COOKIE_NAME);
	}

	/**
	 * Clears the user details cookie.
	 */
	static clearUserDetailsCookie() {
		CookiesWorker.bondUserDetails = undefined;
		Cookies.remove(USER_DETAILS_COOKIE_NAME);
	}
}
