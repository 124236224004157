/** @jsx jsx */

import React, { FC, HTMLProps, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { colors } from '../../../styles/theme';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
const container = (active: boolean, backgroundColor) => css`
	border: 1px solid ${active ? colors.brandPrimary : colors.borderPrimary};
	border-radius: 2px;
	background: ${backgroundColor ??  "none"};
	display: flex;
	padding: 0;
	align-items: center;
	margin: 5px;
	color: ${colors.brandPrimary};
	&:hover {
		background: ${colors.lightGray};
	}
`;

const selectButton = (active: boolean, disabled: boolean, backgroundColor) => css`
	pointer-events: ${disabled ? 'none' : 'auto'};
	display: flex;
	align-items: center;
	padding: 0.5rem 1rem;
`;

const deleteButton = (backgroundColor) => css`
	display: flex;
	align-items: center;
	padding: 0 1rem 0 0;
`;
interface Props {
	active?: boolean;
	disabled?: boolean;
	backgroundColor?: string;
	onClick: () => void;
	onDelete?: () => void;
}
export const CheckboxButton: FC<Props> = ({ children, backgroundColor, active = false, disabled = false, ...props }) => {
	return (
		<div css={container(active, backgroundColor)}>
			<button data-aid="button-checkboxButton" css={selectButton(active, disabled, backgroundColor)} onClick={props.onClick}  type="button">
				{children}
			</button>
			{ props.onDelete && (
				<button css={deleteButton(backgroundColor)} onClick={props.onDelete}>x</button>
			)}
		</div>
	);
};
