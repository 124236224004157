/* @jsx jsx */

import React, { useCallback } from 'react';
import { css, jsx } from '@emotion/react';
import {
	BnIcon,
	Button,
	ETypography,
	ETypographyColor,
	ExpandableSection,
	Icons,
	IconWithTooltip,
	Pricify,
	Typography,
} from '@bondsports/utils';
import {
	flexCol,
	flexRowCss,
	gapCss,
	paddingBottomCss,
	paddingLeftCss,
	paddingRightCss,
	paddingTopCss,
	sidePaddingCss,
} from '../../../../../styles/utils';
import { separatorCss } from '../../styles';
import { colors_v2 } from '../../../../../styles/theme';
import { formatDate, setTimezone } from '../../../../../lib/dates';
import { EDateTimeFormats } from '../../../../../types/times';
import { capitalizedFullName } from '../../../../../lib/utils';
import { capitalize } from 'lodash';
import { TranslationEn } from '../../../../../../../assets/i18n/en';
import { OrderByEnum, PaymentStatusEnum, SimpleResourceDto } from '@bondsports/types';
import { convertToPaymentStatus } from '../../../../../lib/payment';
import { SportItem } from '../../../../../types/sports';
import { ProgramDetailsCardProps } from './';
import { ResourcesList } from '../utils/ResourcesList';
import { ProgramEventDynamicList } from './ProgramEventDynamicList';
import { userApi } from '../../../../../lib/api/userApi';
import { ProgramSegmentRow } from './ProgramSegmentRow';
import { ProgramEventRow } from './ProgramEventRow';

const PAYMENT_STATUS_TO_LABEL_MAPPER = TranslationEn.paymentStatusToLabelMapper;
const PAYMENT_STATUS_TO_COLOR_MAPPER = TranslationEn.paymentStatusToColorMapper;

const SHOW_PROGRAM_PRICE = false;
const DISABLE_SELECTION = true;

const flexRowWithGapCss = (gap: number) => css`
	${flexRowCss};
	${gapCss(gap)};
`;

type ProgramExpendableSectionProps = Pick<
	ProgramDetailsCardProps,
	'data' | 'displayUserDetails' | 'labels' | 'onViewInvoice' | 'onViewPayment' | 'timezone'
> & {
	getSportById: (id: number) => SportItem;
	toggle: () => void;
	setResourcesView: (resources: SimpleResourceDto[]) => void;
};

export const ProgramExpendableSection: React.FC<ProgramExpendableSectionProps> = ({
	labels,
	timezone,
	onViewPayment,
	onViewInvoice,
	data,
	displayUserDetails,
	getSportById,
	setResourcesView,
	toggle,
}) => {
	const {
		facility,
		user,
		resources,
		session,
		productUser: {
			id: productUserId,
			productName,
		},
		financialInfo,
		isSegment,
		organizationId,
		userId,
		id,
	} = data;
	const sport = getSportById(data.sport);

	const paymentStatus: PaymentStatusEnum = convertToPaymentStatus(
		financialInfo.paymentStatus,
		financialInfo.isVoided,
		financialInfo.isRefunded
	);

	const fetchSegments = useCallback(
		async (itemsPerPage: number, startDate: Date, endDate: Date, orderBy: OrderByEnum) => {
			return await userApi.getUserProgramSegments(
				organizationId,
				userId,
				id,
				productUserId,
				itemsPerPage,
				startDate,
				endDate,
				orderBy
			);
		},
		[organizationId, userId, productUserId, id]
	);

	const fetchEvents = useCallback(
		async (itemsPerPage: number, startDate: Date, endDate: Date, orderBy: OrderByEnum) => {
			return await userApi.getUserProgramEvents(
				organizationId,
				userId,
				id,
				productUserId,
				itemsPerPage,
				startDate,
				endDate,
				orderBy
			);
		},
		[organizationId, userId, productUserId, id]
	);

	const setResourcesToView = useCallback(
		(resources: SimpleResourceDto[]) => {
			setResourcesView(resources);
			toggle();
		},
		[setResourcesView, toggle]
	);

	return (
		<ExpandableSection titleOnShow={labels.defaultOnHide} titleOnHide={labels.defaultOnExpended}>
			<div
				data-aid="ProgramExpendableSection"
				datatype="expendable"
				data-testid="program-expendable-details"
				css={[flexCol, gapCss(20), paddingTopCss(28), paddingBottomCss(16)]}
			>
				{displayUserDetails && (
					<div css={flexRowWithGapCss(8)}>
						<BnIcon icon={Icons.profile_outline} color={colors_v2.bg_text_primary} width={16} height={16} />
						<Typography
							datatype="label"
							data-testid="user-name"
							type={ETypography.body2}
							color={ETypographyColor.primary}
						>
							{capitalizedFullName(user.firstName, user.lastName)}
						</Typography>
					</div>
				)}
				<div css={flexRowWithGapCss(8)}>
					<BnIcon icon={Icons.cart} color={colors_v2.bg_text_primary} width={16} height={16} />
					<Typography
						datatype="label"
						data-testid="product-name"
						type={ETypography.body2}
						color={ETypographyColor.primary}
					>
						{capitalize(productName)}
					</Typography>
				</div>
				<div css={[flexRowCss]}>
					<div css={[flexRowWithGapCss(8), separatorCss('right'), paddingRightCss(16)]}>
						<BnIcon icon={Icons.other_payment} color={colors_v2.bg_text_primary} width={16} height={16} />
						<Typography
							datatype="date"
							data-testid="purchse-date"
							type={ETypography.body2}
							color={ETypographyColor.primary}
						>
							{formatDate(setTimezone(financialInfo.purchasedOn, timezone), EDateTimeFormats.DAY_FORMAT)}
						</Typography>
					</div>
					<div css={[flexRowWithGapCss(8), separatorCss('right'), sidePaddingCss(16)]}>
						<span
							datatype="tooltip"
							data-testid="payment-status"
							data-status={PAYMENT_STATUS_TO_LABEL_MAPPER[paymentStatus]}
						>
							<IconWithTooltip
								icon={Icons.indications_payment}
								text={PAYMENT_STATUS_TO_LABEL_MAPPER[paymentStatus]}
								color={PAYMENT_STATUS_TO_COLOR_MAPPER[paymentStatus]}
								size={16}
							/>
						</span>
						{SHOW_PROGRAM_PRICE && (
							<Typography type={ETypography.body2} color={ETypographyColor.primary}>
								{Pricify(financialInfo.price)}
							</Typography>
						)}
					</div>
					<div css={[flexRowWithGapCss(16), paddingLeftCss(16)]}>
						<Button
							data-aid="button-ProgramExpendableSection-invoice"
							datatype="button"
							data-testid="invoice-link"
							theme="basicLine"
							sizer="S"
							onClick={() => onViewInvoice(financialInfo.invoiceId)}
						>
							<Typography type={ETypography.captionLink} color={ETypographyColor.secondary}>
								{labels.viewInvoice}
							</Typography>
						</Button>
						<Button
							data-aid="button-ProgramExpendableSection-link"
							theme="basicLine"
							sizer="S"
							disabled={!financialInfo.paymentId}
							onClick={() => onViewPayment(financialInfo.paymentId)}
							datatype="button"
							data-testid="payment-link"
						>
							<Typography
								type={ETypography.captionLink}
								color={financialInfo.paymentId ? ETypographyColor.secondary : ETypographyColor.disabled}
							>
								{labels.viewReceipt}
							</Typography>
						</Button>
					</div>
				</div>
				<div css={flexRowWithGapCss(8)}>
					<BnIcon icon={Icons.location} color={colors_v2.bg_text_primary} width={16} height={16} />
					<Typography
						datatype="label"
						data-testid="facility-name"
						type={ETypography.body2}
						color={ETypographyColor.primary}
					>
						{capitalize(facility?.name ?? '')}
					</Typography>
				</div>
				<div css={flexRowWithGapCss(8)}>
					<BnIcon icon={Icons.whistle} color={colors_v2.bg_text_primary} width={16} height={16} />
					<Typography
						datatype="label"
						data-testid="activity-type"
						type={ETypography.body2}
						color={ETypographyColor.primary}
					>
						{capitalize(sport?.label)}
					</Typography>
				</div>
				<div css={flexRowWithGapCss(8)}>
					<BnIcon
						icon={Icons.court}
						color={resources?.length ? colors_v2.bg_text_primary : colors_v2.bg_text_secondary}
						width={16}
						height={16}
					/>
					<ResourcesList resources={resources} setResourceToView={setResourcesToView} labels={labels} />
				</div>
				<ProgramEventDynamicList
					sessionStartDate={session.startDate}
					sessionEndDate={session.endDate}
					labels={labels as any}
					fetcher={isSegment ? fetchSegments : fetchEvents}
					timezone={timezone}
				>
					{({ data, index, isLastItem }) => {
						if (isSegment) {
							return (
								<ProgramSegmentRow
									data={{ ...data, organizationId, userId, productUserId, isSegment: true }}
									index={index}
									isLastItem={isLastItem}
									labels={labels}
									isSelected={!DISABLE_SELECTION}
									isDisabled
									timezone={timezone}
								/>
							);
						}

						return (
							<ProgramEventRow
								data={{ ...data, organizationId, userId, productUserId, isSegment: false }}
								index={index}
								isLastItem={isLastItem}
								labels={labels}
								isSelected={!DISABLE_SELECTION}
								isDisabled
								timezone={timezone}
							/>
						);
					}}
				</ProgramEventDynamicList>
			</div>
		</ExpandableSection>
	);
};
