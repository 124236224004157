import { ReasonOption } from "@app/react/types/reasons";
import { TranslationEn } from "@assets/i18n/en";
import { ReasonDto } from "@bondsports/types";

const reasonLabels = TranslationEn.memberships.members.pauseMemberships.pauseReasonsByKey;

export const mapReasonsToOptions = (reasons: ReasonDto[]): ReasonOption[] => {
    return reasons.map((reason) => ({
        id: reason.id,
        label: getReasonLebal(reason.reason),
        value: reason.id,
        type: reason.type,
    }));
};

export const getReasonLebal = (reasonKey: string) => {
    return reasonLabels[reasonKey] || reasonKey;
};
