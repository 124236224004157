import { PaginationMetaDto, ReasonDto } from '@bondsports/types';
import { organizationApi } from '../api/organizationApi';
import { isErrorResponse } from '@bondsports/utils';

export const fetchReasons = async <T>(
	organizationId: number,
	mapDataCallback: (data: ReasonDto[]) => T[]
): Promise<{ items: T[]; meta: PaginationMetaDto } | { err: string }> => {
	try {
		const response = await organizationApi.getPauseReasons(organizationId);

		if (isErrorResponse(response)) {  
            return { err: response.err };  
        }

		const items = mapDataCallback(response);

		return {
			items,
			meta: {
				totalItems: response.length,
				totalPages: 1,
				currentPage: 1,
				itemsPerPage: response.length,
			},
		};
	} catch (error) {
		return { err: String(error) };
	}
};
