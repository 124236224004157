/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';
import { jsx } from '@emotion/react';
import { SkeletonWrapper } from '../../components/skeletonWrapper';
import { loadingContainerCss, weekDayEventContainer } from './styles';

export const LoadingContainer = () => {
	const slots = [0, 1, 2];
	return (
		<div css={loadingContainerCss} data-aid="LoadingContainer-atoms">
			{slots.map(slot => {
				return (
					<SkeletonWrapper isLoading minHeight={12} key={slot}>
						<div css={weekDayEventContainer} data-testid="loading-week-and-month-slot">mock</div>
					</SkeletonWrapper>
				);
			})}
		</div>
	);
};
