/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { AlertsCard } from '../AlertsCard';
import { AlertsStatusEnum } from '@app/react/types/customers';
import { PaymentAlertRow } from './PaymentAlertRow';
import { TranslationEn } from '@assets/i18n/en';
import { PaymentAlert } from './types';
import { ReactNode } from 'react';

interface Props {
	status: AlertsStatusEnum;
	payments: PaymentAlert[];
	footer: ReactNode;
	canViewPayments?: boolean;
	isLoading?: boolean;
}
export const Payments = ({ payments, footer, status, canViewPayments = true, isLoading = false }: Props) => {
	const labels = TranslationEn.customers.overview.payments;

	if (!canViewPayments) {
		payments.forEach((payment) => {
			payment.handleRedirectToNote = undefined;
		});
	}

	return (
		<AlertsCard
			title={labels.title}
			status={status}
			footer={footer}
			main={<Main payments={payments} />}
			errorMessage={labels.errorMessage}
			isLoading={isLoading}
		/>
	);
};

const Main = ({ payments }: { payments: PaymentAlert[] }) => {
	return <GridTable payments={payments} />;
};

const GridTable = ({ payments }: { payments: PaymentAlert[] }) => {
	const gridContainerCss = css`
		display: grid;
		grid-template-columns: 3fr 4fr 3fr 3fr 1fr;
	`;

	return (
		<div css={gridContainerCss}>
			{payments.map((memberShip, index) => (
				<PaymentAlertRow key={index} {...memberShip} />
			))}
		</div>
	);
};
