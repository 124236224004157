/** @jsx jsx */

import { Icons } from 'app/react/styles/theme';
import React, { useState, Fragment, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import { FormDateInput } from '../../shared/FormControls/FormDateInput';
import { flex, marginLeftCss } from '../../../styles/utils';
import { Input } from 'bondsports-utils';
import { Select_v2 } from '@bondsports/utils';
import { selectWidthCss } from '@app/react/forms/activities/pricing/paymentPlans/styles';
import { FormWrapper } from '../../shared/form';
import { FrequencyEnum } from '@bondsports/date-time';
import { TranslationEn } from '@assets/i18n/en';
import { IRollingPaymentPlanState } from './types/types';

const container = css`
	margin: 2rem 3.75rem;
	max-width: 500px;
	min-height: 155px;
    max-height: 250px;
`;

const selectCss = css`
	width: 15rem;
`
const countCss = css`
	width: 7.5rem;
`

const ONE_INSTALLMENT = 1;

const labels = TranslationEn.payments.schedule.rolling;

const REPEAT_OPTIONS = [
	{ value: FrequencyEnum.WEEKLY, label: labels.duration.weekly },
	{ value: FrequencyEnum.MONTHLY, label: labels.duration.monthly },
];

interface Props {
	initialState: IRollingPaymentPlanState;
	onChange: (newState: IRollingPaymentPlanState) => void;
}
export const RollingPaymentPlan = ({
	initialState,
	onChange,
}: Props) => {
	const [repeatValue, setRepeatValue] = useState<string>(initialState.repeat);
	const [countValue, setCountValue] = useState<number | undefined | string>(initialState.count);
	const [repeatDisabled, setRepeatDisabled] = useState<boolean>(false);

	const onStartDateChange = (value) => {
		onChange({ startDate: value });
	}

	const onCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const count = e.target.value ? Math.abs(Number(e.target.value)) : undefined;

		if (count === ONE_INSTALLMENT) {
			setRepeatDisabled(true);
		} else {
			setRepeatDisabled(false);
		}

		setCountValue(count);
		onChange({ count: count });
	}

	const onClearCount = () => {
		setCountValue('');
		onChange({ count: undefined });
	}

	const onRepeatChange = (value) => {
		setRepeatValue(value);
		onChange({ repeat: value });
	}

	return (
		<div data-aid="addSchedulePayment-input-start" css={container}>
			<FormWrapper submit={ () => {return;}} >
				<div data-aid="addSchedulePayment-input-start" css={ flex}>
					<div css={[selectCss]}>
						<FormDateInput
							rightIcon={Icons.calendar}
							required
							name="startDate"
							sizer="S"
							enablePast={false}
							label={labels.startDate}
							initialValue={initialState.startDate}
							placeholder={labels.datePlaceholder}
							onChange={onStartDateChange}
						/>
					</div>
					<div css={[countCss, marginLeftCss(8)]}>
						<Input sizer="S" label={labels.count} name="count" type="number" min="0" onChange={onCountChange} value={countValue} clear={onClearCount} />
					</div>
					<div css={[selectWidthCss, marginLeftCss(8)]}>
						<Select_v2
							value={repeatValue}
							onSelecte={onRepeatChange}
							options={REPEAT_OPTIONS}
							isGroups={false}
							label={labels.repeat}
							disabled={repeatDisabled}
						/>
					</div>
				</div>
			</FormWrapper>
		</div>
	);
};