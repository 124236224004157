import { ESessionResourceSettingKey, ResourceSettingsDto } from '../../../types/resourceSettings';

export const numberOrNull = val => (val == null || isNaN(val) ? null : Number(val));

/**
 * Function to get the value of a specific resource setting by its key
 * @param {ResourceSettingsDto[]} resourceSettings - A list of resource settings
 * @param {ESessionResourceSettingKey} key - The key of the specific resource setting to get the value for
 * @returns The value of the resource setting if it exists, otherwise null
 */
export const getResourceSettingValueByKey = (
	resourceSettings: ResourceSettingsDto[],
	key: ESessionResourceSettingKey
) => {
	const resourceSetting = resourceSettings.find(rs => rs.key === key);
	return resourceSetting ? resourceSetting.value : null;
};
