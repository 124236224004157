export enum MembershipStatusEnum {
	ACTIVE = 'active',
	ACTIVE_CANCELLED = 'active_cancelled',
	CANCELLED = 'cancelled',
	EXPIRED = 'expired',
	PAUSE_PENDING = 'pause_pending',
	PAUSED = 'paused',
	PAUSED_CANCELLED = 'paused_cancelled',
	PENDING = 'pending',
}
