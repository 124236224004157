/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react";
import { Button } from "../../../components/Button";
import { css, jsx } from "@emotion/react";
import { BnIcon } from '../../../components/icons_v2';
import { IColors, Icons } from "../../../types";
import { SizeEnum } from "../../../lib/constants";

const closeButton = (colors: IColors) => css`
    display: flex;
    flex-direction: row-reverse;
    height: 1.4rem;
    width: 100%;
    background-color: ${colors.bg_white};
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 1.5rem 0rem 1.5rem 0rem;
`;

export interface ICloseLabels {
    ariaLabel: {
        close: string
    }
}

export const Close = ({
toggle,
    labels
} : {
    toggle: () => void,
    labels: ICloseLabels
}) => {
    return <div css={closeButton}>
        <Button data-aid="button-popupBody-toggle" sizer={SizeEnum.SMALL} theme="basic" onClick={toggle} aria-label={labels.ariaLabel.close}>
            <BnIcon icon={Icons.close} />
        </Button>
    </div>
}
