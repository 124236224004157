import { Component } from '@angular/core';
import { AuthenticationService } from '@app/shared/services/auth/authentication.service';
import { environment } from '../environments/environment';
import { PermissionsService } from '@app/shared/services/permissions/permissions.service';
import { Router } from '@angular/router';

@Component({
	selector: 'rc-root',
	templateUrl: './app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent {
	constructor(
		private authService: AuthenticationService,
		private permissionsService: PermissionsService,
		private router: Router
	) {
		if (!authService.isLoggedIn()) {
			this.navigateToLogin();
			return;
		}

		// update user permissions when tab get's visible again
		document.addEventListener('onload', () => this.updateUserPermissions(), false);

		this.authService.currentOrganization.subscribe(async response => {
			if (!response || localStorage.getItem('admin_token')) return;
			console.log('AppComponent - constructor');
			await this.permissionsService.getMyPermissions(response.id);
			console.log('Got permissions');

			const { firstName, lastName, id, email } = this.authService.getTokenData();

			const loggedUser = {
				user_id: id,
				name: `${firstName} ${lastName}`,
				email,
			};

			const organization = {
				company_id: response.id,
				name: response.name,
				created_at: response['createdAt'],
				email: response.email,
			};

			window['Intercom']('boot', {
				app_id: environment.INTERCOM_ID,
				...loggedUser,
				company: { ...organization },
			});
		});

		if (this.authService.isLoggedIn()) {
			this.authService.renewToken();
			this.authService.fetchActiveOrganization().subscribe({
				error: error => this.navigateToLogin(),
			});
		} else {
			if (!localStorage.getItem('admin_token')) {
				window['Intercom']('boot', {
					app_id: 'r8n5hkbk',
				});
			}
		}
	}

	private navigateToLogin() {
		this.router.navigate(['/login']);
	}

	async updateUserPermissions() {
		if (!document.hidden) {
			console.log('updateUserPermissions - updating permissions');
			await this.permissionsService.getMyPermissions(this.authService.currentOrganization.value.id);
			console.log('updateUserPermissions - permissions updated');
		}
	}
}
