import {
	Addon,
	AddSlotToSegmenResultDto,
	BaseInvoiceDto,
	Product,
	Slot,
	IValidateEditReservationAddonsResult,
	UpdatePricesTypeEnum,
	PlatformsEnum,
} from '@bondsports/types';

import { Reservation } from 'app/react/types/calendar';
import { Invoice } from 'app/react/types/checkout';
import { AddSegmentToReservationDto, IAddAddon, IRemoveAddonResp } from 'app/react/types/reservation';
import {
	AddSlotToSegmentDto,
	InvoiceDto,
	ReservationDto,
	SaveSlotToSegmentDto,
	SlotDto,
} from 'app/react/types/NewReservation';
import { environment } from '../../../../environments/environment';
import { network } from '../network';
import { IEditAddonReq, IEditAddonRes } from 'app/react/components/shared/EditAddonToReservation/types';
import { IErrorArr, IErrorObj } from '../../types/errors';

const v4APIUrl = `${environment.CS_URLS.API_ROOT_V4}`;

async function calculateAddons(
	organizationId: number,
	facilityId: number,
	data: ReservationDto
): Promise<{ reservation: ReservationDto; invoice: InvoiceDto } | { err: string[] }> {
	const response = await network.post(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/generateSlots`,
		data
	);
	return response;
}

async function updateAddons(
	organizationId: number,
	facilityId: number,
	data: ReservationDto
): Promise<{ reservation: ReservationDto; invoice: InvoiceDto }> {
	const response = await network.post(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/updateAddons`,
		data
	);
	return response;
}

async function updateInvoice(
	organizationId: number,
	facilityId: number,
	data: ReservationDto,
	isUpdateAddons = false,
	isUpdateMaintenance = false
): Promise<{ reservation: ReservationDto; invoice: InvoiceDto } | { err: string[] }> {
	const response = await network.post(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/updateInvoice?updateAddons=${isUpdateAddons}&updateMaintenance=${isUpdateMaintenance}`,
		data
	);
	return response;
}
export interface IUpdatePrices {
	reservation: ReservationDto;
	type: UpdatePricesTypeEnum;
	products?: { id: number; price: number }[];
	category?: { slot: number; addon: number; reservationAddon?: number };
	globalPrice?: number;
}

export interface IInvoiceSlots {
	slotsIds?: number[];
	addonsIds?: number[];
	platform?: PlatformsEnum;
	shiftId?: number;
	addToInvoiceId?: number;
}

async function updatePrices(
	organizationId: number,
	facilityId: number,
	data: IUpdatePrices
): Promise<{ reservation: ReservationDto; invoice: InvoiceDto }> {
	const response = await network.post(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/updatePrices`,
		data
	);
	return response;
}

async function holdBooking(
	organizationId: number,
	facilityId: number,
	data: { reservation: ReservationDto; slotType: string }
): Promise<{ reservation: ReservationDto }> {
	const response = await network.post(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/save`,
		data
	);
	return response;
}

async function getSlotData(
	reservationId: number,
	organizationId: number,
	slotId: number,
	includingProdact?: boolean
): Promise<Slot | { err: string[] }> {
	const response = await network.get(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/slot/${slotId}?addProduct=${includingProdact}`
	);
	return response;
}

async function updateSingleSlot(
	reservationId: number,
	organizationId: number,
	facilityId: number,
	slotId: number,
	data: SlotDto
) {
	const response = await network.put(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/reservation/${reservationId}/slot/${slotId}`,
		data
	);
	return response;
}

async function updatePartialSingleSlot(
	reservationId: number,
	organizationId: number,
	facilityId: number,
	slotId: number,
	data: SlotDto
) {
	const response = await network.put(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/reservation/${reservationId}/slot/${slotId}/partial`,
		data
	);
	return response;
}

type GenerateSlotRes = AddSlotToSegmenResultDto | IErrorObj;

async function generateSingleSlot(
	reservationId: number,
	organizationId: number,
	facilityId: number,
	segmentId: number,
	data: AddSlotToSegmentDto
): Promise<GenerateSlotRes> {
	const response = await network.post(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/reservation/${reservationId}/segment/${segmentId}/generateSlot`,
		data
	);
	return response;
}

async function saveSlotsToSegment(
	reservationId: number,
	organizationId: number,
	facilityId: number,
	segmentId: number,
	data: SaveSlotToSegmentDto
) {
	const response = await network.post(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/facility/${facilityId}/segment/${segmentId}/add-slots`,
		data
	);
	return response;
}

interface IUpdateSlotsAddonsResponse {
	slots: SlotDto[];
	invoice: InvoiceDto;
}

type UpdateSlotsAddonsRes = IUpdateSlotsAddonsResponse | IErrorArr;

async function updateSlotsAddons(organizationId: number, facilityId: number, data: any): Promise<UpdateSlotsAddonsRes> {
	return network.put(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/update-slots-addons`,
		data
	);
}

const invoiceSlots = async (organizationId: number, reservationId: number, data: IInvoiceSlots) => {
	const response = await network.post(
		`${v4APIUrl}/organization/${organizationId}/reservations/${reservationId}/invoice`,
		data
	);
	return response;
};

async function updateSlotsPrices(
	organizationId: number,
	facilityId: number,
	data: any
): Promise<{ slots: SlotDto[]; invoice: InvoiceDto } | { err: string[] }> {
	const response = await network.put(
		`${v4APIUrl}/reservations/organization/${organizationId}/facility/${facilityId}/update-slots-prices`,
		data
	);
	return response;
}

const deleteEventByIds = (organizationId: number, reservationId: number, eventIds: number[]) => {
	return network.delete(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/slots?slotsIds=${eventIds.join(',')}`,
		{}
	);
};

const getEventFinancialDataByIds = (organizationId: number, reservationId: number, eventIds: number[]) => {
	return network.get(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/removed-slots?slotsIds=${eventIds.join(',')}`,
		{}
	);
};


const deleteReservationAddon = (
	organizationId: number,
	reservationId: number,
	addonIds: number[]
): Promise<IRemoveAddonResp> => {
	return network.delete(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/addons?addonsIds=${addonIds.join(',')}`,
		{}
	);
};

const addReservationAddon = async (
	organizationId: number,
	reservationId: number,
	data: IAddAddon
): Promise<Addon[]> => {
	const { newAddons } = await network.post(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/addons`,
		data
	);
	return newAddons;
};

// const invoiceAddonItems = (
// 	organizationId: number,
// 	reservationId: number,
// 	data: IInvoiceAddon
// ): Promise<IUpdateReservationResult> => {
// 	return network.post(`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/invoice`, data);
// };

const getAddonsForReservation = (organizationId: number, reservationId: number): Promise<Product[]> => {
	return network.get(`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/addons-options`);
};

const postConfirmEditAddonResv = (
	organizationId: number,
	reservationId: number,
	data: IEditAddonReq
): Promise<IValidateEditReservationAddonsResult> => {
	return network.post(
		`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/validate-edit-addons`,
		data
	);
};

const putEditAddonResv = (
	organizationId: number,
	reservationId: number,
	data: IEditAddonReq
): Promise<IEditAddonRes> => {
	return network.put(`${v4APIUrl}/reservations/${reservationId}/organization/${organizationId}/addons`, data);
};

export const bookingV2Api = {
	calculateAddons,
	updateAddons,
	updatePrices,
	holdBooking,
	updateInvoice,
	getSlotData,
	updateSingleSlot,
	updatePartialSingleSlot,
	generateSingleSlot,
	saveSlotsToSegment,
	updateSlotsAddons,
	updateSlotsPrices,
	invoiceSlots,
	deleteEventByIds,
	deleteReservationAddon,
	addReservationAddon,
	getAddonsForReservation,
	postConfirmEditAddonResv,
	putEditAddonResv,
	getEventFinancialDataByIds
};
