/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import React, { useMemo } from "react"
import { IExpandedSlot } from "../types";
import { BnIcon } from '../../../components/icons_v2';
import { Typography } from "../../../atoms";
import { ETypography, ETypographyColor, IColors, Icons, sportsIcons } from "../../../types";
import { useColors } from "../../../hooks/useColors";

const secondaryBodyCss = (colors: IColors) => css`
    padding: 0.3rem 2rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 100%;
    gap: 0.3rem 2.6rem;
    border-top: .1rem solid ${colors.bg_border_seperator};
`;

const secondaryBodyLineCss = (colors: IColors, maxWidth:string) => css`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: ${maxWidth};
    margin: 1rem 0rem;
    gap: 0.3rem;
    svg {
        color: ${colors.tx_text_primary};
        width: 1.1rem;
        height: 1.1rem;
        flex: 0 0 auto;
    }
    div {
        display: block;
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis;
    }
`;

export interface ISecondaryBodyLabels {
    sportLabel: string
}

export const SecondaryBody = ({
    expandedSlot,
    labels
} : {
    expandedSlot: IExpandedSlot
    labels: ISecondaryBodyLabels
}) => {
    const { colors } = useColors();

    const TruncatedBodyLine = ({
        text,
        icon,
        width
    } : {
        text: string
        icon: Icons
        width: string
    }) => {
        const secondaryBodyLineStyle = useMemo(() => secondaryBodyLineCss(colors, width), [colors]);
        return <div css={secondaryBodyLineStyle}>
            <BnIcon icon={icon} />
            <Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
                {text}
            </Typography>
        </div>
    }

    const Activity = () => {
        return expandedSlot?.sportIds?.[0] !== undefined ? (
            <TruncatedBodyLine 
                text={labels.sportLabel}
                icon={sportsIcons[expandedSlot.sportIds[0]]}
                width={'12rem'}
                data-aid="popupBody-activity"
            />
        ) : null;
    };

    const Addons = () => {
		return expandedSlot?.addons && expandedSlot?.addons?.length > 0 ? (
            <TruncatedBodyLine 
                text={expandedSlot.addons.map(addon => addon?.productUser?.productName ?? null).join(', ')}
                icon={Icons.add_ons}
                width={'28rem'}
                data-aid="popupBody-addons"
            />
		) : null;
	};

    const secondaryBodyStyle = useMemo(() => secondaryBodyCss(colors), [colors]);
    return <div data-aid="popupBody-secondaryBody" css={secondaryBodyStyle}>
        <Activity />
        <Addons />
    </div>
}
