export enum MoreMenuEnumRole {
	DELETE = 'delete',
	EDIT = 'edit',
	DUPLICATE = 'duplicate',
	VIEW = 'view',
	RESET = 'reset',
}

export enum RoleType {
  Default = 'default',
  Custom = 'custom',
}
