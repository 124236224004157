/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { BnIcon, Button, Icons } from '@bondsports/utils';
import { TranslationEn } from '../../../../../assets/i18n/en';

interface IProps {
	handleBack: () => void;
	disabled?: boolean;
	hidden?: boolean;
}

const backBtnCss = (disable = false, hidden = false) => css`
	position: absolute;
	top: 10px;
	left: 10px;
	button {
		display: flex;
		gap: 4px;
		padding: 0px !important;
	}
	${disable && 'pointer-events: none;'}
	${hidden && 'visibility: hidden;'}
`;

export const BackButton = ({ handleBack, disabled, hidden }: IProps) => {
	return (
		<div css={backBtnCss(disabled, hidden)}>
			<Button data-aid="button-BackButton-left" theme="basic" sizer="S" disabled={disabled} onClick={handleBack}>
				<BnIcon icon={Icons.arrow_left} />
				{TranslationEn.general.buttons.back}
			</Button>
		</div>
	);
};
