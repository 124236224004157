import React from 'react';
import { TranslationEn } from 'assets/i18n/en';
import { customersApi } from '@app/react/lib/api/customersApi';
import { DataGrid, OrderByEnum, PageContentMain } from 'ui';
import { InvoiceColumnDef, invoicesColumns } from './columns';
import { invoicesParser } from './parser';
import { TCustomer } from '@app/react/types/customers';
import { useRedirect } from './hooks/useRedirect';

interface Props {
	customerState: TCustomer;
}

export const Invoices = ({ customerState }: Props) => {
	const redirectToInvoiceDetails = useRedirect();
	
	return (
		<PageContentMain>
			<DataGrid<InvoiceColumnDef>
				columns={invoicesColumns}
				apiMethod={customersApi.getInvoicesByCustomerId}
				apiArgs={[customerState.id]}
				apiResponseParser={invoicesParser}
				actionBarProps={{
					showSearch: true,
					showFilterMenu: true,
					showColumnVisibility: true,
				}}
				onRowClick={row => redirectToInvoiceDetails(row.id)}
				strings={{
					subject: TranslationEn.chooseInvoice.subject,
				}}
				defaultSortColumn="createdAt"
				defaultSortDirection={OrderByEnum.DESC}
			/>
		</PageContentMain>
	);
};
