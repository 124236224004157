import styled from '@emotion/styled';
import { IColors } from '../../types/theme';
import { TAllowedColors, TTypes } from './types';

export const TagColorsMapper = {
	yellow: {
		primary: '#F7B500',
		background: '#FEF4D9',
	},
	red: {
		primary: '#E02020',
		background: '#FBE4E4',
	},
	green: {
		primary: '#24C875',
		background: '#E5F8EE',
	},
	purple: {
		primary: '#A433C5',
		background: '#F4E7F8',
	},
	blue: {
		primary: '#4A90E2',
		background: '#EDF4FC',
	},
	gray: {
		primary: '#9B9B9B',
		background: '#E2E7EB',
	},
	darkGray: {
		primary: 'white',
		background: 'rgba(0,0,0,0.5)',
	},
	liteGray: { primary: '#C8C9CB', background: '#E2E7EB' },
	disabledGray: { primary: '#DBE4EB', background: '#E2E7EB' },
	tertiary: {
		primary: 'white',
		background: '#A6BCCC',
	},
	pink: {
		primary: '#E83D8F',
		background: '#FCE6F1',
	},
	turquoise: { primary: '#33C5C5', background: '#E5F8F8' },
	orange: {
		primary: '#FA771E',
		background: '#FEEDE2',
	},
};

export const StyledTag = styled.div<any>(
	({
		theme: colors,
		color,
		type,
		padding = '3px 4px',
		fontWeight = 600,
	}: { 
		theme: IColors;
		color: TAllowedColors; 
		type: TTypes;
		padding?: string;
		fontWeight?: number;
	}) => `
  background: ${type === 'outline' ? colors.bg_white : TagColorsMapper[color].background};
  color: ${TagColorsMapper[color].primary};
 ${
		type === 'outline' &&
		`
 	border: 1px solid ${TagColorsMapper[color].primary};
 `
 } 
  border-radius: 2px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: ${fontWeight};
  padding: ${padding};
  font-size: 1rem;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
  width: fit-content;
  font-style: normal;
  font-size: 10px;
  height: fit-content;
	white-space: nowrap;

  `
);
