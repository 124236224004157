import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { authApi } from '@app/react/lib/api/authApi';
import { ORGANIZATION_ID } from '@app/react/lib/localStorageKeys';
import { hasPermissions } from '@app/shared/services/permissions/permissions.service';
import { useOrganization } from '@app/react/hooks/useOrganization';
import { useCallback } from 'react';
import { BranchPermission } from '@bondsports/permissions';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

const PERMISSIONS_TTL_IN_MINUTES = 10;

/**
 * Custom hook for managing permissions using a Recoil store.
 * @returns An object containing the permission state and functions to manage permissions.
 */
const usePermissions = () => {
	const { organizationId } = useOrganization();
	const orgId = organizationId || Number(localStorage.getItem(ORGANIZATION_ID));

	const fetchPermissions = async () => {
		if (!orgId) {
			return [];
		}
		const { data } = await authApi.getMyPermissions(orgId);
		if (!data) {
			throw new Error('No permissions found');
		}
		return data;
	};

	const {
		data: userPermissions = [],
		isLoading,
		error,
	} = useQuery({
		queryKey: ['permissions', orgId],
		queryFn: fetchPermissions,
		staleTime: PERMISSIONS_TTL_IN_MINUTES * 60 * 1000,
		cacheTime: PERMISSIONS_TTL_IN_MINUTES * 60 * 1000,
		enabled: true,
	});

	const checkMultiplePermissions = (requiredPermissions: (string | BranchPermission)[][]): boolean[] => {
		return requiredPermissions.map(permissions => checkPermissions(permissions));
	};

	const checkPermissions = useCallback(
		(requiredPermissions: (string | BranchPermission)[]) => {
			const flatPermissions = requiredPermissions.map(permission => typeof permission === 'string' ? permission : permission.parent);
			return hasPermissions(userPermissions, flatPermissions);
		},
		[userPermissions]
	);

	return {
		userPermissions,
		isLoading,
		error,
		checkMultiplePermissions,
		checkPermissions,
	};
};

export default usePermissions;
