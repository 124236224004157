export enum PauseMembershipSteps {
    PAUSE_FORM = 0,
    PAUSE_SUMMARY = 1,
    PAUSE_HISTORY = 2,
}

export const enum FormsEnum {
	pause = 'pause',
}

