import React from "react"
import { TranslationEn } from "@assets/i18n/en"
import { ETypography, ETypographyColor, Typography } from "@bondsports/utils"
import { FAQ_URL } from "@app/react/types/urls";
import { useNavigatoToStation } from "@app/react/hooks/useNavigateToStation";

export const TerminalErrorTooltip = () => {
    const { handleStationRedirection } = useNavigatoToStation();

    const labels = TranslationEn.payments.terminalError;
    return (
        <div className="flex flex-col">
            <Typography type={ETypography.caption} color={ETypographyColor.secondary}>
                {labels.firstLine}
            </Typography>
            <Typography type={ETypography.caption} color={ETypographyColor.secondary}>
                {labels.secondLine}
                <Typography type={ETypography.captionLink} color={ETypographyColor.secondary}>
                    <a href={FAQ_URL} target="_blank">{labels.faqLink}</a>
                </Typography>
            </Typography>
            <Typography type={ETypography.caption} color={ETypographyColor.secondary}>
                {labels.thirdLine}
                <Typography type={ETypography.captionLink} color={ETypographyColor.secondary}>
                    <a href="/client/station#" target="_blank">{labels.stationSetup}</a>
                </Typography>
            </Typography>
        </div>
    )
}
