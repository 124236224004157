/** @jsx jsx */

import React, { useMemo } from 'react';
import { jsx, css } from '@emotion/react';
import { colors } from '../../../styles/theme';
import { EChargeInnerStep, IPayment } from '../../../types/payment';
import { Footer } from '../utils/footer';
import { TranslationEn } from 'assets/i18n/en';
import { Tabs, useMultiTabForm } from '@bondsports/utils';
import { ISingleTab } from '@bondsports/utils/build/src/molecules/tabs/types';
import { RollingPaymentPlan } from './RollingPaymentPlan';
import { useRollingPaymentPlan } from './hooks/useRollingPaymentPlan';
import { InvoiceCustomPaymentPlan } from './InvoiceCustomPaymentPlan';
import { useInvoiceCustomPaymentPlan } from './hooks/useInvoiceCustomPaymentPlan';
import { ICustomPaymentPlanState, InvoicePaymentPlanEnum, IRollingPaymentPlanState, IScheduledPaymentsState } from './types/types';
import { separatorCss } from '@app/react/styles/utils';

const container = css`
	width: 480px;
`;

const headerCss = css`
	display: flex;
	justify-content: center;
`

const tabContainerCss = css`
	width: 400px;
`

const labelCss = css`
	font-family: Montserrat;
	font-weight: 500;
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: ${colors.formInputBg};
`;

interface Props {
	toggle: () => void;
	totalAmount: number;
	previousState: IScheduledPaymentsState;
	setScheduledPaymentsState: (state: IScheduledPaymentsState) => void;
	setPayments: (arr: IPayment[]) => void;
	onNextClicked?: (val: EChargeInnerStep) => void;
}
export const AddSchedulePayment = ({
	toggle,
	totalAmount,
	previousState,
	setScheduledPaymentsState,
	setPayments,
	onNextClicked,
}: Props) => {
	const { rollingPlanState, isValidRollingPlan, OnRollingPlanChange, getRollingPaymentSchedule } = useRollingPaymentPlan(previousState?.rolling);
	const { customPlanState, isValidCustomPlan, OnCustomPlanChange, getCustomPaymentSchedule } = useInvoiceCustomPaymentPlan(previousState?.custom, totalAmount);

	const labels = TranslationEn.payments.schedule;

	const tabs: ISingleTab[] = [
		{ title: labels.rolling.title, link: InvoicePaymentPlanEnum.ROLLING.toString() },
		{ title: labels.custom.title, link: InvoicePaymentPlanEnum.CUSTOM.toString() },
	];

	const { currentTab, handleTabChange } = useMultiTabForm(previousState?.selectedTab || tabs[0].link);

	const tabsComponents = useMemo( () => 
		[
			{
				id: InvoicePaymentPlanEnum.ROLLING,
				component: (
					<RollingPaymentPlan initialState={rollingPlanState} onChange={OnRollingPlanChange} />
				),
			},
			{
				id: InvoicePaymentPlanEnum.CUSTOM,
				component: (
					<InvoiceCustomPaymentPlan initialState={customPlanState} totalAmount={totalAmount} onChange={OnCustomPlanChange} />	
				),
			},
		],
		[totalAmount, rollingPlanState, customPlanState]
	);

	const activeTabComponent = tabsComponents.find(tab => tab.id === currentTab).component;

	const onSubmit = () => {
		const payments: IPayment[] = currentTab === InvoicePaymentPlanEnum.ROLLING ? getRollingPaymentSchedule(totalAmount) : getCustomPaymentSchedule();

		setScheduledPaymentsState({rolling: rollingPlanState, custom: customPlanState, selectedTab: currentTab});

		setPayments(payments);
		onNextClicked(EChargeInnerStep.CHOOSE_PAYMENT_METHOD_TYPE);
	};

	const isValid = useMemo(() => {
		return currentTab === InvoicePaymentPlanEnum.ROLLING ? isValidRollingPlan : isValidCustomPlan;
	}, [currentTab, isValidRollingPlan, isValidCustomPlan]	);

		return (
		<div css={container}>
			<div css={[headerCss, separatorCss('bottom')]}>
				<div css={tabContainerCss}>
					<Tabs tabs={tabs} currentTab={currentTab} handleTabSelect={handleTabChange} fullWidthSpread hideMargins />
				</div>
			</div>
			<div>{activeTabComponent}</div>
			<Footer
				data-aid="addSchedulePayment-footer"
				toggle={toggle}
				disabled={!isValid}
				loader={false}
				submitButtonText={TranslationEn.memberships.footer.defaultNext}
				onSubmit={onSubmit}
			/>
		</div>
		
	);
};
