import {
	EBrandingColorsOptions,
	ETypography,
	ETypographyColor,
	Icons
} from './theme';
import type {
	IColors,
	ITypography,
	IBnProviderSettings,
	TButton,
	TSize,
} from './theme';

export * from './notes';

export interface InputProps {
	placeholder?: string;
	label?: string;
	info?: string;
	helper?: string;
	helperContent?: string;
	sizer: 'M' | 'S';
	insideLeftLabel?: string;
	leftIcon?: Icons;
	rightIcon?: Icons;
	error?: string | undefined;
	rightButton?: string | number;
	onRightButtonClick?: () => void;
	setChange?: (val: string | number) => void;
	disableTrailing?: boolean;
	noLabel?: boolean;
	hideError?: boolean;
	isVisited?: boolean;
	clear?: () => void;
	withArrows?: boolean;
	format?: 'default' | 'time';
	clickOutside?: (e: string | number) => void;
	decimalScale?: number;
	step?: number;
	max?: number;
	isStaticPlaceholder?: boolean;
	displayErrorBeforeVisited?: boolean;
	defaultValue?: any;
	grayedOut?: boolean;
	isMobile?: boolean;
	allowNegative?: boolean;
	marginTop?: number;
}

export interface DateInputProps {
	placeholder?: string;
	label?: string;
	info?: string;
	helper?: string;
	helperContent?: string;
	sizer: 'M' | 'S';
	leftIcon?: string;
	rightIcon?: string;
	error?: string | undefined;
	setChange?: (val: string | number) => void;
	disableTrailing?: boolean;
	clear?: () => void;
	enablePast?: boolean;
	noLabel?: boolean;
	hideError?: boolean;
	notBefore?: string;
	notAfter?: string;
	isBirthday?: boolean;
	visualFormat?: string;
	// colors?: IColors;
	openOnDate?: string | Date;
	displayErrorBeforeVisited?: boolean;
	disabled?: boolean;
	grayedOut?: boolean;
}

export interface DateRangeStartEnd {
	start: Date;
	end: Date | null;
}

export interface PredefinedRange {
	name: string;
	range: DateRangeStartEnd;
	isSelected?: boolean;
}

export type StartDate = Date;
export type EndDate = Date;
export type DateRange = [StartDate, EndDate | null];

export interface DateRangeInputProps extends DateInputProps {
	predefinedRanges?: PredefinedRange[];
	labels?: {
		btnSubmit: string;
		btnCancel: string;
	};
	onSubmitDateRange: (value: DateRange) => void;
	notAfter?: string;
	notAfterError?: string;
	notBeforeError?: string;
	openOnDateRange?: DateRangeStartEnd;
}

export enum AdditionalsConnectorEnum {
	SPACE = ' ',
	DASH = ' - ',
}

export interface AdditionalRow {
	additionals?: string[];
	additionalsConnector?: AdditionalsConnectorEnum;
	color?: ETypographyColor;
	type?: ETypography;
}
export interface IOption {
	label: string;
	value: string | number;
	icon?: Icons;
	isDisabled?: boolean;
	additionalInfo?: string;
	defaultRow?: { additionalTypographyType?: ETypography };
	additionalRows?: AdditionalRow[];
}

export interface IGenericOption<T> {
	label: string;
	value: T;
	icon?: Icons;
}

export enum EPickerTheme {
	FULL = 'full',
	OUTLINE = 'outline',
	PRIMARY = 'primary',
	SECONDARY = 'secondary',
	BASIC = 'basic',
}

export interface IPickerProps {
	label: string;
	theme?: EPickerTheme;
	info?: string;
	sizer: 'M' | 'S';
	withCustom?: boolean;
	options: IOption[];
	isMultiple?: boolean;
	selected: (string | number)[] | (string | number);
	setSelected: (v: any) => void;
}

export const sportsIcons = {
	1: Icons.s_softball,
	2: Icons.s_basketball,
	3: Icons.s_football,
	4: Icons.s_soccer,
	5: Icons.s_bowling,
	6: Icons.s_bocceball,
	7: Icons.s_cornhole,
	8: Icons.s_dodgeball,
	9: Icons.s_frisbee,
	10: Icons.s_hockey,
	11: Icons.s_kickball,
	12: Icons.s_lacrosse,
	13: Icons.s_pingpong,
	14: Icons.s_rugby,
	15: Icons.s_skeeball,
	16: Icons.s_tennis,
	17: Icons.s_vollyball,
	18: Icons.s_wiffleball,
	19: Icons.s_badminton,
	20: Icons.s_fitness,
	21: Icons.s_gold,
	22: Icons.s_pilatis,
	23: Icons.s_runnning,
	24: Icons.s_skiing,
	25: Icons.s_snowboarding,
	26: Icons.s_yoga,
	27: Icons.s_broomball,
	28: Icons.s_cricket,
	29: Icons.s_crossfit,
	30: Icons.s_cycling,
	31: Icons.s_fieldhockey,
	32: Icons.s_raquetball,
	33: Icons.s_spinning,
	34: Icons.s_squash,
	35: Icons.s_surfing,
	36: Icons.s_swiming,
	37: Icons.s_windsurfing,
	38: Icons.s_adventure,
	39: Icons.s_boxing,
	40: Icons.s_baseball,
	41: Icons.s_dance,
	42: Icons.s_kickboxing,
	43: Icons.s_martialArts,
	44: Icons.s_outdoor,
	45: Icons.s_rowing,
	46: Icons.s_sailing,
	47: Icons.s_sup,
	48: Icons.s_triathlon,
	49: Icons.s_handball,
	50: Icons.s_catchball,
	51: Icons.s_blitzball,
	52: Icons.s_rollerderby,
	53: Icons.s_skating,
	54: Icons.s_pickleball,
	55: Icons.s_throwing_axe,
	56: Icons.s_futsal,
	57: Icons.birthday,
	58: Icons.corporateEvents,
	999: Icons.s_other,
};

type TRadioType = 'icon-btn-horizontal' | 'basic';

export enum ESports {
	SOFTBALL = 1,
	BASKETBALL = 2,
	FOOTBALL = 3,
	SOCCER = 4,
	BOWLING = 5,
	BOCCEBALL = 6,
	CORNHOLE = 7,
	DODGEBALL = 8,
	FRISBEE = 9,
	HOCKEY = 10,
	KICKBALL = 11,
	LACROSSE = 12,
	PINGPONG = 13,
	RUGBY = 14,
	SKEEBALL = 15,
	TENNIS = 16,
	VOLLEYBALL = 17,
	WIFFLEBALL = 18,
	BADMINTON = 19,
	FITNESS = 20,
	GOLF = 21,
	PILATES = 22,
	RUNNING = 23,
	SKIING = 24,
	SNOWBOARDING = 25,
	YOGA = 26,
	BROOMBALL = 27,
	CRICKET = 28,
	CROSSFIT = 29,
	CYCLING = 30,
	FIELD_HOCKEY = 31,
	RACQUETBALL = 32,
	SPINNING = 33,
	SQUASH = 34,
	SURFING = 35,
	SWIMMING = 36,
	WIND_SURFING = 37,
	ADVENTURE = 38,
	BOXING = 39,
	BASEBALL = 40,
	DANCE = 41,
	KICKBOXING = 42,
	MARTIAL_ARTS = 43,
	OUTDOORS = 44,
	ROWING = 45,
	SAILING = 46,
	SUP = 47,
	TRIATHLON = 48,
	HANDBALL = 49,
	CATCHBALL = 50,
	BLITZBALL = 51,
	ROLLER_DERBY = 52,
	ICE_SKATING = 53,
	PICKLEBALL = 54,
	AXE_THROWING = 55,
	FUTSAL = 56,
	OTHER = 999,
}

type TypographyColorsMap = {
	[key in ETypographyColor]: string;
} & { default: string };

export {
	IColors,
	EBrandingColorsOptions,
	ITypography,
	IBnProviderSettings,
	TButton,
	TSize,
	ETypography,
	ETypographyColor,
	Icons,
	TRadioType,
	TypographyColorsMap,
};

export type ValuesOf<T extends Record<string, any>> = T[keyof T];
