/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@emotion/react';
import { UserPaymentMethod } from '@bondsports/types';
import { ICustomer } from '../../../../../types/customers';
import { MultiSelectBlock } from '../MultiSelectBlock';

interface IProps {
	paymentMethods: UserPaymentMethod[];
	selectedIds: string[];
	userId: number;
	customer: ICustomer;
	handleSelect: (pmId: string) => void;
	handleDelete: (pmId: number) => void;
}

const MultiSelectPaymentMethods = ({ userId, paymentMethods, handleSelect, handleDelete, customer, selectedIds }: IProps) => {

	const handleSelectClick = (newSelectedId: string) => {
		handleSelect(newSelectedId);
	}
	
	const handleDeleteClick = (pmId: number) => {
		handleDelete(pmId);
	}

	return (
		<MultiSelectBlock customer={customer} handleSelect={handleSelectClick} handleDelete={handleDeleteClick} paymentMethods={paymentMethods} selectedIds={selectedIds} userId={userId} />
	);
};

export { MultiSelectPaymentMethods };
