import { useState, useEffect, useCallback, useMemo } from 'react';
import { AttendanceEntityTypeEnum, EventWithAttendanceDto } from '@bondsports/types';
import { useAttendance } from './useAttendance';
import { useActivities } from './useActivities';
import { useCustomersEvents } from './useCustomersEvents';
import { useErrorModal } from './useErrorModal';
import { useNotification } from './useNotification';
import { ENotificationType } from '../stores/baseStore';
import useAudio, { SoundEffect } from './useAudio';
import { fullName } from '@bondsports/general';
import { ICustomer } from '../types/customers';
import { addToDateHours, isEarlier, isLater } from '@bondsports/date-time';
import { CheckInRequest, useAttendanceParallelCreate } from './useAttendanceParallelCreate';

// Remember: "Memberships" are an "Activity" with .activityType = "membership"
const MEMBERSHIPS_PER_PAGE = 4;

/**
 * This component handles the logic for checking in a customer, used by the "Customer Check-In Modal".
 * 
 * The logic for "what to check in to" and how to react is documented in the flow diagram on this: https://bond-sports.atlassian.net/browse/BOND-10067
 * 
 * For a better UX, we've implemented the logic in a parallel way, so that auto-check-in to Membership and Event can happen at the same time.
 * This results in the following approach (thread's are conceptual, non-blocking IO allows A and B to run in parallel):
 * 
 *  ...thread A...
 * - If the Customer has an Event starting in < 1hr or started but not ended, auto-check-in to that Event.
 *  ...thread B...
 * - If there are any Memberships, auto-check-in to all of them.
 * 	...join threads A and B (ie. wait for both to complete)...
 * - Show appropriate Toast and/or hide Modal (see actual "supervisor" logic below)
 * 
 */
export const useCheckInLogic = (
  customerState: ICustomer,
  userId: number,
  organizationId: number,
  toggle: () => void,
  labels: any,
  page: number
) => {

  const customerFullName = useMemo(() => 
    fullName(customerState.firstName, customerState.lastName),
    [customerState.firstName, customerState.lastName]
  );

  // Only one event is checked in to at a time
  const eventAttendance = useAttendance(customerState.id, organizationId);
  // Multiple memberships can be checked in to at a time (useAttendance cannot handle this)
  const { performParallelCheckIns } = useAttendanceParallelCreate(organizationId, userId);
  
  const { 
    fetchAvailableActivities, 
    isFetchingActivities, 
    availableActivities, 
    activitiesMeta,
    fetchActivitiesError 
  } = useActivities();
  const { fetchCustomerEvents, events } = useCustomersEvents(customerState.id, customerState.organizationId);
  const { setErrorModal } = useErrorModal();
  const { setToastNotification } = useNotification();
  const { playSoundEffect } = useAudio();
  const [eventForCheckin, setEventForCheckin] = useState<EventWithAttendanceDto | undefined>();

  const [pendingAutocheckinOperations, setPendingAutocheckinOperations] = useState(2);
  const [didCheckInTo, setDidCheckInTo] = useState<AttendanceEntityTypeEnum[]>([]);
  const decrementPendingAutocheckinOperations = useCallback(() => {
    setPendingAutocheckinOperations(prev => {
      return prev - 1;
    });
  }, []);

  const checkInToEvent = useCallback(async (
    event: EventWithAttendanceDto
  ) => {
    if (!eventAttendance.isCreatingAttendance) {
      if (event) { setEventForCheckin(event); }
      eventAttendance.createAttendance(customerState, userId, {
        entityId: event.id,
        entityType: AttendanceEntityTypeEnum.EVENT,
      }, event);
    }
  }, [customerState, eventAttendance, setEventForCheckin, userId]);

  // Fetch Memberships and Events
  useEffect(() => {
    fetchAvailableActivities(userId, organizationId, page, MEMBERSHIPS_PER_PAGE);
    if (!events) { fetchCustomerEvents(); }
  }, [page, userId, organizationId, customerState.id]);

  // Do Membership auto-check-in
  // Auto check in to all Memberships
  useEffect(() => {
    const doCheckin = async () => {
      if (!isFetchingActivities && activitiesMeta) {
        if (availableActivities.length === 0) {
          decrementPendingAutocheckinOperations();
          return;
        }

        const requests: CheckInRequest[] = availableActivities.map(activity => ({
          data: { 
            entityId: activity.activityId,
            entityType: AttendanceEntityTypeEnum.MEMBERSHIP 
          }
        }));

        try {
          const results = await performParallelCheckIns(requests);
          setDidCheckInTo(prev => [
            ...prev,
            ...requests.map(() => AttendanceEntityTypeEnum.MEMBERSHIP)
          ]);
        } catch (error) {
          playSoundEffect(SoundEffect.CheckInError);
          setToastNotification(labels.membershipCheckinFailMessage(customerFullName), ENotificationType.warning);
        } finally {
          decrementPendingAutocheckinOperations();
        }

      }
    };

    doCheckin();
  }, [activitiesMeta, isFetchingActivities, availableActivities, customerFullName]);

  // Do Event auto-check-in
	// Auto check in to first Event if it's starting in < 1hr or started but not ended
	useEffect(() => {
    if (!events) { return }
    // Only auto-check-in to Events once
    if (didCheckInTo.includes(AttendanceEntityTypeEnum.EVENT)) { return; }

		if (events.length > 0) {
			const now = new Date();
      const oneHourFromNow = addToDateHours(new Date(), 1);

			for (const event of events) {
				const eventStartDate = new Date(event.startDate);
        const eventEndDate = new Date(event.endDate);

        // If the event is starting in < 1hr or started but not ended
				if (isEarlier(eventStartDate, oneHourFromNow) && isLater(eventEndDate, now)) {
					// If we're already checked in to this event; note it, don't check in again
          if (event.attendance?.some(a => a.userId === userId)) {
            setEventForCheckin(event);
						playSoundEffect(SoundEffect.CheckInSuccess);
						setDidCheckInTo(prevState => [...prevState, AttendanceEntityTypeEnum.EVENT]);
						decrementPendingAutocheckinOperations();
						return;
					}

          // Check in to the event (which will decrement the pending operations on return)
          checkInToEvent(event);
          return;
				}
			}
    }
    
    // There were no matching events; decrement the pending operations
		decrementPendingAutocheckinOperations();
  }, [events]);
  
  // Handle successful Event check-in
  const handleAttendanceSuccess = useCallback(() => {
    if (!didCheckInTo.includes(AttendanceEntityTypeEnum.EVENT)) {
      setDidCheckInTo(prev => [...prev, AttendanceEntityTypeEnum.EVENT]);
      decrementPendingAutocheckinOperations();
    }
  }, [didCheckInTo, decrementPendingAutocheckinOperations]);

  useEffect(() => {
    if (eventAttendance.createAttendanceSuccess) {
      handleAttendanceSuccess();
    }
  }, [
    eventAttendance.createAttendanceSuccess,
    handleAttendanceSuccess
  ]);

  // Handle Event check-in error
  const handleAttendanceError = useCallback((error: string) => {
    if (!didCheckInTo.includes(AttendanceEntityTypeEnum.EVENT)) {
      setErrorModal({ message: error });
      decrementPendingAutocheckinOperations();
    }
  }, [didCheckInTo, decrementPendingAutocheckinOperations, setErrorModal]);

  useEffect(() => {
    if (eventAttendance.createAttendanceError) {
      handleAttendanceError(eventAttendance.createAttendanceError);
    }
  }, [
    eventAttendance.createAttendanceError, 
    handleAttendanceError
  ]);

  // Supervisor logic
  // Auto-check-in for "now" Event and single-Membership is allowed to happen in parallel (above); they don't affect each other.
	// Here we implement the rest of the logic around auto-check-in.
  useEffect(() => {
    if (pendingAutocheckinOperations !== 0) return;

    const hasEvent = didCheckInTo.includes(AttendanceEntityTypeEnum.EVENT);
    const membershipCheckInCount = didCheckInTo.filter(type => type === AttendanceEntityTypeEnum.MEMBERSHIP).length;

    let message;
    let notificationType = ENotificationType.success;

    if (hasEvent && membershipCheckInCount > 0) {
      message = labels.eventAndMembershipCheckinSuccessMessage(customerFullName, eventForCheckin?.title, membershipCheckInCount);
      toggle();
    } else if (hasEvent) {
      message = labels.eventCheckinSuccessMessage(customerFullName, eventForCheckin?.title);
      if (activitiesMeta?.totalItems === 0) toggle();
    } else if (membershipCheckInCount > 0) {
      message = labels.membershipCheckinSuccessMessage(customerFullName, membershipCheckInCount);
      toggle();
    } else if (activitiesMeta?.totalItems === 0) {
      playSoundEffect(SoundEffect.CheckInError);
      message = labels.membershipCheckinFailMessage(customerFullName);
      notificationType = ENotificationType.warning;
      toggle();
    }

    if (message) {
      setToastNotification(message, notificationType);
    }
  }, [pendingAutocheckinOperations, didCheckInTo, activitiesMeta?.totalItems, customerFullName, eventForCheckin]);

  return {
    isFetchingActivities,
    activitiesMeta,
    pendingAutocheckinOperations,
    fetchActivitiesError,
    availableActivities
  };
}; 