import { FormSelect_v2, useSelect, ISelect_V2, SelectedTags } from '@bondsports/utils';
import React, { useEffect, useMemo } from 'react';
import { ReasonDto } from '@bondsports/types';
import { PAUSE_REASONS_OPTIONS_MAX_HEIGHT, TAGS_MAX_HEIGHT } from '@app/react/lib/constants';
import { fetchReasons } from '@app/react/lib/fetchersCallbacks/fetchReasons';
import { ReasonOption } from '@app/react/types/reasons';
import { mapReasonsToOptions } from './utils';
import { FormApi } from 'final-form';
import { PauseMembersFormFieldsEnum } from '@app/react/forms/membership/pause/createPause/types';
import { colors_v2 } from '@app/react/styles/theme';

interface PauseReasonsFormSelectProps extends Partial<ISelect_V2> {
	organizationId: number;
	required?: boolean;
	form: FormApi<any, Partial<any>>;
	selectedReasons?: string[];
	onSelectCallback?: (customer: ReasonDto) => void;
	maxSelections?: number;
	mapDataToOptions?: (data: ReasonDto[]) => ReasonOption[];
	labels: {
		label: string;
		placeholder: string;
	};
}

export const PauseReasonsFormSelect = ({
	organizationId,
	required = false,
	maxSelections = 1,
	mapDataToOptions = mapReasonsToOptions,
	disabled,
	hideError,
	selectedReasons,
	form,
	isWithTags = false,
	labels,
}: PauseReasonsFormSelectProps) => {
	const name = PauseMembersFormFieldsEnum.reason;


	const { isLoading, error, isError, onClick, items, refetch } = useSelect<ReasonOption>({
		fetchData: () =>
			fetchReasons<ReasonOption>(
				organizationId,
				mapDataToOptions
		),
	});

	useEffect(() => {
		if(!items?.length && !isLoading && !isError) {
			refetch();
		}
	}, [items, isLoading, isError]);


	const onSelect = (fieldName: string, value: string) => {
		form.mutators.onSelect(
			fieldName,
			[value]
		);
	};

	const handleRemoveOption = (valueToRemove: string) => {
		form.mutators.onSelect(
			name,
			selectedReasons?.filter(customer => Number(customer) !== Number(valueToRemove)) || []
		);
	};

	const tagsValues = useMemo(() => {
		if (!selectedReasons?.length || !items?.length) {
			return [];
		}

		return selectedReasons.map((id) => {
			const reason = items.find((item) => item.id === Number(id));
			return ({
			value: reason?.value,
			id: reason?.id,
			label: reason?.label,
			});
		});
	}, [selectedReasons, items]);

	return (
			<FormSelect_v2
				name={name}
				label={labels.label}
				placeholder={labels.placeholder}
				required={required}
				onSelecting={onSelect}
				options={items}
				isGroups={false}
				selectedLabel={tagsValues?.[0]?.label}
				isLoading={isLoading || (selectedReasons?.length && !tagsValues?.length)}
				isError={isError}
				error={error}
				onClick={onClick}
				values={selectedReasons}
				marginTop={0}
				maxSelections={maxSelections}
				disabled={disabled}
				hideError={hideError}
				maxHeight={PAUSE_REASONS_OPTIONS_MAX_HEIGHT}
				isWithTags={isWithTags}
				buttonColor={colors_v2.bg_text_secondary}
				tags={
					<SelectedTags
						selectedValues={tagsValues}
						handleRemove={!disabled ? handleRemoveOption : undefined}
						maxHeight={TAGS_MAX_HEIGHT}
					/>
				}
			/>
	);
};
