import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import ReactDOM from 'react-dom';
import React from 'react';
import { Router } from '@angular/router';
import { AuthenticationPage } from '@app/react/pages/authentication';

@Component({
	selector: 'rc-login-page',
	template: ` <div data-aid="loginPage" style="height: 100%">
		<div id="receipt-root" class="print-container" style=" width: 100%; height: 100%; z-index: -1;"></div>
		<div class="react-root" #reactRoot></div>
	</div>`,
})
export class LoginPageComponent implements AfterViewInit, OnDestroy {
	@ViewChild('reactRoot') containerRef: ElementRef;

	constructor(private router: Router) {}

	ngAfterViewInit() {
		this.render();
	}

	ngOnDestroy() {
		ReactDOM.unmountComponentAtNode(this.containerRef.nativeElement);
	}

	private render() {
		if (!this.containerRef) return;

		ReactDOM.render(React.createElement(AuthenticationPage, { router: this.router }), this.containerRef.nativeElement);
	}
}
