/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { AlertsCard } from '../AlertsCard';
import { AlertsStatusEnum } from '@app/react/types/customers';
import { MembershipAlertRow } from './MembershipAlertRow';
import { TranslationEn } from '@assets/i18n/en';
import { MembershipAlert } from './types';
import { ReactNode } from 'react';

interface Props {
	status: AlertsStatusEnum;
	memberships: MembershipAlert[];
	footer: ReactNode;
	isLoading?: boolean;
	canViewMemberships: boolean;
}

const gridCss = css`
	display: grid;
	grid-template-columns: 6fr 8fr 1fr;
`;
const { title, errorMessage } = TranslationEn.customers.overview.memberships;

export const Memberships = ({ memberships, footer, status, isLoading = false, canViewMemberships }: Props) => {
	return (
		<AlertsCard
			title={title}
			status={status}
			footer={footer}
			isLoading={isLoading}
			errorMessage={errorMessage}
			main={
				<div css={gridCss} data-testid="membership-alert-card">
					{memberships.map((membership, index) => {
						if (!canViewMemberships) {
							membership.redirectToMembership = undefined;
						}
						return (
							<MembershipAlertRow key={index} {...membership} />
						);
					})}
				</div>
			}
		/>
	);
};
