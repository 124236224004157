import { SessionParticipantsResponse } from 'app/react/types/sessionParticipant';
import { environment } from '../../../../environments/environment';
import { IApiError, network } from '../network';
import { PublishingStatusEnum } from 'app/react/types/season';
import { FileItem } from 'ng2-file-upload';
import { imageApi } from './imageApi';
import { ResourceNameTypeEnum } from 'app/react/types/customers';
import {
	CreateProgramSeasonDto,
	IEventOfSessionDetails,
	ISeasonAttendeeInfo,
	MaintenanceDto,
	ShallowUpdateProgramSeasonDto,
	EventAsSeasonSegment,
	GenericResponseDto,
	SeasonAsSeasonSegment,
	Event,
	PaginationResultDto,
	SimpleProgramDto,
	SimpleSessionDto,
	RelatedSessionsDto,
	PaginationQuery,
	GetOrganizationProgramSessionsDto,
	RelatedProgramsDto,
	GetOrganizationRelatedProgramsDto,
	GetRelatedProgramSessionsDto,
	ProgramSeason,
} from '@bondsports/types';
import { PromiseCache } from '../promiseCache';
import { IErrorArr } from '@app/react/types/errors';
import { buildQueryString } from './utils';

export interface IUpdateEvents {
	name?: string;
	startDate?: string; // YYYY-MM-dd
	endDate?: string; // YYYY-MM-dd
	startTime?: string; // HH:mm (24H)
	endTime?: string; // HH:mm (24H)
	spacesIds?: number[]; // OPTIONAL
	isAttendeesInform?: boolean; // OPTIONAL
	minutesBeforeSlot?: number; // OPTIONAL
	minutesAfterSlot?: number; // OPTIONAL
	publicNotes?: string;
	privateNotes?: string;
	maintenance?: MaintenanceDto[];
	colorCodeId?: number;
}

export interface IGetEventsParams {
	startDate?: string;
	endDate?: string;
	orderBy?: string;
	page?: number;
	itemsPerPage?: number;
	includeConflicts?: boolean;
	attendeeId?: number;
}

export interface IUpdateOrDeleteEvents {
	eventsIds?: number[];
	segmentsIds?: number[];
	unselectedIds?: number[];
	startDate?: string;
	endDate?: string;
}

export interface PurchaseResourceDto {
	id: number;
	type: ResourceNameTypeEnum;
}

const temporal_url = `${environment.CS_URLS.API_ROOT}/programs`;
const v4APIUrl = `${environment.CS_URLS.API_ROOT_V4}/programs-seasons`;
const programsV4Url = `${environment.CS_URLS.API_ROOT_V4}/programs`;

const cache = new PromiseCache<string>();

const saveProgram = async (organizationId: number, data) => {
	const response = await network.post(`${temporal_url}/organization/${organizationId}`, data);
	return response;
};

const publishProgram = async (organizationId: number, programId: number) => {
	const status = PublishingStatusEnum.PUBLISHED;
	const response = await network.put(`${temporal_url}/${programId}/organization/${organizationId}/status`, { status });
	return response;
};

const saveProgramToCMS = async (programId: number) => {
	const response = await network.post(`${temporal_url}/${programId}/save-cms`, {});
	return response;
};

const updateProgram = async (programId: number, organizationId: number, isUpdateChildrenGls = false, data) => {
	const response = await network.put(
		`${environment.CS_URLS.API_ROOT_V4}/programs/${programId}/organization/${organizationId}?updateChildrenGls=${isUpdateChildrenGls}`,
		data
	);
	return response;
};

export interface MoveParticipantsRequestParams {
	userId: number; // paying user id
	invoiceId: number;
	productsToAdd: {
		id: number; // product id
		amountToPay: number;
		resources: {
			type: string;
			id: number; // resource id
		}[];
	}[];
	resourcesToRemove: {
		id: number;
		resources: {
			type: string;
			id: number;
		}[];
	}[];
}

const getProgram = async (programId: number, organizationId: number, includeSeasons = false) => {
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/programs/${programId}/organization/${organizationId}?includeSeasons=${includeSeasons}`
	);
	return response;
};

const createSession = async (programId: number, organizationId: number, data: CreateProgramSeasonDto) => {
	const response = await network.post(`${v4APIUrl}/organization/${organizationId}/program/${programId}/season`, data);
	return response;
};

const editSessionBasicInfo = async (
	organizationId: number,
	programId: number,
	seasonId: number,
	isUpdateChildrenGls = false,
	updateData: ShallowUpdateProgramSeasonDto
) => {
	const response = await network.put(
		`${v4APIUrl}/organization/${organizationId}/program/${programId}/season/${seasonId}?updateChildrenGls=${isUpdateChildrenGls}`,
		updateData
	);
	return response;
};

const recreateSession = async (sessionId: number, data: any) => {
	const response = await network.put(`${v4APIUrl}/${sessionId}/schedule`, data);
	return response;
};

const getSessionDepricated = async (sessionId: number) => {
	const response = await cache.withCache(
		network.get,
		`getSession__${sessionId}`,
		`${temporal_url}/season/${sessionId}?includeResources=false`
	);

	return response;
};

const getSession = async (
	organizationId: number,
	sessionId: number,
	includeResources = false
): Promise<ProgramSeason | IApiError> => {
	return await network.get(
		`${v4APIUrl}/organization/${organizationId}/${sessionId}?includeResources=${includeResources}`
	);
};

const getSegments = async (
	sessionId: number,
	ignoreEvents = false,
	startDate?: string,
	endDate?: string,
	attendeeId?: number
): Promise<(EventAsSeasonSegment | SeasonAsSeasonSegment)[]> => {
	const queryString = buildQueryString({ startDate, endDate, attendeeId, ignoreEvents: ignoreEvents ?? null });
	// use cache to prevent duplicating long-running requests, i.e. on tab change
	const response = await cache.withCache(
		network.get,
		`getSegments__${sessionId}${queryString}`,
		`${v4APIUrl}/${sessionId}/segments${queryString}`
	);

	return response;
};

const getEvents = async (
	sessionId: number,
	{ attendeeId, startDate, endDate, orderBy, page, itemsPerPage = 13, includeConflicts = false }: IGetEventsParams = {}
) => {
	const queryString = buildQueryString({
		startDate,
		endDate,
		orderBy,
		page,
		itemsPerPage,
		includeConflicts,
		attendeeId,
	});
	const response = await cache.withCache(
		network.get,
		`getEvents__${sessionId}${queryString}`,
		`${v4APIUrl}/${sessionId}/events${queryString}`
	);
	// maintain return format for backwards compatibility
	if (!response.err && !response.data) {
		return { data: response };
	} else {
		return response;
	}
};

const getEventById = async (seasonId: number, eventId: number): Promise<IEventOfSessionDetails> => {
	const response = await network.get(`${v4APIUrl}/${seasonId}/events/${eventId}`);

	return response?.data;
};

const getSessionsByProgram = async (programId: number, organizationId: number) => {
	const response = await network.get(`${temporal_url}/${programId}/organization/${organizationId}/seasons`);
	return response;
};

const getParticipants = async (
	organizationId: number,
	sessionId: number,
	page: number,
	filter = '',
	itemsPerPage = 30,
	isPunchCardUsers = true,
	isFreeAgentsOnly = true
): Promise<SessionParticipantsResponse> => {
	const url = new URL(`${v4APIUrl}/organization/${organizationId}/${sessionId}/attendees`);

	const params = new URLSearchParams({
		isFreeAgentsOnly: String(isFreeAgentsOnly),
		isPunchCardUsers: String(isPunchCardUsers),
		page: String(page),
		itemsPerPage: String(itemsPerPage),
		nameEmailSearch: filter,
	});

	url.search = params.toString();
	const query = url.search;
	const template = `getParticipants__${sessionId}${query}`;

	return cache.withCache(network.get, template, url.toString());
};

const getProgramSessions = async (programId: number, organizationId: number, status?: number) => {
	let url = `${temporal_url}/${programId}/organization/${organizationId}/seasons`;
	if (status) {
		url += `?status=${status}`;
	}
	const response = await network.get(url);
	return response;
};

const removeSessionToArchive = async (programId: number, sessionId: number) => {
	const status = PublishingStatusEnum.ARCHIVE;
	const response = await network.put(`${temporal_url}/${programId}/season/${sessionId}/status`, { status });
	return response;
};

/**
 * Uploads a program media
 * default type of the media equals to main media
 * @param file
 * @param programId
 * @param type - available types are 'logo' and 'main'
 */
const uploadProgramMedia = (file: FileItem, programId: number, type: 'main' | 'logo' = 'main') => {
	return imageApi.uploadFileItemImage(file).then(async response => {
		const fileObject = {
			url: response.secure_url,
			provider: 'cloudinary',
			fileType: response.format,
			mediaKey: response.public_id,
			fileName: response.original_filename,
		};
		return await network.post(`${environment.CS_URLS.API_ROOT}/programs/${programId}/uploadMedia`, {
			file: fileObject,
			handleType: type,
		});
	});
};

const removeProgramMedia = (programId: number, type: 'main' | 'logo' = 'main') => {
	const fileObject = { url: '', provider: 'cloudinary', fileType: 'jpg', mediaKey: '', fileName: '' };

	return network.post(`${environment.CS_URLS.API_ROOT}/programs/${programId}/uploadMedia`, {
		file: fileObject,
		handleType: type,
	});
};

const updateEvent = async (
	organizationId: number,
	sessionId: number,
	eventId: number,
	data: IUpdateEvents
): Promise<GenericResponseDto<Event>> => {
	return await network.put(`${v4APIUrl}/organization/${organizationId}/season/${sessionId}/event/${eventId}`, data);
};

const deleteEvents = async (
	organizationId: number,
	sessionId: number,
	queryParam: IUpdateOrDeleteEvents
): Promise<GenericResponseDto<string>> => {
	const queryString = buildQueryString(queryParam);
	const response = await network.delete(
		`${v4APIUrl}/organization/${organizationId}/season/${sessionId}/events${queryString}`,
		{}
	);

	if (response.err) {
		throw Error(response.err);
	}
	return response;
};

const updateMultipleEvents = async (
	organizationId: number,
	sessionId: number,
	queryParam: IUpdateOrDeleteEvents,
	data: IUpdateEvents
): Promise<GenericResponseDto<Event[]>> => {
	const queryString = buildQueryString(queryParam);
	return await network.put(`${v4APIUrl}/organization/${organizationId}/season/${sessionId}/events${queryString}`, data);
};

const addEventsToSession = async (
	organizationId: number,
	sessionId: number,
	data: IUpdateEvents[]
): Promise<GenericResponseDto<Event[]>> => {
	return await network.post(`${v4APIUrl}/organization/${organizationId}/season/${sessionId}/events`, {
		eventsData: data,
	});
};

const updateSessionStatus = async (programId: number, sessionId: number, status: PublishingStatusEnum) => {
	return await network.put(`${environment.CS_URLS.API_ROOT}/programs/${programId}/season/${sessionId}/status`, {
		status: status,
	});
};

const moveParticipant = async (body: MoveParticipantsRequestParams, organizationId: number) => {
	// If the response returns with a status code that's not 200/300, it throws an error
	try {
		const response = await network.post(
			`${environment.CS_URLS.API_ROOT_V4}/purchase/organization/${organizationId}/add-to-existing-invoice`,
			body
		);
		return response;
	} catch (error) {
		return error;
	}
};

const setSpaceAllocation = async (sessionId: number, data) => {
	return await network.post(
		`${environment.CS_URLS.API_ROOT}/programs/season/${sessionId}/resource-spaces-allocation`,
		data
	);
};

const updateProgramStatus = async (programId: number, organizationId: number, status: PublishingStatusEnum) => {
	const response = await network.put(`${temporal_url}/${programId}/organization/${organizationId}/status`, {
		programId,
		status,
	});
	return response;
};

const getAttendeeInfo = async (
	userId: number,
	organizationId: number,
	seasonId: number
): Promise<ISeasonAttendeeInfo> => {
	return await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/programs-seasons/${seasonId}/organization/${organizationId}/attendees/${userId}`
	);
};

const removeParticipantFromEvents = async (organizationId: number, userId: number, events: number[]) => {
	const response = await network.delete(
		`${v4APIUrl}/organization/${organizationId}/user/${userId}/events/${events}`,
		{}
	);
	return response;
};

const removeParticipantFromSegments = async (organizationId: number, userId: number, segments: number[]) => {
	const response = await network.delete(
		`${v4APIUrl}/organization/${organizationId}/user/${userId}/segments/${segments}`,
		{}
	);
	return response;
};

const removeParticipantFromSession = async (organizationId: number, userId: number, sessionId: number) => {
	const response = await network.delete(
		`${v4APIUrl}/organization/${organizationId}/user/${userId}/session/${sessionId}`,
		{}
	);
	return response;
};

const updateProgramsSessionsColors = async (
	programId: number,
	organizationId: number,
	data: { futureOnly: boolean }
): Promise<{ data: 'success' } | IErrorArr> => {
	const response = await network.put(
		`${v4APIUrl}/organization/${organizationId}/program/${programId}/update-season-colors`,
		data
	);
	return response;
};

const getOrganizationPaginatedSessionsByProgramId = async (
	organizationId: number,
	programId: number,
	filters: GetOrganizationProgramSessionsDto
): Promise<PaginationResultDto<SimpleSessionDto>> => {
	const query: string = buildQueryString(filters);

	const response: PaginationResultDto<SimpleSessionDto> = await network.get(
		`${v4APIUrl}/organization/${organizationId}/program/${programId}/simple${query}`
	);

	return response;
};

const getPaginatedRelatedProgramsByOrganizationId = async (
	organizationId: number,
	pagination: PaginationQuery,
	related: RelatedProgramsDto,
	filters?: Omit<GetOrganizationRelatedProgramsDto, 'related'>
): Promise<PaginationResultDto<SimpleProgramDto>> => {
	const query = buildQueryString(pagination);

	const response = await network.post(`${programsV4Url}/organization/${organizationId}/get-related${query}`, {
		related,
		...(filters ?? {}),
	});
	return response;
};

const getRelatedProgramsSessionCount = async (
	organizationId: number,
	related: RelatedProgramsDto,
	filters?: Omit<GetOrganizationRelatedProgramsDto, 'related'>,
	options?: Partial<RequestInit>
): Promise<number> => {
	const response = await network.post(
		`${v4APIUrl}/organization/${organizationId}/count`,
		{ related, ...(filters ?? {}) },
		options
	);

	return (response as GenericResponseDto<number>).data;
};

const getPaginatedRelatedSessionsByOrganizationId = async (
	organizationId: number,
	pagination: PaginationQuery,
	related: RelatedSessionsDto,
	filters?: Omit<GetRelatedProgramSessionsDto, 'related'>
): Promise<PaginationResultDto<SimpleSessionDto>> => {
	const query: string = buildQueryString(pagination);
	const response = await network.post(`${v4APIUrl}/organization/${organizationId}/get-related${query}`, {
		related,
		...(filters ?? {}),
	});
	return response;
};

const getRelatedSessionsProductsCount = async (
	organizationId: number,
	related: RelatedSessionsDto,
	filters?: Omit<GetRelatedProgramSessionsDto, 'related'>,
	options?: Partial<RequestInit>
): Promise<number> => {
	const response = await network.post(
		`${v4APIUrl}/organization/${organizationId}/count-products`,
		{ related, ...(filters ?? {}) },
		options
	);
	return (response as GenericResponseDto<number>).data;
};
export const programsApi = {
	getProgram,
	publishProgram,
	createSession,
	getSessionDepricated,
	editSessionBasicInfo,
	getSession,
	getSegments,
	getEvents,
	getEventById,
	setSpaceAllocation,
	getParticipants,
	getSessionsByProgram,
	updateEvent,
	saveProgram,
	updateProgram,
	getProgramSessions,
	removeSessionToArchive,
	updateSessionStatus,
	moveParticipant,
	updateMultipleEvents,
	recreateSession,
	uploadProgramMedia,
	saveProgramToCMS,
	updateProgramStatus,
	removeProgramMedia,
	addEventsToSession,
	deleteEvents,
	getAttendeeInfo,
	removeParticipantFromEvents,
	removeParticipantFromSegments,
	removeParticipantFromSession,
	updateProgramsSessionsColors,
	getPaginatedRelatedProgramsByOrganizationId,
	getRelatedProgramsSessionCount,
	getPaginatedRelatedSessionsByOrganizationId,
	getRelatedSessionsProductsCount,
	getOrganizationPaginatedSessionsByProgramId,
};
