/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { PauseMembersFormFieldsEnum } from '../../types';
import { Icons, SizeEnum } from '@bondsports/utils';
import { addToDateDays, getDateTimeString } from '@bondsports/date-time';
import { FormDateInput } from '@app/react/components/shared/FormControls/FormDateInput';
import { PauseStartDateProps } from '../../types/interfaces';

export const PauseStartDate = ({ labels, onChange, disabled, memberEndDate }: PauseStartDateProps) => {
	const TOMORROW = addToDateDays(new Date(), 1);
	const minimumDate = getDateTimeString(TOMORROW);

	return (
			<FormDateInput
				rightIcon={Icons.calendar}
				notBefore={minimumDate}
				notAfter={memberEndDate}
				required
				hideError
				grayedOut={disabled}
				disableTrailing
				disabled={disabled}
				onChange={onChange}
				name={PauseMembersFormFieldsEnum.startDate}
				sizer={SizeEnum.SMALL}
				label={labels.label}
				placeholder={labels.placeholder}
			/>
	);
};
