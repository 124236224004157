import { ENotificationType } from '@bondsports/utils';
import { useState } from 'react';

import { useNotification } from '../useNotification';
import { useToggle } from '../useToggle';
import { useErrorModal } from '../useErrorModal';

import { IMembershipRow } from '../../types/membership';
import { membershipApi } from '../../lib/api/membershipApi';
import { TranslationEn } from '@assets/i18n/en';

export interface ICancelParams {
	isImmediate: boolean;
	organizationId: number;
	callback: () => unknown;
	userToCancel?: IMembershipRow;
	fetchCustomer?: (organizationId: number, userId: number) => unknown;
}

export const useCancelMembership = () => {
	const labels = TranslationEn.memberships.members.cancelMembership;
	const [isProcessing, setIsProcessing] = useState(false);
	const [cancellationReason, setCancellationReason] = useState('');
	const { setToastNotification } = useNotification();
	const [isCancelMembershipShowing, cancelToggle] = useToggle();
	const { setErrorModal } = useErrorModal();

	const handleCancel = async ({ isImmediate, organizationId, callback, userToCancel, fetchCustomer = null }: ICancelParams) => {
		if (userToCancel) {
			setIsProcessing(true);
			fetchCustomer && fetchCustomer(organizationId, userToCancel?.userId);
			const res = await membershipApi.cancelMembership(
				organizationId,
				userToCancel.memberId,
				userToCancel.userId,
				isImmediate,
				cancellationReason
			);
			setIsProcessing(false);
			cancelToggle();
			if (res.err || !res.data) {
				setErrorModal({ message: String(res.err ?? res) });
				setToastNotification(labels.failedToCancel, ENotificationType.warning);
			}
			if (res.data) {
				await callback();
				setToastNotification(labels.membershipCanceled, ENotificationType.success);
			}
		}
	};

	return {
		isCancelMembershipShowing,
		isProcessingCancel: isProcessing,
		cancellationReason,
		setCancellationReason,
		cancelToggle,
		handleCancel,
	};
};
