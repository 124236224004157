/** @jsxRuntime classic */
/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Fragment, useMemo } from 'react';
import { Loader } from '../../../components/Loader';
import { useColors } from '../../../hooks/useColors';
import { EBrandingColorsOptions, IColors, Icons } from '../../../types/theme';
import { IExpandedSlot, IPopupActions, IPopupRedirections, ITimeSlot } from '../types';
import { Footer } from './Footer';
import { Header } from './Header';
import { SecondaryBody } from './SecondaryBody';
import { MainBody } from './MainBody';
import { Close } from './Close';
import { en } from '../../../lib/i18n/en';

const loaderContainerCss = css`
	display: flex;
	justify-content: center;
	padding: 5rem 0;
`;

const popupBodyMoreContainerCss = (colors: IColors) => css`
    width: 100%;
    max-width: 52rem; 
    height: 100%;
    position: relative;
    background-color: ${colors.bg_white};
    display: flex;
    flex-direction: column;
`;

const scrollableContainerCss = css`
    flex: 1; 
    max-height: 78rem;  
    overflow-y: auto;   
`;

export const SlotPopupBody = ({
	toggle,
	expandedSlot,
    redirections,
    actions
} : {
	toggle: () => void;
	expandedSlot?: IExpandedSlot;
    redirections?: IPopupRedirections;
    actions?: IPopupActions;
}) => {
	const { colors } = useColors();
    const containerStyle = useMemo(() => popupBodyMoreContainerCss(colors), [colors]);
	return (
		<div data-aid="popupBody-more" css={containerStyle}>
			{expandedSlot ? (
				<Fragment>
                    <Close toggle={toggle} labels={en.slotCard.close}/>
                    <div css={scrollableContainerCss}>
                        <Header 
                            expandedSlot={expandedSlot}
                            redirections={redirections}
                            labels={{...en.slotCard.header, idHyperlink: en.idHyperlink}}
                        />
                        <MainBody expandedSlot={expandedSlot} labels={en.slotCard.body}/>
                        {(expandedSlot.sportIds?.[0] !== undefined || (expandedSlot.addons && expandedSlot.addons.length > 0)) && 
                            <SecondaryBody expandedSlot={expandedSlot} labels={{sportLabel: en.ESports[expandedSlot.sportIds[0]]}}/>
                        }
                    </div>
					<Footer 
                        actions={actions} 
                        labels={en.slotCard.footer} 
                    />
				</Fragment>
			) : (
				<div css={loaderContainerCss}>
					<Loader width={40} margin="4rem" color={EBrandingColorsOptions.bg_system} />
				</div>
			)}
		</div>
	);
};
