import { useCallback, useEffect, useState } from 'react';
import { useOrganization } from './useOrganization';
import { useRecoilState } from 'recoil';
import { organizationStore } from '../stores/organizationStore';
import { googleMapsApi, TGoogleTimezoneResponse } from '../lib/api/googleMapsApi';
import { isEqual, isUndefined } from 'lodash';
import { ORGANIZATION_ID } from '../lib/localStorageKeys';

export const useOrganizationTimezone = () => {
	const { organizationState, fetchOrganizationData, organizationId } = useOrganization();
	const orgId = organizationId || Number(localStorage.getItem(ORGANIZATION_ID));
	const [organizationTimezone, setOrganizationTimezone] = useRecoilState(organizationStore.organizationTimezone);
	const [isFetching, setIsFetching] = useState(false);

	const getOrganizationTimezone = useCallback(async ({ lat, long }: { lat: number; long: number }) => {
		try {
			setIsFetching(true);

			// if we already have timezone, we don't need to set it again
			if (organizationTimezone) {
				return;
			}

			if (organizationState?.timezone) {
				setOrganizationTimezone(organizationState.timezone);
				return;
			}

			// when we will be sure that all organizations have timezone set, we can remove this call
			const response: TGoogleTimezoneResponse = await googleMapsApi.getTimezoneByGeoCoordinates([lat, long]);

			setOrganizationTimezone(timezone => {
				return timezone || response.timeZoneId;
			});
		} catch (err) {
			console.error('Error in useOrganizationTimezone:', err.message);
			setOrganizationTimezone(timezone => timezone || Intl.DateTimeFormat().resolvedOptions().timeZone);
		} finally {
			setIsFetching(false);
		}
	}, []);

	useEffect(() => {
		if ((!organizationState || isEqual(organizationState, {})) && orgId) {
			fetchOrganizationData(orgId, () => {});
		}
	}, [organizationState, orgId]);

	useEffect(() => {
		if (isFetching || organizationTimezone || !organizationState.id) {
			return;
		}

		const long: number = organizationState?.address?.geo?.coordinates?.[0];
		const lat: number = organizationState?.address?.geo?.coordinates?.[1];

		if (!isUndefined(lat) && !isUndefined(long)) {
			getOrganizationTimezone({ lat, long });
		} else {
			setOrganizationTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
		}
	}, [
		isFetching,
		getOrganizationTimezone,
		organizationTimezone,
		organizationState?.address?.geo?.coordinates?.[0],
		organizationState?.address?.geo?.coordinates?.[1],
		organizationState.id,
	]);

	return {
		isLoading: isFetching,
		timezone: organizationTimezone,
	};
};
