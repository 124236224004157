/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/react';
import { TranslationEn } from '@assets/i18n/en';
import { Icons, ModalWindow } from '@bondsports/utils';
import { ModalBody } from '@app/react/components/shared/ModalBody';
import { colors_v2 } from '@app/react/styles/theme';
import { FlexWrapLayout } from 'ui';
import { PAUSE_POPUP_MARGIN, PAUSE_POPUP_WIDTH } from '../utils/consts';
interface Props {
	displayDeleteModal: boolean;
	handleCancel: () => void;
	handleMainAction: () => void;
}
export const UndoPauseModal = ({ displayDeleteModal, handleCancel, handleMainAction }: Props) => {
	const labels = TranslationEn.forms.pauseMembershipMembers.undoPauseModal;
	return (
			<ModalWindow isShowing={displayDeleteModal} toggle={handleCancel}>
				<FlexWrapLayout containerClassName="gap-2">
					<ModalBody
						icon={Icons.conflict}
						iconColor={colors_v2.tx_error}
						title={labels.title}
						description={labels.description}
						mainAction={handleMainAction}
						mainButton={labels.mainButton}
						subButton={labels.subButton}
						subAction={handleCancel}
						customWidth={PAUSE_POPUP_WIDTH}
						customMargin={PAUSE_POPUP_MARGIN}
						buttonsFlexDirection='row-reverse'
					/>
				</FlexWrapLayout>
			</ModalWindow>
		);
};
