/** @jsx jsx */
import React, { useContext } from 'react';
import { jsx, css } from '@emotion/react';
import { TranslationEn } from '@assets/i18n/en';
import { PauseMembersFormFieldsEnum } from '../../types';
import { FormTextarea, SizeEnum } from '@bondsports/utils';
import { FormContext } from '@app/react/components/shared/form';
import { DurationFormSelect } from '@app/react/components/shared/formBlocks/DurationFormSelect';
import { PauseReasonsFormSelect } from '@app/react/components/shared/formBlocks/PauseReasonsFormSelect';
import { flexWrapCss, marginBottomCss, wrapperContainer } from '@app/react/styles/utils';
import { PauseStartDate } from './pauseStartDate';
import { MAX_DESCRIPTION_LENGTH, DEFAULT_DUTATION_ARRAY } from '../../types/consts';
import { CURRENTLY_PAUSED_MEMBERSHIP_STATUSES } from '@app/react/components/customers/consts';
import { GenericWarning } from '@app/react/components/shared/genericWarning';
import { PauseDetailsProps } from '../../types/interfaces';

export const PauseDetails = ({ isEdit, organizationId, status, showWarning, memberEndDate, onHistoryWarningClick }: PauseDetailsProps) => {
	const labels = TranslationEn.memberships.members.pauseMemberships.form;
	const modalLabels = TranslationEn.memberships.members.pauseMemberships.pauseMembershipModal;
	const { description, reason, startDate, duration } = labels;
	const { form, values } = useContext(FormContext);
	const disableStartDate = isEdit && CURRENTLY_PAUSED_MEMBERSHIP_STATUSES.includes(status);
	return (
		<div css={flexWrapCss}>
			<PauseStartDate 
				labels={startDate}
				disabled={disableStartDate}
				memberEndDate={memberEndDate}
				onChange={val => form.mutators.onSelect(PauseMembersFormFieldsEnum.startDate, val)}
			/>
			<DurationFormSelect 
				initialItems={DEFAULT_DUTATION_ARRAY}
				labels={duration}
				form={form}
				selectedDurations={values[PauseMembersFormFieldsEnum.pauseDurationValue] || []}
				required
			/>
			<div css={[wrapperContainer, marginBottomCss(30)]}>
				<PauseReasonsFormSelect 
					form={form}
					disabled={isEdit}
					organizationId={organizationId}
					required
					selectedReasons={values[PauseMembersFormFieldsEnum.reason]}
					labels={reason}
				/>
				<FormTextarea
					name={PauseMembersFormFieldsEnum.description}
					sizer={SizeEnum.SMALL}
					hideError
					required
					marginTop={0}
					placeholder={description.placeholder}
					maxLength={MAX_DESCRIPTION_LENGTH}
					disabled={isEdit}
					noCarriageReturn
				/>
			</div>
			<GenericWarning warningLink={{ label: modalLabels.historyLink, onClick: onHistoryWarningClick }} warningMessage={modalLabels.historyWarning} showWarning={showWarning} />
		</div>
	);
};
