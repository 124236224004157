/** @jsx jsx */
import React, { useCallback, useContext } from 'react';
import { jsx, css } from '@emotion/react';
import { flexCol, fullWidth, gapCss, marginTop } from '../../../styles/utils';
import { TranslationEn } from '@assets/i18n/en';
import { FormContext } from '../../shared/form';
import { PauseMembersFormFieldsEnum } from '@app/react/forms/membership/pause/createPause/types';
import { addToDateDays, addToDateMonths } from '@bondsports/date-time';
import { FormButtons } from '../../shared/Buttons/FormButtons';
import { FormSaveButton } from '../../shared/Buttons/SaveButton';
import { FormCancelButton } from '../../shared/Buttons/CancelButton';
import { DatesComparisonBlock } from './summaryDateBlock';
import { FamilyPauseWarning } from './familyPauseWarning';
import { ISummaryDates } from '../types/members.interface';

interface PauseSummaryProps {
	handleBack: () => void;
	handlePause: (values: any) => void;
	timezone: string;
	initialEndDate?: Date;
	isEditMode?: boolean;
	showWarning: boolean;
}
const datesContainer = css`
    ${fullWidth};
    gap: 2rem;
    display: flex;
    flex-direction: column;
`;

export const PauseSummaryBody = ({
	handleBack,
	handlePause,
	showWarning,
	initialEndDate,
	isEditMode,
	timezone
}: PauseSummaryProps) => {
	const labels = TranslationEn.memberships.members.pauseMemberships.summary;
	const modalLabels = TranslationEn.memberships.members.pauseMemberships.pauseMembershipModal;

	const { values, getFieldValue } = useContext(FormContext);
	const pauseStartDate = getFieldValue<Date>(PauseMembersFormFieldsEnum.startDate);  

    let [pauseDuration] = getFieldValue<number[]>(PauseMembersFormFieldsEnum.pauseDurationValue);

	pauseDuration = Number(pauseDuration);
	
	const getPauseSummaryDates = useCallback((): ISummaryDates => {
		pauseDuration = Number(pauseDuration);
		const pauseEndDate = addToDateMonths(pauseStartDate, pauseDuration);
		const newEndDate = addToDateMonths(initialEndDate, pauseDuration);
		const originalBillingDate = addToDateDays(initialEndDate, 1);
		const newBillingDate = addToDateDays(newEndDate, 1);
		const reactivationDate = (addToDateDays(pauseEndDate, 1));

		return { originalBillingDate, newBillingDate, reactivationDate };
	}, [initialEndDate, pauseStartDate, pauseDuration]);

	const {
		reactivationDate,
		newBillingDate,
		originalBillingDate
	 } = getPauseSummaryDates();

	const onClick = () => {
		handlePause({
			...values,
			pauseDurationValue: values?.pauseDurationValue[0],
			startDate: pauseStartDate,
			reason: values?.reason[0],
		});
	}
	const submitButtonLabel = isEditMode ? modalLabels.update : modalLabels.pause;
	return (
			<div css={[flexCol, gapCss(40), marginTop(32)]} data-testid="pause-summary-step">
				<div css={datesContainer} data-testid="summary-step-dates-container">
					<div css={fullWidth}>
						<DatesComparisonBlock firstDate={reactivationDate} timezone={timezone} title={labels.reactivationDate} />
						<DatesComparisonBlock firstDate={originalBillingDate} secondDate={newBillingDate} title={labels.impactedBillingDate} />
					</div>
				<FamilyPauseWarning warningMessage={modalLabels.warning} showWarning={showWarning} />
				</div>
				<FormButtons>
					<FormCancelButton label={modalLabels.back} onClick={handleBack} />
					<FormSaveButton label={submitButtonLabel} onClick={onClick} />
				</FormButtons>
			</div>
	);
};
