/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { Fragment } from 'react';

import { BnIcon } from '../../../components/icons_v2';
import { useSlot } from '../../../hooks/useSlot';
import { sportsIcons } from '../../../types';
import { MaintainanceList } from '../MaintenanceSlot';
import { maintenanceIconCss, flexRowCss } from '../style';
import { ColorCode, IMaintSlot, ISlot, TPxHeightSetter } from '../types';
import { fullHeightCss } from '../../../styles/utils';

/** inline css is required here to enforce the style, otherwise it get override */
const MaxContentCenterCss = {
	display: 'flex',
	alignItems: 'center',
	width: 'max-content',
};

const shortSlotCss = css`
	${flexRowCss};
	align-items: center;
	padding: 4px 0;
`;

const dynamicAbsolutePosition = (top: number) => css`
	position: absolute;
	top: ${top}%;
	width: 100%;
`;

dayjs.extend(customParseFormat);
dayjs.extend(utc);

interface ISlotProps {
	event: ISlot;
	isInternal: boolean;
	isConflict: boolean;
	withMaintenance?: boolean;
}

interface ISlotBodyProps extends ISlotProps {
	isShort: boolean;
	isMaintAtBeginning: boolean;
	isMaintAtTheEnd: boolean;
	maintsAtBeginning: any[];
	maintsAtEnd: any[];
	colorCode?: ColorCode;
	calcHeight: (maint: IMaintSlot) => number;
	gapFromTop?: number;
	maintEndHeight: number;
	endAndAfterOverallPxHeight: number;
	getMaintRefHeight: TPxHeightSetter;
	horizontal?: boolean;
}

const ShortSlotBody = ({ event, isInternal, isConflict, withMaintenance }: ISlotProps) => {
	const { TimeShortDisplayComponent, Name, SpacerX, Dot } = useSlot({
		state: event,
		isConflict,
	});
	return (
		<div css={shortSlotCss} data-aid="EventSlotBody-EventSlot">
			{!isInternal && event.sportIds?.[0] && (
				<BnIcon icon={sportsIcons[event.sportIds?.[0]]} css={maintenanceIconCss} className="sub" />
			)}
			<SpacerX width={4} />
			<Name size="S" />
			<Dot />
			<TimeShortDisplayComponent />
		</div>
	);
};

const LongSlotBody = ({ event, isInternal, isConflict }: ISlotProps) => {
	const { TimeLongDisplayComponent, Dot, CustomerName, SpacerY, Name, SpacerX, PrivateIndicator, Frequency } = useSlot({
		state: event,
		isConflict,
	});
	return (
		<Fragment>
			<div style={MaxContentCenterCss}>
				<TimeLongDisplayComponent />
				{!isInternal && (
					<Fragment>
						<Dot />
						<CustomerName />
					</Fragment>
				)}
			</div>
			<SpacerY height={3} />
			<div style={MaxContentCenterCss}>
				{!isInternal && event.sportIds?.[0] && (
					<BnIcon icon={sportsIcons[event.sportIds?.[0]]} css={maintenanceIconCss} className="sub" />
				)}
				<SpacerX width={4} />
				<Name size="M" />
				<PrivateIndicator />
			</div>
			<Frequency />
		</Fragment>
	);
};

export const EventSlotBody = ({
	event,
	isShort,
	isConflict,
	isMaintAtBeginning,
	isMaintAtTheEnd,
	maintsAtBeginning,
	maintsAtEnd,
	isInternal,
	colorCode,
	calcHeight,
	gapFromTop,
	maintEndHeight = 0,
	endAndAfterOverallPxHeight = 0,
	getMaintRefHeight,
	horizontal,
}: ISlotBodyProps) => {
	const { IconsContainer } = useSlot({
		state: event,
		isConflict,
	});
	const withMaintenance = isMaintAtBeginning || isMaintAtTheEnd;
	return (
		<Fragment>
			<div
				data-aid="EventSlotBody-before-and-main-content"
				css={[gapFromTop && dynamicAbsolutePosition(gapFromTop), fullHeightCss]}
			>
				{isMaintAtBeginning && (
					<MaintainanceList
						key={`At-Beginning-MaintenanceList-${event.id}`}
						// currently displaying only one internal maintenance
						maintSlots={[maintsAtBeginning?.[0]]}
						isNestedEvent={true}
						calcHeight={calcHeight}
						colorCode={colorCode}
						horizontal={horizontal}
					/>
				)}
				{isShort ? (
					<ShortSlotBody
						key={`ShortSlotBody-${event.id}`}
						event={event}
						isInternal={isInternal}
						isConflict={isConflict}
						withMaintenance={withMaintenance}
					/>
				) : (
					<LongSlotBody
						key={`LongSlotBody-${event.id}`}
						event={event}
						isInternal={isInternal}
						isConflict={isConflict}
					/>
				)}
			</div>
			<IconsContainer heightModifier={endAndAfterOverallPxHeight} />
			{isMaintAtTheEnd && (
				<MaintainanceList
					key={`At-End-MaintenanceList-${event.id}`}
					// currently displaying only one internal maintenance
					maintSlots={[maintsAtEnd?.[0]]}
					maintAtEndHeight={{ height: maintEndHeight }}
					isNestedEvent={true}
					calcHeight={calcHeight}
					colorCode={colorCode}
					setHeightFromRef={getMaintRefHeight}
					horizontal={horizontal}
				/>
			)}
		</Fragment>
	);
};
