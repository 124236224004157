/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react";
import { css, jsx } from '@emotion/react';
import { Typography } from "../../atoms"
import { Tooltip } from "../../components/Tooltip"
import { ETypography, ETypographyColor } from "../../types"
import { ELLIPSES } from "../../lib/constants";
import { en } from "../../lib/i18n/en";

const truncated3DotsCss = () => css`
    display: inline-block;
`

interface ViewMoreEllipsesProps {
    text: string;
  }

export const ViewMoreEllipses = ({ text }: ViewMoreEllipsesProps) => {
    return <Tooltip 
        content={
            <Typography color={ETypographyColor.primary} type={ETypography.caption} css={truncated3DotsCss}>
                {text}
            </Typography>
        }
        isTopLayer={true}  
        aria-label={en.clampOrTruncateText.ariaLabel.viewMore}
    >
        <Typography color={ETypographyColor.secondary} type={ETypography.body2Link}>
            {ELLIPSES}
        </Typography>
    </Tooltip>
}
