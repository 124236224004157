import { DurationDto, ReasonDto } from "@bondsports/types";

export type TempValues = IPauseDetails;
export interface RouteParams {
	id: string;
	memberId: string;
}

export interface IPauseDetails {
	duration?: number;
	startDate?: string;
	reason?: number;
	reasonId?: number;
	pauseDurationValue?: string;
	pauseDescription?: string;
	description?: string;
}

export interface IPauseMembershipMember {
    familyMembership?: boolean;
    reasonId?: number;
    pauseDescription: string;
    duration: DurationDto;
    startDate: Date;
}

export declare class IUpdatePauseMembershipMember {
    duration: DurationDto;
    startDate: Date;
}

export interface IPauseFormDetails {
	id?: number;
	duration?: DurationDto;
	startDate?: Date;
	reason?: ReasonDto;
	pauseDescription?: string;
}

export enum PauseMembersFormFieldsEnum {
	startDate = 'startDate',
	duration = 'duration',
    pauseDurationValue = 'pauseDurationValue',
    reason = 'reason',
    description = 'description',
}
