/** @jsx jsx */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { jsx, css } from '@emotion/react';
import { CustomersService } from '@app/shared/services/customers/customers.service';
import { TranslationEn } from 'assets/i18n/en';
import { DataGrid, OrderByEnum, PageContentMain } from 'ui';
import { PaymentColumnDef, paymentsColumns } from './columns';
import { paymentsParser } from './parser';
import { TCustomer } from '@app/react/types/customers';
import { useRedirect } from './hooks/useRedirect';

interface Props {
	customerState: TCustomer;
	customersService: CustomersService;
}

export const Payments = ({ customerState, customersService }: Props) => {
	const redirectToReceipt = useRedirect();

	const tableLabels = TranslationEn.customers.paymentsInvoices;

	return (
		<PageContentMain>
			<DataGrid<PaymentColumnDef>
				columns={paymentsColumns}
				apiMethod={customersService.getPaymentsByCustomer}
				apiResponseParser={paymentsParser}
				apiArgs={[Number(customerState.id)]}
				actionBarProps={{
					showSearch: true,
					showFilterMenu: true,
					showColumnVisibility: true,
				}}
				onRowClick={row => redirectToReceipt(row.id)}
				strings={{
					subject: TranslationEn.payments.tables.subject,
				}}
				defaultSortColumn="createdAt"
				defaultSortDirection={OrderByEnum.DESC}
			/>
		</PageContentMain>
	);
};
