/** @jsx jsx */

import { useState } from 'react';
import { jsx } from '@emotion/react';
import { TranslationEn } from 'assets/i18n/en';
import { Input } from '@bondsports/utils';
import { Icons } from 'app/react/styles/theme';
import { EPaymentMethod } from '@app/react/types/payment';
import { ContainerCss, fullWidthCss } from './styles';

const MAX_GIFT_CARD_NUMBER_LENGTH = 25;
const MAX_CHECK_NUMBER_LENGTH = 12;

export const isDocumentType = (type: EPaymentMethod) => [EPaymentMethod.GIFT_CARD, EPaymentMethod.CHECK].includes(type);

export const Document = ({ type, handleDocumentNumberChange }) => {
	const DocumentComponent = type === EPaymentMethod.CHECK ? EnterCheckNumber : EnterGiftCardNumber;

	return (
		<div css={ContainerCss(true)}>
			<DocumentComponent handleDocumentNumber={handleDocumentNumberChange} />
		</div>
	);
};

const EnterCheckNumber = ({ handleDocumentNumber }) => {
	const validate = (val: string) => {
		return /^\d*$/.test(val) && val.length <= MAX_CHECK_NUMBER_LENGTH;
	};

	return (
		<EnterDocumentNumber
			validate={validate}
			handleDocumentNumberChange={handleDocumentNumber}
			label={TranslationEn.payments.enterCheckNumber}
		/>
	);
};

const EnterGiftCardNumber = ({ handleDocumentNumber }) => {
	const validate = (val: string) => val?.trim().length <= MAX_GIFT_CARD_NUMBER_LENGTH;

	return (
		<EnterDocumentNumber
			validate={validate}
			handleDocumentNumberChange={handleDocumentNumber}
			label={TranslationEn.payments.enterGiftCardNumber}
		/>
	);
};

const EnterDocumentNumber = ({ validate, handleDocumentNumberChange, label }) => {
	const [documentNumber, setDocumentNumber] = useState<string>('');

	const handleChange = val => {
		const inputString = (val?.target?.value as string) ?? '';

		if (validate(inputString)) {
			setDocumentNumber(inputString);
			handleDocumentNumberChange(inputString);
		}
	};

	const handleDocumentNumberClear = () => {
		setDocumentNumber('');
		handleDocumentNumberChange('');
	};

	return (
		<div css={fullWidthCss}>
			<Input
			    data-aid="CashOrDocument-input-number"
				type="string"
				value={documentNumber}
				leftIcon={Icons.check_p}
				onChange={handleChange}
				clear={handleDocumentNumberClear}
				sizer="S"
				label={label}
			/>
		</div>
	);
};
