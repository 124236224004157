import { useContext, useMemo } from 'react';
import { FormContext } from '../components/shared/form';
import { getNestedValue } from '../forms/utils/utils';
import { IFormMultipleSelect } from '../types/select';

export const useFormMultipleSelect = ({ optionsCount, name, isAllName }: IFormMultipleSelect) => {
	const { values, form } = useContext(FormContext);

	const toggleSelectAll = () => {
		const isAllOptions = getNestedValue<boolean>(values, isAllName) || false;
		if (isAllOptions) {
			form.mutators.clear(name);
			form.mutators.onSelect(isAllName, false);
		} else {
			form.mutators.onSelect(isAllName, true);
			form.mutators.onSelect(name, []);
		}
	};

	const onClear = (name: string) => {
		const isAllOptions = getNestedValue<boolean>(values, isAllName) || false;
		form.mutators.onSelect(name, []);
		if (isAllOptions) {
			form.mutators.onSelect(isAllName, false);
		}
	};

	const onSelecting = (name: string, value: string[] | string) => {
		form.mutators.onSelect(name, value);
	};

	const selectedLabel = useMemo(() => {
		const isAllSelected = isAllName && getNestedValue<boolean>(values, isAllName);
		const value = getNestedValue<string[]>(values, name) || [];
		return isAllSelected ? optionsCount - value.length : value.length;
	}, [values, isAllName, name, optionsCount]);

	return {
		toggleSelectAll,
		onClear,
		onSelecting,
		selectedLabel,
	};
};
