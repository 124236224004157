import { useHistory } from 'react-router';

export const useNavigation = () => {
	const history = useHistory();

	const ngNavigate = (module: string, reactPath: string) => {
		(window as any).ngRouter.navigate([`/client/${module}`]).then(() => {
			if (reactPath?.[0] === '/') {
				reactPath = reactPath.slice(1);
			}
			if (reactPath !== '') window.location.hash = `#/${reactPath}`;
		});
	};

	/**
	 * To navigate within the same system module, using the history.push() method by 'react-router'
	 * @param path a string of the wanted pathto push
	 */
	const pushNavigation = (path: string) => history.push(path);

	const goBack = () => {
		history.goBack();
	};

	return { ngNavigate, pushNavigation, goBack };
};
