import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { RCUser } from '@rcenter/core';
import { network } from '../../../react/lib/network';
import { FindPayments, IPayment, PaginationResultDto } from '@bondsports/types';
import { buildQueryString } from '@app/react/lib/api/utils';

@Injectable()
export class CustomersService {
	currentCustomer$ = new BehaviorSubject<any>(null);

	constructor(private http: HttpClient) {}

	getCustomers(
		organizationId: number,
		page?: number,
		itemsPerPage?: number,
		typeFilter?: string[],
		searchFilter?: string
	) {
		let searchParam = '';
		if (searchFilter != '') {
			searchParam = `&nameSearch=${encodeURIComponent(searchFilter)}`;
		}

		let typeParam = '';
		if (typeFilter && typeFilter.length != 0) {
			let query = '';
			typeFilter.map((status, index) => {
				if (index == 0) {
					query += status;
				} else {
					query += `,${status}`;
				}
			});
			typeParam = `&customerTypes=${query}`;
		}
		if (!page) {
			page = 1;
		}
		if (!itemsPerPage) {
			itemsPerPage = 10;
		}
		return this.http
			.get<any>(
				`${environment.CS_URLS.API_ROOT_V4}/customers/organization/${organizationId}?page=${page}&itemsPerPage=${itemsPerPage}${typeParam}${searchParam}`
			)
			.pipe(map(response => response));
	}

	getCustomerById(organizationId: number, customerId: number) {
		return this.http
			.get<any>(`${environment.CS_URLS.API_ROOT_V4}/organization/${organizationId}/customer/${customerId}`)
			.pipe(map(response => response));
	}

	// clean up - remove this api and the use of it in the app - not in used
	updateCustomer(organizationId: number, customerId: number, data) {
		return this.http
			.put<any>(`${environment.CS_URLS.API_ROOT_V4}/organization/${organizationId}/customer/${customerId}`, data)
			.pipe(map(response => response));
	}

	/**
	 * 
	 * @deprecated
	 */
	getPaymentsByCustomer_DEPRECATED(
		customerId: number,
		page: number,
		itemsPerPage: number,
		statusFilter: string[],
		methodFilter: string[],
		searchFilter: string
	) {
		let searchParam = '';
		if (searchFilter != '') {
			searchParam = `&searchParam=${encodeURIComponent(searchFilter)}`;
		}

		let statusParam = '';
		if (statusFilter.length != 0) {
			let query = '';
			statusFilter.map((status, index) => {
				if (index == 0) {
					query += status;
				} else {
					query += `,${status}`;
				}
			});
			statusParam = `&paymentStatuses=${query}`;
		}

		let methodParam = '';
		if (methodFilter.length != 0) {
			let query = '';
			methodFilter.map((method, index) => {
				if (index == 0) {
					query += method;
				} else {
					query += `,${method}`;
				}
			});
			methodParam = `&paymentTypes=${query}`;
		}
		if (!page) {
			page = 1;
		}

		const url = network.addOrganizationIdToUrl(
			`${environment.CS_URLS.API_ROOT_V4}/payment/${customerId}/payments?page=${page}&itemsPerPage=${itemsPerPage}${statusParam}${methodParam}${searchParam}`
		);
		return this.http.get<any>(url).pipe(map(response => response));
	}

	async getPaymentsByCustomer(
		customerId: number,
		query: FindPayments & { orderBy: string; search: string }
	): Promise<PaginationResultDto<IPayment>> {
		const searchParam = query.search ?? '';
		const orderByProperty = query.orderBy ?? 'createdAt';
		const queryString = buildQueryString({ ...query, searchParam, orderByProperty });	
		const url = network.addOrganizationIdToUrl(
			`${environment.CS_URLS.API_ROOT_V4}/payment/${customerId}/payments${queryString}`
		);
		return await network.get(url);
	}

	searchCustomers(organizationId: number, searchFilter: string) {
		return this.http
			.get<{
				data: RCUser[];
			}>(
				`${environment.CS_URLS.API_ROOT_V4}/customers/organization/${organizationId}?nameSearch=${encodeURIComponent(
					searchFilter
				)}&customerType=user`
			)
			.pipe(map(response => response));
	}
}
