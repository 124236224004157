import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { attendanceApi } from '../lib/api/attendanceApi';
import { AttendanceCreateDto, AttendanceDto, EventWithAttendanceDto, GenericResponseDto } from '@bondsports/types';
import { useCustomersEvents } from './useCustomersEvents';

// When checking in to an Event, include the Event object in the request;
// on success, the Event attendance state will be updated through `useCustomersEvents` hook
export interface CheckInRequest {
  data: AttendanceCreateDto;
  event?: EventWithAttendanceDto;
}

interface CheckInResponse extends GenericResponseDto<AttendanceDto, string> {}

// React Query mutation key
const CHECK_IN_KEY = ['membership', 'check-in'] as const;

const CHECK_IN_TIMEOUT_MS = 10000;

/**
 * Handles parallel check-ins for multiple memberships/events
 * 
 * @param organizationId - The ID of the organization
 * @param userId - The ID of the user to be checked in
 * @returns Object containing performParallelCheckIns function
 * 
 * The hook provides:
 * - performParallelCheckIns: Function to execute multiple check-ins in parallel
 *   - Takes an array of CheckInRequest objects
 *   - Returns Promise resolving to array of CheckInResponse objects
 *   - Times out after 10 seconds
 *   - Handles both membership and event check-ins
 *   - Updates event attendance state when checking into events
 * 
 * Example usage:
 * ```ts
 * const { performParallelCheckIns } = useAttendanceParallelCreate(orgId, userId);
 * 
 * const requests = [
 *   { data: { entityId: 123, entityType: 'MEMBERSHIP' } },
 *   { data: { entityId: 456, entityType: 'EVENT' }, event: eventObj }
 * ];
 * 
 * try {
 *   const results = await performParallelCheckIns(requests); 
 * } catch (error) {
 *   // Handle error
 * }
 * ```
 */
export const useAttendanceParallelCreate = (organizationId: number, userId: number) => {
  const { addAttendanceToEvent } = useCustomersEvents(userId, organizationId);
  const queryClient = useQueryClient();

  // Set up React Query mutation
  const mutation = useMutation({
    mutationKey: CHECK_IN_KEY,
    mutationFn: async (request: CheckInRequest): Promise<CheckInResponse> => {
      const res = await attendanceApi.createAttendance(organizationId, userId, request.data);
      
      // If this was an event check-in, update the event attendance state
      if (request.event && res.succeeded) {
        addAttendanceToEvent(request.event, res.data);
      }
      
      return res;
    },
    onSuccess: () => {
      // Invalidate and refetch relevant queries
      queryClient.invalidateQueries({ queryKey: ['membership'] });
    },
  });

  // Main function to handle parallel check-ins
  const performParallelCheckIns = useCallback(
    async (requests: CheckInRequest[]): Promise<CheckInResponse[]> => {
      try {
        // Create an array of promises for all check-ins
        const checkInPromises = requests.map((request) =>
          mutation.mutateAsync(request)
        );

        // Create a timeout promise
        const timeout = new Promise<never>((_, reject) => {
          setTimeout(() => reject(new Error('Check-in timeout exceeded seconds')), CHECK_IN_TIMEOUT_MS);
        });

        // Race between all check-ins completing and timeout
        const results = await Promise.race([
          Promise.all(checkInPromises),
          timeout
        ]) as CheckInResponse[];

        return results;
      } catch (error) {
        // Handle errors appropriately
        throw error;
      }
    },
    [mutation]
  );

  return {
    performParallelCheckIns,
    isLoading: mutation.isLoading,
    error: mutation.error,
    reset: mutation.reset,
  };
};
