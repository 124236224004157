import styled from '@emotion/styled';
import { IColors } from '../../types/theme';

export const StyledCheckbox = styled.label<any>(
	({
		theme: colors,
		width,
		disabled,
		color,
    margin,
		isDisplayAsBlock = true,
		checkboxPositionTop = 0,
	}: {
		theme: IColors;
		width: number;
		disabled: boolean;
		isDisplayAsBlock?: boolean;
		color?: string;
		checkboxPositionTop?: number;
    margin?: string;
	}) => `
  display: ${isDisplayAsBlock ? 'block' : 'flex'};
  position: relative;
  padding-left: ${width + 5}px;
  min-width:8px;
  ${margin ? 
      `margin: ${margin};` 
      : 
      'margin-bottom:0 !important;'
  }
  cursor: ${disabled ? 'default' : 'pointer'};
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

    svg{
      height: ${width + 2}px;
    }
    div{ 
      color: ${disabled ? colors.bg_text_disabled : colors.tx_text_primary};
      font-family: Montserrat;
      font-style: normal;
      font-weight: normal;
      display:flex;
      align-items: center;
    }
    .M{
      font-size: 16px;
      line-height: ${width + 2}px;
    }
    .S{
      font-size: 14px;
      line-height: ${width + 2}px;
    }
    .truncate{
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; 
      display: block;
    }
  input{
   // position: absolute;
    opacity: 0;
    cursor: ${disabled ? 'default' : 'pointer'};
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: ${checkboxPositionTop}px;
    left: 0;
    height: ${width}px;
    width: ${width}px;
    background-color: ${color ?? colors.bg_background_fields};
    border-radius:2px;
    border: 1px solid ${disabled ? 'transparent' : colors.bg_background_fields};
  }
  &:hover input ~ .checkmark {
    border: 1px solid ${disabled ? 'transparent' : colors.bg_border_primary};
  }
  input:checked ~ .checkmark {
    // background-color: #2196F3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    color:${color ?? colors.bg_text_primary};
    height: 100%;
    width: 100%;
    background-image: url("data:image/svg+xml;charset=utf-8,<svg width='100%' height='100%' viewBox='0 0 14 14' fill='currentColor' xmlns='http://www.w3.org/2000/svg'><path d='M11.384 3.7701C11.0075 3.40997 10.3972 3.40997 10.0207 3.7701L5.39304 8.2734L3.97856 6.92063C3.60215 6.56046 2.99176 6.56046 2.61535 6.92063C2.23889 7.2808 2.23889 7.86474 2.61535 8.22492L4.71149 10.2299C4.89969 10.41 5.14644 10.5 5.39314 10.5C5.63984 10.5 5.88654 10.41 6.07479 10.2299L11.384 5.07439C11.7605 4.71421 11.7605 4.13027 11.384 3.7701Z' fill='${
			disabled ? colors?.bg_text_disabled?.replace('#', '%23') : colors?.bg_text_primary?.replace('#', '%23')
		}'/></svg>");
    content: "";
  }

  .error{
    border: 1px solid ${colors.bg_error};
  }
  .error:hover {
    border: 1px solid ${colors.bg_error} !important;
  }
  `
);

export const StyleCheckLabel = styled.div<any>(
	({ theme: colors, isActive }: { theme: IColors; isActive: boolean }) => `
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  cursor:pointer;
  font-size: 16px;
  line-height: 20px;
  margin:8px 0;
  margin-left:8px;
  text-transform:capitalize;
  min-width:28px;
  display:flex;
  justify-content:center;
  align-items:center;
  color: ${isActive ? colors.tx_white : colors.tx_text_teriaty};
  padding:4px 6px;
  background: ${isActive ? colors.bg_text_primary : colors.bg_background_primary};
  border-radius: 2px;
`
);
