/** @jsxRuntime classic */
/** @jsx jsx */

import { DateInput, DateTimeFormats } from '@bondsports/date-time';
import { useDraggable } from '@dnd-kit/core';
import { jsx } from '@emotion/react';
import dayjs from 'dayjs';
import { FC, useEffect, useMemo, useRef, useState, MutableRefObject } from 'react';
import { useDebouncedValue } from '../../hooks/useDebouncedValue';
import { useMiddlewareContext } from '../../hooks/useMiddlewareContext';
import { useResize } from '../../hooks/useResize';
import { IDraggableDayToDropProps } from '../../types';
import { getMinutesDifferenceByStep } from '../../utils';
import { DndIcon } from '../../utils/DndIcon';
import { ResizeIcon } from '../../utils/ResizeIcon';
import {
	calculateMarginFromMinutes,
	calculateMinutesFromHeight,
	getLowestTime,
	getMaxTime,
	isSameDate,
} from '../../utils/timeUtils';
import { draggableContainer, draggableContent, draggableHandler, draggableResizeHandler } from '../styles';
import { MIDNIGHT } from '../../../lib/timeUtils';
import { ResourceNameTypeEnum } from '../../../types/enums';

function getDateValue(date: DateInput, time: string) {
	let [hours, minutes] = time.split(':');
	return dayjs(date, 'YYYY-MM-DD').hour(Number(hours)).minute(Number(minutes)).toDate().getTime();
}

export const Draggable: FC<IDraggableDayToDropProps> = ({
	children,
	id,
	state,
	parentId,
	groupTop,
	groupCount,
	groupIndex,
	isEditable,
	currentDate,
}) => {
	const elementId = `draggable-${id}`;

	const { updateDatesRange, getTopAndHeight, options } = useMiddlewareContext();

	if (state.event?.parentType === ResourceNameTypeEnum.RESERVATION) {
		isEditable &&= options.draggingOptions?.canEditReservation;
	} else if (state.event?.parentType === ResourceNameTypeEnum.PROGRAM) {
		isEditable &&= options.draggingOptions?.canEditProgram;
	}

	const { top, height } = useMemo(() => {
		// overriding the start and end times when the slot is overnight
		const startTime: string = state.overallStartTime ?? state.startTime;
		const endTime: string = state.overallEndTime ?? state.endTime;

		let start: number;
		// for the start date always use the current date
		if (state.startDate <= currentDate) {
			start = getDateValue(currentDate, startTime);
		} else {
			start = getDateValue(currentDate, state.overallStartTime!);
		}

		// for the end date use the events end date
		const end: number = getDateValue(currentDate, endTime);

		return getTopAndHeight(start, end);
	}, [state.startTime, state.endTime, state.overallStartTime, state.overallEndTime, currentDate]);

	const { isDragging, setNodeRef, listeners } = useDraggable({
		id: String(id),
		data: {
			parentId: parentId,
			event: state,
		},
	});

	const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
	const { initResize, size, cursor } = useResize(ref as MutableRefObject<HTMLDivElement>, {
		axis: 'vertical',
	});
	const sizeH = useDebouncedValue(size.height, 200);

	const [sizeHeight, setSizeHeight] = useState(size.height);

	useEffect(() => {
		setSizeHeight(sizeH);
	}, [sizeH]);

	const handleResize = () => {
		const draggableElement = ref?.current;
		if (!isDragging && draggableElement && !Number.isNaN(sizeHeight)) {
			const draggableHeight = draggableElement.offsetHeight;
			if (draggableHeight !== sizeHeight) return;

			let [hours, minutes] = state.startTime.split(':').map(Number);

			const startDate = dayjs(
				dayjs
					.utc(isSameDate(state.startDate, currentDate) ? state.startDate : dayjs.utc(currentDate).startOf('day'))
					.format('DD/MM/YYYY'),
				'DD/MM/YYYY'
			)
				.hour(hours)
				.minute(minutes)
				.format();

			// const startDate = dayjs.utc(state?.startTime, "HH:mm:ss").format();
			minutes = Math.floor(calculateMinutesFromHeight(draggableHeight));

			minutes = getMinutesDifferenceByStep(startDate, minutes, options.dndStepLength, true);

			const endDate = dayjs
				.utc(startDate)
				.add(minutes, 'minute')
				.set('milliseconds', 0)
				.set('seconds', 0)
				.toISOString();

			const onChangeData = {
				type: 'SLOT_RESIZE',
				data: {
					startDate: dayjs(startDate).format(DateTimeFormats.YYYY_MM_DD),
					endDate: dayjs(endDate).format(DateTimeFormats.YYYY_MM_DD),
					startTime: dayjs.utc(startDate).format(DateTimeFormats.H24_WITH_SECONDS),
					endTime: dayjs.utc(endDate).format(DateTimeFormats.H24_WITH_SECONDS),
					overId: parentId, // maybe should be targetId
					parentId,
					slotId: String(state.id),
				},
			};

			draggableElement.removeAttribute('style');
			setSizeHeight(calculateMarginFromMinutes(minutes));

			updateDatesRange(state.id, startDate, endDate, onChangeData);
		}
	};

	useEffect(() => {
		handleResize();
	}, [sizeHeight]);

	return (
		<div
			id={elementId}
			css={draggableContainer(top - (groupTop || 0), groupCount, groupIndex)}
			ref={setNodeRef}
			data-aid="Draggable-daily"
		>
			<div css={draggableContent(!Number.isNaN(sizeHeight) && sizeHeight !== Infinity ? sizeHeight : height)} ref={ref}>
				{children}
				{options.dragAndDrop && isEditable && (
					<div {...listeners} css={draggableHandler}>
						<DndIcon />
					</div>
				)}
				{options.resize && isEditable && (
					<div css={draggableResizeHandler(cursor)} onMouseDown={initResize}>
						<ResizeIcon />
					</div>
				)}
			</div>
		</div>
	);
};
