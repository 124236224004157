import { useCallback, useMemo, useState } from 'react';

import { IEditPauseParams, IMembershipRow, IPauseParams, IUnpauseParams, MembershipMoreButtonOptionEnum } from '../../types/membership';
import { ICancelParams, useCancelMembership } from './useCancelMembership';
import { useMembershipPausing } from './useMembershipPausing';
import { isFamilyMembership } from '@app/react/forms/membership/pause/createPause/utils';

export const useMembershipActions = (organizationId: number) => {
	const [selectedMember, setSelectedUser] = useState<IMembershipRow>();

	const {
		isCancelMembershipShowing,
		cancellationReason,
		setCancellationReason,
		cancelToggle,
		isProcessingCancel,
		handleCancel,
	} = useCancelMembership();

	const { 
		isPauseMembershipShowing,
		handlePause,
		handleEditPause,
		handleUndoPause,
		isProcessingPause,
		isPauseHistoryLoading,
		pauseToggle,
		setPauseId,
		pauseHistory,
		pauseId
	 } = useMembershipPausing(organizationId, selectedMember?.memberId, selectedMember?.hasPauseHistory);
	const handleActionIconClick = (user: IMembershipRow, action: () => void) => {
		setSelectedUser(user);
		action?.();
	};

	const handleCancelIconClick = (member: IMembershipRow) => {
		handleActionIconClick(member, cancelToggle);
	}

	const handlePauseIconClick = (member: IMembershipRow, action: MembershipMoreButtonOptionEnum = MembershipMoreButtonOptionEnum.PAUSE) => {
		if ([MembershipMoreButtonOptionEnum.UNDO_PAUSE, MembershipMoreButtonOptionEnum.EDIT_PAUSE].includes(action)) {
			setPauseId(member.memberId);
		}
		handleActionIconClick(member, pauseToggle);
	}

	const handleCancelMembership = (cancelParams: ICancelParams) => {
		if (selectedMember) {
			handleCancel({...cancelParams, userToCancel: selectedMember});
		}
	}

	const handlePauseMembership = useCallback((pauseParams: IPauseParams) => {
		if (selectedMember) {
			handlePause({...pauseParams, pausedMemberId: selectedMember.memberId});
		}
	}, [selectedMember]);

	const handleEditPauseMembership = useCallback((editPauseParams: IEditPauseParams) => {
		if (selectedMember) {
			handleEditPause({...editPauseParams, pausedMemberId: selectedMember.memberId});
		}
	}, [selectedMember]);

	const handleUndoPauseMembership = useCallback((unpauseParams: IUnpauseParams) => {
		if (selectedMember) {
			handleUndoPause({...unpauseParams, pausedMemberId: selectedMember.memberId});
		}
	}, [selectedMember?.memberId]);

	const isMemberInFamilyMembership: boolean = useMemo(() => selectedMember?.customerTypes && isFamilyMembership(selectedMember?.customerTypes), [selectedMember?.customerTypes]);

	return {
		isCancelMembershipShowing,
		isPauseMembershipShowing,
		selectedMember,
		cancellationReason,
		isProcessingActions: isProcessingCancel || isProcessingPause,
		setCancellationReason,
		cancelToggle,
		pauseToggle,
		handleCancelIconClick,
		handlePauseIconClick,
		isMemberInFamilyMembership,
		handleCancel: handleCancelMembership,
		handlePause: handlePauseMembership,
		handleUndoPause: handleUndoPauseMembership,
		handleEditPause: handleEditPauseMembership,
		pauseId,
		setPauseId,
		pauseHistory,
		isPauseHistoryLoading,
		setSelectedUser,
		
	};
};
