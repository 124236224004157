// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
// const API_DOMAIN = 'https://test-api.bondsports.co';
const API_DOMAIN = 'https://cognito.bondsports.co';
const CLOUDINARY_BUCKET = 'rcenter';

const process: any = { env: { NODE_ENV: 'development' } };
window['process'] = process;

export const environment = {
	production: false,
	INTERCOM_ID: 'rk54jlxy',
	STRIPE_KEY: 'pk_test_AM4q8Ui1y3iFC2t0YryIRpux00xuUkH1hA',
	CLOUDINARY_ROOT: 'https://res.cloudinary.com/' + CLOUDINARY_BUCKET + '/image/upload',
	CLOUDINARY_API_ROOT: 'https://api.cloudinary.com/v1_1/' + CLOUDINARY_BUCKET + '/image/upload',
	CLOUDINARY_UPLOAD_PRESET: 'tm4almj6',
	GOOGLE_API_KEY: 'AIzaSyCcoLHXURbT_41iV7PJGjyxV_yYMVjQ4Lw',
	CLOUDINARY_UPLOAD_PRESET_WITH_CROPPING: 'tm4almj6_dupe_with_incoming_crop',
	CLOUDINARY_BUCKET: CLOUDINARY_BUCKET,
	CS_URLS: {
		API_DOMAIN: API_DOMAIN,
		API_ROOT: API_DOMAIN + '/v1',
		API_ROOT_V2: API_DOMAIN + '/v2',
		API_ROOT_V3: API_DOMAIN + '/v3',
		API_ROOT_V4: API_DOMAIN + '/v4',
		AUTH_ROOT: API_DOMAIN + '/auth',
	},
	SITE_URL: 'https://backoffice.cognito.bondsports.co/login',
	PRODUCTION_URL: 'backoffice.bondsports.co',
	SLACK_NOTIFICATIONS: '',
	MIXPANEL_TOKEN: 'cf31e33ace568cad742dc24224ace33a',
	CONSUMER_SITE_URL: 'https://consumer.cognito.bondsports.co/',
	DEBUG_RECOIL: true,
	SENTRY_DSN: '',
	LOGIN_REDIRECT_URL: 'https://cognito.bondsports.co/auth/redirect/login',
	LOGOUT_REDIRECT_URL: 'https://cognito.bondsports.co/auth/redirect/logout',
	TERMS_OF_CONDITIONS_URL: `${API_DOMAIN}}/statics/terms.html`,
};
