import { useHistory, useLocation } from "react-router";

export const useRedirect = () => {
  const history = useHistory();
	const location = useLocation();

	const handleRedirection = (id: number) => {
		history.push(`${location.pathname}/${id}`);
	};

  return handleRedirection;
}
