/** @jsxRuntime classic */
/** @jsx jsx */

import { FC, useEffect, useMemo, useRef, useState, MutableRefObject } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { jsx } from '@emotion/react';
import dayjs from 'dayjs';
import {
	calculateMarginLeftFromMinutes,
	calculateMinutesFromWidth,
	getLowestTime,
	isDayPass,
	isSameDate,
	oneHourWidth,
} from '../../../utils/timeUtils';
import { useMiddlewareContext } from '../../../hooks/useMiddlewareContext';
import { useDebouncedValue } from '../../../hooks/useDebouncedValue';
import { useResize } from '../../../hooks/useResize';
import { IDraggableDayToDropProps } from '../../../types';
import {
	draggableContainerHorizontal,
	draggableContentHorizontal,
	draggableHandler,
	draggableResizeHandler,
} from '../../styles';
import { DndIcon } from '../../../utils/DndIcon';
import { getMinutesDifferenceByStep } from '../../../utils';
import { ResizeIcon } from '../../../utils/ResizeIcon';
import { MIDNIGHT } from '../../../../lib/timeUtils';

export const DraggableHorizontal: FC<IDraggableDayToDropProps> = ({
	children,
	id,
	state,
	parentId,
	groupTop,
	groupCount,
	groupIndex,
	isEditable,
	isConnectedMaintenance = false,
	currentDate,
}) => {
	const elementId = `draggable-${id}`;

	const { updateDatesRange, getTopAndHeight, options, day: daysCount } = useMiddlewareContext();

	const splittedStartTime = (state.overallStartTime ?? state.startTime).split(':');
	const splittedEndTime = (state.overallEndTime ?? state.endTime).split(':');

	/*
	 * need to understand if it is
	 * A. all day or full day -> startDate is start of the day, endDate is end of the day
	 * B. endDate is pass midnight -> start Date no change, endDate is set to end of the day
	 * C. startDate is befor midnight -> end Date no change, startDate is set to start of the day
	 * we need to validate the between the currentDate and (startDate or endDate) has a gap of a day
	 */
	const { top, height } = useMemo(
		() =>
			getTopAndHeight(
				dayjs(
					dayjs
						.utc(isSameDate(state.startDate, currentDate) ? state.startDate : dayjs.utc(currentDate).startOf('day'))
						.format('DD/MM/YYYY'),
					'DD/MM/YYYY'
				)
					.hour(+splittedStartTime[0])
					.minute(+splittedStartTime[1])
					.toDate()
					.getTime(),
				dayjs(
					dayjs
						.utc(isSameDate(state.endDate, currentDate) ? state.endDate : dayjs.utc(state.endDate).startOf('day'))
						.format('DD/MM/YYYY'),
					'DD/MM/YYYY'
				)
					.hour(+splittedEndTime[0])
					.minute(+splittedEndTime[1])
					.toDate()
					.getTime(),
				true
			),
		[state.overallStartTime, state.overallStartTime]
	);

	const { isDragging, setNodeRef, listeners } = useDraggable({
		id: String(id),
		data: {
			parentId: parentId,
			event: state,
		},
	});

	const ref = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
	const { initResize, size, cursor } = useResize(ref as MutableRefObject<HTMLDivElement>, {
		axis: 'horizontal',
	});
	const sizeW = useDebouncedValue(size.width, 200);

	const [sizeWidth, setSizeWidth] = useState(size.width);

	useEffect(() => {
		setSizeWidth(sizeW);
	}, [sizeW]);

	const handleResize = () => {
		const draggableElement = ref?.current;
		if (!isDragging && draggableElement && !Number.isNaN(sizeWidth)) {
			const draggableWidth = draggableElement.offsetWidth;
			if (draggableWidth !== sizeWidth) return;

			const splittedStartTime = state.startTime.split(':');

			const startDate = dayjs(
				dayjs
					.utc(isSameDate(state.startDate, currentDate) ? state.startDate : dayjs.utc(currentDate).startOf('day'))
					.format('DD/MM/YYYY'),
				'DD/MM/YYYY'
			)
				.hour(+splittedStartTime[0])
				.minute(+splittedStartTime[1])
				.format();

			let minutes = Math.floor(calculateMinutesFromWidth(draggableWidth));

			minutes = getMinutesDifferenceByStep(startDate, minutes, options.dndStepLength, true);

			const endDate = dayjs
				.utc(startDate)
				.add(minutes, 'minute')
				.set('milliseconds', 0)
				.set('seconds', 0)
				.toISOString();

			const onChangeData = {
				type: 'SLOT_RESIZE',
				data: {
					startDate: state.startDate,
					endDate: state.endDate,
					startTime: dayjs.utc(startDate).format('HH:mm'),
					endTime: dayjs.utc(endDate).format('HH:mm'),
					overId: parentId, // maybe should be targetId
					parentId,
					slotId: String(state.id),
				},
			};

			draggableElement.removeAttribute('style');
			setSizeWidth(calculateMarginLeftFromMinutes(minutes));

			updateDatesRange(state.id, startDate, endDate, onChangeData);
		}
	};

	useEffect(() => {
		handleResize();
	}, [sizeWidth]);

	const useHeight = top + height > oneHourWidth * 24 * daysCount + 6 ? oneHourWidth * 24 * daysCount + 6 - top : height;

	return (
		<div
			id={elementId}
			css={draggableContainerHorizontal(top - (groupTop || 0), groupCount, groupIndex)}
			ref={setNodeRef}
		>
			<div
				css={draggableContentHorizontal(!Number.isNaN(sizeWidth) && sizeWidth !== Infinity ? sizeWidth : useHeight)}
				ref={ref}
			>
				{children}
				{options.dragAndDrop && isEditable && (
					<div {...listeners} css={draggableHandler}>
						<DndIcon />
					</div>
				)}
				{options.resize && isEditable && (
					<div css={draggableResizeHandler(cursor)} onMouseDown={initResize}>
						<ResizeIcon />
					</div>
				)}
			</div>
		</div>
	);
};
