/** @jsx jsx */
/** @jsxRuntime classic */
import { jsx, css } from '@emotion/react';
import { FormSelect_v2, ISelect_V2, SelectedTags } from '@bondsports/utils';
import React, { useMemo } from 'react';
import { TranslationEn } from '@assets/i18n/en';
import { useFormMultipleSelect } from '@app/react/hooks/useFormMultipleSelect';
import { DURATION } from './constants';
import { MembershipMember } from '@bondsports/types';
import { TAGS_MAX_HEIGHT } from '@app/react/lib/constants';
import { DurationOption } from '@app/react/types/membershipMember';
import { getDurationLabel, getDurationOptionsData } from './utils';
import { FormApi } from 'final-form';
import { getFieldName } from '@app/react/forms/utils/utils';
import { fullWidth, marginTop } from '@app/react/styles/utils';

const mapDurationsToOptions = (data: string[]) => {
	return data?.map(getDurationOptionsData);
};

interface DurationsFormSelectProps extends Partial<ISelect_V2> {
	presetName?: string;
	required?: boolean;
	isMultiple?: boolean;
	initialItems: string[];
	onSelectCallback?: (customer: MembershipMember) => void;
	maxSelections?: number;
	labels?: {
		label: string;
	};
	selectedDurations?: string[];
	mapDataToOptions?: (data?: string[]) => DurationOption[];
	form: FormApi<any, Partial<any>>;
}

export const DurationFormSelect = ({
	presetName,
	required = false,
	maxSelections = 1,
	mapDataToOptions = mapDurationsToOptions,
	disabled,
	hideError,
	labels,
	initialItems,
	form,
	isWithTags = true,
	selectedDurations,
}: DurationsFormSelectProps) => {
	const items = useMemo(() => mapDataToOptions(initialItems), [initialItems]);
	const name = getFieldName(DURATION, presetName);
	const { selectedLabel, onClear } = useFormMultipleSelect({
		name,
	});

	const onSelect = (fieldName: string, value: string) => {
		form.mutators.onSelect(
			fieldName,
			[value]
		);
	};

	const handleClear = (fieldName: string) => {
		onClear(fieldName);
	};

	const handleRemoveOption = (valueToRemove: string) => {
		form.mutators.onSelect(
			name,
			selectedDurations?.filter(duration => duration !== valueToRemove) || []
		);
	};

	const tagsValues = useMemo(() => {
		return selectedDurations?.map((duration) => ({
			value: duration,
			label: getDurationLabel(duration),
		})) || [];
	}, [selectedDurations]);

	return (
		<div css={[fullWidth, marginTop(16)]}>
			<FormSelect_v2
				name={name}
				label={labels.label}
				placeholder={TranslationEn.general.placeholder.select}
				required={required}
				onSelecting={onSelect}
				onClear={handleClear}
				options={items}
				isGroups={false}
				selectedLabel={selectedLabel}
				maxHeight={400}
				isDisplayCounter
				newSelect
				value={selectedDurations?.[0]}
				valueTextAlignment="start"
				maxSelections={maxSelections}
				disabled={disabled}
				hideError={hideError}
				values={selectedDurations?.map(duration => String(duration)) || []}
				isWithTags={isWithTags}
				tags={
					<SelectedTags
						selectedValues={tagsValues}
						handleRemove={!disabled ? handleRemoveOption : undefined}
						maxHeight={TAGS_MAX_HEIGHT}
					/>
				}
			/>
	</div>
	);
};
