/** @jsx jsx */
import { colors } from 'app/react/styles/theme';
import { css, jsx } from '@emotion/react';

export const addButton = (isPrimary?: boolean) => css`
	text-decoration: underline;
	color: ${isPrimary ? colors.brandPrimary + '!important' : ''};
`;

export const RemoveButton = () => css`
	position: absolute;
	right: 0;
	top: 6px;
	z-index: 1;
	button {
		text-decoration: underline;
	}
`;

export const labelCss = css`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	color: ${colors.formInputBg};
	margin-top: 8px;
	padding: 0px;
	display: flex;
	align-items: center;
	font-size: 12px;
	text-transform: capitalize;
	line-height: 15px;
`;
