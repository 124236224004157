import { css } from '@emotion/react';
import { flexRowCss } from '@app/react/styles/utils';
import { colors } from '@app/react/styles/theme';

export const paymentPlanSelectorCss = css`
	${flexRowCss};

	svg {
		color: ${colors.lightText};
		height: 16px;
		width: 16px;
	}
`;

export const paymentPlanSelectInputsContainerCss = css`
	display: flex;
	justify-content: space-evenly;
`;

export const selectWidthCss = css`
	flex-grow: 1;
`;
