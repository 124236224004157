import { TranslationEn } from '../../../assets/i18n/en';
import { environment } from '../../../environments/environment';
import { organizationApi } from '../lib/api/organizationApi';
import { paymentApi } from '../lib/api/paymentApi';
import { auth } from '../lib/auth';
import { download } from '../lib/network';
import { ENotificationType } from '../stores/baseStore';
import { useLayout } from './useLayout';
import { useNotification } from './useNotification';
import { useOrganization } from './useOrganization';
import { buildQueryString } from '@app/react/lib/urlUtils';

type InvoiceURLParams = {
	print?: boolean;
	authorization?: string;
};

export const useInvoice = () => {
	const { setPopupNotification } = useNotification();
	const { organizationId } = useOrganization();
	const { disabledScreenToggle } = useLayout();

	const sendInvoice = async (
		organizationId: number,
		invoiceId: number,
		sendToAddress: string,
		deliveryMethod: 'email' | 'sms'
	) => {
		const data = { paymentId: invoiceId, sendToAddress, deliveryMethod };
		const res = await paymentApi.sendReceipt(organizationId, data);
		return res;
	};

	/**
	 * Creates a URL for the invoice in the consumer project
	 * If the print option is set to true, the URL will be for the print version of the invoice (/templates/invoice)
	 * @param invoiceId {number} - The ID of the invoice
	 * @param userId {number} - The ID of the invoice owner
	 * @param options {InvoiceURLParams} - Additional query parameters
	 */
	const createInvoiceUrl = (invoiceId: number, userId: number, options?: InvoiceURLParams): string => {
		const queryParams: string = buildQueryString({ o: organizationId, u: userId, ...options });

		if (options?.print) {
			return `${environment.CONSUMER_SITE_URL}/templates/invoice/${invoiceId}${queryParams}`;
		}

		return `${environment.CONSUMER_SITE_URL}/invoice/${invoiceId}${queryParams}`;
	};

	/**
	 * Opens a receipt for printing in a new tab of the consumer project
	 * @param userId Paying user id
	 * @param invoiceId Requested invoice to print
	 */
	const printInvoice = async (invoiceId: number, userId: number) => {
		const token = localStorage.getItem('id_token');
		const url = createInvoiceUrl(invoiceId, userId, { print: true, authorization: token });

		await window.open(url, '_blank');
	};

	const handleSendInvoice = (receiptsIds: number[], contactMethod: 'email' | 'sms', sendAddress: string) => {
		if (receiptsIds.every(receiptsId => receiptsId > 0)) {
			disabledScreenToggle(true);
			Promise.all(receiptsIds.map(receiptId => sendInvoice(organizationId, receiptId, sendAddress, contactMethod)))
				.then(res => {
					if (res.find(res => res.err)) {
						console.error(res.find(res => res.err));
					} else {
						setPopupNotification(TranslationEn.payments.sendReceipt.successMessage, ENotificationType.success);
					}
				})
				.catch(err => {
					console.error(err);
				})
				.finally(() => disabledScreenToggle(false));
		} else {
			console.error('Missing paymentId for send receipt');
		}
	};

	const handleDownloadPdf = async (invoiceId: number, payingUserId: number) => {
		disabledScreenToggle(true);
		const response = await organizationApi.downloadInvoicePdf(payingUserId, invoiceId, organizationId);
		if (!response?.content?.url) {
			disabledScreenToggle(false);
			setPopupNotification(TranslationEn.customers.paymentsInvoices.downloadFailed, ENotificationType.warning);
			return;
		}

		download(response.content.url, response.content.name);
		disabledScreenToggle(false);
		setPopupNotification(TranslationEn.customers.paymentsInvoices.downloadSuccess, ENotificationType.success);
	};

	return { handleSendInvoice, printInvoice, handleDownloadPdf, createInvoiceUrl };
};
