import type { DataGridColumnDef } from 'ui';
import { TranslationEn } from '@assets/i18n/en';
import { getPaymentMethodLabelByValue } from '@app/react/components/payments/utils/paymentMethods';
import { PaymentMethodTypeEnum } from '@bondsports/types';
import { filterPaymentMethods } from '@app/react/components/shared/ChooseInvoice/utils';
import { EStatusColorMapper } from '@app/react/types/customers';
import { EPaymentStatusInvoiceFilter } from '@app/react/types/payment';
import { Capitalize } from '@app/react/lib/form';
import { DateTimeFormats, formatDate } from '@bondsports/date-time';
import { InvoicePaymentStatus } from './utils/types';

export interface InvoiceColumnDef {
  createdAt: Date;
  id: number;
  itemsAmount: number;
  price: string;
  paymentType: PaymentMethodTypeEnum;
  paymentStatus: InvoicePaymentStatus;
}

const columns = TranslationEn.customers.paymentsInvoices;

export const invoicesColumns: DataGridColumnDef<InvoiceColumnDef>[] = [
  {
    key: 'createdAt',
    header: columns.createdOn,
    type: 'content',
    parse: (value) => {
      return {
        primaryContent: Capitalize(formatDate(value, DateTimeFormats.MONTH_DAY_COMMA_YEAR)),
        secondaryContent: formatDate(value, DateTimeFormats.H12_AMPM)
      };
    },
  },
  {
    key: 'id',
    header: columns.invoiceNum,
    type: 'text',
  },
  {
    key: 'itemsAmount',
    header: columns.items,
    type: 'text',
    format: columns.itemsAmount,
    sortable: false,
  },
  {
    key: 'price',
    header: columns.total,
    type: 'text',
    sortable: true,
  },
  {
    key: 'paymentType',
    header: columns.paymentMethod,
    sortable: false,
    type: 'text',
    filter: {
      paramName: 'paymentTypes',
      options: filterPaymentMethods,
    },
    parse: (value) => {
      return getPaymentMethodLabelByValue(value) ?? '-';
    }
  },
  {
    key: 'paymentStatus',
    header: columns.status,
    type: 'tags',
    filter: {
      paramName: 'paymentStatuses',
      options: Object.values(EPaymentStatusInvoiceFilter).map((value) => ({
        label: TranslationEn.customers.filterLabels[value],
        value,
      })),
    },
    parse: (status) => {
      return [{
          value: TranslationEn.paymentStatusToLabelMapper[status] ?? status,
          color: EStatusColorMapper[status],
      }];
    }
  }
];
