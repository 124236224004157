import { createContext } from 'react';

export const StepFlowContext = createContext({
	activeStep: 0,
	stepsCount: 4,
	nextStep: (optionalPath?: string) => {},
	previousStep: () => {},
	isNextStepAvailable: false,
	isPreviousStepAvailable: false,
	onClose: (closeOthers?: boolean) => {},
	handleStateProps: v => {},
});
