import { TranslationEn } from '@assets/i18n/en';
import React, { ButtonHTMLAttributes, useContext } from 'react';
import { Button, ButtonSize, ButtonVariant } from 'ui';
import { FormContext } from '../form';
import { isValuesChanged } from '@app/react/lib/form';

interface CancelButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	onClick?: () => void;
	label?: string;
}

export const CancelButton: React.FC<CancelButtonProps> = ({ onClick, label = TranslationEn.general.buttons.cancel, ...props }: CancelButtonProps) => {
	return (
		<Button
			variant={ButtonVariant.HYPERLINK}
			onClick={onClick}
			className="uppercase"
			size={ButtonSize.SM}
			data-testid="cancel-buttton"
			type="button"
			{...props}
		>
			{label}
		</Button>
	);
};

export const FormCancelButton: React.FC<CancelButtonProps> = ({ ...props }) => {
	const { values, initialValues, valid } = useContext(FormContext);
	return <CancelButton {...props} disabled={!isValuesChanged(values, initialValues) || !valid} />;
};
