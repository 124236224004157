import { useRecoilState } from 'recoil';
import { baseStore, IConfirmationAlert } from '../stores/baseStore';

export const useConfirmationAlert = () => {
	const [ConfirmationAlertStore, setConfirmationAlertStore] = useRecoilState(baseStore.ConfirmationAlertAtom);

	const { isShowing, message, onConfirmFunc, onCancelFunc, cancelButtonText, confirmButtonText, title } = ConfirmationAlertStore;

	const closeAlert = () => {
		setConfirmationAlertStore((props) => ({ ...props, isShowing: false }));
	}

	const onConfirm = () => {
		onConfirmFunc();
		closeAlert();
	}

	const onCancel = () => {
		onCancelFunc?.();
		closeAlert();
	}

	const setConfirmationAlert = (props: IConfirmationAlert) => {
		setConfirmationAlertStore({ ...props, isShowing: true });
	};

	return {
		setConfirmationAlert,
		closeAlert,
		isShowing,
		message,
		title,
		onConfirm,
		onCancel,
		confirmButtonText,
		cancelButtonText,
	};
};
