/** @jsx jsx */

import { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { Table } from '../../shared/NewTable';
import { TranslationEn } from 'assets/i18n/en';
import { colors } from '../../../styles/theme';
import { Footer } from '../utils/footer';
import { NO_SUBJECT } from '../../../types/helpers';
import { usePostData } from '@app/react/hooks/usePostData';
import { AlignType } from '../../shared/NewTable/types';

const containerCss = css`
	margin: 3rem 4rem;
	border: 1px solid ${colors.borderPrimary};
	border-radius: 5px;
	.MuiPaper-elevation1,
	.MuiTableContainer-root {
		border-radius: 5px;
	}
	th,
	td {
		padding: 6px 6px 6px 24px !important;
	}
	.MuiTableContainer-root {
		max-height: 200px !important;
	}

`;

const customItemCss = css`
	width: 5rem;
`;

export const ConfirmScheduled = ({ payments, onSubmit, toggle }) => {
	const { fetch, abort, isLoading } = usePostData(onSubmit, {
		confirmationAbortSettings: {
			enabled: true,
			title: TranslationEn.customers.paymentsInvoices.abortScheduledPaymentsRequest.title,
			message: TranslationEn.customers.paymentsInvoices.abortScheduledPaymentsRequest.message
		}
	})

	const columns = [
		{
			id: 'plannedDate',
			label: TranslationEn.customers.paymentsInvoices.date,
			type: 'date',
			styling: { align: AlignType.LEFT, asNarrowAsPossible: true },
		},
		{
			id: 'feeAmount',
			label: TranslationEn.customers.paymentsInvoices.fee,
			type: 'currency',
			styling: { align: AlignType.LEFT },
		},
		{
			id: 'totalPrice',
			label: TranslationEn.customers.paymentsInvoices.total,
			type: 'currency',
			styling: { align: AlignType.LEFT },
		},
	];

	const onCancel = () => {
		abort();
		toggle();
	}

	return (
		<Fragment>
			<div css={containerCss}>
				<Table rows={payments} columns={columns} isHoverRow={false} pagination={false} subject={NO_SUBJECT} maxHeight={200} />
			</div>
			<Footer
				toggle={onCancel}
				disabled={false}
				loader={isLoading}
				submitButtonText={TranslationEn.customers.paymentsInvoices.apply}
				onSubmit={fetch}
			/>
		</Fragment>
	);
};