import { PanelSlot } from '../types/slot';

/**
 * @description use this function to dispatch the event
 * @param eventType - using EventsDispatcher enum => make sure to update the enum if its some new event type
 * @param value - using the map by IDispatchValueMapper
 */
export function eventDispatcher<T extends EventsDispatcher>(eventType: T, value?: IDispatchValueMapper[T]) {
	const event = new CustomEvent(eventType, {
		detail: value,
	});
	window.dispatchEvent(event);
}

// The list of all the events to dispatch
export enum EventsDispatcher {
	CALENDAR_SLOT_UPDATED = 'calendar-slot-updated',
	DRAFT_SLOT_UPDATED = 'draft-slot-updated',
	REFETCH_CALENDAR = 'refetch-calendar',
}

// The Mapping of event to dispatch <> callback data
export interface IDispatchValueMapper {
	[EventsDispatcher.CALENDAR_SLOT_UPDATED]: ICalendarSlotDispatcher;
	[EventsDispatcher.DRAFT_SLOT_UPDATED]: IDraftSlotDispatcher;
	[EventsDispatcher.REFETCH_CALENDAR]: null;
}

interface ICalendarSlotDispatcher {
	slotId: number;
}

interface IDraftSlotDispatcher {
	updatedSlot?: PanelSlot;
	load?: boolean;
}
