/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx, useTheme } from '@emotion/react';
import { FC, useMemo, useState, useEffect, Fragment } from 'react';
import { BnIcon } from '../../../../components/icons_v2';
import { Icons } from '../../../../types';
import { useMiddlewareContext } from '../../../hooks/useMiddlewareContext';
import { IDayToDropProps } from '../../../types';
import { calculateModalPosition } from '../../../utils';
import { Droppable } from '../../Droppable';
import {
	collisionGroupContainerHorizontal,
	horizontalDayToDropContainer,
	moreItemsButtonHorizontal,
	moreModal,
} from '../../styles';
import { AddNewSlotButtonsContainer } from '../AddNewSlotButtons';
import { DraggableHorizontal } from './DraggableHorizontal';
import { ResourceNameTypeEnum } from '../../../../types/enums';
import { LoadingDailyContainer } from '../../LoadingDailyContainer';

export const DayToDropHorizontal: FC<IDayToDropProps> = ({
	id,
	EventComponent,
	onAddNewSlotClick,
	currentDate,
	isLoading,
}) => {
	const { getEventsGroups, day: daysCount, eventsToSpaces, options } = useMiddlewareContext();
	const colors = useTheme();

	const eventsGroups = useMemo(() => {
		const events = eventsToSpaces[id];
		return getEventsGroups(events, true);
	}, [eventsToSpaces, options.date]);

	const [modals, setModals] = useState<boolean[]>([]);

	useEffect(() => {
		setModals(Array(eventsGroups.length).fill(false));
	}, [eventsGroups]);

	const handleToggleModal = (index: number) => {
		const newModals = modals.slice();
		newModals[index] = !newModals[index];
		setModals(newModals);
	};

	const MAX_COUNT = 4;

	return (
		<div data-aid="DayToDropHorizontal" css={horizontalDayToDropContainer(daysCount, colors)}>
			{isLoading ? (
				<LoadingDailyContainer />
			) : (
				<Fragment>
					<AddNewSlotButtonsContainer parentId={id} horizontal={true} onClick={onAddNewSlotClick} />
					<Droppable id={id}>
						{eventsGroups.map((group, index) => {
							const withModal = (group.groups?.length || 0) > MAX_COUNT;

							if (Array.isArray(group.groups)) {
								const moreItemsCount = withModal
									? group.groups.slice(3).reduce((acc, events) => acc + events.length, 0)
									: 0;

								const element = document.querySelector(`#group-${index}`);
								const box = element as HTMLElement;
								const container = box?.parentElement;
								const boxW = container?.parentElement?.parentElement;
								const containerW = boxW?.parentElement;

								return (
									<div
										id={`group-${index}`}
										key={index}
										css={collisionGroupContainerHorizontal(group.top, group.height)}
									>
										{group.groups?.map((groupItems, index) => {
											return (
												<Fragment key={index}>
													{groupItems.map(slot => {
														const isEditable = (slot as any).isDraft
															? false
															: ResourceNameTypeEnum.RESERVATION === slot?.event?.parentType &&
															  String(id) === String(slot.spaceId) &&
															  !slot.parentSlotId;

														const draggableId = String(id) === String(slot.spaceId) ? slot.id : -1;
														return (
															<DraggableHorizontal
																parentId={id}
																state={slot}
																key={`DraggableHorizontal-${slot.id}`}
																id={draggableId}
																groupTop={group.top}
																groupCount={withModal ? MAX_COUNT : group.groups?.length}
																groupIndex={index}
																isEditable={isEditable}
																currentDate={currentDate}
															>
																<EventComponent event={slot} key={`EventComponent-${slot.id}`} horizontal />
															</DraggableHorizontal>
														);
													})}
												</Fragment>
											);
										})}
										{withModal && (
											<button
												data-aid="button-DayToDropHorizontal-more"
												css={moreItemsButtonHorizontal(colors)}
												onClick={() => handleToggleModal(index)}
											>
												<div>+{`${moreItemsCount} more`}</div>
											</button>
										)}
										{withModal && modals[index] && (
											<div css={moreModal(colors, calculateModalPosition(boxW, containerW, box, container))}>
												<div className="more-modal-header">
													<div onClick={() => handleToggleModal(index)}>
														<BnIcon icon={Icons.close} />
													</div>
												</div>
												<div className="events-list">
													{group.items.map(event => (
														<div key={event.id} css={{ margin: 2, height: '50px' }}>
															<EventComponent event={event} key={`EventComponent-${event.id}`} horizontal />
														</div>
													))}
												</div>
											</div>
										)}
									</div>
								);
							} else if (group.item) {
								const isEditable = (group.item as any).isDraft
									? false
									: ResourceNameTypeEnum.RESERVATION === group.item.event?.parentType &&
									  String(id) === String(group.item.spaceId) &&
									  !group.item.parentSlotId;

								const draggableId = String(id) === String(group.item.spaceId) ? group.item.id : -1;

								return (
									<DraggableHorizontal
										parentId={id}
										state={group.item}
										key={group.item.id}
										id={draggableId}
										isEditable={isEditable}
										currentDate={currentDate}
									>
										<EventComponent event={group.item} key={`EventComponent-${group.item.id}`} horizontal />
									</DraggableHorizontal>
								);
							} else {
								return null;
							}
						})}
					</Droppable>
				</Fragment>
			)}
		</div>
	);
};
