/** @jsxRuntime classic */
/* * @jsx jsx */
import React, { Fragment, LegacyRef, useMemo } from 'react';
import { StyledSelectOption } from './style';
import { useTheme, jsx, css } from '@emotion/react';
import { capitalize } from '../../functions';
import { Typography } from '../../atoms';
import { ETypography, ETypographyColor } from '../../types/theme';
import { Checkbox } from '../Checkbox';
import { ISelectOptions, IGroup } from './types';
import { IOption } from '../../types';
import { SelectOption } from '../Select_v2/SelectOption';
import { GroupsOptions } from './GroupsOptions';
import { flexColumn, flexCss, paddingCss } from '../../styles/utils';
import { i18n } from '../../lib/i18n';
import { Loader } from '../Loader';
import { SizeEnum } from '../../lib/constants';
import { ErrorComponent } from '../Error';

const loaderContainerCss = css`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 16px;
`;

export const SelectOptions = ({
	options,
	onSelect,
	value = [],
	autoFocus = true,
	isMultiple = false,
	enableSelectAll = false,
	selectAllLabel = '',
	disableSelectAll,
	onSelectAll,
	size = SizeEnum.MEDIUM,
	lastOptionElementRef,
	isGroups = false,
	isLoading = false,
	isError = false,
	isAllSelected = false,
	NoResultsComponent,
}: ISelectOptions) => {
	const colors = useTheme();

	const selectedValuesArray = useMemo(() => {
		return Array.isArray(value) ? value : value ? [value] : [];
	}, [value]);

	const memoizedHandleSelectAll = useMemo(() => onSelectAll, [onSelectAll]);

	const isEmptyOptions = options.length === 0 || (isGroups && Object.keys(options).length === 0);
	const isAllowSelectAll = !isGroups && options.length > 0 && isMultiple && enableSelectAll;
	const labels = i18n.components.select;
	const noResults = isEmptyOptions && !isLoading && !isError;

	return (
		<div css={flexColumn}>
			{noResults && !NoResultsComponent && (
				<div css={paddingCss(12)}>
					<Typography color={ETypographyColor.primary} type={ETypography.captionAccented}>
						{i18n.noResults}
					</Typography>
				</div>
			)}
			{noResults && Boolean(NoResultsComponent) && <div css={paddingCss(12)}>{NoResultsComponent}</div>}
			{isAllowSelectAll && (
				<StyledSelectOption
					theme={{ ...colors }}
					size={size}
					onClick={() => {
						if (!disableSelectAll && memoizedHandleSelectAll) {
							memoizedHandleSelectAll();
						}
					}}
					tabIndex={0}
					autoFocus={false}
					isBorder
					isDisabled={disableSelectAll}
				>
					<Typography
						color={disableSelectAll ? ETypographyColor.disabled : ETypographyColor.secondary}
						type={size === 'M' ? ETypography.body2 : ETypography.cta1}
					>
						<span>
							{isMultiple && (
								<Checkbox
									width={16}
									setChecked={() => {}}
									checked={isAllSelected}
									isDisabled={disableSelectAll}
									functionDisable
								/>
							)}

							{capitalize(selectAllLabel || labels.selectAll)}
						</span>
					</Typography>
				</StyledSelectOption>
			)}
			{isGroups ? (
				<GroupsOptions
					groups={options as IGroup[]}
					onSelect={onSelect}
					isMultiple={isMultiple}
					selectedValuesArray={selectedValuesArray}
					autoFocus={autoFocus}
				/>
			) : (
				<Fragment>
					{(options as IOption[]).map((opt, index) => {
						const optionVal = String(opt.value);

						return (
							<SelectOption
								key={opt.value}
								lastOptionElementRef={
									lastOptionElementRef && options.length - 1 === index ? lastOptionElementRef : undefined
								}
								colors={colors}
								index={index}
								size={size}
								onSelect={onSelect}
								opt={opt as IOption}
								autoFocus={autoFocus}
								isMultiple={isMultiple}
								selectedValuesArray={selectedValuesArray}
								value={value}
								optionVal={optionVal}
								isAllSelected={isAllSelected}
							/>
						);
					})}
					{isLoading && (
						<div css={loaderContainerCss}>
							<Loader />
						</div>
					)}
					{isError && <ErrorComponent otherStyles={paddingCss(8)}>{labels.error}</ErrorComponent>}
				</Fragment>
			)}
		</div>
	);
};
