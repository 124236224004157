import { addToDate, DateTimeFormats, FrequencyEnum, RepetitionUnit } from '@bondsports/date-time';
import { Dispatch, useMemo, useReducer } from 'react';
import { ActionsEnum, IRollingPaymentPlanState, RollingPaymentPlanActions, RollingPaymentPlanReturn } from '../types/types';
import { splitPriceAmount } from '@bondsports/general';
import dayjs from 'dayjs';

function reducer(state: IRollingPaymentPlanState, action: RollingPaymentPlanActions) {
    switch (action.type) {
        case ActionsEnum.SET_STATE: {
            return { ...state, ...action.payload };
        }
    }
};

export const useRollingPaymentPlan = (previousState?: IRollingPaymentPlanState): RollingPaymentPlanReturn => {

	const defaultState = { repeat: FrequencyEnum.MONTHLY };

	const [rollingState, dispatch]: [IRollingPaymentPlanState, Dispatch<RollingPaymentPlanActions>] = useReducer(reducer, previousState ? previousState : defaultState);

	const OnChange = (newState: IRollingPaymentPlanState) => {
		dispatch({ type: ActionsEnum.SET_STATE, payload: { ...rollingState, ...newState } });
	};

	const isValid = useMemo(() => {
		const { startDate, count, repeat } = rollingState;
		if (!startDate || !count ) return false;

        if (count > 1 && !repeat) {
            return  false;
        }
        return true;
	}, [rollingState]);

    const getNextPaymentDate = (firstPaymentDate, index) => {
		const duration = rollingState.repeat === FrequencyEnum.WEEKLY ? RepetitionUnit.WEEK : RepetitionUnit.MONTH;
		const nextDate = addToDate(firstPaymentDate, index, duration, dayjs.tz.guess());
		return dayjs(nextDate).format(DateTimeFormats.YYYY_MM_DD).toLocaleLowerCase();
	};

	const getPaymentSchedule = (totalAmount) => {
		const firstPayment = rollingState.startDate;

		const splitAmounts = splitPriceAmount(totalAmount, rollingState.count);
		const payments = [];
		for (let i = 0; i < rollingState.count; i++) {
			payments.push({
				plannedDate: getNextPaymentDate(firstPayment, i),
				price: splitAmounts[i],
			});
		}

		return payments;
	};

	return {
        rollingPlanState: rollingState,
		isValidRollingPlan: isValid,
        OnRollingPlanChange: OnChange,
        getRollingPaymentSchedule: getPaymentSchedule,
	};
};
