import { CookiesWorker } from '@app/shared/services/auth/CookiesWorker';
import Cookies from 'js-cookie';

function getToken() {
	const idTokenCookie: string = Cookies.get('BondUserIdToken');

	return idTokenCookie || false;
}

export interface Prop {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
}
function getUserId() {
	return CookiesWorker.getBondUserDetails().id;
}

function getAuthHeaders() {
	const options = {
		headers: {
			accept: 'application/json, text/plain, */*',
			'Content-Type': 'application/json',
		},
	};

	return options;
}

function getUserEmail() {
	return CookiesWorker.getBondUserEmail();
}

const getAuthOrganizationId = () => {
	return localStorage.getItem('selected_org_id');
};

const handleLogout = () => {
	localStorage.clear();
	CookiesWorker.clearUserDetailsCookie();

	if ((window as any).router) {
		(window as any).router.navigate(['/login']);
	} else {
		window.location.href = '/login';
	}
};

export const auth = {
	getToken,
	getUserId,
	getAuthHeaders,
	getUserEmail,
	getAuthOrganizationId,
	handleLogout,
};
