export type TAllowedColors =
	| 'blue'
	| 'yellow'
	| 'red'
	| 'green'
	| 'purple'
	| 'gray'
	| 'darkGray'
	| 'tertiary'
	| 'pink'
	| 'orange';

export enum ETagAllowedColors {
	Blue = 'blue',
	Yellow = 'yellow',
	Red = 'red',
	Green = 'green',
	Purple = 'purple',
	Gray = 'gray',
	DarkGray = 'darkGray',
	Tertiary = 'tertiary',
	Pink = 'pink',
	Orange = 'orange',
}

export type TTypes = 'basic' | 'outline';
export interface TagProps {
	title: string;
	color: TAllowedColors | ETagAllowedColors;
	type?: TTypes;
	padding?: string;
	fontWeight?: number;
}
