/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx, useTheme } from '@emotion/react';
import { FC, useMemo, useState, useEffect, Fragment } from 'react';

import { useMiddlewareContext } from '../../hooks/useMiddlewareContext';
import { IDayToDropProps } from '../../types';
import { Droppable } from '../Droppable';
import { weekDayToDropContainer } from '../styles';
import { AddNewSlotButtonsContainer } from './AddNewSlotButtons';
import { DraggableSlot } from './DraggableSlot';
import { SlotGroups } from './SlotGroup';
import { LoadingDailyContainer } from '../LoadingDailyContainer';

const MAX_COUNT = 4;

export const DayToDrop: FC<IDayToDropProps> = ({
	id,
	EventComponent,
	withoutChildren,
	isModeChanged,
	onAddNewSlotClick,
	currentDate,
	isLoading,
}) => {
	const [modals, setModals] = useState<boolean[]>([]);
	const { getEventsGroups, eventsToSpaces, options } = useMiddlewareContext();
	const colors = useTheme();

	const eventsGroups = useMemo(() => {
		if (!isModeChanged) {
			const events = eventsToSpaces[id];
			if (events?.length > 0) {
				return getEventsGroups(events);
			}
		}
		return [];
	}, [eventsToSpaces, isModeChanged, options.date]);

	useEffect(() => {
		setModals(Array(eventsGroups.length).fill(false));
	}, [eventsGroups]);

	const handleToggleModal = (index: number) => {
		const newModals = modals.slice();
		newModals[index] = !newModals[index];
		setModals(newModals);
	};

	return (
		<div css={weekDayToDropContainer(colors, withoutChildren)}>
			{isLoading ? (
				<LoadingDailyContainer />
			) : (
			<Fragment>
				<AddNewSlotButtonsContainer parentId={id} onClick={onAddNewSlotClick} />
				<Droppable id={id}>
					{eventsGroups.map((group, index) => {
						const withModal = (group.groups?.length || 0) > MAX_COUNT;

						if (Array.isArray(group.groups)) {
							return (
								<SlotGroups
									key={`SlotGroups-${index}`}
									group={group}
									withModal={withModal}
									index={index}
									id={id}
									EventComponent={EventComponent}
									handleToggleModal={handleToggleModal}
									colors={colors}
									modals={modals}
									currentDate={currentDate}
								/>
							);
						} else if (group.item) {
							const slot = group.item;
							return (
								<DraggableSlot
									key={`Draggable-Slot-${slot.id}`}
									slot={slot}
									parentId={id}
									EventComponent={EventComponent}
									currentDate={currentDate}
								/>
							);
						} else {
							return null;
						}
					})}
				</Droppable>
			</Fragment>
			)}
		</div>
	);
};
