import React, { FC } from 'react';
import { jsx, css } from '@emotion/react';
import { SizeEnum } from '../../../lib/constants';
import { ETypographyColor } from '../../../types';
import { Checkbox } from '../../../components/Checkbox';
import { ThemeColors } from '../../../styles/theme';

interface Props {
	isDisabled: boolean;
	checked: boolean;
	action: (val: boolean) => void;
	label: string;
	sizer?: SizeEnum;
	isShown?: boolean;
	color?: ETypographyColor;
	margin?: string;
}

export const ApplyMethodCheckbox: FC<Props> = ({ label, isDisabled, checked, action, color, margin, isShown = false }) => {
	return isShown ? (
		<Checkbox
			label={label}
			isDisabled={isDisabled}
			margin={margin}
			data-aid="Apply-Payment-Method-to-future-checkbox"
			checked={checked}
			setChecked={action}
			color={ThemeColors.bg_background_fields}
			isDisplayAsBlock={false}
			sizer={SizeEnum.SMALL}
		/>
	) : null;
};
