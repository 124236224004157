/** @jsx jsx */
import { Fragment, useEffect, useState, ComponentType, useMemo } from 'react';
import { jsx, css } from '@emotion/react';
import { colors } from '../../../styles/theme';
import { CellMapper } from './Cell';
import { cellCss } from './styles';
import { checkboxCss, iconCss, checkboxContainer, CheckedIconCss } from '../../../styles/utils';
import { Radio, Checkbox } from '@bondsports/utils';
import { CustomCheckbox } from '../Checkbox';
import { ETableSource } from './types';

const makeCellKey = (id, index) => `CellMapper_key:${id}-${index}`;

interface ExpandableRowProps {
	row: any;
	columns: any;
	isItemSelected: boolean;
	isRadio: boolean;
	expandableKey: string;
	expandable: boolean;
	handleClick: any;
	isSelectRow: boolean;
	subBottomBorder: boolean;
	labelId: string;
	expandOn: string[];
	isSelected: any;
	isLast: boolean;
	isHoverRow?: boolean;
	isExpand?: boolean;
	source?: ETableSource;
	disableCheckbox?: boolean;
	isSubCheckboxDisabled?: (row: any) => boolean;
	RowWrapper?: ComponentType<{ rowKey: string | number; isItemSelected?: boolean }>;
	ExpandedRowWrapper?: ComponentType<{ key: string | number; isItemSelected?: boolean }>;
	disable?: boolean;
	timezone?: string;
}

const checkboxCell = css`
	background: ${colors.lightGray};
`;

const radioContainerCss = css`
	display: flex;
	align-items: center;
	justify-content: center;
	label {
		margin: 0 !important;
	}
	.checkmark {
		transform: translateY(-50%);
	}
`;

export const ExpandableRow = ({
	row,
	columns,
	isItemSelected,
	expandableKey,
	handleClick,
	isSelectRow,
	labelId,
	expandOn,
	isRadio,
	expandable,
	isSelected,
	isLast,
	isExpand,
	source,
	disableCheckbox,
	isSubCheckboxDisabled,
	RowWrapper = Fragment,
	ExpandedRowWrapper = Fragment,
	disable,
	timezone,
}: ExpandableRowProps) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	useEffect(() => {
		setIsExpanded(isExpand);
	}, [isExpand]);

	const optionalWrapperProps = useMemo(
		() => (RowWrapper === Fragment ? {} : { isItemSelected, isDisabled: row.isDisabled }),
		[RowWrapper]
	);
	const optionalExpandedWrapperProps = useMemo(
		() => (ExpandedRowWrapper === Fragment ? {} : { isItemSelected }),
		[ExpandedRowWrapper]
	);

	return (
		<Fragment>
			<RowWrapper
				rowKey={source === ETableSource.PARTICIPANTS ? row.title?.replace(/ /g, '-') ?? '' : row.id ?? ''}
				{...optionalWrapperProps}
			>
				{isSelectRow && (
					<td
						data-aid="checkbox-td-expand"
						className="TableCell-root TableCell-body"
						css={cellCss({ isAction: true, isLast })}
						onClick={event => {
							if (!disableCheckbox || (disableCheckbox && isItemSelected)) {
								event.stopPropagation();
								handleClick(row.id);
							}
						}}
						aria-checked={isItemSelected}
						role="checkbox"
					>
						{isRadio ? (
							<div css={radioContainerCss}>
								<Radio value={row.id} checked={isItemSelected} />
							</div>
						) : (
							<CustomCheckbox
								disabled={disableCheckbox && !isItemSelected}
								checked={isItemSelected}
								inputProps={{ 'aria-labelledby': labelId }}
								css={checkboxCss(false)}
								checkedIcon={<span id="checked" css={CheckedIconCss} />}
								icon={<span css={iconCss} />}
							/>
						)}
					</td>
				)}
				{columns.map((column, index) => {
					return (
						<CellMapper
							expandableKey={expandableKey}
							key={makeCellKey(column.id, index)}
							isLast={isLast}
							setIsExpanded={setIsExpanded}
							isExpanded={isExpanded}
							type={column.type}
							column={column}
							row={row}
							index={index}
							expandOn={expandOn}
							source={source}
							timezone={timezone}
						/>
					);
				})}
			</RowWrapper>
			{isExpanded &&
				expandable &&
				row[expandableKey] &&
				row[expandableKey].map((subRow, sindex) => {
					return (
						<ExpandedRowWrapper key={subRow.name + sindex} {...optionalExpandedWrapperProps}>
							{isSelectRow && (
								<td
									data-aid="checkbox-td-expand-sub"
									className="TableCell-root TableCell-body"
									css={[checkboxCell, checkboxContainer]}
									aria-checked={isItemSelected}
									role="checkbox"
								>
									<Checkbox
										checked={isSelected(subRow.id)}
										isDisabled={isSubCheckboxDisabled && isSubCheckboxDisabled(subRow)}
										setChecked={() => {
											return !isSubCheckboxDisabled(subRow) && handleClick(subRow?.id);
										}}
									/>
								</td>
							)}
							{columns.map((column, index) => {
								return (
									<CellMapper
										expandableKey={expandableKey}
										isExpanded={isExpanded}
										key={`${index} - ${sindex}`}
										type={column.type}
										column={column}
										row={subRow}
										index={index}
										expandOn={expandOn}
										setIsExpanded={() => {}}
										source={source}
										timezone={timezone}
									/>
								);
							})}
						</ExpandedRowWrapper>
					);
				})}
		</Fragment>
	);
};
