/** @jsx jsx */
import * as React from 'react';
import { jsx, css } from '@emotion/react';
import { TranslationEn } from '@assets/i18n/en';
import { colors } from '@app/react/styles/theme';
import { Conflict, Dialog, DialogSimpleLayout } from 'ui';

interface Props {
	toggle: () => void;
	isShowing: boolean;
	message?: string;
	title?: string;
}
export const FailureModal = ({ toggle, isShowing, title, message }: Props) => {
	const labels = TranslationEn.singleReservationDisplay.resources.actions.deleteEvent.failureModal;

	return (
		<Dialog open={isShowing} onOpenChange={toggle}>
			<DialogSimpleLayout
				icon={<Conflict color={colors.dangerRed} />}
				title={title ?? (message ? labels.failToRefund : labels.title)}
				description={message ?? labels.description}
				onPrimaryAction={toggle}
				primaryButton={labels.buttonText}
			/>
		</Dialog>
	);
};
