/** @jsx jsx */
import React, { useContext, useMemo } from 'react';
import { css, jsx } from '@emotion/react';
import { FormContext, FormWrapper } from '@app/react/components/shared/form';
import { PauseFormProvider, usePauseFormContext } from './pauseFormProvider';
import { FlexWrapLayout } from 'ui';
import { PauseForm } from './components/pauseForm';
import { PauseSummaryBody } from '@app/react/components/memberships/PauseMembershipModal/pauseSummaryStep';
import { PauseMembershipSteps } from '@app/react/components/memberships/types/members.enums';
import { PauseHistoryStep } from '@app/react/components/memberships/PauseMembershipModal/pauseHistoryStep';
import { PauseFormProps, PauseStepProps } from './types/interfaces';
import { addToDateMonths, isLater } from '@bondsports/date-time';
import { CancellationStatusEnum } from '@bondsports/types';
import { PauseMembersFormFieldsEnum } from './types';
import { calculateOutOfRangeDurationMonthsOffset } from '@app/react/components/customers/PageBodyMapper/MembershipTab/membershipTabUtils';

export const CreatePause = ({ 
	organizationId,
	isLoading,
	isLoadingHistory,
	prevStep,
	handlePause,
	nextStep,
	handleReset,
	handlePauseHistoryClick,
	pauseHistory,
	currentStep,
	timezone,
	selectedMember,
	isFamilyMembership,
	isEdit = false,
	data,
	updateData,
}: PauseFormProps) => {

	const generateInitialValues = (data: Record<string, any>) => {
		return {
			startDate: data.startDate ? data.startDate : null,
			duration: data.duration ? data.duration.amount : null,
			pauseDurationValue: [String(data.duration?.amount)],
			reason: [data.reason?.id],
			description: data.pauseDescription,
		};
	};

	const initVals = useMemo(() => (isEdit && data ? generateInitialValues(data) : {}), [data]);

	return (
		<FlexWrapLayout containerClassName="gap-2">
			{((isEdit && data) || (!isEdit && !isLoading)) && (
				<PauseFormProvider
					data={data}
					updateData={updateData}
					organizationId={organizationId}
					isEdit={isEdit}
				>
					<FormWrapper initialValuesProps={initVals} submit={() => {}}>
						<PauseFormStep 
							currentStep={currentStep} 
							prevStep={prevStep}
							handlePause={handlePause}
							nextStep={nextStep}
							handlePauseHistoryClick={handlePauseHistoryClick}
							pauseHistory={pauseHistory}
							timezone={timezone} 
							handleReset={handleReset}
							isLoadingHistory={isLoadingHistory}
							isFamilyMembership={isFamilyMembership}
							selectedMember={selectedMember} 
						/>
					</FormWrapper>
				</PauseFormProvider>
			)}
		</FlexWrapLayout>
	);
};

const PauseFormStep = ({
	selectedMember,
	timezone,
	nextStep, 
	prevStep,
	currentStep,
	handlePause,
	handleReset,
	isFamilyMembership,
	handlePauseHistoryClick,
	isLoadingHistory,
	pauseHistory
}: PauseStepProps) => {
	const { form, getFieldValue } = useContext(FormContext);
	const { organizationId, isEdit } = usePauseFormContext();
	const pauseStartDate = getFieldValue<Date>(PauseMembersFormFieldsEnum.startDate);  

	const handleNext = () => {
		nextStep();
	};

	const handleResetAndClose = () => {
		form.reset();
		handleReset();
	};

	const step = useMemo(() => {
		switch (currentStep) {
			case PauseMembershipSteps.PAUSE_FORM:
				const memberEndDate: string = selectedMember.cancellationStatus === CancellationStatusEnum.AUTO_RENEWAL ? selectedMember.originalEndDate : null;
				return <PauseForm  
						isEdit={isEdit}
						showWarning={selectedMember.hasPauseHistory}
						status={selectedMember.membershipStatus}
						memberEndDate={memberEndDate}
						organizationId={organizationId}
						handleReset={handleResetAndClose}
						onClick={handleNext}
						handlePauseHistoryClick={handlePauseHistoryClick}
						/>;
			case PauseMembershipSteps.PAUSE_SUMMARY:
				const isPauseOutOfRange: boolean = isLater(pauseStartDate, selectedMember.originalEndDate);
				const initialDurationMonthsOffset: number = calculateOutOfRangeDurationMonthsOffset(isPauseOutOfRange, selectedMember.durationMonths, selectedMember.originalEndDate, String(pauseStartDate));
				const endDateWithRangeOffset: Date = addToDateMonths(selectedMember.originalEndDate, initialDurationMonthsOffset);

				return <PauseSummaryBody
					timezone={timezone}
					initialEndDate={endDateWithRangeOffset}
					handleBack={prevStep}
					isEditMode={isEdit}
					showWarning={isFamilyMembership}
					handlePause={handlePause}
				/>;
			case PauseMembershipSteps.PAUSE_HISTORY:
				return <PauseHistoryStep
					isLoading={isLoadingHistory}
					pauseHistory={pauseHistory}
				/>;
			default:
				return null;
		}
	}, [currentStep, selectedMember, isEdit, pauseStartDate, organizationId, pauseHistory, timezone]);

	return step;
};
