/** @jsxRuntime classic */
/** @jsx jsx */
import React from "react";
import { Button } from "../../components/Button";
import { Typography } from "../../atoms";
import { ETypography, ETypographyColor } from "../../types";
import { css, jsx } from '@emotion/react';

const reservationNumberCss = css`
    padding: 0px !important;
`;

export interface IdHyperlinkLabels {
    id: (id: number) => string
}

export const IdHyperlink = ({
    id,
    isHyperlink,
    onClick,
    labels
} : {
    id: number,
    isHyperlink: boolean,
    onClick?: () => void,
    labels: IdHyperlinkLabels
}) => {
    const label = labels.id(id);
    return isHyperlink ? (
        <div>
            <Button
                data-aid="button-popupBody-res"
                sizer="XS"
                theme="basic"
                onClick={onClick}
                css={reservationNumberCss}
            >
                <Typography color={ETypographyColor.secondary} type={ETypography.captionLink}>
                    {label}
                </Typography>
            </Button>
        </div>
    ) : (
        <div>
            <Typography color={ETypographyColor.primary} type={ETypography.caption}>
                {label}
            </Typography>
        </div>
    );
}
