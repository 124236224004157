import { ReactNode } from 'react';
import { atom, AtomEffect, DefaultValue, selector } from 'recoil';
import { localStorage } from '../lib/storage';
import { EStorageKeys } from '../types/enums';

interface NameAndCounter {
	name: string;
	counter: number;
}

interface localStorageEffectOptions {
	defaultValue?: any;
}
export const localStorageEffect =
	<T>(key: EStorageKeys, options: localStorageEffectOptions = {}): AtomEffect<T> =>
	({ setSelf, onSet }) => {
		const savedValue = localStorage.getItem(key) || options.defaultValue || {};

		setSelf(savedValue);

		onSet(newValue => {
			if (newValue instanceof DefaultValue) {
				localStorage.removeItem(key);
			}

			localStorage.setItem(key, newValue);
		});
	};

const nameAndCounter = atom<NameAndCounter | null>({
	key: 'nameAndCounter',
	default: null,
	effects_UNSTABLE: [localStorageEffect(EStorageKeys.BASE_STORE, { defaultValue: { name: 'init', counter: 0 } })],
});

const NotificationAtom = atom<{ isShowing: boolean; notification: ReactNode | string; toastType?: ENotificationType }>({
	key: 'notification',
	default: {
		isShowing: false,
		notification: '',
	},
});

export enum ENotificationType {
	warning = 'warning',
	action_confirmed = 'action_confirmed',
	info = 'info',
	success = 'success',
}

export enum EPopupNotificationType {
	INLINE = 'inline',
	TERMINAL = 'terminal',
	TOAST = 'toast',
}

const notificationType = atom<ENotificationType | EPopupNotificationType>({
	key: 'notificationType',
	default: EPopupNotificationType.INLINE,
});

type TPermission = { id: number; name: string };
const permissions = atom<TPermission[]>({
	key: 'baseStorePermissions',
	default: [],
});

const roles = atom<any[]>({
	key: 'roles',
	default: [],
});

const isOpen = atom<boolean>({
	key: 'isOpen',
	default: false,
});

export type IErrorModal = {
	isShowing: boolean;
	message: string;
	title?: string;
	goBack?: boolean;
	isDismissable?: boolean;
	approve?: boolean;
	isNewUi?: boolean;
	tryAgainText?: string;
};

const ErrorModalAtom = atom<IErrorModal>({
	key: 'ErrorModalAtom',
	default: {
		isShowing: false,
		message: '',
		title: '',
		goBack: false,
		isDismissable: true,
		approve: false,
	},
});


export type IConfirmationAlert = {
	isShowing?: boolean;
	message: string;
	title?: string;
	onConfirmFunc: () => void;
	onCancelFunc?: () => void;
	confirmButtonText?: string;
	cancelButtonText?: string;
	
};

const ConfirmationAlertAtom = atom<IConfirmationAlert>({
	key: 'ConfirmationAlertAtom',
	default: {
		isShowing: false,
		message: '',
		title: '',
		onConfirmFunc: () => {},
		cancelButtonText: '',
		confirmButtonText: '',
	},
});


export const baseStore = {
	nameAndCounter,
	NotificationAtom,
	notificationType,
	roles,
	permissions,
	isOpen,
	ErrorModalAtom,
	ConfirmationAlertAtom
};
