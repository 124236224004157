/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Fragment, useMemo } from "react"
import { IExpandedSlot, ISlot, MaintenanceTimingEnum, SlotTypeEnum } from "../types"
import { Dot, Typography } from "../../../atoms"
import { css, jsx } from "@emotion/react";
import { ETypography, ETypographyColor, IColors, Icons } from "../../../types";
import { useColors } from "../../../hooks/useColors";
import { BnIcon } from '../../../components/icons_v2';
import { flexWrapCss } from "../../../styles/utils";
import { ETimeFormat, diffCalculationFromDateTime, formatTime, isSameDate } from "../../../calendar/utils/timeUtils";
import { Dash } from "../../../components/Dash";
import { Tooltip } from "../../../components/Tooltip";
import { ClampOrTruncateWithTooltip } from "../../../molecules/ClampOrTruncateTextWithTooltip";
import { format } from "path";

const truncateDiv = css`
    div {
        display: block;
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis;
    }
`;

const noFlexWrapCss = css`
    display: flex;
    white-space: nowrap; 
    overflow: hidden; 
`;

const noFlexShrinkCss = css`
    flex-shrink: 0;
    align-items: flex-start;
    white-space: nowrap !important;
`;

const mainBodyLineCss = (colors: IColors) => css`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 100%;
    margin: 0.7rem 0rem;
    gap: 0.86rem;
    svg {
        color: ${colors.tx_text_primary};
        width: 1.7rem;
        height: 1.7rem;
        flex: 0 0 auto;
    }
`;

const textLineCss = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    gap: 0.3rem;
`;

const maintenaceTitleCss = css`
    display: block !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: flex-start;
`;

const bodyCss = css`
	padding: 1rem 2rem;
	display: flex;
	flex-direction: column;
`;

export interface IMainBodyLabels {
    duration: (duration: string) => string
    viewMoreMaintenace: (
        title: string, 
        maintenanceTiming: string, 
        startDate: string, 
        startTime: string, 
        durationValue: string, 
        durationUnit: string
    ) => string,
    viewMoreButton: (count: string|number) => string,
    maintenanceTiming: {
        AFTER: string,
        BEFORE: string,
        AT_THE_BEGINING: string,
        AT_THE_END: string
    },
    durationUnitTypes: {
		weeks: string,
		minutes: string,
		hours: string,
		days: string
	},
}

export const MainBody = ({
    expandedSlot,
    labels
} : {
    expandedSlot: IExpandedSlot
    labels: IMainBodyLabels
}) => {
	const { colors } = useColors();

    const TruncatedBodyLine = ({
        text,
        icon
    } : {
        text: string
        icon: Icons
    }) => {
        const mainBodyLineStyle = useMemo(() => mainBodyLineCss(colors), [colors]);
        return <div css={[mainBodyLineStyle, truncateDiv]}>
            <BnIcon icon={icon} />
            <Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
                {text}
            </Typography>
        </div>
    }

    const Customer = () => {
		return expandedSlot?.slotType === SlotTypeEnum.EXTERNAL && (expandedSlot?.customer?.[0]?.firstName || expandedSlot?.customer?.[0]?.lastName)  ? (
            <TruncatedBodyLine 
                text={`${expandedSlot.customer[0].firstName} ${expandedSlot.customer[0].lastName}`}
                icon={Icons.profile_outline}
                data-aid="popupBody-customer"
            />
		) : null;
	};

	const TimeFrame = () => {
		return expandedSlot?.startDate && expandedSlot?.endDate && expandedSlot?.startTime && expandedSlot?.endTime ? (
			<div data-aid="popupBody-timeFrame" css={[mainBodyLineCss(colors)]}>
                <BnIcon icon={Icons.clock} />
                <div css={[textLineCss, flexWrapCss]}>
                    <Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
                        {formatTime(expandedSlot.startDate, ETimeFormat.SHORT_DATE)}
                    </Typography>
                    <Dot color={colors.tx_text_primary} />
                    {isSameDate(expandedSlot.startDate, expandedSlot.endDate) ?
                        (<div css={[textLineCss, noFlexWrapCss]}>
                            <Typography color={ETypographyColor.primary} type={ETypography.body2}>
                                {formatTime(expandedSlot.startTime, ETimeFormat.TIME_12H_NO_SPACE, ETimeFormat.TIME_24H)}
                            </Typography>
                            <Dash color={ETypographyColor.primary} type={ETypography.body2}/>
                            <Typography color={ETypographyColor.primary} type={ETypography.body2}>
                                {formatTime(expandedSlot.endTime, ETimeFormat.TIME_12H_NO_SPACE, ETimeFormat.TIME_24H)}
                            </Typography>
                        </div>
                        ) : (
                        <Fragment>
                            <Typography color={ETypographyColor.primary} type={ETypography.body2}>
                                {formatTime(expandedSlot.startTime, ETimeFormat.TIME_12H_NO_SPACE, ETimeFormat.TIME_24H)}
                            </Typography>
                            <Dash color={ETypographyColor.primary} type={ETypography.body2Accented}/>
                            <Typography color={ETypographyColor.primary} type={ETypography.body2Accented}>
                                {formatTime(expandedSlot.endDate, ETimeFormat.SHORT_DATE)}
                            </Typography>
                            <Dot color={colors.tx_text_primary} />
                            <Typography color={ETypographyColor.primary} type={ETypography.body2}>
                                {formatTime(expandedSlot.endTime, ETimeFormat.TIME_12H_NO_SPACE, ETimeFormat.TIME_24H)}
                            </Typography>
                        </Fragment>
                    )}
                    <Dot color={colors.tx_text_primary} />
                    <Typography color={ETypographyColor.secondary} type={ETypography.body2}>
                        {labels.duration(diffCalculationFromDateTime(
                            expandedSlot.startDate, 
                            expandedSlot.startTime, 
                            expandedSlot.endDate, 
                            expandedSlot.endTime
                        ))}
                    </Typography>
                </div>
			</div>
		) : null;
	};

	const Location = () => {
		return expandedSlot?.resource?.name ? (
            <TruncatedBodyLine 
                text={expandedSlot.resource.name}
                icon={Icons.location}
                data-aid="popupBody-location"
            />
        ) : null;
	};

    const Product = () => {
        return expandedSlot?.productUser?.productName ? (
            <TruncatedBodyLine 
                text={expandedSlot.productUser.productName}
                icon={Icons.bag}
                data-aid="popupBody-product"
            />
        ) : null;
    };

    const Maintenance = () => {
        const formatMaintenanceTiming = (timing: number): string => {
            return labels.maintenanceTiming[MaintenanceTimingEnum[timing]]
        }        

        const sortSlotsByStart = (slots: ISlot[]): ISlot[] => {
            return slots.sort((slot1, slot2) => {
                const dateTimeA = new Date(slot1.startDate.split("T")[0] + "T" + slot1.startTime + "Z").getTime();
                const dateTimeB = new Date(slot2.startDate.split("T")[0] + "T" + slot2.startTime + "Z").getTime();
                return dateTimeA - dateTimeB;
            });
        }

        const ViewMoreMaintenance = () => {
            return expandedSlot?.maintenanceSlots 
                ? (<Tooltip content={sortSlotsByStart(expandedSlot.maintenanceSlots).map(slot => { 
                            return slot.title !== undefined ? <Typography color={ETypographyColor.primary} type={ETypography.caption}>
                                    {labels.viewMoreMaintenace(
                                        slot.title, 
                                        formatMaintenanceTiming(slot.maintenanceTiming), 
                                        formatTime(slot.startDate, ETimeFormat.SHORT_DATE), 
                                        formatTime(expandedSlot.startTime, ETimeFormat.TIME_12H_NO_SPACE, ETimeFormat.TIME_24H),
                                        slot.durationValue.toString(),
                                        labels.durationUnitTypes[slot.maintenanceDurationdurationType]
                                    )}
                                </Typography> : null;
                            })}
                        isTopLayer={true} 
                    >
                        <Typography data-aid={"viewMoreButton"} color={ETypographyColor.secondary} type={ETypography.overlineLink} css={noFlexShrinkCss} aria-label="View more maintenance slots">
                            {labels.viewMoreButton(expandedSlot.maintenanceSlots.length - 1)}
                        </Typography>
                    </Tooltip>) 
                : null;
        }

        const firstSlot = expandedSlot?.maintenanceSlots?.[0];
        return firstSlot 
            && firstSlot?.title !== undefined 
            && firstSlot?.maintenanceTiming !== undefined 
            && firstSlot.startTime !== undefined 
            && firstSlot.durationValue !== undefined 
            && firstSlot.maintenanceTiming !== undefined 
            && firstSlot.maintenanceDurationdurationType !== undefined 
        ? (
			<div data-aid="popupBody-maintenance" css={[mainBodyLineCss(colors)]}>
                <BnIcon icon={Icons.maintenance} />
                <div css={textLineCss}>
                    <Typography color={ETypographyColor.primary} type={ETypography.body2Accented} css={maintenaceTitleCss}>
                        {firstSlot.title}
                    </Typography>
                    <Dot color={colors.tx_text_primary} />
                    <Typography color={ETypographyColor.primary} type={ETypography.body2} css={noFlexShrinkCss}>
                        {formatMaintenanceTiming(firstSlot.maintenanceTiming)}
                    </Typography>
                    <Dot color={colors.tx_text_primary} />
                    <Typography color={ETypographyColor.primary} type={ETypography.body2} css={noFlexShrinkCss}>
                        {formatTime(expandedSlot.startTime, ETimeFormat.TIME_12H_NO_SPACE, ETimeFormat.TIME_24H)}
                    </Typography>
                    <Dot color={colors.tx_text_primary} />
                    <Typography color={ETypographyColor.secondary} type={ETypography.body2} css={noFlexShrinkCss}>
                        {labels.duration(`${firstSlot.durationValue} ${labels.durationUnitTypes[firstSlot.maintenanceDurationdurationType]}`)}
                    </Typography>
                    {expandedSlot?.maintenanceSlots && expandedSlot.maintenanceSlots.length > 1 && 
                        <ViewMoreMaintenance />
                    }
                </div>
			</div>
		) : null;
    };

    const NotesBodyLine = ({
        text,
        icon
    } : {
        text: string,
        icon: Icons
    }) => {
        const mainBodyLineStyle = useMemo(() => mainBodyLineCss(colors), [colors]);
        const charsPerLine = 60;
        const maxDisplayedLine = 3;
        return <div css={mainBodyLineStyle}>
            <BnIcon icon={icon} />
            <ClampOrTruncateWithTooltip 
                text={text}
                maxCharsPerLine={charsPerLine}
                maxDisplayedLines={maxDisplayedLine}
                data-aid="popupBody-notes-text"
            />  
        </div>
    }

    const PrivateNotes = () => {
        return expandedSlot?.privateNotes ? (  
            <NotesBodyLine 
                text={expandedSlot.privateNotes}
                icon={Icons.private_notes}
                data-aid="popupBody-privateNotes"
            />
        ) : null;
    };

    const PublicNotes = () => {
        return expandedSlot?.publicNotes ? (
             <NotesBodyLine 
                text={expandedSlot.publicNotes}
                icon={Icons.public_notes}
                data-aid="popupBody-publicNotes"
            />
        ) : null
    };

    return <div data-aid="popupBody-mainBody" css={bodyCss}>
        <Customer />
        <TimeFrame />
        <Location />
        <Product />
        <Maintenance />
        <PrivateNotes />
        <PublicNotes />
    </div>
}
