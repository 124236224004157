/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { FC, Fragment, memo, useEffect, useMemo, useState } from 'react';
import { spaceColumnContainer } from '../../styles';
import { IDailyViewProps } from '../../types';
import { spaceWidth } from '../styles';
import { CurrentTimeLine } from './CurrentTimeLine';
import { DayToDrop } from './DayToDrop';
import { sumBy } from 'lodash';

export const DailyViewVertical: FC<IDailyViewProps> = memo(
	({ spaces, EventComponent, isModeChanged, onAddNewSlotClick, hourSize, isLoading, currentDate }) => {
		const [updates, setUpdates] = useState(false);

		useEffect(() => {
			setUpdates(true);
		}, [hourSize]);

		const columnsCount = useMemo(() => {
			return sumBy(spaces, (space) => space.children?.length || 1);
		}, [spaces]);

		if (updates) {
			setUpdates(false);
			return <Fragment key="DailyViewVertical" />;
		}

		return (
			<Fragment key="DailyViewVertical">
				{spaces.map((space, index) => {
					const hiddenEvents = false;

					if (!space.children.length) {
						return (
							<div
								key={`DailyViewVertical-Space-${space.id}-${index}`}
								css={spaceColumnContainer(columnsCount, 1)}
								data-aid="DailyViewVertical-daily"
							>
								<DayToDrop
									id={String(space.id)}
									EventComponent={EventComponent}
									withoutChildren
									isLoading={isLoading}
									isModeChanged={isModeChanged}
									hiddenEvents={hiddenEvents}
									onAddNewSlotClick={onAddNewSlotClick}
									currentDate={currentDate}
								/>
							</div>
						);
					}

					return (
						<div
							key={`DailyViewVertical-DayToDrop-Wrapper-${space.id}`}
							css={spaceColumnContainer(columnsCount, space.children.length || 1)}
						>
							{space.children.map(item => {
								return (
									<DayToDrop
										key={`DailyViewVertical-DayToDrop-Child-${item.id}`}
										id={String(item.id)}
										isLoading={isLoading}
										EventComponent={EventComponent}
										isModeChanged={isModeChanged}
										hiddenEvents={hiddenEvents}
										onAddNewSlotClick={onAddNewSlotClick}
										currentDate={currentDate}
									/>
								);
							})}
						</div>
					);
				})}
				<CurrentTimeLine width={columnsCount * spaceWidth} />
			</Fragment>
		);
	}
);
