import { useState } from 'react';

export const useUndoPauseModal = () => {
	const [displayUndoPauseModal, setDisplayUndoPauseModal] = useState(false);

	const toggleUndoPauseModal = () => {
		setDisplayUndoPauseModal((prevState) => !prevState);
	}

	return {
		toggleUndoPauseModal,
		displayUndoPauseModal,
	};
};
