import React, { Fragment } from 'react';
import { BnIcon, Button, Icons, IOption, Popup, SelectOptions } from '@bondsports/utils';
import { IMembershipRow, MembershipMoreButtonOptionEnum } from '../../../../types/membership';

interface MoreButtonProps {
	isDisabled: boolean;
	handleMoreMenu: (option: MembershipMoreButtonOptionEnum, tabRow: IMembershipRow) => void;
	selectedMember: IMembershipRow;
	options: IOption[];
}

const MoreButton = ({ isDisabled }: { isDisabled?: boolean }) => {
	return (
		<Button data-aid="button-MembershipTab-more" sizer="S" theme="basic" disabled={isDisabled}>
			<BnIcon icon={Icons.more} />
		</Button>
	);
};

export const OptionsButton = ({ isDisabled, handleMoreMenu, options, selectedMember: selectedUser }: MoreButtonProps) => {
	return (
		<Fragment>
			{isDisabled ? (
				<MoreButton isDisabled={isDisabled} />
			) : (
				<Popup
					body={
						<SelectOptions
							onSelect={(v: MembershipMoreButtonOptionEnum) => handleMoreMenu(v, selectedUser)}
							options={options}
						/>
					}
				>
					<MoreButton />
				</Popup>
			)}
		</Fragment>
	);
};
