import { IBasicError } from '@app/react/types/errors';
import { PlatformsEnum } from '@bondsports/types';
import { environment } from '../../../environments/environment';
import { CustomError } from './../types/helpers';
import { auth } from './auth';
import { deepFindFirst } from './utils';
import { NetworkClientBuilder, type Request, type Response } from 'auth';

export { HttpStatusEnum } from 'auth';

const AUTH_URLS = {
	logout: `${environment.CS_URLS.AUTH_ROOT}/logout`,
	refresh: `${environment.CS_URLS.AUTH_ROOT}/refresh`,
};

const client = NetworkClientBuilder.createWithAuth(AUTH_URLS, () => auth.handleLogout())
	.addRequestInterceptor({
		onRequest: request => {
			validatePath(request.url);
			return request;
		},
	})
	.addRequestInterceptor({
		onRequest: (request: Request<any>) => {
			request.url = addOrganizationIdToUrl(request.url);
			return request;
		},
	})
	.addResponseInterceptor({
		onResponse: response => handleHttpResponse(response),
	})
	.build();

/**
 * A wrapper around the browser `fetch` API:
 * https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
 */
export function ParseError(err: Error) {
	const { meta } = JSON.parse(JSON.stringify(err));
	return meta;
}

/**
 * Checks that a response from the API is from the same organization as the user is logged in to.
 * If the response does not have a organizationId, it will pass.
 * @param responseBody
 */
const checkIfResponseIsOk = (responseBody: { data: any | any[] } | any | any[]) => {
	const body = responseBody.data ?? responseBody;
	const bodyArray = Array.isArray(body) ? body : [body];

	// find organizationId using lodash
	const orgObject = deepFindFirst(bodyArray, 'organizationId');
	if (orgObject) {
		const storedOrgId = Number(auth.getAuthOrganizationId());
		if (storedOrgId !== orgObject) {
			return false;
		}
	}

	// No organizationId in response bodyArray, let it pass
	return true;
};

async function handleHttpResponse(response: Response<any>) {
	const responseBody = response.data;

	const isResponseFromProperOrg =
		Array.isArray(responseBody) || typeof responseBody === 'object' ? checkIfResponseIsOk(responseBody) : true;

	/**
	 * Handle a success response.
	 * Anything which isn't between 200-300 HTTP status will throw an error.
	 */
	if (isResponseFromProperOrg && response.status >= 200 && response.status < 300) {
		/**
		 * Handle debug message from the API
		 * won't appear in production - just for inner use
		 */
		if (!window.location.pathname.includes(environment.PRODUCTION_URL) && responseBody.debug) {
			console.log(
				'%c Debug output: %o',
				'background: #444; color: #bada55; padding: 2px; border-radius:2px ;',
				responseBody.debug
			);
		}
		return response;
	} else if (isErrorResponse(responseBody)) {
		/**
		 * Handle error response
		 * If the response is an error, it will be handled by the interceptor
		 */
		return response;
	}

	/**
	 * Handle logout if localstorage organizationId does not match respo nse organizationId
	 */
	if (!isResponseFromProperOrg) {
		return auth.handleLogout();
	}

	const errorMsg = responseBody.message || responseBody.error || response.statusText || response.status;
	const error: CustomError = new Error(errorMsg);

	error.meta = {
		body: responseBody,
		message: responseBody.error,
		statusCode: response.status,
		statusText: response.statusText,
	};
	throw error;
}

const validatePath = (path: string) => {
	const invalidWords = [undefined, null, NaN.toString()];
	const invalidPath = invalidWords.some(element => {
		if (path.includes(element)) {
			return true;
		}

		return false;
	});

	if (invalidPath) {
		const msg = `The following path = ${path} is invalid`;
		console.error(`msg`);
		throw msg;
	}
	return;
};

export interface IApiError {
	err?: string;
	status: number;
	rawErr?: unknown;
}

const addPlatformToBody = (body = {}) => {
	return { ...body, platform: PlatformsEnum.BO };
};

const addOrganizationIdToUrl = (url: string) => {
	const validationUrl = new URL(url);
	if (validationUrl.searchParams.has('organizationId')) {
		return url;
	}

	const organizationId = auth.getAuthOrganizationId();
	if (organizationId === null || organizationId === undefined) {
		return url.trim();
	}
	const separator = url.includes('?') ? '&' : '?';
	return `${url.trim()}${separator}organizationId=${organizationId}`;
};

export const network = {
	get: client.get.bind(client) as typeof client.get,
	post: client.post.bind(client) as typeof client.post,
	patch: client.patch.bind(client) as typeof client.patch,
	put: client.put.bind(client) as typeof client.put,
	delete: client.delete.bind(client) as typeof client.delete,
	deleteMethod: client.delete.bind(client) as typeof client.delete,
	addPlatformToBody,
	addOrganizationIdToUrl,
};

export const download = (dataurl: string, filename: string) => {
	const link = document.createElement('a');
	link.href = dataurl;
	link.target = '_blank';
	link.download = filename;
	link.click();
};

export function isErrorResponse(response: any): response is IApiError | IBasicError {
	return response.err !== undefined;
}
