import { TranslationEn } from '@assets/i18n/en';
import React, { ButtonHTMLAttributes, useContext } from 'react';
import { Button, ButtonSize } from 'ui';
import { FormContext } from '../form';
import { isValuesChanged } from '@app/react/lib/form';

interface SaveButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	onClick?: () => void;
	isLoading?: boolean;
	label?: string
}

export const SaveButton: React.FC<SaveButtonProps> = ({ onClick, type = 'button', label, ...props }) => {
	label ??= TranslationEn.general.buttons.save;
	return (
		<Button
			type={type}
			onClick={onClick}
			className="uppercase"
			size={ButtonSize.SM}
			data-testid="save-buttton"
			{...props}
		>
			{label}
		</Button>
	);
};

export const FormSaveButton: React.FC<SaveButtonProps> = ({ ...props }) => {
	const { values, initialValues, valid } = useContext(FormContext);
	return (
		<SaveButton
			type={props.type || 'submit'}
			{...props}
			disabled={!isValuesChanged(values, initialValues) || !valid || props.disabled}
		/>
	);
};
