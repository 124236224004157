import { Invoice, PaginationResultDto } from "@bondsports/types"
import { Pricify } from "@bondsports/utils";
import { getInvoiceStatus } from "../../../utils/invoice.utils";
import { InvoiceColumnDef } from "./columns"

export const invoicesParser = (invoices: PaginationResultDto<Invoice>): InvoiceColumnDef[] => {
  return invoices.data.map((invoice) => ({
    createdAt: invoice.createdAt,
    id: invoice.id,
    itemsAmount: invoice.itemsAmount,
    price: Pricify(invoice.price),
    paymentType: invoice.paymentType,
    paymentStatus: getInvoiceStatus(invoice.paymentStatus, invoice.isScheduled),
  }));
}
