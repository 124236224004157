import { useState } from 'react';
import { ENotificationType, isErrorResponse } from '@bondsports/utils';

import { useNotification } from '../useNotification';
import { useToggle } from '../useToggle';
import { useErrorModal } from '../useErrorModal';

import { membershipApi } from '../../lib/api/membershipApi';
import { TranslationEn } from '@assets/i18n/en';
import { useFetchMembershipPauseHistory } from './useFetchMembershipPauseHistory';
import { IEditPauseParams, IPauseParams, IUnpauseParams } from '@app/react/types/membership';


export const useMembershipPausing = (
	organizationId: number,
	memberId: number,
	hasPauseHistory: boolean,
) => {
	const labels = TranslationEn.memberships.members.pauseMemberships;
	const [isProcessing, setIsProcessing] = useState(false);
	const [pauseId, setPauseId] = useState<number | null>(null);
	const [isPauseMembershipShowing, pauseToggle] = useToggle();
	const { setToastNotification } = useNotification();
	const { setErrorModal } = useErrorModal();
	const onFetchPauseHistoryError = (error: string) => {
		setErrorModal({ message: error });
		setToastNotification(labels.pauseHistoryFailed, ENotificationType.warning);
	};

	const {
		pauseHistory,
		isLoading: isPauseHistoryLoading,
	} = useFetchMembershipPauseHistory(
		organizationId,
		memberId,
		hasPauseHistory,
		onFetchPauseHistoryError,
	);

	const handlePause = async ({ pauseData, pausedMemberId, organizationId, familyMembership, callback }: IPauseParams) => {
		setIsProcessing(true);
		const res = await membershipApi.pauseMembership(
			organizationId,
			pausedMemberId,
			familyMembership,
			pauseData
		);
		setIsProcessing(false);
		if (!res || res.err) {
			setErrorModal({ message: String(res.err ?? res) });
			setToastNotification(labels.pauseFailed, ENotificationType.warning);
		}
		if (res.membershipMemberIds?.length) {
			await callback();
			setToastNotification(labels.pauseSuccess, ENotificationType.success);
		}
	};

	const handleEditPause = async ({ editPauseData, pausedMemberId, organizationId, familyMembership, callback }: IEditPauseParams) => {
		setIsProcessing(true);
		const res = await membershipApi.updatePausedMembership(
			organizationId,
			pausedMemberId,
			familyMembership,
			editPauseData,
		);
		setIsProcessing(false);
		if (isErrorResponse(res)) {
			setErrorModal({ message: String(res.err ?? res) });
			setToastNotification(labels.pauseEditFailed, ENotificationType.warning);
		}
		if (res.membershipMemberIds?.length) {
			await callback();
			setToastNotification(labels.pauseEditSuccess, ENotificationType.success);
		}
	}

	const handleUndoPause = async ({organizationId, pausedMemberId, familyMembership, callback}: IUnpauseParams) => {
		setIsProcessing(true);
		const res = await membershipApi.undoPauseMembership(organizationId, pausedMemberId, familyMembership);
		setIsProcessing(false);
		if (isErrorResponse(res)) {
			setErrorModal({ message: String(res.err ?? res) });
			setToastNotification(labels.undoPauseFailed, ENotificationType.warning);
		}
		if (res.membershipMemberIds?.length) {
			await callback();
			setToastNotification(labels.undoPauseSuccess, ENotificationType.success);
		}
	}

	return {
		handlePause,
		isProcessingPause: isProcessing,
		isPauseHistoryLoading,
		handleEditPause,
		pauseHistory,
		handleUndoPause,
		pauseToggle,
		setPauseId,
		pauseId,
		isPauseMembershipShowing,
	};
};
