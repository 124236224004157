/** @jsxRuntime classic */
/** @jsx jsx */
import { useMemo } from "react";
import { jsx } from '@emotion/react';
import { ETypography, ETypographyColor } from "../../types";
import { Typography } from "../../atoms";
import { flexRowCss } from "../../styles/utils";
import { NEW_LINE } from "../../lib/constants";
import { ViewMoreEllipses } from "./ViewMoreEllipses";

const DefaultTypography = ({ line }: { line: string }) => {
    return (
        <Typography color={ETypographyColor.primary} type={ETypography.body2}>
            {line}
        </Typography>
    );
}

interface processedLinesAndCount {
    processedLines: string[],
    totalCharCount: number,
}

const splitStringsWithMaxLength = (lines: string[], maxCharsPerLine: number): processedLinesAndCount => {
    let totalCharCount = 0;
    const processedLines: string[] = [];
    for (let i = 0; i < lines.length; i++) {
        totalCharCount = totalCharCount + processedLines.length;
        const line = lines[i];
        const words = line.split(' ');

        let newLine = "";
        let hasLineOverflowedYet = false;
        for (const word of words) {
            const sentenceLength: number = newLine.length + word.length + 1;
            if (sentenceLength < maxCharsPerLine) {
                newLine = !newLine.length ? word : `${newLine} ${word}`;
                continue;
            }
            //If the next word will make the line too long, create an overflow line or if an overflow line exists, add the word to it
            //(its ok if that next line is too long, as we will process it too later in the loop)
            const nextLineIndex: number = i + 1;
            if(!hasLineOverflowedYet){
                //If the line hasn't every overflowed, create a new line in lines to contain all the overflow
                lines.splice(nextLineIndex, 0, word);
                hasLineOverflowedYet = true;
            } else {
                lines[nextLineIndex] += ` ${word}`
            }
        }
        processedLines.push(newLine);
    }
    return { processedLines, totalCharCount };
}

interface ClampOrTruncateWithTooltipProps {
    text: string,
    maxCharsPerLine: number,
    maxDisplayedLines: number,
}

export const ClampOrTruncateWithTooltip = ({
    text,
    maxCharsPerLine,
    maxDisplayedLines,
}: ClampOrTruncateWithTooltipProps) => {
    const maxDisplayedChars = maxCharsPerLine * maxDisplayedLines;

    const {
        isTruncated,
        outputLines
    } = useMemo(() => {
        const lines = text.split(NEW_LINE);
        const { processedLines, totalCharCount } = splitStringsWithMaxLength(lines, maxCharsPerLine);
        const isTruncated = totalCharCount > maxDisplayedChars || processedLines.length > maxDisplayedLines;
        const outputLines = processedLines.slice(0, maxDisplayedLines);

        return { processedLines, isTruncated, outputLines };
    }, [text, maxCharsPerLine, maxDisplayedChars, maxDisplayedLines]);

    return (
        <div>
            {outputLines.map((line, index) => {
                const isLastLine = index === outputLines.length - 1 && isTruncated;

                return isLastLine ? (
                    <div key={line} css={flexRowCss}>
                        <DefaultTypography line={line} />
                        <ViewMoreEllipses text={text} />
                    </div>
                ) : (
                    <DefaultTypography key={line} line={line} />
                );
            })}
        </div>
    );
};
