import { IDuePayment } from "@app/react/forms/activities/pricing/types/interfaces";
import { CustomPaymentPlanAllocationEnum } from "@app/react/types/enums";
import { IPayment } from "@app/react/types/payment";
import { PaymentPlanStatusEnum, PaymentPlanTypeEnum } from "@bondsports/types";

export interface RollingPaymentPlanReturn {
	rollingPlanState: IRollingPaymentPlanState;
	isValidRollingPlan: boolean;
	OnRollingPlanChange: (state: IRollingPaymentPlanState) => void;
	getRollingPaymentSchedule: (totalAmount: number) => IPayment[];
};

export interface IRollingPaymentPlanState {
	startDate?: string;
	count?: number;
	repeat?: string;
}

export enum ActionsEnum {
	SET_STATE = 'SET_STATE',
};

export type RollingPaymentPlanActions =
	| { type: ActionsEnum.SET_STATE; payload: IRollingPaymentPlanState }

export interface CustomPaymentPlanReturn {
	customPlanState: ICustomPaymentPlanState;
	isValidCustomPlan: boolean;
	OnCustomPlanChange: (state: ICustomPaymentPlanState) => void;
	getCustomPaymentSchedule: () => IPayment[];
};

export interface ICustomPaymentPlanState {
	totalAmount?: number;
	schedule?: IDuePayment[];
	notBeforeDate: Date;
	valid?: boolean;
	scheduleAmountType: CustomPaymentPlanAllocationEnum;
	paymentPlanType: PaymentPlanTypeEnum;
	paymentPlanStatus: PaymentPlanStatusEnum;
}

export interface IScheduledPaymentsState {
	rolling?: IRollingPaymentPlanState,
	custom?: ICustomPaymentPlanState,
	selectedTab?: string
}

export type CustomPaymentPlanActions =
	| { type: ActionsEnum.SET_STATE; payload: ICustomPaymentPlanState }


export enum InvoicePaymentPlanEnum {
  CUSTOM = PaymentPlanTypeEnum.CUSTOM,
  ROLLING = PaymentPlanTypeEnum.ROLLING,
}