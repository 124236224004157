
/** @jsx jsx */
import React, { ReactNode } from 'react';
import { jsx, css } from '@emotion/react';
import { BnIcon, Icons } from '@bondsports/utils';
import { dateBlockCss, flexCol } from '../../../styles/utils';
import { CaptionAccentTypography } from 'ui';
import { getDateTimeString } from '@bondsports/date-time';
import { TimeDisplay } from '../../shared/NewTable/Cell/DateTime';
import { EDateTimeFormats } from '@app/react/types/times';
import { colors_v2 } from '@app/react/styles/theme';

interface DatesComparisonBlockProps {
    firstDate: string | Date;
    secondDate?: string | Date;
	timezone?: string;
    title: string;
    iconDevider?: ReactNode;
}

const datesCss = css`
width: 100%;
display: flex;
flex-wrap: nowrap;
align-items: center;
gap: 1rem;
flex-direction: row;
`;

const deviderCss = css`
width: 18px;
height: 18px;
color: ${colors_v2.tx_text_primary};
`;

const containerCss = css`
width: 100%;
text-align: left;
margin-bottom: 2rem;
${flexCol}
`;

export const DatesComparisonBlock = ({
    firstDate,
    secondDate,
	timezone,
    iconDevider = <BnIcon icon={Icons.arrow_right} css={deviderCss} />,
    title
}: DatesComparisonBlockProps) => {

    const firstDateColor = secondDate ? colors_v2.tx_text_secondary : colors_v2.tx_text_primary;
	return (
			<div css={containerCss}>
                <div data-testid="date-block-title-container">
                    <CaptionAccentTypography className="text-primary">{title}</CaptionAccentTypography>
                </div>
                <div css={datesCss} data-testid="date-block-dates-container">
				    <TimeDisplay time={getDateTimeString(firstDate)} format={EDateTimeFormats.DAY_FORMAT} stylingCss={dateBlockCss(firstDateColor)} timezone={timezone}/>
                    {secondDate ? iconDevider : null}
                    {secondDate && <TimeDisplay time={getDateTimeString(secondDate)} format={EDateTimeFormats.DAY_FORMAT} stylingCss={dateBlockCss()} timezone={timezone} />}
                </div>
			</div>
	);
};
