/** @jsx jsx */
import React, { Fragment, useEffect, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { ErrorResponse, ISdkManagedPaymentIntent, IPaymentIntent } from '@stripe/terminal-js';
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';

import { organizationApi } from '../../../lib/api/organizationApi';
import { paymentApi } from 'app/react/lib/api/paymentApi';
import { useStation } from 'app/react/hooks/useStation';
import { useLayout } from 'app/react/hooks/useLayout';
import { useOrganization } from 'app/react/hooks/useOrganization';
import { NotificationApi } from 'app/react/lib/api/notificationsApi';
import { Mixpanel, MixpanelEvents } from 'app/react/lib/mixpanel';
import { BnTERMINAL } from '../../../types/station';
import { EPaymentMethod, TChargeSteps } from 'app/react/types/payment';
import { TranslationEn } from '@assets/i18n/en';
import { PaymentMethodTypeEnum, PlatformsEnum } from '@bondsports/types';
import { FailedSwipeConnection } from './failedSwipeConnection';

const swipeCss = css`
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 100px;
	padding-bottom: 100px;
	text-align: center;
`;

interface Props {
	amount: number;
	currency: 'usd';
	userId: number;
	handleSwipeReady: (val: string) => void;
	handleSwipeSuccess: (val: any, isTriggerSwipe: boolean) => void;
	triggerPay: boolean;
	handleError: (message: string) => void;
	setMethod: (val: TChargeSteps) => void;
	paymentAction: (val: string, val2: string) => void;
}

export const Swipe = ({
	amount,
	currency,
	userId,
	handleSwipeReady,
	setMethod,
	handleSwipeSuccess,
	paymentAction,
	handleError,
}: Props) => {
	const {
		isTerminalSet,
		setSwipeAllowed,
		isTerminalLoading: isLoading,
		isSwipeAllowed,
		clearTerminalDisplay,
		initiateTerminal,
	} = useStation();

	const { disabledScreenToggle } = useLayout();
	const { organizationId } = useOrganization();
	const [isCreatingPaymentIntent, setCreatingPaymentIntent] = useState(false);
	const [isPaymentIntentReady, setPaymentIntentReady] = useState(false);
	const [clientSecret, setClientSecret] = useState('');
	const [isProcessingPayment, setProcessingPayment] = useState(false);
	const labels = TranslationEn.terminal;

	useEffect(() => {
		return () => {
			clearTerminalDisplay();
		};
	}, []);

	useEffect(() => {
		if (clientSecret) {
			setPaymentIntentReady(true);
			handleSwipeReady(clientSecret);
			collectPaymentMethod(clientSecret);
		}
	}, [clientSecret]);

	const processPayment = (paymentInt: ISdkManagedPaymentIntent) => {
		setProcessingPayment(true);
		window[BnTERMINAL]?.processPayment(paymentInt).then(result => {
			if ((result as ErrorResponse).error) {
				setProcessingPayment(false);
				// Placeholder for handling result.error
				console.log('error in process payments ', (result as ErrorResponse).error);
				handleError((result as ErrorResponse).error.message);
				NotificationApi.TerminalError({ ...result, organizationId });
				disabledScreenToggle(false);
			} else if ((result as { paymentIntent: IPaymentIntent }).paymentIntent) {
				const theData = (result as { paymentIntent: IPaymentIntent }).paymentIntent.charges.data;
				if (theData && theData.length > 0) {
					if (theData[0].payment_method_details?.card_present) {
						handleSwipeSuccess(theData[0].payment_method_details, true);
						//const card = theData[0].payment_method_details.card_present;
						// on terminal we need to pass the intent since we're NOT really dealing
						// with the actual card
						paymentAction(paymentInt.id, EPaymentMethod.CARD_ON_TERMINAL);
					}
				}
			}
		});
	};

	const collectPaymentMethod = (clientSecret: string) => {
		window[BnTERMINAL]?.setSimulatorConfiguration({
			testCardNumber: '4242424242424242',
		});
		// clientSecret is the client_secret from the PaymentIntent you created in Step 1.
		window[BnTERMINAL]?.collectPaymentMethod(clientSecret).then(result => {
			if ((result as ErrorResponse).error) {
				// Placeholder for handling result.error
				disabledScreenToggle(false);
				console.log((result as ErrorResponse).error);
				handleError((result as ErrorResponse).error.message);
				NotificationApi.TerminalError({ ...result, organizationId });
			} else {
				disabledScreenToggle(true);
				processPayment((result as { paymentIntent: ISdkManagedPaymentIntent }).paymentIntent);
			}
		});
	};

	const handleSwiping = async () => {
		if (isCreatingPaymentIntent) {
			return;
		}
		setMethod(PaymentMethodTypeEnum.CARD_ON_TERMINAL);
		Mixpanel.track(MixpanelEvents.PICK_TERMINAL, {});
		const customerId = userId ?? (await organizationApi.getAnonymousCustomer(organizationId));

		setCreatingPaymentIntent(true);
		const intentObj = {
			amount: amount * 100,
			customer: customerId,
			currency,
			payment_method_types: ['card_present'],
			capture_method: 'manual',
			organizationId,
			platform: PlatformsEnum.BO,
		};
		// create payment intent
		paymentApi.createPaymentIntent(intentObj).then(res => {
			if (res.err) {
				const errorMessage: string = Array.isArray(res.err) ? res.err[0] : res.err;
				handleError(errorMessage);
				setSwipeAllowed(false);
			} else {
				setClientSecret(res.client_secret);
			}
		});
	};

	useEffect(() => {
		if (!isSwipeAllowed && isTerminalSet) {
			initiateTerminal();
		}
	}, []);

	useEffect(() => {
		if (isSwipeAllowed) {
			handleSwiping();
		}
	}, [isSwipeAllowed])

	if (isLoading) {
		return <Fragment></Fragment>;
	}

	return (
		<Fragment>
			{isTerminalSet ? (
				isSwipeAllowed ? (
					<div css={swipeCss}>
						<Typography type={ETypography.body1Accented} color={ETypographyColor.secondary}>{
							isProcessingPayment ? labels.swipe.processing :
							isPaymentIntentReady ? labels.swipe.callToAction :
							TranslationEn.isLoading
						}</Typography>
					</div>
				) : (
					<FailedSwipeConnection labels={labels.connectionFailed}/>
				)
			) : undefined}
		</Fragment>
	);
};
