/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useMemo } from "react";
import { Typography } from "../../../atoms";
import { IExpandedSlot, IPopupRedirections, ITimeSlot, PaymentStatusEnum, PrivacySettingsEnum, ReservationPaymentStatusExtendedEnum, SlotTypeEnum } from "../types";
import { ETypography, ETypographyColor, IColors, Icons } from "../../../types";
import { IdHyperlink } from "../../../molecules/IdHyperlink";
import { BnIcon } from '../../../components/icons_v2';
import { css, jsx } from "@emotion/react";
import { ETimeFormat, formatTime } from "../../../calendar/utils/timeUtils";
import { flexCenterCss, flexRowCss, flexWrapCss, gapCss } from "../../../styles/utils";
import { ETagAllowedColors } from "../../../components/Tag/types";
import { useColors } from "../../../hooks/useColors";
import { Tag } from "../../../components/Tag";

const slotHeader = (colors: IColors) => css`
	display: flex;
    flex-direction: column;
	padding: 0rem 2rem 1.3rem 2rem;
	gap: 0.3rem 0.6rem;
	border-bottom: 0.1rem solid ${colors.bg_border_seperator};
`;

const titleCss = css`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 0.3rem 0.86rem;
    width: 95%;
    h4 {
        display: block;
        white-space: nowrap; 
        overflow: hidden; 
        text-overflow: ellipsis;
    }
`;

const reservationDetails = css`
	display: flex;
	gap: 0.6rem;
	position: relative;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const conflictCss = (colors: IColors) => css`
	cursor: pointer;
	display: flex;
	gap: 0.3rem;
	div {
		color: ${colors.tx_error};
	}
	svg {
		color: ${colors.tx_error};
		height: 100%;
		width: auto;
	}
`;

export interface IHeaderLabels {
    reservation: string,
    conflicts: string,
    ariaLabel: {
        conflicts: string, 
        reservationId: string
    }
    tags: {
        customer: string,
        maintenance: string,
        internal: string,
        paid: string,
        unpaid: string,
        partiallyPaid: string,
        hold: string,
        approved: string,
        awaitingAdmin: string,
        awaitingCustomer: string,
        planned: string,
        private: string
    }
    occurenceCount: (index: number, totalCount:number) => string,
    idHyperlink: {
        id: (id: number) => string
    }
}

export const Header = ({
    expandedSlot,
    redirections,
    labels
} : {
    expandedSlot: IExpandedSlot;
    redirections?: IPopupRedirections;
    labels: IHeaderLabels;
}) => {
    const { colors } = useColors();

    const ReservationTag = () => {
        const goToReservation = redirections?.goToReservation;
        return expandedSlot?.reservationId ? <div css={[flexRowCss, flexCenterCss, gapCss(4)]}>
            <Typography color={ETypographyColor.secondary} type={ETypography.body2}>
                {labels.reservation}
            </Typography>
            <IdHyperlink 
                id={expandedSlot.reservationId}
                isHyperlink={!!goToReservation}
                onClick={() => goToReservation?.(expandedSlot.reservationId)}
                aria-label={labels.ariaLabel.reservationId}
                labels={labels.idHyperlink}
            />
        </div> : null;
    };

    const SlotOccurrenceCount = () => {
        return expandedSlot?.occurrence && expandedSlot?.totalSlotsInReservation ? (
            <Typography color={ETypographyColor.secondary} type={ETypography.caption}>
                {labels.occurenceCount(expandedSlot.occurrence, expandedSlot.totalSlotsInReservation)}
            </Typography>
        ) : null;
    };

    const Conflicts = () => {
        const goToConflicts = redirections?.goToConflicts;
        const handleGoToConflicts = () => {
            if(!expandedSlot || expandedSlot?.spaceId === undefined) return;
            const timeSlot: ITimeSlot = {
                resourceId: expandedSlot.spaceId,
                startTime: expandedSlot.startTime,
                endTime: expandedSlot.endTime,
                startDate: formatTime(expandedSlot.startDate, ETimeFormat.FULL_DATE),
                endDate: formatTime(expandedSlot.endDate, ETimeFormat.FULL_DATE),
                relevantSlotId: expandedSlot.id.toString(),
            };
            goToConflicts && goToConflicts(timeSlot);
        };

        const conflictsStyle = useMemo(() => conflictCss(colors), [colors]);
        return (
            <div>
                {expandedSlot?.conflictCount !== undefined && expandedSlot.conflictCount > 0 && (
                    <div data-aid="popupBody-conflicts-button" css={conflictsStyle} onClick={handleGoToConflicts}>
                        <BnIcon icon={Icons.conflict} />
                        <Typography type={ETypography.body2Link} aria-label={labels.ariaLabel.conflicts}>
                            {labels.conflicts}
                        </Typography>
                    </div>
                )}
            </div>
        )
    };

    const Tags = () => {  
        if (!expandedSlot) return null;  
      
        const slotTypeMap = {  
            [SlotTypeEnum.EXTERNAL]: { title: labels.tags.customer, color: ETagAllowedColors.Pink },  
            [SlotTypeEnum.MAINTENANCE]: expandedSlot.parentSlotId ? { title: labels.tags.maintenance, color: ETagAllowedColors.Gray } : null,  
            [SlotTypeEnum.INTERNAL]: { title: labels.tags.internal, color: ETagAllowedColors.Purple },  
        };  
      
        const paymentStatusMap = {  
            [PaymentStatusEnum.FULLY_PAID]: { title: labels.tags.paid, color: ETagAllowedColors.Green },  
            [PaymentStatusEnum.NOT_PAID]: { title: labels.tags.unpaid, color: ETagAllowedColors.Red },  
            [PaymentStatusEnum.PARTIAL_PAYMENT]: { title: labels.tags.partiallyPaid, color: ETagAllowedColors.Yellow },  
            [ReservationPaymentStatusExtendedEnum.PURCHASE_ORDER]: { title: labels.tags.hold, color: ETagAllowedColors.Yellow },  
        };  
      
        const tags = [  
            slotTypeMap[expandedSlot.slotType],  
            paymentStatusMap[expandedSlot.paymentStatus],  
            expandedSlot.privacySetting === PrivacySettingsEnum.PRIVATE && { title: labels.tags.private, color: "orange" },  
        ].filter(Boolean);  
      
        return (  
            <div css={[flexRowCss, flexCenterCss, gapCss(4)]}>  
                {tags.map(({ title, color }) => (  
                    <Tag title={title} color={color} />  
                ))}  
            </div>  
        );  
    };

    const slotHeaderStyle = useMemo(() => slotHeader(colors), [colors]);
    return (
        <div data-aid="popupBody-header" css={slotHeaderStyle}>
            <div data-aid="popupBody-title" css={titleCss}>
                {expandedSlot?.title ? 
                    <Typography color={ETypographyColor.primary} type={ETypography.h4}>
                        {expandedSlot.title}
                    </Typography> 
                : null}
            </div>
            <div data-aid="popupBody-reservationDetails" css={reservationDetails}>
                <div css={[flexWrapCss, flexCenterCss, gapCss(8)]}>
                    <ReservationTag />
                    <SlotOccurrenceCount />
                    <Tags />
                </div>
                <Conflicts />
            </div>
        </div>
    );
};
