/* @jsx jsx */

import React, { useCallback } from 'react';
import { jsx } from '@emotion/react';
import { flexCol, fullWidthCss, gapCss, paddingBottomCss, pointerCss } from '../../../../../styles/utils';
import { separatorCss } from '../../styles';
import { ETypography, ETypographyColor, Typography } from '@bondsports/utils';
import { capitalize } from 'lodash';
import { SimpleProgramWithSportDto } from '../../types/types';
import { PunchPassDetailsCardProps } from './types';
import { SimpleProductUserDto, SimpleSessionDto } from '@bondsports/types';

type PunchPassBasicInfoProps = Pick<PunchPassDetailsCardProps, 'onNavigateToSession'> & {
	session: SimpleSessionDto;
	program: SimpleProgramWithSportDto;
	productUser: SimpleProductUserDto;
};

export const PunchPassBasicInfo: React.FC<PunchPassBasicInfoProps> = ({
	onNavigateToSession,
	session,
	productUser,
	program,
}) => {
	const onClickedSessionLinkHandler = useCallback(() => {
		onNavigateToSession(session, program);
	}, [session, program]);

	return (
		<div css={[flexCol, fullWidthCss, gapCss(4), separatorCss('bottom'), paddingBottomCss(16)]}>
			<Typography
				datatype="title"
				data-testid="punch-pass-name"
				type={ETypography.body1Accented}
				color={ETypographyColor.primary}
			>
				{capitalize(productUser.productName)}
			</Typography>
			<span datatype="link" data-testid="session-link" onClick={onClickedSessionLinkHandler} css={pointerCss}>
				<Typography type={ETypography.body2Link} color={ETypographyColor.primary}>
					{capitalize(session.name)}
				</Typography>
			</span>
		</div>
	);
};
