/** @jsx jsx */
import React, { Fragment, ReactNode, useState } from 'react';
import { jsx, css } from '@emotion/react';
import { SkeletonWrapper } from '@bondsports/utils';

import { CustomCheckbox } from '../Checkbox';
import { TabelColumnTitle } from './TabelColumnTitle';
import { CheckedIconCss } from '../../../styles/utils';
import { headerTdCss, checkboxCss, intermidiateIconCss, iconCss } from './styles';
import { AlignType } from './types';

const loadingCss = css`
	display: flex;
	justify-content: space-between;
`;

const IntermidateCss = css`
	${iconCss}
	${intermidiateIconCss}
`;

type Order = 'asc' | 'desc';

interface StylingType {
	align?: AlignType;
	asNarrowAsPossible?: boolean;
}
export interface ColumnType {
	id: string;
	label: string;
	type: string;
	numeric?: boolean;
	disablePadding?: boolean;
	isSortable?: boolean;
	styling?: StylingType;
	component?: (value: unknown, value2: unknown) => ReactNode;
	action?: (value: unknown, value2: unknown) => void;
	onSort?: (isAsc: boolean) => void;
	isInitialAscending?: boolean;
	hideHoursDisplay?: boolean;
	isUnderlined?: boolean;
	ellipsisCell?: { minWidth: number; maxWidth: number };
}

interface EnhancedTableProps {
	numSelected: number;
	onRequestSort?: (event: React.MouseEvent<unknown>, property: any) => void;
	onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order?: Order;
	orderBy?: string;
	rowCount: number;
	columns: ColumnType[];
	isSelectRow: boolean;
	isRadio: boolean;
	isLoading?: boolean;
	isSortable?: boolean;
	defaultSortColumn?: string;
	isSelectIndeterminate?: boolean;
}

export const HeaderRow = ({
	onSelectAllClick,
	numSelected,
	rowCount,
	columns,
	isSelectRow,
	isLoading = false,
	isRadio,
	defaultSortColumn,
	isSelectIndeterminate = false,
}: EnhancedTableProps) => {
	const [currentSortedColumn, setCurrentSortedColumn] = useState(defaultSortColumn);

	const handleSortColumn = (isAsc: boolean, currColumn: string, onSort: (v: boolean) => void) => {
		setCurrentSortedColumn(currColumn);
		onSort(isAsc);
	};

	const handleOnChange = e => {
		onSelectAllClick(e);
	};

	return (
		<Fragment>
			{isLoading ? (
				<tr css={loadingCss}>
					{columns.map((headCell, index) =>
						<SkeletonWrapper key={`${headCell.id}_${index}`} isLoading={isLoading} breakToLines minHeight={40}
														 minWidth={120}>
							<td
								className={`TableCell-root ${headCell.id}-head`}
								css={headerTdCss(false)}
								key={headCell.id}
								align={
									headCell?.styling?.align ? (headCell?.styling?.align === 'right' ? 'right' : 'left') : 'center'
								}
							>
								{headCell.id}
							</td>
						</SkeletonWrapper>)}
				</tr>
			) : (
				<tr>
					{isSelectRow && (
						<td className="TableCell-root" css={headerTdCss(isRadio, true)}>
							{!isRadio && (
								<CustomCheckbox
									css={checkboxCss}
									checkedIcon={<span css={CheckedIconCss} />}
									indeterminateIcon={<span css={IntermidateCss} />}
									icon={<span css={iconCss} />}
									checked={rowCount > 0 && numSelected === rowCount}
									onChange={handleOnChange}
									inputProps={{ 'aria-label': 'select all desserts' }}
									indeterminate={isSelectIndeterminate || (numSelected > 0 && numSelected < rowCount)}
								/>
							)}
						</td>
					)}
					{columns.map((headCell, index) => {
						const { id, label, styling, onSort } = headCell ?? {};
						const asNarrowAsPossible = styling?.asNarrowAsPossible;
						return (
							<td
								key={`${id}_${index}` ?? index} //using index is not recommended, but here is just a fallback
								className={`TableCell-root ${id ?? index}-head`}
								css={headerTdCss(false, asNarrowAsPossible)}
								align={styling?.align ? (styling?.align === 'right' ? 'right' : 'left') : 'center'}
							>
								<TabelColumnTitle
									isSortable={headCell.isSortable}
									sortFunction={headCell.isSortable ? (isAsc: boolean) => handleSortColumn(isAsc, label, onSort) : null}
									columnTitle={label}
									currentSortColumn={currentSortedColumn}
									isInitialAscending={headCell.isInitialAscending}
								/>
							</td>
						);
					})}
				</tr>
			)}
		</Fragment>
	);
};
