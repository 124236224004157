/** @jsx jsx */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { PaymentMethodTypeEnum, UserPaymentMethod, UserPaymentMethodOption } from '@bondsports/types';
import { ModalWindow, BackButton } from '@bondsports/utils';
import { css, jsx } from '@emotion/react';
import { mobileOnly, paddingCss } from '../../../../../styles/utils';
import { NormalizedPaymentMethod } from '../../types';
import { Header } from './Header';
import { NextStepFooter } from './NextStepFooter';
import { SuccessMessage } from './steps/Success';
import { useErrorModalDepricated } from '@app/react/components/shared/ErrorDepricated';
import { ReplaceMethodSteps } from './enums';
import { useMediaQuery } from '@material-ui/core';
import { ICustomer } from '@app/react/types/customers';
import { SelectMethod } from '@app/react/components/payments/Charge/selectMethod';
import { CreditCard } from '@app/react/components/payments/Charge/CreditCard';
import { ACH } from '@app/react/components/payments/Charge/ACH';
import { EChargeInnerStep, TChargeSteps } from '@app/react/types/payment';
import { Charge } from '@app/react/components/payments/Charge';

const SMALL_MIN_WIDTH = '35rem';
const LARGE_MIN_WIDTH = '55rem';

const selectContainerCss = css`
	width: 100%;
`;

export interface ReplacePaymentMethodModalProps {
	isShowing: boolean;
	toggle: () => void;
	userId: number;
	replacablePaymentMethods: NormalizedPaymentMethod[];
	paymentMethods: UserPaymentMethod[];
	methodOptions: UserPaymentMethodOption[];
	customerState: ICustomer
	onReplace: (newMethodId: string) => Promise<void>;
	refetchPaymentMethods: () => Promise<void>;
}

const backButtonPositionCss = css`
	position: absolute;
	left: 1em;
	top: 0.5em;
`;
export const ReplacePaymentMethodModal = ({
	isShowing,
	toggle,
	replacablePaymentMethods,
	methodOptions,
	paymentMethods,
	onReplace,
	refetchPaymentMethods,
	customerState,
}: ReplacePaymentMethodModalProps) => {
	const isMobile = useMediaQuery(mobileOnly);

	const [newMethodId, setNewMethodId] = useState<string>('');
	const { ErrorModalDepricated, error, setError } = useErrorModalDepricated();
	const cards: UserPaymentMethod[] = paymentMethods?.filter(pm => pm?.paymentMethodType === PaymentMethodTypeEnum.CARD);
	const ach: UserPaymentMethod[] = paymentMethods?.filter(pm => pm?.paymentMethodType === PaymentMethodTypeEnum.ACH);
	const nextStepPredicates = {
		[ReplaceMethodSteps.CHOOSE_TYPE]: undefined,
		[ReplaceMethodSteps.CARD]: newMethodId,
		[ReplaceMethodSteps.ACH]: newMethodId,
		[ReplaceMethodSteps.NEW_CARD]: undefined,
		[ReplaceMethodSteps.SUCCESS]: undefined,
		[ReplaceMethodSteps.ERROR]: undefined,
	};

	const [step, setStep] = useState<TChargeSteps>(ReplaceMethodSteps.CHOOSE_TYPE);
	const nextStep = (specificStep?: ReplaceMethodSteps) => setStep(specificStep);
	const prevStep = () => setStep(ReplaceMethodSteps.CHOOSE_TYPE);

	useEffect(() => {
		isShowing && setStep(ReplaceMethodSteps.CHOOSE_TYPE);
	}, [isShowing]);

	const handleReplace = async () => {
		try {
			await onReplace(newMethodId);
			nextStep(ReplaceMethodSteps.SUCCESS);
		} catch (error) {
			setError(error as string);
			nextStep(ReplaceMethodSteps.ERROR);
		}
	};

	const handleToggleClose = () => {
		refetchPaymentMethods();
	};

	const { content, minWidthModal } = useMemo(() => {
		const canContinue = Boolean(nextStepPredicates[step]);
		const width = isMobile ? SMALL_MIN_WIDTH : LARGE_MIN_WIDTH;
		switch (step) {
			case ReplaceMethodSteps.ACH:
				return {
					content: (
						<div>
							<div css={backButtonPositionCss}>
								<BackButton handleBack={prevStep} />
							</div>
							<Header margins={'4.5rem 1rem 1.5rem 1rem'} />
							<BodyContainer>
								<ACH
									ACHMethods={ach}
									setSelectedCard={setNewMethodId}
									selectedCard={newMethodId}
								/>
							</BodyContainer>
							<NextStepFooter onCancel={toggle} onContinue={handleReplace} canContinue={canContinue} />
						</div>
					),
					minWidthModal: width,
				};
			case ReplaceMethodSteps.CARD:
				return {
					content: (
						<div>
							<div css={backButtonPositionCss}>
								<BackButton handleBack={prevStep} />
							</div>
							<Header margins={'4.5rem 1rem 1.5rem 1rem'} />
							<BodyContainer>
								<CreditCard
									cards={cards}
									setMethod={setStep}
									selectedCard={newMethodId}
									setSelectedCard={setNewMethodId}
									customer={customerState}
								/>
							</BodyContainer>
							<NextStepFooter onCancel={toggle} onContinue={handleReplace} canContinue={canContinue} />
						</div>
					),
					minWidthModal: width,
			};
			case ReplaceMethodSteps.NEW_CARD:
				return {
					content: (
							<div css={selectContainerCss}>
								<BodyContainer>
									<ModalWindow isShowing={true} toggle={handleToggleClose}>
										<Charge
											organizationId={customerState?.organizationId}
											toggle={handleToggleClose}
											userId={customerState?.entityId}
											totalAmount={0}
											customer={customerState}
											paymentMethods={paymentMethods}
											isScheduled={false}
											initialStep={EChargeInnerStep.NEW_CARD}
											showFeeWarning={false}
										/>
									</ModalWindow>
								</BodyContainer>
							</div>
					),
					minWidthModal: width,
				};
			case ReplaceMethodSteps.CHOOSE_TYPE:
				return {
					content: (
							<div css={selectContainerCss}>
								<Header />
								<BodyContainer>
									<SelectMethod
										isAmountEditable={false}
										totalAmount={0}
										amount={0}
										setAmount={() => {}}
										method={step}
										setMethod={setStep}
										paymentMethods={methodOptions}
										onlyAutoChargeMethods={true}
										disableBalance={true} />
								</BodyContainer>
							</div>
					),
					minWidthModal: width,
				};
			case ReplaceMethodSteps.SUCCESS:
				return {
					content: (
						<SuccessMessage paymentMethod={replacablePaymentMethods?.find(method => method.id === newMethodId)} onClick={toggle} />
					),
					minWidthModal: width,
				};
			default:
				return {
					content: <ErrorModalDepricated message={error} />,
					minWidthModal: isMobile ? SMALL_MIN_WIDTH : LARGE_MIN_WIDTH,
				};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newMethodId, step, customerState, methodOptions, replacablePaymentMethods, paymentMethods]);

	useEffect(() => {
		if (!isShowing) {
			setStep(undefined);
			setNewMethodId(undefined);
		}
	}, [isShowing]);

	return (
		<ModalWindow
			isShowing={isShowing}
			toggle={toggle}
			padding={1}
			minWidth={minWidthModal}
			maxWidth={isMobile ? '300px' : '360px'}
		>
			<div>{content}</div>
		</ModalWindow>
	);
};

const BodyContainer: FC = ({ children }) => {
	return <div css={[paddingCss(12)]}>{children}</div>;
};
