/** @jsx jsx */

import React, { Fragment } from 'react';
import { jsx, css } from '@emotion/react';
import { Tag } from '../../shared/Tag';
import { CustomerTypeEnum, EStatusColorMapper } from '../../../types/customers';
import { TranslationEn } from '@assets/i18n/en';
import { CustomerFamilyStatusEnum } from '@bondsports/types';

interface IProps {
	accountType?: CustomerTypeEnum | CustomerFamilyStatusEnum;
	labels?: Record<string, string>;
}

interface ICustomerTagProps {
	entityType?: CustomerTypeEnum;
	familyStatus?: CustomerFamilyStatusEnum;
}

const CustomerTag = ({ accountType, labels }: IProps) => {

	return <Tag title={labels[accountType]} color={EStatusColorMapper[accountType]} />;
};

export const CustomerTagMapper  = ({ entityType, familyStatus }: ICustomerTagProps) => {
	const labels = TranslationEn.customers.tags;
	const accountType = entityType === CustomerTypeEnum.USER ? familyStatus : entityType;
	const accountTypeMap: Record<string, JSX.Element> = {
	  [CustomerTypeEnum.EMPLOYEE]: <CustomerTag accountType={CustomerTypeEnum.EMPLOYEE} labels={labels} />,
	  [CustomerTypeEnum.FAMILY]: <CustomerTag accountType={CustomerTypeEnum.FAMILY} labels={labels} />,
	  [CustomerTypeEnum.ORGANIZATION]: <CustomerTag accountType={CustomerTypeEnum.ORGANIZATION} labels={labels} />,
	  [CustomerFamilyStatusEnum.DEPENDENT]: <CustomerTag accountType={CustomerFamilyStatusEnum.DEPENDENT} labels={labels} />,
	  [CustomerFamilyStatusEnum.INDEPENDENT]: <CustomerTag accountType={CustomerFamilyStatusEnum.INDEPENDENT} labels={labels} />,
	  [CustomerFamilyStatusEnum.PRIMARY]: <CustomerTag accountType={CustomerFamilyStatusEnum.PRIMARY} labels={labels} />,
	};
	return accountTypeMap[accountType] || <Fragment />;
}
