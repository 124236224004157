import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { DEFAULT_REACT_QUERY_RETRY_COUNT, DEFAULT_REACT_QUERY_STALE_MINUTES } from '../lib/constants';
import { IPaginatedReactQueryFetch } from '../types/reactQuery';


export const usePaginatedReactQueryFetch = <T>({
    staleMinutes = DEFAULT_REACT_QUERY_STALE_MINUTES,
    requestRetyCount = DEFAULT_REACT_QUERY_RETRY_COUNT,
    queryKey,
    fetchData,
    enabled,
}: IPaginatedReactQueryFetch<T>) => {
	const { data, isLoading, isError, refetch } = useQuery(
		queryKey,
		fetchData,
		{
		  enabled, // Ensure the query runs only when `memberId` is defined
		  staleTime: staleMinutes * 60 * 1000, // Cache data for 5 minutes
		  retry: requestRetyCount, // Retry failed requests up to 3 times
		}
	);

	return {
		data: data?.rows || [],
		meta: data?.meta,
		isLoading,
		isError,
		refetch, // Allows manual refetching if needed
	};
};
