export const SUB_TOTAL_PRICE = 'subTotalPrice';
export const TOTAL_PRICE = 'totalPrice';
export const TOTAL_TAX = 'totalTax';
export const PRODUCTS_SUB_TOTAL = 'productsSubTotal';
export const ADDONS_SUB_TOTAL = 'addonsSubTotal';
export const CATEGORIES_ADDONS_SUBTOTAL = 'categoriesAddonsSubTotal';
export const CATEGORIES_PRODUCTS_SUBTOTAL = 'categoriesProductsSubTotal';
export const RESERVATION_TOTAL_PRICE = 'reservationTotalPrice';
export const PRICING_TYPE = 'pricing_type';
export const ADDONS = 'addons';
export const PRODUCTS = 'products';
