import React from 'react';
import { isErrorResponse } from '@bondsports/utils';
import { membershipApi } from '../../lib/api/membershipApi';
import { mapPauseHistoryFromBackend } from '@app/react/mappers/members.pause.mapper';
import { IPauseHistory } from '@app/react/forms/membership/pause/createPause/types/interfaces';
import { usePaginatedReactQueryFetch } from '@app/react/hooks/usePaginatedReactQueryFetch';

export const useFetchMembershipPauseHistory = (
	organizationId: number, 
	memberId: number,
	memberHasPauseHistory: boolean,
	onError: (error: string) => void,
) => {

	const fetchPauseHistory = async () :Promise<{
		rows: IPauseHistory[];
		meta: any;
	}> => {
		try {
			const memberPauseHistory = await membershipApi.getPauseHistoryByMemberId(
				organizationId,
				memberId
			);
			if (isErrorResponse(memberPauseHistory)) {
				onError(String(memberPauseHistory.err ?? memberPauseHistory));
			}
			if (memberPauseHistory.data && memberPauseHistory.meta) {
				const data = mapPauseHistoryFromBackend(memberPauseHistory.data);
				return {
					rows: data,
					meta: memberPauseHistory.meta
				}
			}
		} catch (error) {
			onError(String(error));
		}
	};
	const queryKey = ['pauseHistory', organizationId, memberId]; // Query key for caching

	const { data, meta, isLoading, isError, refetch } = usePaginatedReactQueryFetch<IPauseHistory>({
		queryKey,
		enabled: !!memberId && memberHasPauseHistory,
		fetchData: fetchPauseHistory,
	});

	return {
		pauseHistory: data || [],
		meta: meta,
		isLoading,
		isError,
		fetchPauseHistoryData: refetch, // Allows manual refetching if needed
	
	};
};
