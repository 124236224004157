import React, { createContext, useContext } from 'react';

const PauseFormContext = createContext<any | undefined>(undefined);

export const usePauseFormContext = () => {
	const context = useContext(PauseFormContext);
	if (!context) {
		console.log('usePauseFormContext must be used within an PauseFormProvider');
	}
	return context;
};

export const PauseFormProvider: React.FC<any> = ({
	children,
	data,
	updateData,
	organizationId,
	isEdit,
}) => {
	return (
		<PauseFormContext.Provider
			value={{
				data,
				updateData,
				organizationId,
				isEdit,
			}}
		>
			{children}
		</PauseFormContext.Provider>
	);
};
