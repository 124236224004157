/** @jsx jsx */
import React, { useMemo } from 'react';
import { jsx, css } from '@emotion/react';
import { IMembershipRow } from '../../../types/membership';
import { TranslationEn } from '@assets/i18n/en';
import { FlexWrapLayout } from 'ui';
import { useSteps } from '@app/react/forms/FormTemplate/useSteps';
import { DEFAULT_PAUSE_POPUP_HEIGHT, PAUSE_POPUP_CUSTOM_MARGIN, PAUSE_POPUP_WIDTH, PAUSE_STEPS_LENGTH } from '../utils/consts';
import { PauseMembershipSteps } from '../types/members.enums';
import { ModalBody } from 'app/react/components/shared/ModalBody';
import { ModalWindow, SuspenseWrapper } from '@bondsports/utils';
import { CreatePause } from '@app/react/forms/membership/pause/createPause';
import { IPauseDetails } from '@app/react/forms/membership/pause/createPause/types';
import { useOrganizationTimezone } from '@app/react/hooks/useOrganizationTimezone';
import { usePauseForm } from '@app/react/forms/membership/pause/createPause/hooks/usePauseForm';
import { isFamilyMembership } from '@app/react/forms/membership/pause/createPause/utils';
import { getDescriptionAndTitle } from '../utils/pause.utils';
import { IPauseHistory } from '@app/react/forms/membership/pause/createPause/types/interfaces';
import { fullWidth, minSizeCss } from '@app/react/styles/utils';

interface Props {
	isProcessing: boolean;
	selectedMember: IMembershipRow;
	organizationId: number;
	isModalShown: boolean;
	pauseId?: number;
	setPauseId: (id: number) => void;
	pauseToggle: () => void;
	isLoadingHistory: boolean;
	pauseHistory: IPauseHistory[];
	handlePause: (values: IPauseDetails, familyMembership?: boolean) => void;
}

export const PauseMembershipModal = ({
	isProcessing,
	selectedMember,
	pauseToggle,
	organizationId,
	isModalShown,
	pauseId,
	setPauseId,
	handlePause,
	isLoadingHistory,
	pauseHistory
}: Props) => {
	const name = selectedMember?.name ?? '';
	const memberOfFamilyMembership: boolean = useMemo(() => selectedMember?.customerTypes && isFamilyMembership(selectedMember?.customerTypes), [selectedMember?.customerTypes]);
	const { currentStep, nextStep, prevStep, resetSteps, setStep } = useSteps(PauseMembershipSteps.PAUSE_FORM, PAUSE_STEPS_LENGTH);
	const labels = TranslationEn.memberships.members.pauseMemberships.pauseMembershipModal;
	const { data, updateData, isEdit, error, isLoading: isLoadingInitialPause } = usePauseForm(organizationId, pauseId);
	const { timezone } = useOrganizationTimezone();

	const handleReset = () => {
		pauseToggle();
		pauseId && setPauseId(null);
		resetSteps();
	};

	const handlePauseClick = (values: IPauseDetails) => {
		handlePause(values, memberOfFamilyMembership);
		handleReset();
	};

	const handleHistoryClick = () => {
		setStep(PauseMembershipSteps.PAUSE_HISTORY);
	};

	const { description, title } = getDescriptionAndTitle(currentStep, labels, name);
	const isLoading = isLoadingInitialPause || isProcessing;

	const setFirstStep = () => {
		setStep(PauseMembershipSteps.PAUSE_FORM);
	};

	return (
		<ModalWindow toggle={handleReset} isShowing={isModalShown}>
			<FlexWrapLayout>
				<div css={[minSizeCss(PAUSE_POPUP_WIDTH, DEFAULT_PAUSE_POPUP_HEIGHT)]}>
					<div css={fullWidth}>
						<SuspenseWrapper width={30} loading={isLoading}>
							<ModalBody
								title={title}
								handleBack={setFirstStep}
								backHidden={currentStep === PauseMembershipSteps.PAUSE_FORM}
								description={description}
								customWidth={PAUSE_POPUP_WIDTH}
								customMargin={PAUSE_POPUP_CUSTOM_MARGIN}
								h4Margin='0'
							>
								<CreatePause 
									organizationId={organizationId}
									timezone={timezone}
									data={data}
									updateData={updateData}
									isEdit={isEdit}
									nextStep={nextStep}
									prevStep={prevStep}
									pauseHistory={pauseHistory}
									handlePauseHistoryClick={handleHistoryClick}
									isLoading={isLoading}
									isLoadingHistory={isLoadingHistory}
									isFamilyMembership={memberOfFamilyMembership}
									handleReset={handleReset}
									currentStep={currentStep}
									handlePause={handlePauseClick}
									selectedMember={selectedMember}
								/>
							</ModalBody>
						</SuspenseWrapper>
					</div>
				</div>
			</FlexWrapLayout>
		</ModalWindow>
	);
};
