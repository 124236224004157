/** @jsxRuntime classic */
/** @jsx jsx */
import { useMemo } from 'react';
import { jsx } from '@emotion/react';
import { PaymentDetailAndIcon } from './PaymentDetailAndIcon';

import { getXLastChars } from '../../../lib/utils';
import { PaymentMethodTypeEnum } from '../../../types/entities/payment';
import { i18n } from '../../../lib/i18n';
import { EPaymentIcons } from '../../../atoms/PaymentMethod';
import { ETypographyColor } from '../../../types';
import { DEFAULT_LINE_HEIGHT, CHECK_LAST_CHARS, GIFT_CARD_LAST_CHARS, DEFAULT_ICON_SIZE } from '../utils/consts';

interface IPaymentMethod {
	paymentType: PaymentMethodTypeEnum;
	ccBrand?: string;
	ccLast4?: string;
	paymentMethodId?: string;
	stripePaymentMethodId?: string;
}

interface PaymentMethodProps {
	state: IPaymentMethod;
	noIcons?: boolean;
	color?: ETypographyColor;
}

export const PaymentMethodMapper = ({ state, color, noIcons = false }: PaymentMethodProps) => {
	const { paymentMethodId, stripePaymentMethodId, ccBrand, ccLast4 } = state;
	const pmId = stripePaymentMethodId ?? paymentMethodId;
	const paymentType = (state?.paymentType ?? (state as any).method) || PaymentMethodTypeEnum.OTHER;
	const labels = i18n.PaymentMethodTypeEnum;

	const { icon, text, iconSize } = useMemo(() => {
		const icon = [PaymentMethodTypeEnum.CARD_ON_TERMINAL, PaymentMethodTypeEnum.CARD].includes(paymentType)
			? state.ccBrand
			: !noIcons
			? EPaymentIcons[paymentType]
			: '';
		let text = paymentType as string;
		let iconSize = DEFAULT_ICON_SIZE;
		let methodId = '';
		switch (paymentType.replace(' ', '-').toLowerCase()) {
			case PaymentMethodTypeEnum.CARD_ON_TERMINAL:
			case PaymentMethodTypeEnum.CARD:
				text = `${noIcons ? `${ccBrand} ` : ''}${ccLast4}`;
				iconSize = DEFAULT_LINE_HEIGHT;
				break;
			case PaymentMethodTypeEnum.CHECK:
				methodId = pmId ? `#${pmId.substring(pmId.length - CHECK_LAST_CHARS, pmId.length)}` : '';
				text = `${labels[PaymentMethodTypeEnum.CHECK]} ${methodId}`;
				break;
			case PaymentMethodTypeEnum.GIFT_CARD:
				methodId = pmId ? `#${getXLastChars(String(pmId), GIFT_CARD_LAST_CHARS)}` : '';
				text = `${labels[PaymentMethodTypeEnum.GIFT_CARD]} ${methodId}`;
				break;
			case PaymentMethodTypeEnum.ACH:
				text = `${labels[PaymentMethodTypeEnum.ACH]} ${ccLast4}`;
				break;
			case PaymentMethodTypeEnum.OTHER:
				text = labels[PaymentMethodTypeEnum.OTHER];
				break;
		}
		return { icon, text, iconSize };
	}, [state]);

	return <PaymentDetailAndIcon text={text} height={DEFAULT_LINE_HEIGHT} icon={!noIcons && icon} color={color} iconSize={iconSize} />;
};
export { PaymentMethodTypeEnum };
