/** @jsx jsx */

import React from 'react';
import { css, jsx } from '@emotion/react';
import { flexEndCss, ChargeButtonCss } from '../../../styles/utils';
import { colors } from '../../../styles/theme';
import { TranslationEn } from 'assets/i18n/en';
import { CTAButton } from '../../shared/Button/CTAButton';
import { ButtonLoader } from '../../shared/Loader';

const bottomCss = css`
	${flexEndCss};
	margin-top: 1rem;
	border-top: 1px solid ${colors.borderSeperator};
	padding-top: 2rem;
	padding-bottom: 2rem;
	padding-right: 3.75rem;
	padding-left: 2rem;
`;

interface Props {
	toggle: () => void;
	disabled: boolean;
	loader: boolean;
	submitButtonText?: string;
	onSubmit?: () => void;
}

export const Footer = ({ toggle, disabled = false, loader, submitButtonText, onSubmit = () => {} }: Props) => {
	return (
		<div data-aid="utilsFooter" css={bottomCss}>
			<CTAButton onClick={toggle}>{TranslationEn.memberships.selectPage.cancel}</CTAButton>

			{submitButtonText && onSubmit && 
				<CTAButton
					onClick={() => {
						if (onSubmit) {
							onSubmit();
						}
					}}
					type="submit"
					css={ChargeButtonCss}
					disabled={disabled}
				>
					{loader ? <ButtonLoader /> : <React.Fragment>{submitButtonText}</React.Fragment>}
				</CTAButton>
			}
		</div>
	);
};
