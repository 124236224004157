import * as React from 'react';
import { TranslationEn } from '@assets/i18n/en';
import {
	CancellationStatusEnum,
	IExpandedMembershipMemberData,
	MembershipMember,
	MembershipStatusEnum,
	PaymentStatusEnum,
} from '@bondsports/types';
import { Tag } from '@bondsports/utils';

import { getRenwalTitle } from '../../../memberships/utils/utils';
import { ColumnsIDsEnum, IMembershipRow } from '../../../../types/membership';
import { BOdocumentsEnum } from '../../../../types/enums';
import { getLineItemPaymentStatus } from './utils';
import { calculateEndDates, isInFuture, isToday } from '@bondsports/date-time';

const labels = TranslationEn.memberships;
const tabLabels = TranslationEn.customers.membershipTab;

const generateEndDate = (member: MembershipMember) => {
	const memberHasAnActiveOrPendingPause: boolean =
	(!!member.pauseEndDate && isInFuture(member.pauseEndDate)) || isToday(member.pauseEndDate);
	return memberHasAnActiveOrPendingPause
	? calculateEndDates(
			[member.endDate],
			{
				unit: member.pauseDurationType,
				amount: member.pauseDurationValue,
			},
			false
	  )[0]
	: member.endDate;
}
const getCancellationTagComponent = (cancellationStatus: CancellationStatusEnum) => {
	const { autoRenewalCancel } = labels.tags;
	if (cancellationStatus === CancellationStatusEnum.AUTO_RENEWAL) {
		return <Tag title={autoRenewalCancel} color={'red'} />;
	}
	return null;
};

const checkIfMoreButtonDisabled = (membershipStatus: MembershipStatusEnum) => {
	const { CANCELLED, EXPIRED } = MembershipStatusEnum;
	const isDisabled = membershipStatus === CANCELLED || membershipStatus === EXPIRED;
	return isDisabled;
};

export const createMembershipRows = (data: IExpandedMembershipMemberData) => {
	const { membership, member, user, lineItem, invoice } = data;
	const { cancellationStatus } = member;
	const flattenMembership = {} as IMembershipRow;

	Object.keys(data).forEach(key => {
		if (key === BOdocumentsEnum.PAYMENT) {
			const shouldShowPayment = lineItem.paymentStatus !== PaymentStatusEnum.NOT_PAID;
			if (shouldShowPayment) {
				flattenMembership.paymentId = data[key].id;
			}
		}
		if (key === 'member') {
			flattenMembership.memberId = data[key].id;
		}
		if (key !== BOdocumentsEnum.INVOICE && key !== BOdocumentsEnum.PAYMENT) {
			const fields = Object.keys(data[key]);
			fields.forEach(field => {
				flattenMembership[field] = data[key][field];
			});
		}
	});

	const newProps = {
		customerName: `${user.firstName} ${user.lastName}`,
		renewal: getRenwalTitle(membership.durationMonths),
		invoiceId: invoice.id,
		paymentStatus: getLineItemPaymentStatus(lineItem),
		price: lineItem.price,
		withPaymentStatusIcon: true,
		TagComponent: cancellationStatus ? getCancellationTagComponent(cancellationStatus) : null,
		taggedColumnId: ColumnsIDsEnum.END_DATE,
		additionalDate: flattenMembership.cancelledAt,
		additionalDatePrfix: tabLabels.cancellationDatePrefix,
		originalEndDate: member.hasPauseHistory ? generateEndDate(member) : member.originalEndDate,
		more: {
			membershipStatus: flattenMembership.membershipStatus,
			hasPauseHistory: member.hasPauseHistory,
			cancellationStatus,
			isDisabled: checkIfMoreButtonDisabled(flattenMembership.membershipStatus),
			isAutoRenew: flattenMembership.isAutoRenew,
		},
	};
	delete flattenMembership.firstName;
	delete flattenMembership.lastName;

	return { ...flattenMembership, ...newProps };
};

export const calculateOutOfRangeDurationMonthsOffset = (isPauseOutOfRange: boolean, durationMonths: number, initialEndDate: string, pauseStartDate: string): number => {
	if (!isPauseOutOfRange) {
		return 0;
	}
	const pauseStartMonth = new Date(pauseStartDate).getMonth();
	const initialEndDateMonth = new Date(initialEndDate).getMonth();
	const monthDifference: number = Math.abs(pauseStartMonth - initialEndDateMonth);
	const isPauseDayAfterEndDateDay: boolean = new Date(pauseStartDate).getDate() > new Date(initialEndDate).getDate();
	const isAfterDayInMonthOffset: number = !!monthDifference && isPauseDayAfterEndDateDay ? 1 : 0; 
	return Math.max(durationMonths, (monthDifference + isAfterDayInMonthOffset));
}
