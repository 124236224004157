/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { flexBetweenCss } from '../../../styles/utils';
import { TagColorsMapper } from '@bondsports/utils';
import { CaptionTypography, InfoOutline } from 'ui';

interface PauseWarningProps {
    warningMessage: string;
    showWarning: boolean;

}

const warningIconCss = css`
	width: 24px;
	height: 24px;
	color: ${TagColorsMapper.orange.primary};
`;
const warningContainerCss = css`
 ${flexBetweenCss};
 gap: 1rem;
 text-align: left;
`;

export const FamilyPauseWarning = ({ warningMessage, showWarning }: PauseWarningProps) => {
    if (!showWarning) {
        return null;
    }

	return (
		<div css={warningContainerCss} data-testid="pause-warning-container">
			<InfoOutline css={warningIconCss} />
			<CaptionTypography className='text-primary' truncate={false}>
				{warningMessage}
			</CaptionTypography>
		</div>
    );
};
