/** @jsx jsx */

import { useState, useRef, useEffect, useCallback } from 'react';
import { jsx, css } from '@emotion/react';
import { useSteps } from '@app/react/forms/FormTemplate/useSteps';
import { colors } from '../../../styles/theme';
import SelectInstallmentsStep from '../Schedule/selectInstallmentsStep';
import { useTablePagination } from '@app/react/hooks/useTablePagination';
import ChargeInstallmentStep from '../Schedule/chargeInstallmentStep';
import { useGetPaymentMethods } from '../Schedule/hooks/useGetPaymentMethods';
import { useInstallments } from '../Schedule/hooks/useInstallments';
import { useErrorModalDepricated } from '../../shared/ErrorDepricated';

const container = css`
	padding: 2rem;
	display: flex;
	align-items: center;
	flex-direction: column;

	h1 {
		font-family: Montserrat;
		font-style: normal;
		font-weight: bold;
		font-size: 2rem;
		line-height: 2.4rem;
		text-align: center;
		color: ${colors.brandPrimary};
	}

	h2 {
		max-width: 400px;
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 1.4rem;
		line-height: 130%;
		text-align: center;
		color: ${colors.formInputBg};
	}
`;

enum ScheduledPaymentsStep {
	SELECT_INSTALLMENTS,
	SELECT_PAYMENTS,
}

interface IProps {
	totalAmount: number; // Display -> with fees
	totalToCharge: number; // To api -> without fees
	toggle: () => void;
	userId: number;
	hasMembershipLine?: boolean;
	invoiceId: number;
	organizationId: number;
}

export const Scheduled = ({ userId, toggle, invoiceId, organizationId, totalToCharge, hasMembershipLine }: IProps) => {
	const { currentStep, nextStep, prevStep } = useSteps(0, 2);
	const { currentPage, nextPage } = useTablePagination();
	const {
		meta,
		selectedIds,
		getScheduledPayments,
		scheduledPayments,
		selectedInstallmentsPrice,
		handleSelectAll,
		isSelectAll,
		handleSelect,
		isApplyPmDisabled,
	} = useInstallments();
	const { getMethods, paymentMethods, customer, setCustomer } = useGetPaymentMethods();
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const { ErrorModalDepricated, setError, ErrorToggle, error } = useErrorModalDepricated();
	const tableRef = useRef<HTMLDivElement>(null);

	const handleBack = () => {
		prevStep();
	};

	const fetchData = useCallback(async () => {
		setIsProcessing(true);
		try {
			await getMethods(organizationId, customer?.entityId || userId);
			await getScheduledPayments(organizationId, invoiceId, customer?.entityId || userId, nextPage);
		} finally {
			setIsProcessing(false);
		}
	}, [organizationId, invoiceId, userId, currentPage]);

	useEffect(() => {
		fetchData();
	}, [userId, organizationId, invoiceId, currentPage]);

	const priceToCharge = isSelectAll ? totalToCharge - selectedInstallmentsPrice : selectedInstallmentsPrice;
	return (
		<div data-aid="ScheduledPopup" css={container}>
			{currentStep === ScheduledPaymentsStep.SELECT_INSTALLMENTS ? (
				<SelectInstallmentsStep
					handleNext={nextStep}
					isLoading={isProcessing}
					scheduledPayments={scheduledPayments}
					meta={meta}
					tableRef={tableRef}
					selectedIds={selectedIds}
					handleSelect={handleSelect}
					isAllSelected={isSelectAll}
					handleSelectAll={handleSelectAll}
					page={currentPage}
					onClose={toggle}
				/>
			) : (
				<ChargeInstallmentStep
					invoiceId={invoiceId}
					ErrorToggle={ErrorToggle}
					setError={setError}
					isProcessing={isProcessing}
					setIsProcessing={setIsProcessing}
					organizationId={organizationId}
					toggle={toggle}
					isApplyPaymentMethodDisabled={!hasMembershipLine && isApplyPmDisabled}
					initialPrice={priceToCharge}
					customer={customer}
					setSelectedCustomer={setCustomer}
					userId={customer?.entityId}
					initialPaymentMethods={paymentMethods}
					selectedIds={selectedIds}
					isSelectAll={isSelectAll}
					handleBack={handleBack}
					handleNext={nextStep}
				/>
			)}
			<ErrorModalDepricated message={error} />
		</div>
	);
};
