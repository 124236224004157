import { useState } from 'react';
//TODO: Remove and replace usage with usePagination
export const useSteps = (initialStep = 0, totalSteps = 1) => {
	const [currentStep, setCurrentStep] = useState(initialStep);

	const nextStep = () => {
		setCurrentStep(prevStep => prevStep + 1);
	};

	const prevStep = () => {
		setCurrentStep(prevStep => prevStep - 1);
	};

	const setStep = (step: number) => {
		setCurrentStep(step);
	};

	const resetSteps = () => {
		setCurrentStep(initialStep);
	};

	return {
		currentStep,
		totalSteps,
		isLastStep: currentStep === totalSteps - 1,
		isFirstStep: currentStep === 0,
		nextStep,
		prevStep,
		setStep,
		resetSteps,
	};
};
