/** @jsxRuntime classic*/
/** @jsx jsx */
import { css, jsx } from '@emotion/react';

import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { ETypography, ETypographyColor, IColors, Icons } from '../../types/theme';
import { PaymentMethodTypeEnum } from '../../types/entities/payment';
import { Pricify } from '../../lib/price';
import { Typography } from '../../atoms/Typography';
import { PaymentMethod } from '../../atoms/PaymentMethod';
import { Input } from '../../components/Input';
import { usePaymentMethodPicker } from './hooks/usePaymentMethodPicker';
import { ThemeColors as colors } from '../../styles/theme';
import { i18n } from '../../lib/i18n';
import { typesWithoutRegularLimit } from './utils/consts';
import { getErrorDisplayed } from './utils/funcs';

const containerCss = (showAmount: boolean) => css`
	width: 100%;
	min-width: 300px;
`;

const methodContainerCss = (
	methodCount: number,
	height: { collapsed: number; expanded: number },
	expanded = false
) => css`
	gap: 8px;
	display: flex;
	flex-direction: column;
	overflow: clip;
	transition: height 0.25s;
	width: 100%;
	${!expanded && `height: ${(methodCount !== 4 ? height.collapsed : height.expanded) + 15}px;`};
	${expanded && `height: ${height.expanded + 15}px;`};
`;

const rowContainerCss = css`
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: 16px;
`;

const selectContainerCss = css`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

const amountContainerCss = css`
	display: flex;
	flex-direction: column;
	width: 100px;
	svg {
		width: 100%;
		height: 100%;
	}
	.amount-input {
		margin: 0;
		div {
			padding: 0;
			div {
				padding: 8px 12px;
			}
		}
	}
`;

const showMoreCss = css`
	cursor: pointer;
`;

const footerCss = css`
	margin-top: 8px;
	height: 20px;
`;

const justifySpreadCss = css`
	justify-content: space-between;
`;

const amountLeftCss = (colors: IColors, negativeAmount: boolean) => css`
	${negativeAmount && `color: ${colors.tx_error};`}
`;

export interface IPayingUser {
	firstName?: string;
	email?: string;
	lastName?: string;
	id: number;
}

export interface ICanUseOtherPaymentMethod {
	canUseOtherPaymentMethod: boolean;
}

export interface IPaymentMethod {
	type: PaymentMethodTypeEnum;
	selected?: boolean;
	id?: number;
	paymentMethodId?: string;
	icon?: Icons;
	primaryText?: string;
	secondaryText?: string;
	amount?: number; // Amount on a single card or ach payment
	refundAmount?: number; // amount to refund on this payment method
	refundSuccess?: boolean;
	payingUser?: IPayingUser;
	payingUserId?: number;
	user?: IPayingUser;
}

export interface PaymentMethodPickerProps extends ICanUseOtherPaymentMethod {
	showAmount: boolean;
	totalPrice?: number;
	usedPaymentMethods?: IPaymentMethod[];
	onChangeOfDetails: (pms: IPaymentMethod[]) => void;
	handleDisable?: (val: boolean) => void;
	isPublicInvoice?: boolean;
	invoiceOwner?: IPayingUser;
}

export const PaymentMethodPicker = ({
	showAmount,
	totalPrice,
	usedPaymentMethods = [],
	onChangeOfDetails,
	handleDisable,
	isPublicInvoice = false,
	invoiceOwner,
	canUseOtherPaymentMethod,
}: PaymentMethodPickerProps) => {
	const {
		priceRemaining,
		currentUser,
		currentLimit,
		currentRemainingPrice,
		paymentMethods,
		labels,
		height,
		handlePaymentSelect,
		buildPaymentMethods,
		recalcPriceRemaining,
	} = usePaymentMethodPicker(invoiceOwner?.id! ?? Number(invoiceOwner), handleDisable, canUseOtherPaymentMethod);
	const [expanded, setExpanded] = useState<boolean>(false);

	const handleSelect = (id: number, amount?: number) => {
		handlePaymentSelect(id, totalPrice, showAmount, amount);
	};

	const calcPaymentMethods = useCallback(() => {
		buildPaymentMethods(usedPaymentMethods, invoiceOwner);
	}, [usedPaymentMethods, invoiceOwner, buildPaymentMethods]);

	const handleOnChange = useCallback(() => {
		onChangeOfDetails(paymentMethods);
	}, [paymentMethods]);

	const recalcRemaining = () => {
		if (totalPrice) {
			recalcPriceRemaining(totalPrice);
		}
	};

	const showMoreToggle = () => {
		setExpanded(!expanded);
	};

	useEffect(() => {
		calcPaymentMethods();
	}, [usedPaymentMethods.length]);

	useEffect(() => {
		if (paymentMethods?.length > 0) {
			recalcRemaining();
			handleOnChange();
		}
	}, [paymentMethods]);

	return (
		<Fragment>
			{paymentMethods && paymentMethods.length > 0 && height && (
				<div data-testid="pm-picker-container" css={containerCss(showAmount)} data-aid="index-PaymentMethodPicker">
					<div css={methodContainerCss(paymentMethods.length, height, expanded)}>
						<div css={rowContainerCss}>
							<div css={selectContainerCss}>
								<Typography color={ETypographyColor.secondary} type={ETypography.captionAccented}>
									{labels.selectTitle}
								</Typography>
							</div>
							{showAmount && (
								<div css={amountContainerCss} data-testid="pm-amount-title">
									<Typography color={ETypographyColor.secondary} type={ETypography.captionAccented}>
										{labels.amountTitle}
									</Typography>
								</div>
							)}
						</div>
						{paymentMethods &&
							paymentMethods.map((pm, idx) => {
								const handleOnChange = (val: number) => {
									if (!isNaN(val) && val >= 0 && val !== pm.refundAmount) {
										handleSelect(idx, val);
									}
								};

								const error = currentUser
									? getErrorDisplayed(pm, currentUser, currentLimit, currentRemainingPrice, labels)
									: null;

								const showError = error?.errorMessage || (priceRemaining < 0 && pm.refundAmount && pm.refundAmount > 0);

								const includeTag: boolean = !typesWithoutRegularLimit.includes(pm.type);

								return (
									<div key={idx} css={rowContainerCss}>
										<div css={selectContainerCss}>
											<PaymentMethod
												isPublicInvoice={isPublicInvoice}
												data-testid={`payment-method-${pm.type}`}
												id={idx}
												selected={pm.selected ?? false}
												type={pm.type}
												onSelect={handleSelect}
												primaryText={pm.primaryText}
												secondaryText={pm.secondaryText}
												icon={pm.icon}
												customErrorAmount={error?.disableErrorAmount}
												displayTag={includeTag}
												amountTag={pm.amount}
												error={error?.errorMessage}
												info={
													pm.type === PaymentMethodTypeEnum.ACH && pm.selected
														? i18n.refundPupop.paymentMethodPicker.achIfo
														: undefined
												}
												payingUser={pm.payingUser ?? invoiceOwner}
											/>
										</div>
										{showAmount && (
											<div css={amountContainerCss}>
												<Input
													data-testid={`pm-amount-input-${idx}`}
													decimalScale={2}
													disableTrailing
													hideError
													error={showError ? 'error' : undefined}
													leftIcon={Icons.dollar}
													onChange={e => handleOnChange(Number(e.currentTarget.value))}
													sizer="S"
													type="number"
													value={pm.refundAmount === 0 ? undefined : pm.refundAmount}
													className="amount-input"
												/>
											</div>
										)}
									</div>
								);
							})}
					</div>
					<div css={rowContainerCss}>
						<div
							css={[selectContainerCss, footerCss, paymentMethods?.length > 4 && showMoreCss]}
							onClick={showMoreToggle}
						>
							{paymentMethods?.length > 4 && (
								<Typography color={ETypographyColor.primary} type={ETypography.captionLink}>
									{expanded ? labels.showLess : labels.showMore}
								</Typography>
							)}
						</div>
						{showAmount && (
							<div css={[amountContainerCss, footerCss]}>
								<Typography
									color={priceRemaining >= 0 ? ETypographyColor.primary : ETypographyColor.red}
									type={ETypography.captionAccented}
								>
									<span css={amountLeftCss(colors, priceRemaining < 0)}>
										{labels.totalLeft}
										{priceRemaining ? Pricify(priceRemaining).replace('.00', '') : '$0'}
									</span>
								</Typography>
							</div>
						)}
					</div>
				</div>
			)}
		</Fragment>
	);
};
