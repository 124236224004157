import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { getLocationByLatLng } from '../components/shared/GoogleMap/utils';
import { membershipApi } from '../lib/api/membershipApi';
import { organizationApi } from '../lib/api/organizationApi';
import { productApi } from '../lib/api/productsApi';
import { isEmptyObject } from '../lib/utils';
import {
	CREATE_FACILITY_PATH,
	CREATE_ORGANIZATION,
	FACILITIES_PATH,
	FINANCIAL_PATH,
	ORGANIZATION_PATH,
	SCANNERS_TEST_PATH,
	SPACES_PATH,
} from '../pages/settings/paths';
import { organizationStore } from '../stores/organizationStore';
import { Membership } from '../types/membership';
import { Organization } from '../types/organization';
import { useFacility } from './useFacility';
import { resourceApi } from '../lib/api/resourceApi';

const ORG_SETTINGS_LOCAL_KEY = 'organizationSettings';

export const useOrganization = () => {
	const [organizationId, setOrganizationId] = useRecoilState(organizationStore.orgnanizationId);
	const [permissions, setPermissions] = useRecoilState(organizationStore.permissions);
	const [organizationSports, setOrganizationSports] = useRecoilState(organizationStore.organizationSports);
	const [organizationState, setOrganizationState] = useRecoilState(organizationStore.organizationData);
	const [isFetchingOrganizationState, setFetchingOrganizationState] = useRecoilState(
		organizationStore.isOrganizationFetching
	);

	const [isOrganizationProfileCompleted, setIsOrganizationProfileCompleted] = useRecoilState(
		organizationStore.isOrganizationProfileCompleted
	);

	const [refreshResourcesNeeded, setrefreshResourcesNeeded] = useRecoilState(organizationStore.refreshResourcesNeeded);
	const [facilities, setFacilities] = useRecoilState(organizationStore.facilities);
	const [isFetchingfacilities, setIsFetchingfacilities] = useRecoilState(organizationStore.isFetchingfacilities);
	const [resources, setResources] = useRecoilState(organizationStore.resources);
	const [location, setLocation] = useRecoilState(organizationStore.organizationLocation);
	const [forms, setForms] = useRecoilState(organizationStore.forms);
	const [addons, setAddons] = useRecoilState(organizationStore.addons);
	const [isFetchingAddons, setIsFetchingAddons] = useRecoilState(organizationStore.isFetchingAddons);
	const [sports, setSports] = useRecoilState(organizationStore.sports);
	const [memberships, setMemberships] = useRecoilState(organizationStore.memberships);
	const history = useHistory();
	const { isRefreshFacilitiesNeeded, setIsRefreshFacilitiesNeeded } = useFacility();
	const currency = organizationState?.currency;

	useEffect(() => {
		if (organizationState) {
			setIsOrganizationProfileCompleted(Boolean(organizationState.address && organizationState.sports?.length > 0));
		}
	}, [organizationState]);

	useEffect(() => {
		if (!organizationId) {
			setOrganizationId(Number(localStorage.getItem('selected_org_id') ?? 0));
		}
	}, [organizationId]);

	const initiateOrganizationData = (data: any) => {
		setOrganizationState(data);
		setFetchingOrganizationState(false);
		setOrganizationSports(data.sports);
		setLocation(undefined);
	};

	const fetchOrganizationData = (
		organizationId: number,
		handleError: (message: string, turnOffProcessing?: boolean) => void
	) => {
		setFetchingOrganizationState(true);
		organizationApi
			.getOrganizationData(organizationId)
			.then(res => {
				if (res.data) {
					localStorage.setItem(ORG_SETTINGS_LOCAL_KEY, JSON.stringify(res.data));
					initiateOrganizationData(res.data);
				} else if (res.err) {
					setFetchingOrganizationState(false);
					handleError(String(res.message), false);
				}
			})
			.catch(err => {
				setFetchingOrganizationState(false);
				handleError(err.message ? String(err.message) : String(err), false);
			});
	};

	const fetchOrganizationDataIfEmpty = (
		organizationId: number,
		handleError: (message: string, turnOffProcessing?: boolean) => void
	) => {
		if (isEmptyObject(organizationState)) {
			const organizationSettings = JSON.parse(localStorage.getItem(ORG_SETTINGS_LOCAL_KEY)) as unknown as Organization;
			if (organizationSettings?.id === organizationId) {
				initiateOrganizationData(organizationSettings);
			} else {
				fetchOrganizationData(organizationId, handleError);
			}
		}
	};

	const fetchFacilities = (
		organizationId: number,
		handleError: (message: string, turnOffProcessing?: boolean) => void
	) => {
		setIsFetchingfacilities(true);
		organizationApi
			.getFacilitiesDepricated(organizationId)
			.then(res => {
				if (res.data) {
					setFacilities(res.data);
					setIsRefreshFacilitiesNeeded(false);
				} else if (res.message) {
					handleError(String(res.message), false);
				}
			})
			.finally(() => {
				setIsFetchingfacilities(false);
			})
			.catch(err => {
				handleError(err.message ? String(err.message) : String(err), false);
			});
	};

	const fetchFacilitiesIfNeeded = (
		organizationId: number,
		handleError: (message: string, turnOffProcessing?: boolean) => void
	) => {
		if (isRefreshFacilitiesNeeded) {
			fetchFacilities(organizationId, handleError);
		}
	};

	const fetchOrganizationResources = (
		organizationId: number,
		handleError: (message: string, turnOffProcessing?: boolean) => void
	) => {
		resourceApi
			.getResourcesByOrganizationID(organizationId, { includeFacilities: true })
			.then(res => {
				if ((res as any).data) {
					//Todo ontop: remove as any
					setResources((res as any).data);
					setrefreshResourcesNeeded(false);
				} else if ((res as any).message) {
					handleError(String((res as any).message), false);
				}
			})
			.catch(err => {
				handleError(err.message ? String(err.message) : String(err), false);
			});
	};

	const fetchFacilitySpaces = async (organizationId: number, facilityId: number) => {
		const res = await organizationApi.getSpaces(organizationId, facilityId);
		setResources(res.data);
	};

	const initLocationIfEmpty = () => {
		if (organizationState.address && !location) {
			getLocationByLatLng(
				organizationState.address.geo.coordinates[1],
				organizationState.address.geo.coordinates[0],
				organizationState.address
			).then(res => {
				if (res) {
					setLocation(res);
				}
			});
		}
	};

	const getFormsOptions = (orgId: number) => {
		organizationApi.getForms(orgId).then(response => {
			if (response.data) {
				setForms(response.data);
			}
		});
	};

	const getOrganizationSports = (orgId?: number) => {
		organizationApi.getOrganizationData(orgId ?? organizationId).then(response => {
			if (response.data) {
				setSports(response.data.sports);
			}
		});
	};

	const getAddons = (orgId: number) => {
		productApi.getAddons(orgId ?? organizationId).then(response => {
			setIsFetchingAddons(false);
			if (response.data) {
				setAddons(response.data);
			}
		});
	};

	const getAllMemnerships = (orgId: number) => {
		membershipApi.getMembershipsByOrganizationId(orgId ?? organizationId).then((response: Membership[]) => {
			if (response.length) {
				setMemberships(response);
			}
		});
	};

	const navigateToCreateOrganization = () => {
		history.push(CREATE_ORGANIZATION);
	};

	const navigateToOrganization = () => {
		history.push(ORGANIZATION_PATH);
	};

	const navigateToFinancial = () => {
		history.push(`${FINANCIAL_PATH}`);
	};

	const navigateToCreateFacility = () => {
		history.push(CREATE_FACILITY_PATH);
	};

	const navigateToFacilities = () => {
		history.push(FACILITIES_PATH);
	};

	const navigateToScannersTest = () => {
		history.push(SCANNERS_TEST_PATH);
	};

	const navigateToSpaces = () => {
		history.push(SPACES_PATH);
	};

	return {
		organizationId,
		setOrganizationId,
		permissions,
		setPermissions,
		isFetchingOrganizationState,
		organizationState,
		fetchOrganizationData,
		fetchOrganizationDataIfEmpty,
		organizationSports,
		setOrganizationSports,
		fetchFacilities,
		fetchFacilitiesIfNeeded,
		facilities,
		navigateToCreateFacility,
		navigateToFacilities,
		navigateToScannersTest,
		initLocationIfEmpty,
		location,
		fetchOrganizationResources,
		resources,
		navigateToOrganization,
		setrefreshResourcesNeeded,
		refreshResourcesNeeded,
		navigateToCreateOrganization,
		isOrganizationProfileCompleted,
		getFormsOptions,
		forms,
		setForms,
		addons,
		getAddons,
		setAddons,
		isFetchingAddons,
		setIsFetchingAddons,
		getOrganizationSports,
		sports,
		setSports,
		memberships,
		setMemberships,
		getAllMemnerships,
		fetchFacilitySpaces,
		colorCodes: organizationState?.colorCodes || [],
		currency,
		settings: organizationState?.settings || { autopopulateDuration: 60 },
		isNewlyCreatedOrganization: !organizationState.addressId || !organizationState.address,
		isFetchingfacilities,
		navigateToSpaces,
		navigateToFinancial,
	};
};
