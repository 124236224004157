import { DurationOption } from "@app/react/types/membershipMember";
import { TimeUnit } from "@bondsports/date-time";

export function getDurationLabel(pauseDurationValue: string, pauseDurationType = TimeUnit.MONTH): string {
    const singular = Number(pauseDurationValue) === 1;
    const durationType = `${pauseDurationType.toLowerCase()}${singular ? '' : 's'}`;
    return `${pauseDurationValue} ${durationType}`;
}

export function getDurationOptionsData(pauseDurationValue: string): DurationOption {
	const label = getDurationLabel(pauseDurationValue);
    return {
		label,
        pauseDurationType: TimeUnit.MONTH,
        pauseDurationValue,
        isDisabled: false,
		value: pauseDurationValue,
	};
};
