import { TranslationEn } from '@assets/i18n/en';
import { PaymentMethodTypeEnum } from '@bondsports/types';

const filterablePaymentMethods = [
	PaymentMethodTypeEnum.CARD_ON_TERMINAL,
	PaymentMethodTypeEnum.CARD,
	PaymentMethodTypeEnum.ACH,
	PaymentMethodTypeEnum.BALANCE,
	PaymentMethodTypeEnum.CASH,
	PaymentMethodTypeEnum.CHECK,
	PaymentMethodTypeEnum.GIFT_CARD,
	PaymentMethodTypeEnum.OTHER,
];

export const filterPaymentMethods = filterablePaymentMethods
	.map(value => {
		return {
			label: TranslationEn.filterPaymentMethods[value],
			value,
		};
	});
