import * as yup from 'yup';
import { useCallback } from 'react';
export type ValidationMethod<T> = (values: any) => {} | Record<keyof T, any>;

export type ValidationOptions = {
	abortEarly?: boolean;
};

export function useSchemaValidation<T>(
	schema: yup.Schema<T>,
	options?: ValidationOptions
): [yup.Schema<T>, ValidationMethod<yup.InferType<yup.Schema<T>>>] {
	const validate: ValidationMethod<yup.InferType<yup.Schema<T>>> = useCallback(
		(formValues: any) => {
			try {
				schema.validateSync(formValues, { abortEarly: options?.abortEarly || false });
				return {};
			} catch (error) {
				if (error instanceof yup.ValidationError) {
					if (error.inner?.length) {
						return error.inner.reduce((errors, error) => {
							errors[error.path] = error.message;
							return errors;
						}, {});
					}

					return { [error.path]: error.message };
				}
			}
		},
		[schema]
	);

	return [schema, validate];
}
