import { TestContext, ValidationError } from 'yup';
import { PaymentPlanStatusEnum, PaymentPlanTypeEnum } from '@bondsports/types';
import { every, filter, minBy, sumBy } from 'lodash';
import { getLowestProductPrice } from '@app/react/forms/activities/pricing/utils/price';
import { CustomPaymentPlanAllocationEnum } from '@app/react/types/enums';
import { isEarlier } from '@app/react/lib/dates';
import { TranslationEn } from '@assets/i18n/en';
import { roundPriceCents } from '@bondsports/utils';
import { IDuePayment } from '../types/interfaces';
import { getDateString } from '@bondsports/date-time';

const LABELS = TranslationEn.activities.forms;

export function testCustomPlanWithEndRegistrationDate(schedule: IDuePayment[], ctx: TestContext) {
	const { createError, path } = this;

	if (!schedule || ctx.parent.status === PaymentPlanStatusEnum.INACTIVE) {
		return true;
	}

	const errors = [];
	for (const product of ctx.parent.products) {
		const productRegistrationErrors = schedule.map((due, i) => {
			if (due.date && isEarlier(due.date, new Date(product.registrationEndDate))) {
				return createError({ path: `${path}[${i}].date`, message: LABELS.validation.paymentPlan.custom.mustBeLess });
			}

			return;
		});

		errors.push(...productRegistrationErrors.filter(e => e));
	}

	if (!errors?.length) {
		return true;
	}

	return new ValidationError(errors);
}

export function testCustomPlanAllRequiredFields(isEdit = false) {
	return function (schedule: IDuePayment[], ctx: TestContext) {
		const { createError, path } = this;

		if (!schedule) {
			return true;
		}

		if (ctx.parent.paymentPlanType === PaymentPlanTypeEnum.CUSTOM) {
			const errors = [];
			for (let i = 0; i < schedule.length; i++) {
				const due = schedule[i];

				if (!due.date) {
					errors.push(createError({ path: `${path}[${i}].date`, message: LABELS.validation.required }));
				}

				if (!due.amount) {
					errors.push(createError({ path: `${path}[${i}].amount`, message: LABELS.validation.required }));
				}
			}

			const amount = roundPriceCents(
				sumBy(
					filter(ctx.parent.schedule as IDuePayment[], due => due.amount != null),
					due => Number(due.amount)
				)
			);

			let total = ctx.parent.totalAmount;

			total ||= getLowestProductPrice(
				ctx.parent.products.map(product =>
					Number(
						product.productPrice -
							Number(isEdit ? ctx.parent.downpayment : product.downpayment || ctx.parent.downpayment)
					)
				)
			);
			const noAmountLeft =
				ctx.parent.scheduleAmountType === CustomPaymentPlanAllocationEnum.PERCENT
					? amount === 100
					: amount === total;

			!noAmountLeft && errors.push(new ValidationError(LABELS.validation.paymentPlan.custom.percentMoreThanHundred));

			return errors?.length ? new ValidationError(errors) : true;
		}

		return true;
	};
}

export function testRequiredDeposit(downpayment: number, ctx: TestContext) {
	if (ctx.parent.paymentPlanType === PaymentPlanTypeEnum.NO_PLAN) {
		return true;
	}

	return every(ctx.parent.products as any[], product => product.downpayment != null || downpayment != null);
}

export function testAmountBasedOnScheduleAmountType(isEdit = false) {
	return function (scheduleAmountType: CustomPaymentPlanAllocationEnum, ctx: TestContext) {
		const { createError } = this;
		const errors: ValidationError[] = [];

		let totalAmount: number = ctx.parent.totalAmount;
		let deposit: number = 0;

		if (ctx.parent.products) {
			const lowestPriceProduct = minBy(ctx.parent.products as any[], product => Number(product.productPrice));

			totalAmount =
				lowestPriceProduct.productPrice == null ? null : Number(lowestPriceProduct.productPrice);

			deposit = isEdit ? ctx.parent?.downpayment : lowestPriceProduct.downpayment || ctx.parent.downpayment;
		}

		const max: number =
			scheduleAmountType === CustomPaymentPlanAllocationEnum.PERCENT
				? 100
				: roundPriceCents(totalAmount - (deposit || 0));

		let sum = 0;
		for (let i = 0; i < (ctx.parent.schedule as IDuePayment[]).length; i++) {
			const due = ctx.parent.schedule[i];

			sum = roundPriceCents(sum + due.amount);
			if (sum > max) {
				errors.push(
					createError({
						path: `schedule[${i}].amount`,
						message: LABELS.validation.paymentPlan.custom.amountMustBeLess,
					})
				);
			}
		}

		if (!errors.length) {
			return true;
		}

		return new ValidationError(errors);
	};
}

export function testScheduleDates() {
	return function (schedule: IDuePayment[], ctx: TestContext) {
		const { createError } = this;
		const errors: ValidationError[] = [];

		const dates = new Set();
		for (let i = 0; i < (ctx.parent.schedule as IDuePayment[]).length; i++) {
			const due = ctx.parent.schedule[i];

			if (due.date) {
				const dueDate = getDateString(due.date);

				if (!dates.has(dueDate)) {
					dates.add(dueDate);
				} else {
					errors.push(
						createError({
							path: `schedule[${i}].date`,
							message: LABELS.validation.paymentPlan.custom.dateMustBeUnique,
						})
					);
				}

				if (due.date < ctx.parent.notBeforeDate) {
					errors.push(
						createError({
							path: `schedule[${i}].date`,
							message: LABELS.validation.paymentPlan.custom.mustBeFutureDate,
						})
					);
				}
			}

		}

		if (!errors.length) {
			return true;
		}

		return new ValidationError(errors);
	};
}
