import {
	FindMembershipByFiltersDto,
	IExpandedMembershipMemberData,
	IPaginationData,
	MembershipFilterByPropertyEnum,
	MembershipOrderByPropertyEnum,
	OrderByEnum,
	PaginationResultDto,
	SimpleMembershipDto,
} from '@bondsports/types';

import { environment } from '../../../../environments/environment';
import { IApiError, network } from '../network';
import { MediaUpload } from './../../types/media';
import { MembershipMember } from './../../types/membershipMember';
import { IErrorArr } from '../../types/errors';

import { Membership, CreateMembershipDto, UpdateMembrshipDto, TMembershipFilterValue } from '../../types/membership';
import { buildQueryString } from '@app/react/lib/urlUtils';
import { IPauseMembershipMember, IUpdatePauseMembershipMember } from '@app/react/forms/membership/pause/createPause/types';

const membershipAPIUrl = `${environment.CS_URLS.API_ROOT_V4}/membership`;

async function getMembershipById(membershipId: number): Promise<Membership> {
	const response = await network.get(`${membershipAPIUrl}/${membershipId}`);

	return response;
}

export interface IMeta {
	itemsPerPage: number;
	page: number;
	totalPages: number;
	totalItems?: number;
}

async function getMembersInMembership(
	membershipId: number,
	page: number,
	itemsPerPage: number,
	searchFilter: string,
	pastMemberships?: boolean
): Promise<{ data: MembershipMember[]; meta: IMeta }> {
	const response = await network.get(
		`${membershipAPIUrl}/${membershipId}/members?page=${page}&itemsPerPage=${itemsPerPage}&nameEmailSearch=${searchFilter}&pastMemberships=${pastMemberships}`
	);

	return response;
}

async function getMembershipsByOrganizationId(organizationId: number): Promise<Membership[] | IApiError> {
	const response = await network.get(`${membershipAPIUrl}/organization/${organizationId}`);

	return response;
}

async function getPaginatedSimpleMembershipsByOrganizationId(
	organizationId: number,
	filters: FindMembershipByFiltersDto
): Promise<PaginationResultDto<SimpleMembershipDto>> {
	const query: string = buildQueryString(filters);
	return await network.get(`${membershipAPIUrl}/organization/${organizationId}/memberships/simple${query}`);
}

async function createMembership(createMembershipData: CreateMembershipDto): Promise<Membership> {
	// const { headers } = auth.getAuthHeaders();

	const response = await network.post(
		`${membershipAPIUrl}`,
		createMembershipData
		//headers
	);

	return response;
}

async function updateMembership(updateMembershipData: UpdateMembrshipDto): Promise<Membership> {
	// const { headers } = auth.getAuthHeaders();

	const response = await network.put(
		`${membershipAPIUrl}`,
		updateMembershipData
		//headers
	);

	return response;
}

async function updateMembershipMedia(membrshipId: number, mediaUploadData: MediaUpload): Promise<Membership> {
	// TODO: move api v1 logic to v2 and point to V4
	const response = await network.put(
		`${environment.CS_URLS.API_ROOT_V3}/membership/${membrshipId}/uploadMedia`,
		mediaUploadData
		//headers
	);

	return response;
}

async function getMembershipsOrganizationIdQuestion(organizationId: number): Promise<Membership[]> {
	const response = await network.get(`${environment.CS_URLS.API_ROOT}/organizations/${organizationId}/questionnaires`);

	return response;
}

const cancelMembership = async (
	organizationId: number,
	membershipMemberId: number,
	userId: number,
	isImmediatelyCancel: boolean,
	cancellationReason?: string
) => {
	const response = await network.delete(
		`${membershipAPIUrl}/organization/${organizationId}/${membershipMemberId}/user/${userId}/cancel`,
		{
			isImmediatelyCancel: isImmediatelyCancel,
			cancellationReason: cancellationReason,
		}
	);
	return response;
};

const getPauseById = async (organizationId: number, pauseId: number) => {
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/organization/${organizationId}/membership-member/${pauseId}`
	);
	return response;
}

const getPauseHistoryByMemberId = async (organizationId: number, memberId: number) => {
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/organization/${organizationId}/membership-member/${memberId}/pause/history`
	);
	return response;
}

const pauseMembership = async (
	organizationId: number,
	membershipMemberId: number,
	familyMembership: boolean,
	newPause: IPauseMembershipMember
) => {
	const response = await network.post(
		`${environment.CS_URLS.API_ROOT_V4}/organization/${organizationId}/membership-member/${membershipMemberId}/pause`,
		{	
			...newPause,
			familyMembership,
			duration: {
				amount: newPause.duration.amount,
				unit: newPause.duration.unit,
			},
		}
	);
	return response;
}

const updatePausedMembership = async (
	organizationId: number,
	membershipMemberId: number,
	isFamilyMembership: boolean,
	updatePauseData: IUpdatePauseMembershipMember
) => {
	const response = await network.put(
		`${environment.CS_URLS.API_ROOT_V4}/organization/${organizationId}/membership-member/${membershipMemberId}`,
		{
			...updatePauseData,
			familyMembership: isFamilyMembership,
			duration: {
				amount: updatePauseData.duration.amount,
				unit: updatePauseData.duration.unit,
			}
		}
	);
	return response;
}

const undoPauseMembership = async (organizationId: number, membershipMemberId: number, familyMembership: boolean) => {
	const response = await network.put(
		`${environment.CS_URLS.API_ROOT_V4}/organization/${organizationId}/membership-member/${membershipMemberId}/unpause`,
		{ familyMembership }
	);
	return response;
}

function saveMembershipToCMS(membershipId: number) {
	// const { headers } = auth.getAuthHeaders();

	network.post(
		`${membershipAPIUrl}/${membershipId}/save-cms`,
		{}
		//headers
	);
}

async function getEntitlementGroupsByOrganiztionId(organizationId: number, entitlementGroups?: string): Promise<any> {
	let entitlementParam = '';
	if (entitlementGroups && entitlementGroups !== '') {
		entitlementParam = `?entitlmentGroups=${entitlementGroups}`;
	}
	const response = await network.get(
		`${environment.CS_URLS.API_ROOT_V4}/organization/${organizationId}/entitlement-groups${entitlementParam}`
	);

	return response;
}

interface IGetMembershipsParams {
	customerId: number;
	organizationId: number;
	itemsPerPage?: number | string;
	page?: number;
	properties?: MembershipOrderByPropertyEnum[];
	sortOrder?: OrderByEnum[];
	filterBy?: MembershipFilterByPropertyEnum[];
	filterValues?: TMembershipFilterValue[];
	searchBy?: string;
}

const getMembershipsByCustomerId = async ({
	customerId,
	organizationId,
	itemsPerPage = '',
	page = 1,
	properties = [MembershipOrderByPropertyEnum.MEMBERSHIP_STATUS, MembershipOrderByPropertyEnum.STARTDATE],
	sortOrder = [OrderByEnum.DESC, OrderByEnum.DESC],
	filterBy = [],
	filterValues = [],
	searchBy = '',
}: IGetMembershipsParams): Promise<IPaginationData<IExpandedMembershipMemberData> | IErrorArr> => {
	const response = await network.get(
		`${membershipAPIUrl}/organization/${organizationId}/customer/${customerId}?page=${page}&itemsPerPage=${itemsPerPage}&orderByProperties=${properties}&order=${sortOrder}&filterByProperties=${filterBy}&filterValues=${filterValues}&search=${searchBy}`
	);
	return response;
};

export const membershipApi = {
	getMembershipById,
	getMembershipsByOrganizationId,
	getMembersInMembership,
	createMembership,
	pauseMembership,
	undoPauseMembership,
	getPauseById,
	getPauseHistoryByMemberId,
	updatePausedMembership,
	updateMembership,
	updateMembershipMedia,
	getMembershipsOrganizationIdQuestion,
	saveMembershipToCMS,
	getEntitlementGroupsByOrganiztionId,
	cancelMembership,
	getMembershipsByCustomerId,
	getPaginatedSimpleMembershipsByOrganizationId,
};
