import { IPauseDetails, IPauseFormDetails, IPauseMembershipMember, IUpdatePauseMembershipMember } from '../types';
import { MembershipPauseResponeDto, MembershipStatusEnum, PauseMembershipMemberDto } from '@bondsports/types';
import { TimeUnit } from '@bondsports/date-time';
import { membershipApi } from '@app/react/lib/api/membershipApi';
import { CustomerTypeEnum } from '@app/react/types/membership';
import { CURRENTLY_PAUSED_MEMBERSHIP_STATUSES } from '@app/react/components/customers/consts';
import { isErrorResponse } from '@bondsports/utils';

export const mapValuesToUpdatePauseDto = (values: IPauseDetails, membershipStatus?: MembershipStatusEnum): IUpdatePauseMembershipMember => {
	const pauseCurrentlyActive = CURRENTLY_PAUSED_MEMBERSHIP_STATUSES.includes(membershipStatus);
	return {
		...(!pauseCurrentlyActive && {startDate: new Date(values.startDate)}),
		...(values.pauseDurationValue && { 
				duration: {
					amount: Number(values.pauseDurationValue),
					// in the future, we will enable the user to select the time unit
					unit: TimeUnit.MONTH,
				} 
			}
		),
	};
};

export const generateInitialValues = (data: Record<string, any>): IPauseFormDetails => {

	return {
		id: data.id,
		reason: data.reason,
		pauseDescription: data.pauseDescription,
		startDate: new Date(data.startDate),
		duration: data.duration
	};
};

export const mapValuesToCreatePauseDto = (values: IPauseDetails): IPauseMembershipMember => {
	return {
		reasonId: values.reason,
		pauseDescription: values.description,
		startDate: new Date(values.startDate),
		duration: {
			amount: Number(values.pauseDurationValue),
			// in the future, we will enable the user to select the time unit
			unit: TimeUnit.MONTH,
		}
	};
};

export const generatePostUpdateValues = (data: MembershipPauseResponeDto) => {
	return {
		startDate: data.startDate ? new Date(data.startDate) : null,
		duration: data.duration ? data.duration.amount : null,
		reason: data.pauseReason?.id,
		description: data.pauseDescription
	};
};

export const getPauseById = async (
	organizationId: number,
	pauseId: number,
	handleSuccess: (data: PauseMembershipMemberDto) => void,
	handleError: (err) => void
) => {
	return membershipApi
		.getPauseById(organizationId, pauseId)
        .then(res => {  
            if (isErrorResponse(res)) {  
                handleError(res.err);  
            } else {  
                handleSuccess(res);  
            }  
        })  
		.catch(err => {
			handleError(err);
		});
};

export const isFamilyMembership = (membershipCustomerTypes: CustomerTypeEnum[]) => {
	return membershipCustomerTypes.includes(CustomerTypeEnum.FAMILY);
};
