import React, { useState } from 'react';
import { StepsWrapper } from 'app/react/components/shared/StepFlow';
import { PaymentRequest } from '../paymentRequest';
import { ConfirmationModal } from './ConfirmationModal';

//use from type??
export enum SharePaymentOptionsEnum {
	PRIVATE = 'private',
	PUBLIC = 'public',
}

export const PaymentRequestFlow = ({
	initialEmail,
	customTitle,
	handleSendRequestPayment,
	amountToPay,
	onClose,
	isLoading,
	isPublicInvoice,
	invoiceUrl,
}: {
	initialEmail: string;
	customTitle: string;
	handleSendRequestPayment: ({ email, memo, isPublic }: { email: string; memo?: string; isPublic?: boolean }) => void;
	amountToPay: number;
	onClose: () => void;
	isLoading: boolean;
	isPublicInvoice: boolean;
	invoiceUrl: string;
}) => {
	const [email, setEmail] = useState(initialEmail);
	const [memo, setMemo] = useState('');
	const [sharePaymentType, setSharePaymentType] = useState(
		isPublicInvoice ? SharePaymentOptionsEnum.PUBLIC : SharePaymentOptionsEnum.PRIVATE
	);

	const handleSendRequest = () => {
		handleSendRequestPayment({ email, memo });
	};

	const handleSendRequestAsPublic = () => {
		handleSendRequestPayment({ email, memo, isPublic: true });
	};
	const paymentRequestSteps = [
		<PaymentRequest
			amountToPay={amountToPay}
			handleSendRequestPayment={handleSendRequest}
			handleCancel={onClose}
			isLoading={isLoading}
			customTitle={customTitle}
			email={email}
			memo={memo}
			handleSetEmail={(val: string) => setEmail(val)}
			handleSetMemo={(val: string) => setMemo(val)}
			sharePaymentType={sharePaymentType}
			handleSetsharePaymentType={(val: SharePaymentOptionsEnum) => setSharePaymentType(val)}
			isPublicInvoice={isPublicInvoice}
			invoiceUrl={invoiceUrl}
		/>,
		<ConfirmationModal handleSendRequest={handleSendRequestAsPublic} />,
	];

	return <StepsWrapper steps={paymentRequestSteps} onClose={onClose} isModal padding={0} />;
};
