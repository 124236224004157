import { PauseMembershipSteps } from "../types/members.enums";

export const getDescriptionAndTitle = (
    currentStep: PauseMembershipSteps, 
    labels: {
        summaryDescription: (name: string) => string,
        historyDescription: string,
        title: string,
        historyTitle: string
    }, name: string
): {
    description: string,
    title: string
    } => {
    switch (currentStep) {
        case PauseMembershipSteps.PAUSE_FORM:
            return { description: name, title: labels.title };
        case PauseMembershipSteps.PAUSE_SUMMARY:
            return { description: labels.summaryDescription(name), title: labels.title };
        case PauseMembershipSteps.PAUSE_HISTORY:
            return { description: labels.historyDescription, title: labels.historyTitle };
        default:
    }
};
