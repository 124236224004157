import { useState } from 'react';
import { ArchiveSlotInvoiceActionEnum } from '../../../molecules/ActionSelect';
import { IPaymentMethod } from '../../../molecules/PaymentMethodPicker';

export const useRefundPopup = () => {
	const [step, setStep] = useState<number>();
	const [loading, setLoading] = useState(false);
	const [amountToRefund, setAmountToRefund] = useState<number | undefined>();
	const [selectedMethods, setSelectedMethods] = useState<IPaymentMethod[]>([]);
	const [selectedAction, setSelectAction] = useState<ArchiveSlotInvoiceActionEnum | undefined>();

	const labels = {
		0: {
			title: 'Issue refund',
			refundAmount: 'Total refund amount',
			refundAmountSubtitle:
				'Select your preferred payment method. Refund can be divided between several payment methods.',
			mainButton: 'Done',
			secondaryButton: 'Cancel',
		},
		1: {
			title: 'Refund complete',
			subtitle: 'The payment was refunded',
			subtitleMulti: 'The payments were refunded',
			subtitleFail: 'No payments were refunded, there was an issue',
			subtitleMultiMixed: 'Some payments were refunded, but there was an issue',
			lineItemCount: 'line item',
			lineItemsCount: 'line items',
			mainButton: 'SEND RECEIPT',
			secondaryButton: 'PRINT RECEIPT',
			tertiaryButton: 'Skip',
			summaryTitle: 'PAYMENTS',
			failedPayment: 'This payment can’t be refunded, Please try later.',
			membership: {
				resourceNotice: `Notice! Please don’t forget to remove the participant from the membership.`,
				resourceNoticeLink: `Link to membership`,
			},
			program: {
				resourceNotice: `Notice! Please don’t forget to remove the participant from the program.`,
				resourceNoticeLink: `Link to program participant`,
			},
		},
		noPaymentMethodsPermissionError: {
			title: 'No Payment Methods Permission',
			description: 'You do not have permission to process payments with the available payment methods. Please contact your administrator for access.',
			button: 'OKAY',
		}
	};

	const handleSelectAction = (method: ArchiveSlotInvoiceActionEnum) => {
		setSelectAction(method);
	};

	const init = (amount: number): void => {
		if (amount) setSelectedMethods([]);
		setAmountToRefund(amount);
		setSelectAction(undefined);
		setStep(0);
	};

	return {
		step,
		loading,
		labels,
		selectedMethods,
		selectedAction,
		amountToRefund,
		init,
		setLoading,
		setStep,
		setSelectedMethods,
		handleSelectAction,
	};
};
