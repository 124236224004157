/** @jsx jsx */
import React, { useContext, useState } from 'react';
import { ETypography, ETypographyColor, Icons, Input, Select, Textarea, Typography } from '@bondsports/utils';
import { css, jsx } from '@emotion/react';
import { Pricify } from '@bondsports/utils';
import { colors, colors_v2 } from 'app/react/styles/theme';
import { TranslationEn } from '@assets/i18n/en';
import { FlowLayout } from '../../shared/ModalBody/FlowLayout';
import { flexCol, flexCss } from '@app/react/styles/utils';
import { StepFlowContext } from '../../shared/StepFlow/context';
import { SharePaymentOptionsEnum } from './PaymentRequestFlow';
import { CopyLinkButton } from '@app/react/components/shared/Buttons/CopyLinkButton';

const sectionACss = css`
	padding: 24px 40px;
	${flexCol};
	border-bottom: 1px solid ${colors_v2.bg_border_seperator};
`;

const sectionBCss = css`
	${flexCol};
	padding: 36px 40px 20px 40px;
	gap: 24px;
	.S {
		margin: 0;
	}
`;

const totalRequestCss = css`
	${flexCol};
	gap: 4px;
	justify-content: center;
	align-items: center;
`;

const copyButtonContainerCss = css`
	display: flex;
	flex-direction: row;
	justify-content: start;
	align-items: center;
	// done to override gap property of parent container
	margin-top: -30px;
`;

interface IProps {
	amountToPay: number;
	handleSendRequestPayment: () => void;
	handleCancel: () => void;
	isLoading: boolean;
	customTitle?: string;
	email: string;
	memo: string;
	sharePaymentType: SharePaymentOptionsEnum;
	handleSetsharePaymentType: (val: SharePaymentOptionsEnum) => void;
	handleSetEmail: (val: string) => void;
	handleSetMemo: (val: string) => void;
	isPublicInvoice: boolean;
	invoiceUrl: string;
}

export const PaymentRequest = ({
	amountToPay,
	handleSendRequestPayment,
	handleCancel,
	isLoading,
	customTitle,
	email,
	memo,
	handleSetEmail,
	handleSetMemo,
	sharePaymentType,
	handleSetsharePaymentType,
	isPublicInvoice,
	invoiceUrl,
}: IProps) => {
	const { nextStep } = useContext(StepFlowContext);
	const labels = TranslationEn.customers.paymentsInvoices.paymentRequestModal;
	const title = customTitle ? customTitle : labels.title;
	const actionButtonText = customTitle ? customTitle : `${labels.sendRequest} ${Pricify(amountToPay)}`;

	const handleSendRequest = () => {
		if (sharePaymentType === SharePaymentOptionsEnum.PRIVATE || isPublicInvoice) {
			handleSendRequestPayment();
		} else {
			nextStep();
		}
	};

	return (
		<FlowLayout
			icon={Icons.wallet}
			iconColor={colors.brandPrimary}
			title={title}
			actionButtonText={actionButtonText}
			actionButtonInProgress={isLoading}
			subButtonText={labels.cancel}
			width="480px"
			handleSubButton={handleCancel}
			handleActionButton={handleSendRequest}
			centralizedFooter
			footerPaddingBottom="38px"
			footerButtonsGap={16}
		>
			<div data-aid="paymentRequest">
				<div css={sectionACss}>
					{!customTitle && (
						<div css={totalRequestCss}>
							<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
								{labels.totalRequest}
							</Typography>
							<Typography type={ETypography.body1Accented} color={ETypographyColor.primary}>
								{Pricify(amountToPay)}
							</Typography>
						</div>
					)}
					<Textarea
						data-aid="paymentRequest-input-memo"
						sizer="S"
						label={labels.memoLabel}
						value={memo}
						onChange={e => handleSetMemo(e.target.value)}
						hideError
					/>
				</div>
				<div css={sectionBCss}>
					<Input
						data-aid="paymentRequest-input-email"
						value={email}
						onChange={e => handleSetEmail(e.target.value)}
						sizer="S"
						label={labels.emailLabel}
						placeholder={labels.emailPlaceholder}
						hideError
					/>
					<Select
						data-aid="paymentRequest-select-handle"
						value={sharePaymentType}
						onSelecte={handleSetsharePaymentType}
						options={[
							{ label: labels.sharePaymentOptions.private, value: SharePaymentOptionsEnum.PRIVATE },
							{ label: labels.sharePaymentOptions.public, value: SharePaymentOptionsEnum.PUBLIC },
						]}
						sizer="S"
						label={labels.sharePaymentLabel}
						disabled={isPublicInvoice}
					/>
					<div css={copyButtonContainerCss}>
						<CopyLinkButton text={invoiceUrl} title={labels.copyInvoiceLink} successTitle={labels.copiedInvoiceLink} />
					</div>
				</div>
			</div>
		</FlowLayout>
	);
};
