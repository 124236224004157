/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { flexCol, gapCss, marginTop } from '../../../styles/utils';
import { TranslationEn } from '@assets/i18n/en';
import { DatesComparisonBlock } from './summaryDateBlock';
import { EmptyPage } from '../../settings/shared/EmptyPage';
import { IPauseHistory } from '@app/react/forms/membership/pause/createPause/types/interfaces';
import { isInFuture } from '@bondsports/date-time';
import { SuspenseWrapper } from '@bondsports/utils';

interface PauseSummaryProps {
	pauseHistory?: IPauseHistory[];
	isLoading?: boolean;
}

const datesContainer = css`
	gap: 8rem;
	justify-items: center;
	display: flex;
	self-align: center;
	margin: auto;    
`;

export const PauseHistoryStep = ({
	pauseHistory,
	isLoading
}: PauseSummaryProps) => {
	const labels = TranslationEn.memberships.members.pauseMemberships;
	const pauseHistoryStatusByKey = labels.pauseHistoryStatusByKey;
	const emptyLabels = TranslationEn.memberships.members.pauseMemberships.pauseMembershipModal.empty;
	return (
			<div css={[flexCol, gapCss(40), marginTop(32)]} data-testid="pause-history-step">
				<SuspenseWrapper loading={isLoading}>
				{!pauseHistory?.length && <EmptyPage title={emptyLabels.noHistory} description={emptyLabels.noHisotryDescription}/>}
				{pauseHistory?.map(pause => {
					const pauseStartLabel = isInFuture(pause.startDate) ? labels.summary.pauseScheduled : labels.summary.paused;
					const currentStatusLabel = pauseHistoryStatusByKey[pause.status];
					return (
					<div css={datesContainer} key={`${pause.startDate}_${pause.endDate}`} data-testid="summary-history-single-pause-container">
						<DatesComparisonBlock firstDate={pause.startDate} title={pauseStartLabel} />
						<DatesComparisonBlock firstDate={pause.endDate} title={currentStatusLabel} />
					</div>
				)})}
				</SuspenseWrapper>
			</div>
	);
};
