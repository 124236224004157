import { useCallback, useEffect, useState } from 'react';
import { IPauseDetails } from '../types';
import { generateInitialValues, getPauseById } from '../utils';
import { PauseMembershipMemberDto } from '@bondsports/types';

export const usePauseForm = (organizationId: number, editId?: number) => {
	const [error, setError] = useState('');
	const [isLoading, setIsloading] = useState(false);
	const [data, setData] = useState<IPauseDetails | null>(null);

	const fetchPauseById = useCallback(
		async (memberId: number) => {
			try {
				setIsloading(true);
                await getPauseById(organizationId, memberId, handleSuccess, handleError);
			} catch (error) {
				setError(String(error));
			}
		},
		[organizationId, editId]
	);

	const handleSuccess = (newData: PauseMembershipMemberDto) => {
		const formatedData = generateInitialValues(newData || {});
		if (formatedData) {
			updateData(formatedData || {});
		}
		setIsloading(false);
	};

	const handleError = (err) => {
		setError(err);
		setIsloading(false);
	};

	const updateData = useCallback(
		(newData) => {
			setData(prevData => ({
				...prevData,
				...newData,
			}));
		},
		[data, setData]
	);

	useEffect(() => {
		if (editId) {
			setIsloading(true);
			fetchPauseById(editId);
		}
	}, [editId, fetchPauseById]);

	return {
		isEdit: Boolean(editId),
		data,
		updateData,
		fetchPauseById,
		error: error,
		isLoading: isLoading,
	};
};
