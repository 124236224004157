/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { FormApi } from 'final-form';
import { sumBy } from 'lodash';
import { BnIcon, Button, FormInput, Icons, roundPriceCents, Select, FormDateInput } from '@bondsports/utils';
import { CustomPaymentPlanAllocationEnum } from '@app/react/types/enums';
import { formatDate } from '@app/react/lib/dates';
import { priceContainerCss } from '@app/react/forms/activities/pricing/style';
import { flexCol, marginLeftCss, marginRightCss, paddingTopCss } from '@app/react/styles/utils';
import { FieldArray } from 'react-final-form-arrays';
import { addButton } from '@app/react/forms/activities/createNewSession/style';
import {
	paymentPlanSelectInputsContainerCss,
	selectWidthCss,
} from '@app/react/forms/activities/pricing/paymentPlans/styles';
import { EDateTimeFormats } from '@app/react/types/times';
import { IDuePayment } from '@app/react/forms/activities/pricing/types/interfaces';
import { PaymentPlanStatusEnum, PaymentPlanTypeEnum } from '@bondsports/types';

const errorRedCss = css`
	color: #d44950;
`;

const sumCss = (isNoAmount: boolean) => css`
	${isNoAmount ? errorRedCss : 'color: #0d4774'};
	grid-column: 2;
	justify-self: end;
	font-weight: 500;
	font-size: 12px;
`;

const footerCss = css`
	padding-top: 1rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100%;
	align-items: end;
	button {
		cursor: pointer;
		justify-self: start;
		grid-row: 1;
	}
`;

const MAX_CUSTOM_PLAN_INSTALLMENTS = 12;
const HUNDRED_PERCENT = 100;

export const CustomPaymentPlan: React.FC<{
	values;
	form: FormApi;
	labels: any;
	amount: number;
	notBeforeDate: Date;
	paymentPlanType: PaymentPlanTypeEnum;
	paymentPlanStatus: PaymentPlanStatusEnum;
	hideContainerCss?: boolean;
}> = ({ values, form, labels, amount, notBeforeDate, paymentPlanType, paymentPlanStatus, hideContainerCss = false }) => {
	const [payments, setPayments] = useState(values?.schedule?.length || 1);

	const sumOutOfPrice: number = useMemo(() => {
		const sum: number = sumBy(values.schedule as IDuePayment[], due => Number(due.amount || 0));

		if (values.scheduleAmountType === CustomPaymentPlanAllocationEnum.PERCENT) {
			return Math.abs(HUNDRED_PERCENT - sum) < 0.001 ? HUNDRED_PERCENT : sum;
		}

		const percentage = (sum / amount) * HUNDRED_PERCENT;

		return percentage;
	}, [amount, values.schedule, values.scheduleAmountType]);

	const isSumNotEqualToPrice = useMemo(() => {
		if (sumOutOfPrice !== HUNDRED_PERCENT || Math.abs(HUNDRED_PERCENT - sumOutOfPrice) > 0.001) {
			return true;
		}

		return false;
	}, [sumOutOfPrice]);

	const amountLeft = useMemo(() => {
		return labels.paymentPlan.custom.amountLeft(sumOutOfPrice, amount);
	}, [sumOutOfPrice, amount]);

	const isDisabled =
		amount <= 0 || paymentPlanType !== PaymentPlanTypeEnum.CUSTOM || paymentPlanStatus === PaymentPlanStatusEnum.INACTIVE;

	const onAddInstallment = () => {
		if (values.schedule.length < MAX_CUSTOM_PLAN_INSTALLMENTS) {
			setPayments(prev => values.schedule.length + 1);
		}
	};

	const onRemoveInstallment = index => {
		setPayments(prev => values.schedule.length - 1);
	};

	const onSelect = (value: CustomPaymentPlanAllocationEnum | null) => {
		if (value === values.scheduleAmountType) {
			return;
		}

		form.batch(() => {
			const scheduleField = form.getFieldState('schedule');
			scheduleField.change(
				scheduleField.value.map((due, index) => ({
					...due,
					amount: !index
						? values.scheduleAmountType === CustomPaymentPlanAllocationEnum.PERCENT
							? amount
							: HUNDRED_PERCENT
						: null,
				}))
			);
			form.change('scheduleAmountType', value);
		});
		setPayments(1);
	};

	const containerCss = hideContainerCss ? '' : priceContainerCss;

	return (
		<Fragment>
			<div data-aid="CustomPaymentPlan" css={containerCss}>
				<div css={flexCol}>
					<FieldArray multiple name="schedule">
						{({ fields }) => {
							useEffect(() => {
								if (fields.length < payments) {
									fields.push({
										date: null,
										amount: null,
									});
								}
							}, [payments, fields?.length]);

							const formState = form.getState();

							return fields?.length > 0 ? (
								fields.map((field, index) => {
									return (
										<CustomPaymentPlanInput
											fields={fields}
											form={form}
											errors={formState.errors}
											index={index}
											defaultAmount={
												index
													? null
													: values.scheduleAmountType === CustomPaymentPlanAllocationEnum.PERCENT
													? HUNDRED_PERCENT
													: amount
											}
											defaultDate={!index ? notBeforeDate : null}
											key={`${field}_${index}`}
											onSelect={onSelect}
											disabled={isDisabled}
											labels={labels}
											notBefore={notBeforeDate}
											values={values}
											removeInstallment={onRemoveInstallment}
										/>
									);
								})
							) : (
								<Fragment />
							);
						}}
					</FieldArray>
					<div data-aid="CustomPaymentPlan-add" css={footerCss}>
						{payments < MAX_CUSTOM_PLAN_INSTALLMENTS && (
							<Button
								data-aid="button-CustomPaymentPlan-add"
								css={addButton}
								onClick={onAddInstallment}
								disabled={isDisabled}
								sizer="XS"
								theme="basic"
								type="button"
							>
								{labels.paymentPlan.custom.addInstallment}
							</Button>
						)}
						<span css={sumCss(isSumNotEqualToPrice)}>
							{amountLeft}
						</span>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const customTypeSelectWidthCss = css`
	flex-grow: 0.5;
`;

const clearButtonCss = css`
	padding-top: 28px;
	margin-left: 8px;
	align-self: center;
`;

const CustomPaymentPlanInput: React.FC<{
	errors: any;
	fields: any;
	index: number;
	form: FormApi;
	onSelect: (type: CustomPaymentPlanAllocationEnum) => void;
	disabled: boolean;
	notBefore?: Date;
	defaultAmount?: number;
	defaultDate?: Date;
	labels: any;
	values: any;
	removeInstallment: (index: number) => void;
}> = ({
	errors,
	fields,
	form,
	index,
	onSelect,
	disabled,
	labels,
	notBefore,
	values,
	defaultAmount,
	defaultDate,
	removeInstallment,
}) => {
	const fieldName = `schedule[${index}]`;

	useEffect(() => {
		if (index == 0 && values.schedule[0] && !values.schedule[0].date) {
			form.change(`${fieldName}.date`, notBefore);
		}
	}, [notBefore]);

	useEffect(() => {
		if (index == 0 && values.schedule[0] && !values.schedule[0].amount) {
			form.change(`${fieldName}.amount`, defaultAmount);
		}
	}, [defaultAmount]);

	return (
		<Fragment>
			<div data-aid="CustomPaymentPlanInp" css={paymentPlanSelectInputsContainerCss}>
				<div css={selectWidthCss}>
					<FormDateInput
						name={`${fieldName}.date`}
						sizer="S"
						placeholder={
							defaultDate
								? formatDate(defaultDate, EDateTimeFormats.MONTH_DAY_COMMA_YEAR)
								: labels.paymentPlan.custom.selectPaymentDatePlaceHolder
						}
						label={labels.paymentPlan.custom.selectPaymentDate}
						rightIcon={Icons.calendar}
						error={errors?.[`${fieldName}.date`]}
						disabled={disabled}
						notBefore={notBefore.toISOString()}
						openOnDate={notBefore}
						initialValue={values.schedule?.[index]?.date || defaultDate}
						required={values.paymentPlanType === PaymentPlanTypeEnum.CUSTOM}
						useyup
						grayedOut
						displayErrorBeforeVisited
					/>
				</div>
				<div css={[selectWidthCss, marginLeftCss(8)]}>
					<FormInput
						name={`${fieldName}.amount`}
						sizer="S"
						type="number"
						label={labels.paymentPlan.custom.paymentAllocation}
						placeholder={defaultAmount ? defaultAmount : labels.paymentPlan.custom.paymentAllocationPlaceHolder}
						useyup
						defaultValue={defaultAmount}
						step={0.01}
						leftIcon={
							values.scheduleAmountType === CustomPaymentPlanAllocationEnum.PERCENT ? Icons.percent : Icons.dollar
						}
						required={values.paymentPlanType === PaymentPlanTypeEnum.CUSTOM}
						error={errors?.[`${fieldName}.amount`]}
						disableTrailing
						disabled={disabled}
						decimalScale={2}
						displayErrorBeforeVisited
					/>
				</div>
				{!index ? (
					<div css={[customTypeSelectWidthCss, marginLeftCss(8)]}>
						<Select
							name="customPaymentAmountType"
							defaultValue={CustomPaymentPlanAllocationEnum.PERCENT}
							label={' '}
							value={values.scheduleAmountType}
							options={labels.paymentPlan.custom.selectOptions}
							onSelecte={val => onSelect(val as CustomPaymentPlanAllocationEnum)}
							disabled={disabled}
						/>
					</div>
				) : (
					<div css={[customTypeSelectWidthCss, marginRightCss(56), paddingTopCss(44)]}>
						<Button
							data-aid="button-CustomPaymentPlan-remove"
							theme={'basic'}
							sizer={'XS'}
							onClick={e => {
								e.preventDefault();
								fields.remove(index);
								removeInstallment(index);
							}}
							disabled={disabled}
						>
							<BnIcon icon={Icons.remove} />
						</Button>
					</div>
				)}
			</div>
		</Fragment>
	);
};
