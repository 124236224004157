import { CustomPaymentPlanAllocationEnum } from '@app/react/types/enums';
import { IPayment } from '@app/react/types/payment';
import { addToDate, getDateString, TimeUnit } from '@bondsports/date-time';
import { PaymentPlanStatusEnum, PaymentPlanTypeEnum } from '@bondsports/types';
import { Dispatch, useMemo, useReducer } from 'react';
import { ActionsEnum, CustomPaymentPlanActions, CustomPaymentPlanReturn, ICustomPaymentPlanState } from '../types/types';
import { splitAmountByPercents } from '@bondsports/general';

function reducer(state: ICustomPaymentPlanState, action: CustomPaymentPlanActions) {
	switch (action.type) {
		case ActionsEnum.SET_STATE: {
			return { ...state, ...action.payload };
		}
	}
};

export const useInvoiceCustomPaymentPlan = (previousState: ICustomPaymentPlanState, totalAmount: number): CustomPaymentPlanReturn => {

	const defaultState = () => {
		const tomorrow = addToDate(new Date().setHours(0, 0, 0, 0), 1, TimeUnit.DAY); 

		return {
			totalAmount: totalAmount,
				schedule: [
					{ date: tomorrow, amount: 100 }
				],
				notBeforeDate: tomorrow,
				scheduleAmountType: CustomPaymentPlanAllocationEnum.PERCENT,
				paymentPlanType: PaymentPlanTypeEnum.CUSTOM,
				paymentPlanStatus: PaymentPlanStatusEnum.ACTIVE,
		};
	};

	const [customState, dispatch]:  [ICustomPaymentPlanState, Dispatch<CustomPaymentPlanActions>]  = useReducer(
		reducer,
		previousState ? previousState : defaultState()
	);

	const OnChange = (newState: ICustomPaymentPlanState) => {
		dispatch({ type: ActionsEnum.SET_STATE, payload: { ...customState, ...newState } });
	};

	const getPaymentSchedule = () => {
		if (customState.scheduleAmountType === CustomPaymentPlanAllocationEnum.FIXED) {
			return getFixedPaymentSchedule();
		} else {
			return getPercentPaymentSchedule();
		}	
	};

	const getFixedPaymentSchedule = () => {
		return customState.schedule.map((duePayment) => (
			buildPayment(duePayment.amount, duePayment.date)
		));	
	}

	const getPercentPaymentSchedule = () => {
		const percents = customState.schedule.map((duePayment) => Number(duePayment.amount));
		let amountSplits = splitAmountByPercents(customState.totalAmount, percents);

		return customState.schedule.map((duePayment) => (
			buildPayment(amountSplits.shift(), duePayment.date)
		));	
	}

	const buildPayment = (amount: number, plannedDate: Date): IPayment => {
		return {
			price: amount,
			plannedDate: getDateString(plannedDate),
		} as IPayment
	};

	const isValid = useMemo(() => {
		return customState.valid;
	}, [customState.valid]);

	return {
		customPlanState: customState,
		isValidCustomPlan: isValid,
		OnCustomPlanChange: OnChange,
		getCustomPaymentSchedule: getPaymentSchedule,
	};
};