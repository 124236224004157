export interface IBreadcrumbStep {
	label: string;
	to: string;
}

//  Used for breadcrumb
export type currentPageType = 'void' | 'refund' | 'invoice' | 'customer';

export enum ERoutePaths {
	ACTION = 'action',
	ACTIVITIES = 'activities',
	ADD = 'add',
	CALENDAR = 'calendar',
	CLINICS = 'clinics',
	CONFLICTS = 'conflicts',
	CUSTOMER = 'customer',
	CUSTOMERS = 'customers',
	DASHBOARD = 'dashboard',
	DETAILS = 'details',
	EDIT_DETAILS = 'editDetails',
	FAMILY = 'family',
	FORMATTED_PATH = 'formattedPath',
	INVOICES = 'invoices',
	ORGANIZATION = 'organization',
	OVERVIEW = 'overview',
	PAYMENT = 'payment',
	PAYMENTS = 'payments',
	PROGRAM = 'program',
	REFUND = 'refund',
	RESERVATION = 'reservation',
	RESERVATIONS = 'reservations',
	SEND_REQUEST_PAYMENT = 'send-request-payment',
	SESSIONS = 'sessions',
	STATION = 'station',
	SPACE_ALLOCATION = 'space_allocations',
	TAB = 'tab',
	MEMBERSHIP = 'membership',
	MEMBERSHIPS = 'memberships',
	METHODS = 'methods',
}

export const BackofficeRoot = '/client/';
