/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useColors } from '../../../hooks/useColors';
import { IColors } from '../../../types';
import { Button } from '../../../components/Button';
import { flexRowCss } from '../style';
import { flexCenterCss, gapCss } from '../../../styles/utils';
import { useMemo } from 'react';
import { IPopupActions } from '../types';
import { SizeEnum } from '../../../lib/constants';

const footerCss = (colors: IColors) => css`
	border-top: 0.1rem solid ${colors.bg_border_seperator};
	display: flex;
	padding: 1.3rem 2rem;
	justify-content: space-between;
	position: sticky;
	bottom: 0;
	background-color: ${colors.bg_white};
	z-index: 10;
`;

const flexBoxFillerCss = css`
	flex-grow: 1;
`;

export interface IFooterLabels {
	deleteButton: string;
	editButton: string;
	duplicateButton: string;
	ariaLabel: {
		deleteButton: string;
		editButton: string;
		duplicateButton: string;
	};
}

export const Footer = ({ actions, labels }: { actions?: IPopupActions; labels: IFooterLabels }) => {
	const { colors } = useColors();
	const footerStyle = useMemo(() => footerCss(colors), [colors]);
	return (
		<div data-aid="popupBody-footer" css={footerStyle}>
			{actions?.delete?.allow ? (
				<Button
					data-aid="button-popupBody-delete"
					sizer={SizeEnum.SMALL}
					theme="basic"
					onClick={actions.delete?.handle}
					aria-label={labels.ariaLabel.deleteButton}
				>
					{labels.deleteButton}
				</Button>
			) : (
				// This div is used to keep the edit and duplicate buttons aligned to the right when there is no delete button
				<div css={flexBoxFillerCss}></div>
			)}
			<div css={[flexRowCss, flexCenterCss, gapCss(12)]}>
				{actions?.duplicate?.allow && (
					<Button
						data-aid="button-popupBody-duplicate"
						sizer={SizeEnum.SMALL}
						theme="secondary"
						onClick={actions.duplicate?.handle}
						aria-label={labels.ariaLabel.duplicateButton}
					>
						{labels.duplicateButton}
					</Button>
				)}
				{actions?.edit?.allow && (
					<Button
						data-aid="button-popupBody-edit"
						sizer={SizeEnum.SMALL}
						theme="primary"
						onClick={actions.edit?.handle}
						aria-label={labels.ariaLabel.editButton}
					>
						{labels.editButton}
					</Button>
				)}
			</div>
		</div>
	);
};
