/** @jsx jsx */
import { colors } from 'app/react/styles/theme';
import { css, jsx } from '@emotion/react';
import { formLine } from '@app/react/pages/customers/DetailsPage/styles';

export const priceContainerCss = css`
	background: ${colors.lightGray};
	border: 1px solid ${colors.borderPrimary};
	border-radius: 4px;
	padding: 0 20px 16px 20px;
	margin-bottom: 1rem;
	margin-top: 16px;
`;

export const labelCss = css`
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: ${colors.formInputBg};
`;

export const subContainer = css`
	padding: 16px;
	position: relative;
	h3 {
		font-family: Montserrat;
		font-style: normal;
		font-weight: 500;
		font-size: 1.4rem;
		line-height: 1.7rem;
		color: ${colors.brandPrimary};
		margin: 0;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		svg {
			margin-right: 10px;
		}
	}
	h4 {
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 1.2rem;
		line-height: 1.5rem;
		color: ${colors.formInputBg};
		margin: 0;
	}
	.description {
		font-family: Montserrat;
		font-style: normal;
		font-weight: normal;
		font-size: 1.2rem;
		line-height: 1.5rem;
		color: ${colors.formInputBg};
	}
	.footer {
		margin-top: 12px;
		display: flex;
		justify-content: space-between;
		span {
			font-family: Montserrat;
			font-style: normal;
			font-weight: 500;
			font-size: 1.4rem;
			line-height: 1.7rem;
			color: ${colors.brandPrimary};
		}
		label {
			${labelCss};
		}
	}
	.divider {
		border-bottom: 1px solid ${colors.borderSeperator};
	}
`;

export const productCardContainer = css`
	background: ${colors.lightGray};
	border: 1px solid ${colors.borderPrimary};
	box-sizing: border-box;
	border-radius: 5px;
	margin: 0 32px 0 16px;
	margin-bottom: 12px;
	width: 286px;
`;

export const productCardDivider = css`
	display: block;
	width: 100%;
	height: 1px;
	background: ${colors.borderSeperator};
`;

export const headerCss = css`
	${formLine};
	align-items: center;
	display: flex;
	justify-content: flex-start;
	h1 {
		font-family: Montserrat;
		font-style: normal;
		font-weight: 600;
		font-size: 1.6rem;
		line-height: 2rem;
		color: ${colors.brandPrimary};
		margin: 0;
		margin-top: 8px;
	}
	div {
		display: flex;
		align-items: center;
	}
	svg {
		color: ${colors.lightText};
		height: 16px;
		width: 16px;
		margin-left: 8px;
	}
`;

export const discountLine = (isThreeColumns?: boolean, isFirst?: boolean) => css`
	display: flex;
	margin-top: 8px;
	margin-bottom: ${isFirst ? '4px' : '0'};
	.left {
		width: ${isThreeColumns ? '30%' : '40%'};
		display: flex;
		align-items: center;
		.S {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;
		}
		label {
			display: flex;
		}
	}
	.center {
		width: ${isThreeColumns ? '30%' : '0'};
		display: flex;
		align-items: center;
		.body-2 {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;
		}
	}
	.right {
		width: ${isThreeColumns ? '40%' : '60%'};
		div {
			margin-top: 0;
		}
	}
`;
