import { PauseHistoryDto } from "@bondsports/types";
import { IPauseHistory } from "../forms/membership/pause/createPause/types/interfaces";

export const mapPauseHistoryFromBackend = (pauseHistory: PauseHistoryDto[]): IPauseHistory[] => {
    return pauseHistory?.map((pause) => {
        return {
            startDate: pause.startDate,
            endDate: pause.endDate,
            status: pause.status,
            memberId: pause.memberId
        };
    });
}