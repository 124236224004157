import * as yup from 'yup';
import { TranslationEn } from '../../../../../assets/i18n/en';
import { CustomPaymentPlanAllocationEnum } from '../../../types/enums';
import { testAmountBasedOnScheduleAmountType, testCustomPlanAllRequiredFields, testScheduleDates } from '@app/react/forms/activities/pricing/utils/schema.utils';
import { BASIC_NUMBER_SCHEMA } from '@app/react/forms/yupValidation';
const LABELS = TranslationEn.activities.forms;
export const FORM_SCHEMA = yup.object().shape({
	schedule: yup
		.array()
		.of(
			yup.object().shape({
				date: yup.date().nullable(),
				amount: BASIC_NUMBER_SCHEMA.required().min(0.5, LABELS.validation.paymentPlan.custom.mustBeMoreThan(0.5)),
			})
		)
		.test('date', 'unique', testScheduleDates())
		.test('isCustom', 'required', testCustomPlanAllRequiredFields()),
	scheduleAmountType: yup
		.string()
		.nullable()
		.oneOf([CustomPaymentPlanAllocationEnum.PERCENT, CustomPaymentPlanAllocationEnum.FIXED])
		.default(CustomPaymentPlanAllocationEnum.PERCENT)
		.test('test_amount_based_on_scheduleAmountType', 'amount issues', testAmountBasedOnScheduleAmountType()),
});