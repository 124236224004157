import { MembershipStatusEnum } from '../../molecules/StatusIndication/MembershipStatusIndication/types';
import { PaymentMethodTypeEnum } from '../../types/entities/payment';
import { formatTransactionFee, Pricify } from '../price';
import { ProductPaymentPlanTypeEnum } from '../constants';
import dayjs from 'dayjs';
import { DiscountModalTabEnum } from '../../organisms/discount-modal/types';
import { PaymentStatusEnum, EInvoiceVariant } from '../../organisms/invoiceTemplate/types';
import { ESports } from '../../types';

function daysUntilRetryMapper(daysUntilRetry: number): string {
	const TODAY = 0;
	const TOMORROW = 1;
	switch (daysUntilRetry) {
		case TODAY:
			return `The scheduled payment will be retried today.`;
		case TOMORROW:
			return `The scheduled payment will be retried tomorrow.`;
		default:
			return `The scheduled payment will be retried in ${daysUntilRetry} days.`;
	}
}

function dayOfMonthMapper(day: number): string {
	if (!day || day < 1 || day > 31) {
		return '';
	}

	if (day > 10 && day < 20) {
		return `${day}th`;
	}

	const reminder = day % 10;

	switch (reminder) {
		case 1:
			return `${day}st`;
		case 2:
			return `${day}nd`;
		case 3:
			return `${day}rd`;
		default:
			return `${day}th`;
	}
}

function startsInMonthsMapper(month?: number): string {
	if (!month) {
		return 'immediately';
	}

	if (month === 1) {
		return '1 month';
	}

	return `${month} months`;
}

export const priceTypeTranslations = {
	group_rental: 'Group',
	per_participant_rental: 'Participant',
	rental: 'Rental'
};

export const en = {
	PaymentMethodTypeEnum: {
		[PaymentMethodTypeEnum.BALANCE]: 'Credit Balance',
		[PaymentMethodTypeEnum.CASH]: 'Cash',
		[PaymentMethodTypeEnum.CARD]: 'Credit Card',
		[PaymentMethodTypeEnum.ACH]: 'ACH',
		[PaymentMethodTypeEnum.CHECK]: 'Check',
		[PaymentMethodTypeEnum.OTHER]: 'Other',
		migrated: 'Migrated',
		[PaymentMethodTypeEnum.CARD_ON_TERMINAL]: 'Card Terminal',
		swipe: 'Swipe',
		[PaymentMethodTypeEnum.GIFT_CARD]: 'Gift Card',
	},
	refundPupop: {
		paymentMethodPicker: { achIfo: 'If the bank initiates a dispute, that may result in two refund transactions.' },
	},
	ShowMoreButton: { showMore: 'Show more', showLess: 'Show less' },
	buttons: { back: 'Back' },
	invoice: {
		toolTips: {
			paidInAdvance: (val: string) => `Paid in advance on ${val}.`,
			paidOn: (val: string) => `Paid on ${val}`,
			paid: 'Paid',
			after: 'after',
			failedAttemps: (failedCount?: number) => (!!failedCount ? `${failedCount} failed attempts.` : ''),
			failedAttempsAfterPayment: (content?: string, paid?: string, after?: string, failedContent?: string) => (`${content || paid} ${after} ${failedContent}`),
			lastFailed: (lastFailedAt: string) => `Last failed attempt was on ${lastFailedAt}`,
			error: (ErrorMessage: string) => (!!ErrorMessage ? `due to: ${ErrorMessage}` : ''),
			nextRetry: (daysUntilRetry: number) => daysUntilRetryMapper(daysUntilRetry),
			combined: 'This installment is now combined with the next one, totaling',
			paymentMethodUpdated: 'NOTICE: The payment method has been updated.',
		},
		card: {
			defaultFeeNotice: 'Notice! Some payment methods may include a transaction fee.',
			feeNotice: (percentVal?: string, fixedVal?: string, methodType?: string) => {
				const feeText = formatTransactionFee(percentVal, fixedVal);
				const method = methodType || `payment method`;

				return `This ${method} will include a ${feeText} transaction fee.`;
			},
			nameOnCard: 'Name on Card',
			defaultCard: 'Default',
			expirationDate: 'Expiration',
			addCreditCardTitle: 'Credit Card',
			addCreditCardSubtitle: (val: string) => `Charging amount - ${val}`,
			addCreditCardFeeNotice: (percentVal: string, fixedVal: string, methodType: string) =>
				`Please notice! This ${methodType || `payment method`} will include a transaction fee of ${
					Number(percentVal) > 0 ? `${Number(percentVal) * 100}%` : ''
				}${Number(percentVal) > 0 && Number(fixedVal) > 0 ? ` + ` : ''}${
					Number(fixedVal) > 0 ? `${Pricify(Number(fixedVal))}` : ``
				}`,
			holderName: 'Card Holder Name',
			number: 'Card Number',
			securityCode: 'Security Code',
			addPaymentMethod: 'Add Payment Method',
			billingAddress: 'Billing Address',
			savecreditcardAsDefault: 'Save credit card as default',
			remove: {
				modals: {
					confirmationModal: {
						title: 'Are you sure?',
						description: 'Payment method will be removed from the payment method list.',
						mainButton: 'Approve',
						subButton: 'Dismiss',
					},
					successModal: {
						title: 'Payment removed successfully',
						description: 'Payment method was removed from the payment method list successfully.',
						button: 'OK, Thanks',
					},
					warningModal: {
						title: 'Cannot remove due to payment plan',
						description: 'A payment method that is connected to a payment plan cannot be removed.',
						contactInfo: 'To replace payment method please contact support at support@bondsports.co',
						button: 'OK, Thanks',
					},
				},
			},
			methodTypes: {
				card: 'credit card',
				us_bank_account: 'ACH bank transfer',
			},
		},
		header: {
			title: 'Invoice no.',
			[EInvoiceVariant.PAYMENT_PRINT]: 'Receipt no.',
			[EInvoiceVariant.PRINT]: 'Invoice no.',
			[EInvoiceVariant.PAYMENT]: 'Receipt no.',
		},
		billingInfo: {
			title: 'Billing Info',
			purchaseDate: 'Purchase date',
			customer: 'Customer',
			transactionFee: (totalFee?: number) =>
				totalFee
					? `(includes a ${Pricify(totalFee, undefined, undefined, true)} transaction fee)`
					: '(Transaction fee may apply)',
			total: (isPayment?: boolean) => (isPayment ? 'Amount paid' : 'Total'),
		},
		details: {
			voidToggle: 'Voided Items',
			title: (isPayment?: boolean) => (isPayment ? 'Invoice details' : 'Details'),
			summary: {
				subtotal: 'Subtotal',
				taxRate: 'Tax Rate',
				membershipDiscount: 'Membership Discount',
				total: (isPayment?: boolean) => (isPayment ? 'Invoice total' : 'Total'),
				totalDue: 'Total Due',
				tax: 'Tax',
				taxInc: 'Tax Inc.',
				fees: 'Fees',
				discounts: 'Discounts',
				amountPaid: 'Amount paid',
			},
			table: {
				columns: {
					item: 'Purchased items',
					quantity: 'Qty',
					fees: 'Fees',
					price: 'Unit price',
					tax: 'Tax',
					total: 'Total',
				},
			},
		},
		landingPage: {
			title: 'Invoice Page',
		},
		checkoutCard: {
			title: 'Invoice from',
			invoiceNum: 'Invoice no.',
			memo: 'Memo',
			download: 'Download',
			pay: 'go to payment',
			paidOn: 'Paid on',
		},
		paymentForm: {
			title: 'Complete invoice payment',
		},
		paymentMethods: {
			title: 'Payment Method',
			description: 'Select your payment method. You can split the payment into several different payment methods.',
			newCreditCard: '+ Credit/Debit card',
			newACH: '+ ACH',
			saved: 'Saved',
			pay: 'Pay',
			'gift-card': 'Gift Card',
			check: 'Check',
			balance: 'Balance',
		},
		paymentsSection: {
			title: 'Payments',
			table: {
				columns: {
					customerName: 'Customer Name',
					id: 'Receipt ID',
					amount: 'Amount',
					total: 'Total',
					method: 'Method',
					date: 'Date',
					fees: 'Fees',
					status: 'Status',
				},
			},
			summary: {
				amountDue: 'Balance Due',
				fees: 'Fees',
				amountPaid: 'Amount Paid',
			},
		},
		scheduledSection: {
			title: 'PAYMENTS SCHEDULE',
			subTitle: 'Select any scheduled payments you would like to pay now. First select any failed payments.',
			subTitleFirstLine: 'Select any scheduled payments you would like to pay now.',
			subTitleSecondLine: 'First select any failed payments.',
			selectAll: 'Select all scheduled payments',
			subject: 'scheduled payments',
			table: {
				columns: {
					date: 'Date',
					amount: 'Amount',
					status: 'Status',
					fees: 'Fees',
					method: 'Method',
					total: 'Total Price',
				},
			},
			next: 'Next',
			back: 'Back',
			cancel: 'cancel',
			leftToPay: 'left to pay:',
			warnings: {
				failedDeselect: 'Failed payment was deselected!',
				selectAllFailed: 'Select all failed payments,',
			},
		},
		notes: {
			title: 'Notes',
		},
		payments: {
			paid: 'Paid',
			partial: 'Partially paid',
			not_paid: 'Unpaid',
			pending: 'Pending',
			refund: 'Refunded',
			void: 'Voided',
			scheduled: 'Scheduled Payments',
		},
		appendix: {
			title: 'Schedule',
			scheduleTab: {
				columns: {
					description: 'Description',
					addons: 'Add Ons',
					dayTime: 'Day & Time',
					resource: 'Resource',
					event: 'Event Name',
				},
				info: {
					title: 'Schedule #',
					schedule: 'Schedule',
					participant: 'Participant',
					program: 'Program',
					products: 'Products',
					sessions: 'Sessions',
					more: 'More...',
				},
				attendeeInfo: {
					title: 'Schedule #',
					schedule: 'Schedule',
					participant: 'Participant',
					program: 'Program',
					products: 'Products',
					sessions: 'Sessions',
					more: 'More...',
				},
			},
		},
	},
	public: 'Public',
	paymentStatusTooltipMapper: {
		[PaymentStatusEnum.FULLY_PAID]: 'Paid',
		[PaymentStatusEnum.NOT_PAID]: 'Not Paid',
		[PaymentStatusEnum.PARTIAL_PAYMENT]: 'Partially Paid',
		[PaymentStatusEnum.REFUNDED]: 'Refunded',
		[PaymentStatusEnum.VOID]: 'Void',
	},
	membershipStatusMapper: {
		[MembershipStatusEnum.ACTIVE]: 'Active',
		[MembershipStatusEnum.PAUSE_PENDING]: 'Pause Scheduled',
		[MembershipStatusEnum.PAUSED_CANCELLED]: 'Paused',
		[MembershipStatusEnum.ACTIVE_CANCELLED]: 'Active',
		[MembershipStatusEnum.CANCELLED]: 'Cancelled',
		[MembershipStatusEnum.EXPIRED]: 'Expired',
		[MembershipStatusEnum.PAUSED]: 'Paused',
		[MembershipStatusEnum.PENDING]: 'Pending',
	},
	eventSlot: { join: 'Join', register: 'Register' },
	selcted: 'Selected',
	noResults: 'No Results',
	productCard: {
		buttons: {
			seeAll: 'See all',
			seeLess: 'See less',
			purchase: 'Purchase',
		},
		prices: {
			prorated: 'Prorated',
		},
		tax: {
			tax: 'Tax',
			included: 'inc',
		},
		addons: {
			title: 'Addons',
		},
		discounts: {
			title: 'Discounts',
		},
		registration: {
			title: 'Registration',
		},
		deposit: {
			title: 'Deposit',
			noDeposit: 'No deposit',
		},
		paymentPlan: {
			title: (isCanceled: boolean, type?: ProductPaymentPlanTypeEnum): string => {
				if (isCanceled) {
					return 'No payment plan';
				}

				switch (type) {
					case ProductPaymentPlanTypeEnum.CUSTOM:
						return 'Custom payment plan';
					case ProductPaymentPlanTypeEnum.ROLLING:
						return 'Rolling payment plan';
					case ProductPaymentPlanTypeEnum.PAYMENT_DATE:
						return 'Payment date plan';
					default:
						return 'No payment plan';
				}
			},
			rolling: {
				maxInstallments: (installments: number) =>
					installments ? `${installments} installments` : 'no set no. of installments',
				dayOfMonth: (dayOfMonth: number) =>
					dayOfMonth ? `every ${dayOfMonthMapper(dayOfMonth)} of the month` : 'no day set',
				startsImmediately: `starts immediately`,
				startsInMonths: (startsInMonths: number) => `starts after ${startsInMonthsMapper(startsInMonths)}`,
			},
			custom: {
				noSetDate: 'no set date',
			},
			paymentDate: {
				dueOn: (schedule?: any[]) =>
					`payment due on ${schedule?.length && dayjs(schedule[0].paymentDate).format('MMM DD, YYYY')}`,
			},
			inactive: 'Inactive',
		},
	},
	dayOfMonthMapper: dayOfMonthMapper,
	startsInMonthsMapper: startsInMonthsMapper,
	more: 'more',
	glCodes: {
		accountingCodes: 'Accounting Codes',
		noAccountingCodes:
			'There are no accounting codes in this program.\nYou can edit the basic info to add accounting codes.',
		other: 'other',
	},
	addDiscount: {
		lineItemMenu: 'Add discount to line item',
		amountInput: {
			inputLabel: 'Discount amount',
			currencySymbol: '$',
			subtotalBalanceAfterDiscount: (val?: number) =>
				`Subtotal balance after discount: ${Pricify(val, undefined, undefined, true)}`,
			invoiceSubtotalBalance: (val?: number) => `Line item subtotal: ${Pricify(val, undefined, undefined, true)}`,
			discountTotals: (amount: number, percent: number) =>
				`${percent}% (${Pricify(amount, undefined, undefined, true)})`,
		},
		header: {
			title: 'Add Discount',
			invoiceAmount: (val: string) => `Invoice subtotal: ${val}`,
			lineItemAmount: (val: string) => `Line item amount: ${val}`,
			invoiceSubtotalBalance: (val: string) => `Invoice subtotal balance: ${val}`,
			tabs: {
				[DiscountModalTabEnum.PROMO_CODE]: 'Promo Code',
				[DiscountModalTabEnum.CUSTOM]: 'Custom',
			},
		},
		content: {
			custom: {
				discountError: (val: string, percent: string) => `Max discount ${val} or ${percent}%`,
				reasonSelect: {
					label: 'Select reason',
					placeholder: 'Search',
				},
				notes: {
					private: {
						openNote: 'Private note',
						closedNote: 'Add Public note',
						tooltip: 'The public note will appear on the invoice in the notes section',
						cancel: 'Cancel',
					},
					public: {
						openNote: 'Public note',
						closedNote: 'Add private note',
						tooltip: "The private note will appear on the customer's page in the private notes section",
						cancel: 'Cancel',
					},
					both: {
						private: 'Private note',
						public: 'Public note',
						privateTooltip: "The private note will appear on the customer's page in the private notes section",
						publicTooltip: 'The public note will appear on the invoice in the notes section',
						cancel: 'Cancel',
					},
					privateTooltip: "The private note will appear on the customer's page in the private notes section",
					publicTooltip: 'The public note will appear on the invoice in the notes section',
					initialPublic: 'Add public note',
					initialPrivateNote: 'Add private note',
				},
			},
			promoCode: {
				alreadyApplied: 'A promo code has already been added.\nA custom discount can still be applied.',
				error: 'Error in loading promo codes. Please try again later',
				noValidCodes: 'There are currently no valid promo codes.',
				defaultStateMessage: 'Oops, Something went wrong.',
				select: { placeholder: 'search', label: 'Select promo code' },
			},
		},
		footer: {
			cancel: 'CANCEL',
			confirm: 'APPLY',
		},
	},
	fieldValidationErrors: {
		required: 'required',
		minLength: 'minimum length is',
	},
	applicationTemplate: {
		formTitle: 'FORM',
		customerAgreementColumn: 'Customer Agreement: ',
		customerDetails: {
			title: 'CUSTOMER DETAILS',
			at: 'at',
			details: {
				dateSigned: 'Date signed',
				participantName: 'Participant name',
				primary: 'Primary / Guardian (if applicable)',
				emailAddress: 'E-mail address',
				phoneNumber: 'Phone number',
				address: 'Address',
			},
		},
	},
	components: { select: { selectAll: 'Select All', error: 'Error loading' } },
	usePermissions: { noPermissions: 'No permissions found' },
	slotCard: {
		close: {
			ariaLabel: {
				close: `Close Slot Card Popup`
			}
		},
		header: {
			reservation: `Reservation:`,
			conflicts: `Conflicts`,
			ariaLabel: {
				conflicts: "View conflicts button", 
				reservationId: "Reservation link"
			},
			tags: {
				customer: 'Customer',
				maintenance: 'Maintenance',
				internal: 'Internal',
				paid: 'Paid',
				unpaid: 'Unpaid',
				partiallyPaid: 'Partially Paid',
				hold: 'Hold',
				approved: 'Approved',
				awaitingAdmin: `Awaiting Admin`,
				awaitingCustomer: `Awaiting Customer`,
				planned: `Planned`,
				private: `Private`
			},
			occurenceCount: (index, totalCount) => `${index}/${totalCount}`,
		},
		body: {
			duration: (duration: string) => `(${duration})`,
			viewMoreMaintenace: (
				title: string, 
				maintenanceTiming: string, 
				startDate: string, 
				startTime: string, 
				durationValue: string, 
				durationUnit: string
			) => `${title} - ${maintenanceTiming} - ${startDate} - ${startTime} - (${durationValue} ${durationUnit})`,
			viewMoreButton: (count: string|number) => `view ${count} more`,
			maintenanceTiming: {
				AFTER: 'After',
				BEFORE: 'Before',
				AT_THE_BEGINING: 'At the Beginning',
				AT_THE_END: 'At the End'
			},
			durationUnitTypes: {
				weeks: 'w',
				minutes: 'm',
				hours: 'hr',
				days: 'd'
			},
		},
		footer: {
			deleteButton: `DELETE`,
			editButton: `Edit`,
			duplicateButton: `Duplicate`,
			ariaLabel : {
				deleteButton: `Delete button`,
				editButton: `Edit button`,
				duplicateButton: `Edit button`
			}
		}
	},
	ESports: {
		[ESports.SOFTBALL]: "Softball",
		[ESports.BASKETBALL]: "Basketball",
		[ESports.FOOTBALL]: "Football",
		[ESports.SOCCER]: "Soccer",
		[ESports.BOWLING]: "Bowling",
		[ESports.BOCCEBALL]: "Bocceball",
		[ESports.CORNHOLE]: "Cornhole",
		[ESports.DODGEBALL]: "Dodgeball",
		[ESports.FRISBEE]: "Frisbee",
		[ESports.HOCKEY]: "Hockey",
		[ESports.KICKBALL]: "Kickball",
		[ESports.LACROSSE]: "Lacrosse",
		[ESports.PINGPONG]: "Pingpong",
		[ESports.RUGBY]: "Rugby",
		[ESports.SKEEBALL]: "Skeeball",
		[ESports.TENNIS]: "Tennis",
		[ESports.VOLLEYBALL]: "Volleyball",
		[ESports.WIFFLEBALL]: "Wiffleball",
		[ESports.BADMINTON]: "Badminton",
		[ESports.FITNESS]: "Fitness",
		[ESports.GOLF]: "Golf",
		[ESports.PILATES]: "Pilates",
		[ESports.RUNNING]: "Running",
		[ESports.SKIING]: "Skiing",
		[ESports.SNOWBOARDING]: "Snowboarding",
		[ESports.YOGA]: "Yoga",
		[ESports.BROOMBALL]: "Broomball",
		[ESports.CRICKET]: "Cricket",
		[ESports.CROSSFIT]: "Crossfit",
		[ESports.CYCLING]: "Cycling",
		[ESports.FIELD_HOCKEY]: "Field Hockey",
		[ESports.RACQUETBALL]: "Racquetball",
		[ESports.SPINNING]: "Spinning",
		[ESports.SQUASH]: "Squash",
		[ESports.SURFING]: "Surfing",
		[ESports.SWIMMING]: "Swimming",
		[ESports.WIND_SURFING]: "Wind Surfing",
		[ESports.ADVENTURE]: "Adventure",
		[ESports.BOXING]: "Boxing",
		[ESports.BASEBALL]: "Baseball",
		[ESports.DANCE]: "Dance",
		[ESports.KICKBOXING]: "Kickboxing",
		[ESports.MARTIAL_ARTS]: "Martial Arts",
		[ESports.OUTDOORS]: "Outdoors",
		[ESports.ROWING]: "Rowing",
		[ESports.SAILING]: "Sailing",
		[ESports.SUP]: "Sup",
		[ESports.TRIATHLON]: "Triathlon",
		[ESports.HANDBALL]: "Handball",
		[ESports.CATCHBALL]: "Catchball",
		[ESports.BLITZBALL]: "Blitzball",
		[ESports.ROLLER_DERBY]: "Roller Derby",
		[ESports.ICE_SKATING]: "Ice Skating",
		[ESports.PICKLEBALL]: "Pickleball",
		[ESports.AXE_THROWING]: "Axe Throwing",
		[ESports.FUTSAL]: "Futsal",
		[ESports.OTHER]: "Other",
	},
	idHyperlink: {
		id: (id: string|number) => `#${id}`
	},
	durationUnitTypes: {
		weeks: 'w',
		minutes: 'm',
		hours: 'hr',
		days: 'd'
	},
	clampOrTruncateText: {
		ariaLabel: {
			viewMore: "View more text"
		}
	}
};
