import { getFullName } from '@app/react/lib/utils';
import { IPayingUser, TChargeSteps } from '../../../../../app/react/types/payment';
import { TranslationEn } from '../../../../../assets/i18n/en';
import { PaymentMethodTypeEnum } from '@bondsports/types';

import { takeRight } from 'lodash';

export const getPaymentMethodLabelByValue = (method: PaymentMethodTypeEnum | TChargeSteps) =>
	TranslationEn.payments.methods[method];

export const getPaymentMethodText = (payment: {
	paymentType: PaymentMethodTypeEnum;
	paymentMethodId: string;
	ccLast4?: string;
	ccBrand?: string;
	isPublicInvoice?: boolean;
}) => {
	if (!payment) {
		return '';
	}
	switch (payment.paymentType) {
		case PaymentMethodTypeEnum.CARD:
		case PaymentMethodTypeEnum.CARD_ON_TERMINAL:
			return payment.ccLast4;
		case PaymentMethodTypeEnum.CHECK:
			return `${TranslationEn.payments.methods.check} #${takeRight(payment.paymentMethodId, 4).join('')}`;
		case PaymentMethodTypeEnum.GIFT_CARD:
			return `${TranslationEn.payments.methods['gift-card']} #${takeRight(payment.paymentMethodId, 5).join('')}`;
		case PaymentMethodTypeEnum.ACH:
			return `${TranslationEn.payments.methods[PaymentMethodTypeEnum.ACH]} #${takeRight(payment.ccLast4, 4).join('')}`;
		default:
			return getPaymentMethodLabelByValue(payment.paymentType) || '';
	}
};

export const getPaymentMethodPayingUserName = (isPublic: boolean, payingUser: IPayingUser) => {
	return isPublic ? getFullName(payingUser) : '';
};

const sortedMethodTypesKeys: PaymentMethodTypeEnum[] = [
	PaymentMethodTypeEnum.CARD,
	PaymentMethodTypeEnum.ACH,
	PaymentMethodTypeEnum.BALANCE,
	PaymentMethodTypeEnum.CASH,
	PaymentMethodTypeEnum.CHECK,
	PaymentMethodTypeEnum.GIFT_CARD,
	PaymentMethodTypeEnum.OTHER,
]

const paymentMethodToKeyIndex: Record<string, number> = {};
sortedMethodTypesKeys.forEach((key, idx) => {
	paymentMethodToKeyIndex[key] = idx;
});

export const sortPaymentMethods = (paymentMethods) => {
	return paymentMethods.sort((a, b) => (paymentMethodToKeyIndex[a.paymentMethodType] ?? Infinity) - (paymentMethodToKeyIndex[b.paymentMethodType] ?? Infinity));
};

export const buildPaymentMethodLabel = (hasRollingMembership = false, hasFuturePayments = false): string => {
	if (hasRollingMembership && hasFuturePayments) {
		return TranslationEn.payments.applyPaymentMethod.combinedCase;
	}
	else if (hasFuturePayments) {
		return TranslationEn.payments.applyPaymentMethod.installments;
	}
	else if (hasRollingMembership) {
		return TranslationEn.payments.applyPaymentMethod.membershipRolling;
	}
	return '';
}
