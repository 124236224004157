/** @jsx jsx */
import { TranslationEn } from '@assets/i18n/en';
import {
	InvoiceDiscountOnEnum,
	DiscountTypeEnum,
	EntitlementGroup,
	ItemDiscountDto,
	PaymentStatusEnum,
	UserPaymentMethodOption,
	ReservationStatusEnum,
} from '@bondsports/types';
import {
	Button,
	DiscountModal,
	EInvoiceAction,
	EInvoiceVariant,
	EInvoicingTabs,
	ETypography,
	ETypographyColor,
	IGetPromoCodesFilters,
	InvoiceTemplate,
	IReason,
	isErrorResponse,
	TDiscountModalState,
	Typography,
	useModal,
} from '@bondsports/utils';
import { css, jsx } from '@emotion/react';
import { RCOrganization } from '@rcenter/core';
import { useCustomers } from 'app/react/components/customers/hooks/useCustomers';
import { useNotification } from 'app/react/hooks/useNotification';
import {
	IPurchasedResource,
	ProductTypesEnum,
	VoidActionType,
	PaymentStatusEnum as PaymentStatusEnumOrders,
} from 'app/react/types/orders';
import { cloneDeep } from 'lodash';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useErrorModal } from '../../../hooks/useErrorModal';
import { useNotes } from '../../../hooks/useNotes';
import { useOrganization } from '../../../hooks/useOrganization';
import { useOrganizationTimezone } from '../../../hooks/useOrganizationTimezone';
import { useStation } from '../../../hooks/useStation';
import { customersApi } from '../../../lib/api/customersApi';
import { discountsApi } from '../../../lib/api/discountsApi';
import { membershipApi } from '../../../lib/api/membershipApi';
import { organizationApi } from '../../../lib/api/organizationApi';
import { paymentApi } from '../../../lib/api/paymentApi';
import { Capitalize } from '../../../lib/form';
import { roundPriceCents } from '../../../lib/pricing';
import { useTablePagination } from '@app/react/hooks/useTablePagination';
import { flexCol, TableContainer } from '../../../styles/utils';
import { ICustomer } from '../../../types/customers';
import { IEvent } from '../../../types/events';
import { EDocumentType } from '../../../types/notes';
import { EChargeInnerStep } from '../../../types/payment';
import { MiddleWhiteContainer, SectionContainer } from '../../shared/Containers';
import { PublicNotes } from '../../shared/Notes/PublicNotes';
import { ChargeFlowWrapper } from './ChargeFlowWrapper';
import { DeleteDiscount } from './components/delete-discount-modal';
import { Footer } from './components/Footer';
import { Header } from './Header';
import { useInvoiceDiscounts } from './hooks/useInvoiceDiscounts';
import { getLineItemById, hasPromoCodeByType, processInvoiceResponse } from './lib';
import { useReceipt } from '../../../hooks/useReceipt';
import { fetchPromoCodes } from './hooks/promoCodes.lib';
import {
	EDiscountApplicant,
	EScheduledChargeStatus,
	IInvoice,
	IInvoiceTemplateState,
	scheduledChargeStatusWithTreansactionFee,
} from './types';
import usePermissions from '@app/react/hooks/permissions/usePermissions';
import permissions from '@bondsports/permissions';
import { sortPaymentMethods } from '../../payments/utils/paymentMethods';

const containerCss = css`
	${flexCol};
	height: 100%;
	overflow-y: auto;
	flex-grow: 1;
`;

export const detailsTableContainer = css`
	${TableContainer};

	thead {
		tr {
			td {
				max-height: 28px;
				padding-top: 6px !important;
				padding-bottom: 6px !important;
			}
		}
	}

	.totalRefund {
		width: 100px;
	}

	.quantity {
		width: 120px;
	}
`;

interface Props {
	organization: RCOrganization;
	invoiceId?: number;
	customerId?: number;
	seperateHeader?: boolean;
	toggleBack?: () => void;
}

const PAYMENT_STATUS_DISCOUNT_ALLOWED = [PaymentStatusEnum.PARTIAL_PAYMENT, PaymentStatusEnum.NOT_PAID];

const cloneAndProcessInvoiceState = (invoiceState: IInvoice): IInvoice => {
	const updatedInvoiceState = cloneDeep(invoiceState);

	const serializeSessionEventsTimes = (resource: IPurchasedResource) => {
		const { sessionEvents } = resource.programSeason || {};
		if (sessionEvents?.length) {
			resource.programSeason.sessionEvents = sessionEvents.map((event: IEvent) => ({
				...event,
				startDate: event.startDateString || event.startDate,
				endDate: event.endDateString || event.endDate,
			}));
		}
	};

	updatedInvoiceState?.lineItems?.forEach(lineItem => {
		lineItem.purchasedResources?.forEach(resource => {
			serializeSessionEventsTimes(resource);
		});
	});
	return updatedInvoiceState;
};

interface IAuthorizations {
	usePromoDiscount: boolean;
	useCustomDiscount: boolean;
}

export const SingleInvoice = ({
	organization,
	invoiceId: initialInvoiceId,
	customerId: initialCustomerId,
	seperateHeader = false,
	toggleBack,
}: Props) => {
	const location = useLocation();
	const history = useHistory();
	const {
		customerId,
		invoiceId,
		familyId,
		tab = 'details' as EInvoicingTabs,
	} = useParams<{
		customerId: string;
		invoiceId: string;
		familyId?: string;
		tab?: 'details' | 'schedule';
	}>();
	const [invoiceState, setInvoiceState] = useState<IInvoice>();
	const [invoiceSubtotalBalance, setInvoiceSubtotalBalance] = useState<number>();
	const [entitlementGroups, setEntitlementGroups] = useState<EntitlementGroup[]>();

	const [customerState, setCustomerState] = useState<ICustomer>();
	const [isLoading, setIsLoading] = useState(true);
	const [scheduledPayments, setScheduledPayments] = useState<any[]>();
	const [discountReasons, setDiscountReasons] = useState<IReason[]>([]);

	const [manualDiscountId, setManualDiscountId] = useState<number>();
	const [discountType, setDiscountType] = useState<EDiscountApplicant>(EDiscountApplicant.INVOICE);
	const [discountLineItemsIds, setDiscountLineItemsIds] = useState<number[]>([]);
	const [discountGroupId, setDiscountGroupId] = useState<string>();
	const [isApiInProgress, setIsApiInProgress] = useState(false);

	const [isApplyDiscountProcessing, setIsApplyDiscountProcessing] = useState<boolean>(false);

	const [paymentMethodsData, setPaymentMethodsData] = useState<UserPaymentMethodOption[]>([]);
	const [invoicePreviewActiveTab, setInvoicePreviewActiveTab] = useState<EInvoicingTabs>(
		(tab as EInvoicingTabs) ?? EInvoicingTabs.DETAILS
	);
	const { initiateFirstPage, removeFromQuery } = useTablePagination();
	const { addDiscount, removeDiscount } = useInvoiceDiscounts();
	const { setInlineNotification } = useNotification();
	const { setErrorModal, checkForErrorResponse } = useErrorModal();
	const { toggle: toggleDeleteDiscount, isShowing: isDeleteDiscountShowing } = useModal();

	const { toggle: toggleDiscount, isShowing: isShowingDiscount } = useModal();
	const { toggle: toggleScheduledCharge, isShowing: isShowingScheduleCharge } = useModal();
	const { timezone } = useOrganizationTimezone();
	const { buildRedirectUrls } = useCustomers();
	const { voidUrl, paymentUrl } = buildRedirectUrls(location.pathname);
	const { setOrganizationId } = useOrganization();
	const { shiftId, setShouldConnectToTerminal } = useStation();
	const { downloadReceipt } = useReceipt();

	const invoiceLabels = TranslationEn.customers.paymentsInvoices;
	const notesLabels = invoiceLabels.invoiceNotes;
	const discountLabels = invoiceLabels.discounts;
	const showChargeFooter = !seperateHeader && invoiceState && invoiceState?.paidAmount !== invoiceState?.price;

	const { publicNotes, sortAndFormatNotes, toggleShowMoreHandler, isMoreNotes, setIsMoreNotes, isShowMore } = useNotes({
		isPublic: true,
	});
	const showMoreBtnInnerText = isShowMore ? notesLabels.showLess : notesLabels.showMore;

	setOrganizationId(organization.id);

	const showFee =
		(invoiceState &&
			(invoiceState.paymentsFees.length > 0 ||
				(invoiceState?.paidAmount !== invoiceState?.price && paymentMethodsData?.some(pm => pm.fee)))) ??
		false;

	const { headerTabs, activeTabInvoice } = useMemo(() => {
	const hasApprovedReservations = (invoiceState as any)?.reservations?.some(
			reservation => reservation.approvalStatus === ReservationStatusEnum.APPROVED
		);
		const hasProductItems = invoiceState?.lineItems.some(li =>
			[ProductTypesEnum.REGISTRATION].includes(li.productType)
		);
		const allowSchedule = hasApprovedReservations || hasProductItems;

		return {
			headerTabs: [
				{
					title: Capitalize(invoiceLabels.header.tabs.details),
					link: EInvoicingTabs.DETAILS as string,
					disabled: false,
					isActive: seperateHeader ? invoicePreviewActiveTab === EInvoicingTabs.DETAILS : null,
					onAction: seperateHeader ? () => handleTabChange(EInvoicingTabs.DETAILS) : null,
				},
				{
					title: Capitalize(invoiceLabels.header.tabs.schedule),
					link: EInvoicingTabs.SCHEDULE as string,
					disabled: !allowSchedule,
					isActive: seperateHeader ? invoicePreviewActiveTab === EInvoicingTabs.SCHEDULE : null,
					onAction: seperateHeader ? () => handleTabChange(EInvoicingTabs.SCHEDULE) : null,
				},
			] as {
				title: string;
				link: string;
				disabled: boolean;
				isActive?: boolean;
				onAction?: () => void;
			}[],
			activeTabInvoice: (seperateHeader ? invoicePreviewActiveTab : tab) as EInvoicingTabs,
		};
	}, [invoiceState?.lineItems, seperateHeader, invoicePreviewActiveTab, tab]);

	const invoiceTemplateState = useMemo(() => {
		const processedInvoiceState = cloneAndProcessInvoiceState(invoiceState);
		setIsApiInProgress(false);

		const res = {
			invoice: processedInvoiceState,
			entitlementGroups: entitlementGroups,
			organization: organization,
			scheduledPayments: scheduledPayments,
		};
		// console.log(`${JSON.stringify(res)}`);
		return res;
	}, [invoiceState, entitlementGroups, organization, scheduledPayments]);

	const discountTotalPrice = useMemo(() => {
		if (!invoiceState) {
			return 0;
		}
		if (!discountLineItemsIds?.length) {
			return (invoiceState as any)?.subtotal;
		}

		// line item subtotal (exclude exclusive tax)
		return invoiceState.lineItems
			.filter(li => discountLineItemsIds.includes(li.id))
			.filter(li => !li.isRefunded && !li.isVoided)
			.reduce((acc, li) => {
				const discountTotal = li.discounts.reduce((acc, d) => roundPriceCents(acc + Math.abs(d.discountAmount)), 0);
				const netPrice = li.displayFullPrice - discountTotal;
				return acc + roundPriceCents(netPrice);
			}, 0);
	}, [invoiceState, discountLineItemsIds]);

	const handleInvoiceResponse = async (data: any) => {
		if (data.err) {
			setErrorModal({ message: String(data.err) });
		} else {
			let invoiceData = processInvoiceResponse(data);
			sortAndFormatNotes(invoiceData.invoiceNotes);

			try {
				// Parallelize the customer lookup, payment methods, and entitlement groups
				const [customerById, entitlementGroups, paymentMethods] = await Promise.all([
					customersApi.getCustomerByUserId(organization.id, data.payingUserId),
					membershipApi.getEntitlementGroupsByOrganiztionId(organization.id),
					// Only call payment methods after fetching customer
					customersApi
						.getCustomerByUserId(organization.id, data.payingUserId)
						.then(customer =>
							customer?.data
								? paymentApi.getCustomerUserPaymentMethods(organization.id, Number(customer.data.entityId))
								: null
						).then(res => res?.err ? [] : sortPaymentMethods(res)),
				]);

				if (customerById.data) {
					invoiceData.customer = customerById.data;

					// Handle the results
					setEntitlementGroups(entitlementGroups);
					setPaymentMethodsData(paymentMethods);
					setCustomerState(customerById.data);

					// Continue with the scheduled payments fetch in parallel as well
					invoiceData = await fetchScheduledPayments(data, paymentMethods);

					setInvoiceState({ ...invoiceData, customer: customerById.data });
				} else {
					setErrorModal({ message: customerById.err });
				}
			} catch (error) {
				setErrorModal({ message: TranslationEn.genericError });
			} finally {
				setIsLoading(false);
			}
		}
	};

	const fetchData = useCallback(async () => {
		try {
			const fetchDiscountReasons = !discountReasons?.length
				? organizationApi
						.getDiscountReasons(organization.id)
						.then(res => {
							if (!isErrorResponse(res)) {
								setDiscountReasons(res);
							} else {
								checkForErrorResponse(res.err);
							}
						})
						.catch(err => checkForErrorResponse(err))
				: Promise.resolve(); // Skip if already loaded

			const fetchManualDiscounts = discountsApi
				.getOrgDiscounts(organization.id, DiscountTypeEnum.MANUAL)
				.then(res => {
					const isError = checkForErrorResponse(res);
					if (!isError) {
						const { data } = res;
						const manualDiscount = data.find(d => d.type === DiscountTypeEnum.MANUAL);
						setManualDiscountId(manualDiscount?.id);
					}
				})
				.catch(err => checkForErrorResponse(err));

			const customerIdToUse = customerId ?? initialCustomerId ?? familyId;

			if (!customerIdToUse) {
				setErrorModal({ message: TranslationEn.errorLoadingPage });
				return;
			}

			const fetchInvoice = customersApi
				.getInvoiceById({
					customerId: Number(customerIdToUse),
					invoiceId: Number(invoiceId ?? initialInvoiceId),
					organizationId: organization.id,
					getExtended: true,
					getReservations: true,
				})
				.then(data => handleInvoiceResponse(data))
				.catch(() => {
					setErrorModal({ message: TranslationEn.genericError });
				});

			// Execute all promises in parallel
			await Promise.all([fetchDiscountReasons, fetchManualDiscounts, fetchInvoice]);
		} catch (error) {
			// Handle any unexpected errors
			console.error('An unexpected error occurred:', error);
		}
	}, [invoiceId, initialCustomerId, discountReasons, manualDiscountId]);

	// Todo - fetchScheduledPayments  challenge chargeBalance flow
	// 1. GetScheduledPayments need to return the relevant total
	// 2. We don't need to calculate the finalFuturePrice we need to allow them to pay by any requested payment
	const fetchScheduledPayments = async (invoiceData, paymentMethods) => {
		let finalFuturePrice = 0;
		const scheduledPayments = await paymentApi.getScheduledPayments(
			organization.id,
			invoiceData.id,
			invoiceData.payingUserId
		);
		const futurePayments = scheduledPayments.filter(sp => scheduledChargeStatusWithTreansactionFee.includes(sp.status));

		if (futurePayments?.length) {
			// fetch the invoice subtotalBalance
			finalFuturePrice = invoiceData.subTotalBalance;
		}
		scheduledPayments?.sort(
			(a: any, b: any) => new Date(a?.originalPlannedDate).getTime() - new Date(b?.originalPlannedDate).getTime()
		);
		setScheduledPayments(scheduledPayments);

		return { ...invoiceData, futurePrice: finalFuturePrice };
	};

	const refetch = () => {
		if (isShowingScheduleCharge) toggleScheduledCharge();
		//  Why @jbknick?
		if (invoiceState) {
			fetchData().then();
		}
	};

	const handleSetLoading = (val: boolean) => {
		setIsLoading(val);
	};

	useEffect(() => {
		if (invoiceState) {
			sortAndFormatNotes(invoiceState.invoiceNotes);
		}
	}, [invoiceState]);

	useEffect(() => {
		fetchData();
		setShouldConnectToTerminal(true);
	}, []);

	let arr = location.pathname.split('/');
	arr.splice(0, 4);
	arr = ['/customer', String(customerState?.id ?? 0), ...arr];
	const familyRedirection = arr.join('/');
	const redirectionToRefund = location.pathname.includes('family') ? familyRedirection : location.pathname;

	const voidCompleted = (voidType: VoidActionType) => {
		if (voidType === 'items') {
			history.push(voidUrl);
		} else {
			refetch();
			setInlineNotification(invoiceLabels.voidModal.voidAllSucceeded);
		}
	};

	const handleSchedulePayment = () => {
		if (isShowingScheduleCharge) toggleScheduledCharge();
		toggleScheduledCharge();
	};

	const handleOnAction = (action: EInvoiceAction, val?: string | string[] | number | number[]) => {
		switch (action) {
			case EInvoiceAction.REMOVE_DISCOUNT:
				setDiscountGroupId(val as string);
				toggleDeleteDiscount();
				break;
			case EInvoiceAction.ADD_DISCOUNT:
				handleAddLineItemDiscount(val as number[]);
				break;
			case EInvoiceAction.DOWNLOAD:
				handleDownloadReceipt(+val);
				break;
			case EInvoiceAction.VIEW:
			default:
				history.push(`/${paymentUrl}/${val}`);
				break;
		}
	};

	const handleTabChange = (val: EInvoicingTabs) => {
		setInvoicePreviewActiveTab(val);
	};

	const handleAddDiscount = (isInvoice: boolean, lineItemIds?: number[]) => {
		const isStatusValid = PAYMENT_STATUS_DISCOUNT_ALLOWED.includes(invoiceState.paymentStatus);
		const isLineItemValid = !invoiceState.lineItems.some(li => li.productType === ProductTypesEnum.RESERVATION);
		const hasUnpaidSchedule = scheduledPayments.find(sp => sp.status === EScheduledChargeStatus.FUTURE);
		const currentLineItemId = lineItemIds?.[0]; //DiscountLineItemsIds should contain just 1 item, It will contain more when we wil have different action
		const priceToCheck = currentLineItemId
			? getLineItemById(invoiceState, currentLineItemId)?.price
			: invoiceState.price;
		const isValidAmount = priceToCheck > 0;
		if (!isStatusValid || !isLineItemValid || hasUnpaidSchedule || !isValidAmount) {
			setErrorModal({
				title: invoiceLabels.discountRestrictedTitle(isInvoice),
				message: !isLineItemValid
					? invoiceLabels.discountRestrictedReservation
					: invoiceState.paymentStatus === PaymentStatusEnum.PENDING
					? invoiceLabels.discountRestrictedPending
					: hasUnpaidSchedule
					? invoiceLabels.discountRestrictedSchedulePayments
					: !isValidAmount
					? invoiceLabels.discountRestrictedPrice
					: invoiceLabels.discountRestricted(invoiceState.paymentStatus),
				tryAgainText: '  ',
				isNewUi: true,
			});
		} else {
			setDiscountType(isInvoice ? EDiscountApplicant.INVOICE : EDiscountApplicant.LINE_ITEM);
			toggleDiscount();
		}

		if (isInvoice) {
			setInvoiceSubtotalBalance((invoiceState as any)?.subtotalBalance);
		}
	};

	const handleAddLineItemDiscount = (lineItemIds: number[]) => {
		setInvoiceSubtotalBalance((invoiceState as any)?.subtotalBalance);
		setDiscountLineItemsIds(lineItemIds);
		handleAddDiscount(false, lineItemIds);
	};

	const handleDownloadReceipt = (paymentId: number) => {
		downloadReceipt(paymentId, +invoiceState.payingUserId);
	};

	const handleRemoveDiscount = async (actionId: string) => {
		const hasUnpaidSchedule = scheduledPayments.find(sp => sp.status === EScheduledChargeStatus.FUTURE);

		if (hasUnpaidSchedule) {
			setErrorModal({
				title: invoiceLabels.discountRemoveTitle,
				message: hasUnpaidSchedule ? invoiceLabels.discountRemoveWithSchedulePayment : invoiceLabels.discountRemove,
				tryAgainText: '  ',
				isNewUi: true,
			});
		} else if (actionId) {
			// We need to find the line items correlating with the paymentId of the discount
			try {
				const res = await removeDiscount(organization.id, invoiceState.id, actionId, shiftId);
				const isError = checkForErrorResponse(res);
				if (isError) {
					setErrorModal({ message: discountLabels.failedToAddDiscount });
				}
			} catch (error) {
				setErrorModal({ message: discountLabels.failedToRemoveDiscount });
			}
		}
		resetDiscountState();
		refetch();
	};

	const resetDiscountState = () => {
		// Why @jbknick
		// setManualDiscountId(undefined);
		// setDiscountType(undefined);
		setDiscountLineItemsIds([]);
		setIsApiInProgress(false);
		setDiscountGroupId(undefined);
	};

	const handleCloseDiscount = () => {
		resetDiscountState();
		toggleDiscount();
	};

	const handleCloseDeleteDiscount = (isConfirm: boolean) => {
		if (!isConfirm) {
			toggleDeleteDiscount();
		} else {
			setIsApiInProgress(true);
			handleRemoveDiscount(discountGroupId).then(() => {
				toggleDeleteDiscount();
			});
		}
	};

	const handleSubmitAndCloseDiscount = async (discountModalState?: TDiscountModalState) => {
		const discountId = discountModalState?.discountId ?? manualDiscountId;

		if (discountModalState && !Number.isNaN(discountId)) {
			try {
				setIsApplyDiscountProcessing(true);
				const res = await addDiscount(
					discountModalState.discountType,
					organization.id,
					invoiceState.id,
					invoiceState?.futurePrice || invoiceState?.price,
					discountModalState.discountState,
					discountId,
					discountLineItemsIds,
					shiftId
				);
				setIsApplyDiscountProcessing(false);
				const isError = checkForErrorResponse(res);
				if (isError) {
					setErrorModal({ message: discountLabels.failedToAddDiscount });
				}
			} catch (error) {
				setErrorModal({ message: discountLabels.failedToAddDiscount });
			}
			refetch();
		}
		handleCloseDiscount();
	};
	const initiateScheduleChargeFlow = () => {
		initiateFirstPage();
	};
	const resetScheduledChargeFlow = () => {
		removeFromQuery();
	};

	const { isLoading: isPermissionsLoading, checkMultiplePermissions } = usePermissions();
	const authorizations = useMemo((): IAuthorizations => {
		if (isPermissionsLoading)
			return {
				usePromoDiscount: false,
				useCustomDiscount: false,
			};
		const [usePromoDiscount, useCustomDiscount] = checkMultiplePermissions([
			[permissions.customers.invoices.discounts.promo],
			[permissions.customers.invoices.discounts.custom],
		]);
		return {
			usePromoDiscount,
			useCustomDiscount,
		};
	}, [isPermissionsLoading, checkMultiplePermissions]);

	return (
		<Fragment>
			{isLoading || !invoiceState ? (
				<div>{TranslationEn.isLoading}</div>
			) : (
				<div css={containerCss}>
					{seperateHeader && (
						<Header
							separateHeader={seperateHeader}
							invoiceState={invoiceState}
							customerState={customerState}
							DiscountToggle={handleAddDiscount}
							scheduledPayments={scheduledPayments}
							fetchScheduledPayments={refetch}
							isPrimaryButton={!invoiceState?.isScheduled}
							redirectionToRefund={redirectionToRefund}
							onVoidSuccess={voidCompleted}
							toggleBack={toggleBack}
							tabs={headerTabs}
						/>
					)}
					<div className="no-print">
						{!seperateHeader && (
							<Header
								invoiceState={invoiceState}
								customerState={customerState}
								DiscountToggle={handleAddDiscount}
								scheduledPayments={scheduledPayments}
								fetchScheduledPayments={refetch}
								isPrimaryButton={!invoiceState?.isScheduled}
								redirectionToRefund={redirectionToRefund}
								tabs={headerTabs}
								onVoidSuccess={voidCompleted}
								ChargeButton={
									<ChargeFlowWrapper
										invoiceState={invoiceState}
										customerState={customerState}
										isPrimaryButton={!invoiceState?.isScheduled}
										refetch={refetch}
										onClickCallback={initiateScheduleChargeFlow}
										onCloseCallback={resetScheduledChargeFlow}
										paymentMethodsData={paymentMethodsData}
										showFeeWarning={showFee}
									/>
								}
								onSchedulePayment={handleSchedulePayment}
								totalAmount={invoiceState?.futurePrice}
							/>
						)}
						<MiddleWhiteContainer
							isTransparent={seperateHeader}
							width={!seperateHeader ? 900 : null}
							fullWidth={seperateHeader}
						>
							{invoiceTemplateState && (
								<InvoiceTemplate
									state={invoiceTemplateState}
									isLoading={isLoading}
									setLoading={handleSetLoading}
									variant={seperateHeader ? EInvoiceVariant.BACKOFFICE_PREVIEW : EInvoiceVariant.BACKOFFICE}
									activeTab={activeTabInvoice}
									setActiveTab={handleTabChange}
									onAction={handleOnAction}
									organizationTimeZone={timezone}
									discountVisibility={{
										promoCode: authorizations.usePromoDiscount,
										customDiscount: authorizations.usePromoDiscount,
									}}
								>
									{!seperateHeader && (
										<SectionContainer
											label={TranslationEn.components.notes}
											footer={
												isMoreNotes && (
													<Button
														data-aid="button-singleInvoice-basic"
														sizer="XS"
														theme="basic"
														onClick={toggleShowMoreHandler}
													>
														<Typography color={ETypographyColor.primary} type={ETypography.captionLink}>
															{TranslationEn.activities.attendance[showMoreBtnInnerText]}
														</Typography>
													</Button>
												)
											}
										>
											<PublicNotes
												publicNotes={publicNotes}
												invoiceId={invoiceState.id}
												documentType={EDocumentType.INVOICE}
												setMoreNotesButton={setIsMoreNotes}
											/>
										</SectionContainer>
									)}
								</InvoiceTemplate>
							)}
							{showChargeFooter && (
								<Footer
									showFeeWarning={showFee}
									ChargeButton={
										<ChargeFlowWrapper
											invoiceState={invoiceState}
											customerState={customerState}
											isPrimaryButton={!invoiceState?.isScheduled}
											refetch={refetch}
											paymentMethodsData={paymentMethodsData}
											showFeeWarning={showFee}
										/>
									}
								/>
							)}
						</MiddleWhiteContainer>
					</div>
				</div>
			)}

			{invoiceState && customerState && (
				<Fragment>
					{isShowingDiscount && (
						<DiscountModalWrapper
							isApplyProcessing={isApplyDiscountProcessing}
							invoiceTemplateState={invoiceTemplateState}
							discountLineItemsIds={discountLineItemsIds}
							discountTotalPrice={discountTotalPrice}
							isShowingDiscount={isShowingDiscount}
							discountReasons={discountReasons}
							invoiceSubtotalBalance={invoiceSubtotalBalance}
							discountType={discountType}
							organizationId={organization.id}
							handleSubmitAndCloseDiscount={handleSubmitAndCloseDiscount}
						/>
					)}
					<DeleteDiscount
						isOpen={isDeleteDiscountShowing}
						onClose={handleCloseDeleteDiscount}
						isLoading={isApiInProgress}
					/>
					<ChargeFlowWrapper
						invoiceState={invoiceState}
						customerState={customerState}
						isPrimaryButton={!invoiceState?.isScheduled}
						refetch={refetch}
						paymentMethodsData={paymentMethodsData}
						showFeeWarning={showFee}
						isShowing={isShowingScheduleCharge}
						isShowChargeButton={false}
						initialStep={invoiceState?.isScheduled ? EChargeInnerStep.RESCHEDULE : EChargeInnerStep.SCHEDULED_CHARGE}
						scheduledPayments={scheduledPayments}
					/>
				</Fragment>
			)}
		</Fragment>
	);
};

const DiscountModalWrapper = ({
	isApplyProcessing,
	invoiceTemplateState,
	discountLineItemsIds,
	discountTotalPrice,
	isShowingDiscount,
	discountReasons,
	invoiceSubtotalBalance,
	discountType,
	organizationId,
	handleSubmitAndCloseDiscount,
}: {
	isApplyProcessing: boolean;
	invoiceTemplateState: IInvoiceTemplateState;
	discountLineItemsIds: number[];
	discountTotalPrice: number;
	isShowingDiscount: boolean;
	discountReasons: IReason[];
	invoiceSubtotalBalance: number;
	discountType: EDiscountApplicant;
	organizationId: number;
	handleSubmitAndCloseDiscount: (discountModalState?: TDiscountModalState) => Promise<void>;
}) => {
	const isInvoiceDiscount = discountType === EDiscountApplicant.INVOICE;

	const isAppliedPromoCode = useMemo(() => {
		switch (discountType) {
			case EDiscountApplicant.INVOICE:
				return (
					!!invoiceTemplateState?.invoice?.discounts &&
					validateDiscounts(
						invoiceTemplateState.invoice.discounts,
						hasPromoCode,
						hasPromoCodeByType(InvoiceDiscountOnEnum.INVOICE)
					)
				);
			case EDiscountApplicant.LINE_ITEM:
				const lineItem =
					!!invoiceTemplateState?.invoice && getLineItemById(invoiceTemplateState.invoice, discountLineItemsIds[0]);
				return (
					!!lineItem?.discounts &&
					validateDiscounts(lineItem.discounts, hasPromoCode, hasPromoCodeByType(InvoiceDiscountOnEnum.LINE_ITEM))
				);
			default:
				return false;
		}
	}, [isInvoiceDiscount, invoiceTemplateState, discountLineItemsIds]);

	const relatedProductId = useMemo(() => {
		if (!isInvoiceDiscount) {
			return invoiceTemplateState.invoice.lineItems.find(lineItem => lineItem.id === discountLineItemsIds[0])
				?.productId;
		}
		return undefined;
	}, [isInvoiceDiscount, invoiceTemplateState, discountLineItemsIds]);

	const fetchData = useCallback(
		(fetchDataParams: { page: number; itemsPerPage: number; filters?: IGetPromoCodesFilters }) =>
			fetchPromoCodes(organizationId, {
				page: fetchDataParams.page,
				itemsPerPage: fetchDataParams.itemsPerPage,
				search: fetchDataParams.filters?.search || '',
				onlyTotalDiscounts: isInvoiceDiscount,
				relatedProductId: relatedProductId,
			}),
		[organizationId, isInvoiceDiscount, relatedProductId]
	);
	return (
		<DiscountModal
			isOpen={isShowingDiscount}
			totalAmount={discountTotalPrice}
			discountType={discountType}
			reasons={discountReasons ?? []}
			onClose={handleSubmitAndCloseDiscount}
			invoiceSubtotalBalance={
				invoiceSubtotalBalance && invoiceSubtotalBalance < discountTotalPrice ? invoiceSubtotalBalance : undefined
			}
			withTabs={true}
			fetchData={fetchData}
			isAppliedPromoCode={isAppliedPromoCode}
			isApplyProcessing={isApplyProcessing}
		/>
	);
};

const validateDiscounts = <T,>(values: T[], ...validators: ((values: T) => boolean)[]): boolean => {
	return values.some(value => validators.every(validator => validator(value)));
};

const hasPromoCode = (discount: ItemDiscountDto): boolean => {
	return discount.discount?.type === DiscountTypeEnum.FIXED;
};
