/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/react';
import { Button, SizeEnum, TagColorsMapper } from '@bondsports/utils';
import { CaptionTypography, InfoOutline } from 'ui';
import { flexBetweenCss, flexCol } from '@app/react/styles/utils';

interface GenericWarningBlockProps {
    warningMessage: string;
    showWarning?: boolean;
	warningLink?: {
		label: string;
		onClick: () => void;
	}
	icon?: React.ReactNode;
}

const warningIconCss = css`
	width: 24px;
	height: 24px;
	color: ${TagColorsMapper.orange.primary};
`;

const warningContainerCss = css`
 ${flexBetweenCss};
 gap: 1rem;
 text-align: left;
`;

export const GenericWarning = ({ warningMessage, showWarning, warningLink, icon = <InfoOutline css={warningIconCss} /> }: GenericWarningBlockProps) => {
    if (!showWarning) {
        return null;
    }

	return (
		<div css={warningContainerCss} data-testid="generic-warning-container">
			{icon}
			<div css={flexCol} data-testid="generic-warning-text-container">
				<CaptionTypography truncate={false} className='text-primary' data-testid="generic-warning-messsage">
					{warningMessage}
				</CaptionTypography>
				{!!warningLink?.label &&
					<CaptionTypography className='text-primary underline' truncate={false} data-testid="generic-warning-link">
						<Button sizer={SizeEnum.EXTRA_SMALL} theme="basic" onClick={warningLink.onClick}>
							{warningLink.label}
						</Button>
					</CaptionTypography>
				}
			</div>
		</div>
    );
};
