import { useCallback, useRef, useState } from 'react';
import { useIntersectionObserver } from './useIntersectionObserver';

export function useInView() {
	const [isInView, setIsInView] = useState(false);

	const { observe } = useIntersectionObserver(entries => {
		setIsInView(entries?.[0]?.isIntersecting);
	});

	return { isInView, onView: observe };
}
