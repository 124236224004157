/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import React from 'react';

import { flexEndCss, gapCss } from '@app/react/styles/utils';

interface FormButtonsProps {
	children: React.ReactNode;
}

export const FormButtons: React.FC<FormButtonsProps> = ({ children }) => {
	return <div css={[flexEndCss, gapCss(16)]}>{children}</div>;
};
