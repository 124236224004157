/** @jsx jsx */

import React, { useState, Fragment, useEffect } from 'react';
import { jsx, css } from '@emotion/react';
import { flex, gapCss } from '../../../styles/utils';
import { colors } from '../../../styles/theme';
import { EChargeInnerStep, IPayment } from '../../../types/payment';
import { PaymentMethodTypeEnum, UserPaymentMethod } from '@bondsports/types';
import { PaymentMethodBox } from '../utils';
import { TranslationEn } from '@assets/i18n/en';
import { Footer } from '../utils/footer';

const container = css`
	margin: 2rem;
	min-width: 350px;
	justify-content: center;
`;

const groupCss = css`
	width: fit-content;
`;

const labelCss = css`
	font-family: Montserrat;
	font-weight: 500;
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: ${colors.formInputBg};
`;

type TPaymentMethodTemporal = PaymentMethodTypeEnum.ACH | PaymentMethodTypeEnum.CARD;

interface Props {
	toggle: () => void;
	setPaymentMethodType: (paymentMethodType: TPaymentMethodTemporal | string) => void;
	onNextClicked?: (val: EChargeInnerStep) => void;
	ACHMethods?: UserPaymentMethod[];
}
export const SelectPaymentMethod = ({
	toggle,
	setPaymentMethodType,
	ACHMethods,
	onNextClicked,
}: Props) => {
	const [paymentMethod, setPaymentMethod] = useState<TPaymentMethodTemporal | string>('');
	const [disabled, setDisabled] = useState<boolean>(true);
	const showAch = ACHMethods?.length > 0;

	const handleSelectPayment = (pm: TPaymentMethodTemporal | string) => {
		setDisabled(false);
		setPaymentMethodType(pm);
		setPaymentMethod(pm);
		if (onNextClicked) {
			onNextClicked(pm as EChargeInnerStep);
		}
	};

	return (
		<Fragment>
			<div css={[container, flex]}>
				<div css={groupCss}>
					<label css={labelCss}>{TranslationEn.payments.whichPaymentMethod}</label>
					<div data-aid="addSchedulePayment-card" css={[flex, gapCss(16)]}>
						<PaymentMethodBox method={PaymentMethodTypeEnum.CARD} active={paymentMethod === PaymentMethodTypeEnum.CARD} handleClick={handleSelectPayment} />
						{showAch && (
							<PaymentMethodBox
								method={PaymentMethodTypeEnum.ACH}
								active={paymentMethod === PaymentMethodTypeEnum.ACH}
								handleClick={handleSelectPayment}
							/>
						)}
					</div>
				</div>
			</div>
			<Footer
				data-aid="addSchedulePayment-footer"
				toggle={toggle}
				disabled={disabled}
				loader={false}
			/>
		</Fragment>
	);
}