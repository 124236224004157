import {
	AlertsStatusEnum,
	CustomerPageTabsEnum,
	ILinkedAccount,
	PaymentAlertEnum,
	TCustomer,
} from '@app/react/types/customers';
import { geShowingAlerts } from '../../../Identification/lib';
import {
	FutureInstallment,
	FuturePaymentStatusEnum,
	IExpandedMembershipMemberData,
	Invoice,
	MembershipStatusEnum,
	OrderByEnum,
	PaymentFilterByEnum,
	PaymentStatusEnum,
} from '@bondsports/types';
import { PaymentAlert } from '../../../Identification/sections/Payments/types';
import { getInvoiceByIdPath } from '@app/react/lib/paths';
import { paymentApi } from '@app/react/lib/api/paymentApi';
import { membershipApi } from '@app/react/lib/api/membershipApi';
import { customersApi } from '@app/react/lib/api/customersApi';
import { getLineItemPaymentStatus } from '../../../PageBodyMapper/MembershipTab/utils';
import { groupBy, last } from 'lodash';
import { MembershipAlert } from '@app/react/components/customers/Identification/sections/Memberships/types';

export const MAX_ALERTS_TO_DISPLAY = 2;
const ALL_MBERSHIPS = 100;

export enum ScrollToSectionEnum {
	WAIVER = 'waiver',
}

export enum OpenSectionEnum {
	PRIVATE_NOTES = 'privateNotes',
}

export enum SortEnum {
	PAYMENT_ALERTS = 'paymentAlerts',
}

export enum UrlParamEnum {
	SCROLL_TO_SECTION = 'scrollToSection',
	OPEN_SECTION = 'openSection',
	SORT_BY = 'sortBy',
}

export const sortInvoicesPath = `${UrlParamEnum.SORT_BY}=${SortEnum.PAYMENT_ALERTS}`;
export const viewWaiverPath = `${CustomerPageTabsEnum.CUSTOMER_DETAILS}?${UrlParamEnum.SCROLL_TO_SECTION}=${ScrollToSectionEnum.WAIVER}`;
export const viewAllMembershipsPath = CustomerPageTabsEnum.MEMBERSHIP;
export const viewAllInvoicesPath = `${CustomerPageTabsEnum.INVOICES}?${sortInvoicesPath}`;
export const openNotesPath = `${UrlParamEnum.OPEN_SECTION}=${OpenSectionEnum.PRIVATE_NOTES}`;

export const generatePersonalProfileState = (
	customerState: TCustomer,
	connectedAccounts: ILinkedAccount[],
	isLoadingLinkedAccounts: boolean
) => {
	const { birthDate, phoneNumber, email, waiverSignedDate } = customerState;
	return {
		viewWaiverPath,
		birthDate: birthDate,
		phoneNumber: phoneNumber,
		email: email,
		waiverSignedDate: waiverSignedDate,
		linkedAccounts: connectedAccounts,
		isLoadingLinkedAccounts,
	};
};

export const generateMembershipAlertsText = (numberOfMembershipAlerts: number) => {
	return numberOfMembershipAlerts
		? geShowingAlerts(
				numberOfMembershipAlerts < MAX_ALERTS_TO_DISPLAY ? numberOfMembershipAlerts : MAX_ALERTS_TO_DISPLAY,
				numberOfMembershipAlerts
		  )
		: '';
};

export const generateMembershipsAlerts = (
	memberMemberships: IExpandedMembershipMemberData[],
	redirectToMembership: (id: number) => void
): MembershipAlert[] => {
	const groupByMembership = groupBy(memberMemberships, data => data.membership.id);
	const membershipAlerts = [];
	for (const membershipId in groupByMembership) {
		const members = groupByMembership[membershipId];
		const lastMember = last(members);

		membershipAlerts.push({
			membershipStatus: lastMember.member.membershipStatus,
			paymentStatus: getLineItemPaymentStatus(lastMember.lineItem),
			name: lastMember.membership.name,
			redirectToMembership: () => redirectToMembership(lastMember.membership.id),
		});
	}

	return orderMembershipAlertsByMembershipStatus(membershipAlerts);
};

function orderMembershipAlertsByMembershipStatus(membershipStateArray: MembershipAlert[]): MembershipAlert[] {
	return membershipStateArray.sort((a, b) => {
		const rankA: number = getRankByMembershipStatus(a.membershipStatus);
		const rankB: number = getRankByMembershipStatus(b.membershipStatus);
		return rankB - rankA;
	});
}

function getRankByMembershipStatus(membershipStatus: MembershipStatusEnum): number {
	switch (membershipStatus) {
		case MembershipStatusEnum.ACTIVE:
			return 1;
		case MembershipStatusEnum.ACTIVE_CANCELLED:
			return 2;
		case MembershipStatusEnum.PENDING:
			return 3;
		case MembershipStatusEnum.CANCELLED:
			return 4;
		case MembershipStatusEnum.EXPIRED:
			return 5;
		default:
			return 0;
	}
}

export const alertStatuses = [MembershipStatusEnum.CANCELLED, MembershipStatusEnum.EXPIRED];

export const generateMembershipsState = (
	memberships: IExpandedMembershipMemberData[],
	navigateToMembershipById: (id: number) => void
) => {
	const membershipsAlerts: MembershipAlert[] = generateMembershipsAlerts(memberships, navigateToMembershipById);

	const numberOfAlerts: number =
		membershipsAlerts.filter(membershipAlert => alertStatuses.includes(membershipAlert.membershipStatus))?.length || 0;

	return {
		isExistingMemberships: Boolean(memberships.length),
		membershipAlerts: membershipsAlerts,
		numberOfMembershipAlerts: numberOfAlerts,
	};
};

export const membershipInitialState = {
	isExistingMemberships: false,
	membershipAlerts: [],
	numberOfMembershipAlerts: 0,
};

export function getSectionStatus(isFetchingDataFaild: boolean, isExistingData: boolean, numberOfAlerts: number) {
	if (isFetchingDataFaild) {
		return AlertsStatusEnum.ERROR;
	}
	if (!isExistingData) {
		return AlertsStatusEnum.NO_DATA;
	}
	if (numberOfAlerts) {
		return AlertsStatusEnum.ISSUES;
	}

	return AlertsStatusEnum.GOOD_STANGING;
}

function getFailedInstallmentsSumAmount(faildPayments: FutureInstallment[]) {
	return faildPayments.reduce((accumulator, object) => {
		return accumulator + object.price;
	}, 0);
}

const getInvoiceRemainingPaymentAmount = (invoice: Invoice) => {
	const { price, paidAmount, paymentStatus } = invoice;
	return paymentStatus === PaymentStatusEnum.PARTIAL_PAYMENT ? price - paidAmount : price;
};

function getPaymentAlertAmount(invoice: Invoice) {
	const { installments } = invoice;
	const failedInstallments = installments?.filter(payment => payment.status === FuturePaymentStatusEnum.FAILED);
	return failedInstallments?.length > 0
		? getFailedInstallmentsSumAmount(failedInstallments)
		: getInvoiceRemainingPaymentAmount(invoice);
}

const getInvoicesNotes = async (invoices: Invoice[], organizationId: number) => {
	const getPrivateInvoices = invoices.map(invoice => paymentApi.getInvoiceNotes(organizationId, invoice.id, false));
	return await Promise.all(getPrivateInvoices);
};

export const generatePaymentsAlerts = async (
	organizationId: number,
	invoices: Invoice[],
	redirectToInvoiceNote: (id: number, param?: string) => void
) => {
	const privateInvoices = await getInvoicesNotes(invoices, organizationId);
	return invoices.map((invoice, index) => {
		const { id, createdAt, hasFailedPayments, hasFailedInstallments } = invoice;
		const unpaidAmount = getPaymentAlertAmount(invoice);
		const paymentAlertType =
			hasFailedPayments || hasFailedInstallments ? PaymentAlertEnum.FAILED : PaymentAlertEnum.NOT_PAID;

		const isExistingPrivateNotes = privateInvoices[index].length;

		const handleRedirectToNote = () => {
			redirectToInvoiceNote(id, openNotesPath);
		};

		return {
			paymentAlertType,
			date: createdAt,
			unpaidAmount,
			invoiceId: id,
			handleRedirectToNote: isExistingPrivateNotes && handleRedirectToNote,
			invoicePath: getInvoiceByIdPath(id),
		} as PaymentAlert;
	});
};

export const getMemberships = (customerId: number, organizationId: number) =>
	membershipApi.getMembershipsByCustomerId({
		customerId: customerId,
		organizationId: organizationId,
		sortOrder: [OrderByEnum.ASC, OrderByEnum.ASC],
		itemsPerPage: ALL_MBERSHIPS,
	});

export const getPaymentsAlerts = (customerId: number) =>
	customersApi.getInvoicesByCustomerId(customerId, {
		itemsPerPage: 2,
		alerts: true,
		orderByProperty: [PaymentFilterByEnum.ID],
		order: [OrderByEnum.ASC],
	});
