/** @jsx jsx */

import React, { Fragment, useContext } from 'react';
import { jsx, css } from '@emotion/react';
import { TranslationEn } from 'assets/i18n/en';
import { CustomPaymentPlan } from '@app/react/forms/activities/pricing/paymentPlans/CustomPaymentPlan';
import { useSchemaValidation } from '@app/react/forms/hooks/formValidation';
import { FORM_SCHEMA } from './schema';
import { FormContext, FormWrapper } from '../../shared/form';
import { ICustomPaymentPlanState } from './types/types';

const container = css`
    padding-top: 0px;
	margin: 2rem 3.75rem;
    min-width: 350px;
    min-height: 155px;
    max-height: 250px;
`;

const scrollWrapperCss = css`
	flex-direction: column;
	display: flex;
`;
const subScrollWrapperCss = css`
	flex-direction: column;
	display: flex;
	flex-grow: 1;
    max-height: 250px;
	overflow: auto;
    padding-right: 1rem;
`;

interface Props {
    initialState: ICustomPaymentPlanState,
	totalAmount: number;
	onChange: (ICustomPaymentPlanState) => void;
}
export const InvoiceCustomPaymentPlan = ({
    initialState,
	totalAmount,
    onChange,
}: Props) => {

    const [, validate] = useSchemaValidation(FORM_SCHEMA);

    const handleFormStateChange = (newFormState) => {
        const { values, valid } = newFormState;
        if (values) {
            onChange({ schedule: values.schedule, scheduleAmountType: values.scheduleAmountType, valid: valid }); 
        }
    };

	return (
        <div data-aid="addSchedulePayment-input-start" css={container}>
            <div css={scrollWrapperCss}>
                <div css={subScrollWrapperCss}>
                    <Fragment>
                        <FormWrapper
                            validate={validate}
                            initialValuesProps={initialState}
                            exposeState={handleFormStateChange}
                            submit={() => { }}
                            keepDirtyOnReinitialize={true}
                        >
                            <CustomPaymentPlanForm
                                initialState={initialState}
                                totalAmount={totalAmount}
                            />
                        </FormWrapper>
                    </Fragment>
                </div>
            </div>
        </div>
	);
};

export interface ICustomPlanFormProps {
    initialState: ICustomPaymentPlanState;
    totalAmount: number;
}

const CustomPaymentPlanForm = ({ initialState, totalAmount }: ICustomPlanFormProps) => {
    const { form, values } = useContext(FormContext);
    return (
        <CustomPaymentPlan
            paymentPlanType={initialState.paymentPlanType}
            paymentPlanStatus={initialState.paymentPlanStatus}
            amount={totalAmount}
            notBeforeDate={initialState.notBeforeDate}
            labels={TranslationEn.activities.forms.addPricing.discountsAndPayments}
            form={form}
            values={values}
            hideContainerCss={true}
        />
    );
};