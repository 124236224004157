/** @jsxRuntime classic*/
/** @jsx jsx */
import React, { Fragment, isValidElement, ReactNode, useCallback, useRef, useState } from 'react';
import { css, jsx } from '@emotion/react';
import { ETypography, ETypographyColor, type IColors, type Icons, sportsIcons } from '../../types';
import { useColors } from '../../hooks/useColors';
import { Button } from '../../components/Button';
import { BnIcon } from '../../components/icons_v2';
import { Typography } from '../../atoms';
import { useTimeFormats } from '../../hooks/useTimeFormats';
import { DotCss } from '../bo_calendar_slot/style';
import dayjs from 'dayjs';
import { Checkbox } from '../../components/Checkbox';
import { Pricify } from '../../lib/price';
import { ConflictsIndication } from '../conflicts/ConflictsIndication';
import { SportsEnum } from '@bondsports/types';
import {  DateTimeFormats } from '@bondsports/date-time';
import { getCombinedDateTimeAsString } from '../../calendar/utils/timeUtils';

export interface IAddonProp {
	icon: Icons;
	title: string;
	price?: number;
	subTitle?: string;
	hasConflicts?: boolean;
	OnTriggerConflicts?: () => void;
}
export interface ISlot {
	id?: number | string;
	index?: number;
	title: string;
	sport?: SportsEnum;
	startDate?: string;
	startTime?: string;
	endDate?: string;
	endTime?: string;
	addons?: IAddonProp[];
	hasConflicts?: boolean;
	icon?: Icons;
}

export interface ISlotProps extends ISlotContainerProps {}

const buttonContainerCss = css`
	margin-top: 8px;
	button {
		text-decoration: underline;
	}
`;

const expandableContainerCss = (isExpanded: boolean, height: number) => css`
	max-height: ${isExpanded ? `${height}px` : '56px'};
	overflow-y: hidden;
	transition: max-height 400ms;
`;

export const PanelSlotEvent = (props: ISlotProps) => {
	const [isExpanded, setExpanded] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);

	const handleExpandAndCollapse = (e: any) => {
		setExpanded(!isExpanded);
		e.stopPropagation();
	};

	return (
		<div data-aid="panelSlotEvent">
			<PanelSlotEventContainer {...props}>
				{props.state.addons && props.state.addons.length > 0 && (
					<Fragment>
						<div
							ref={containerRef}
							css={expandableContainerCss(isExpanded, containerRef?.current?.scrollHeight || 50)}
							data-aid="index-panelSlotEvent"
						>
							{props.state.addons?.map((addonData, index) => {
								return <AddonLine {...addonData} index={index} key={index} />;
							})}
						</div>
						{(props.state.addons?.length || 0) > 2 && (
							<div css={buttonContainerCss}>
								<Button
									data-aid="button-panelSlotEvent-handle"
									theme="basic"
									sizer="XS"
									onClick={handleExpandAndCollapse}
									type="button"
								>
									{isExpanded ? 'See Less' : 'See All'}
								</Button>
							</div>
						)}
					</Fragment>
				)}
			</PanelSlotEventContainer>
		</div>
	);
};

// ----------------------------------------------------------------

const addonLineCss = (colors: IColors, isFirst: boolean) => css`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: ${isFirst ? '0' : '8px'};
	span {
		display: flex;
		align-items: center;
		gap: 4px;
		svg {
			height: 20px;
			width: 20px;
			margin-right: 4px;
			color: ${colors.bg_text_secondary};
		}
	}

	.title {
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 230px;
		white-space: nowrap;
		display: inline;
	}
`;

interface IAddonLineProps extends IAddonProp {
	index: number;
}

const AddonLine = ({ icon, title, price, index, subTitle, hasConflicts, OnTriggerConflicts }: IAddonLineProps) => {
	const { colors } = useColors();
	const labels = { conflict: 'conflict' };
	return (
		<div data-aid="IAddonLineProps" css={addonLineCss(colors, index === 0)}>
			<span>
				<BnIcon icon={icon} />
				<Typography className="title" type={ETypography.body2Accented} color={ETypographyColor.primary}>
					{title}
				</Typography>
				{subTitle && (
					<Typography type={ETypography.body2Accented} color={ETypographyColor.secondary}>
						({subTitle})
					</Typography>
				)}
				{hasConflicts && (
					<ConflictsIndication
						caption={labels.conflict}
						isSub={!OnTriggerConflicts}
						OnTriggerConflicts={OnTriggerConflicts}
					/>
				)}
			</span>
			{typeof price === 'number' && price > 0 && (
				<span>
					<Typography type={ETypography.body2} color={ETypographyColor.primary}>
						{Pricify(price)}
					</Typography>
				</span>
			)}
		</div>
	);
};

// ----------------------------------------------------------------

const containerCss = (colors: IColors, isChecked?: boolean, isSelectable?: boolean, isCheckable?: boolean, isFooterVisible?: boolean) => css`
	background: ${colors.bg_background_secondary};
	cursor: ${isSelectable || isCheckable ? 'pointer' : 'default'};
	border: 1px solid ${isChecked ? colors.bg_text_primary : colors.bg_border_primary};
	box-sizing: border-box;
	border-radius: 5px;

	${isSelectable &&
	`
  &:hover{
    background:${colors.bg_background_primary};
  }
  `}

	.slot-header {
		display: flex;
		flex-direction: ${isCheckable ? 'row' : 'column'};
		padding: 8px 16px;
		border-bottom: 1px solid ${colors.bg_border_seperator};
		position: relative;

		.firstLine {
			display: flex;
			justify-content: space-between;
			align-items: center;

			margin-bottom: 2px;
			.body-2 {
				text-overflow: ellipsis;
				overflow: hidden;
				max-width: 170px;
				white-space: nowrap;
				display: inline;
			}
		}

		.secondLine {
			overflow: hidden;
		}

		div {
			display: flex;
			align-items: center;
			white-space: pre;
			svg {
				margin-right: 4px;
				height: 16px;
				width: 16px;
				color: ${colors.bg_text_primary};
			}
			.last {
				margin-left: 4px;
			}
		}
		.headerWithCheckbox {
			flex-direction: column;
			align-items: flex-start;
			flex-grow: 1;
			width: 20px;
			.secondLine {
				width: 100%;
			}
		}
	}
	main {
		padding: 8px 16px;
		border-bottom: ${(isFooterVisible ? `1px solid ${colors.bg_border_seperator}` : 'none')};
	}
	.slot-footer {
		padding: 8px 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		div {
			display: flex;
		}
	}
`;

export interface ISlotContainerProps {
	children?: ReactNode;
	state: ISlot;
	headerLeft?: boolean;
	footer?: boolean;
	footerLeft?: string;
	customFooter?: ReactNode;
	handleEdit?: (state: ISlot) => void;
	handleDelete?: (state: ISlot) => void;
	isSelectable?: boolean;
	isCheckable?: boolean;
	isSelected?: boolean;
	isChecked?: boolean;
	handleSelection?: (id: string) => void;
	handleChecked?: (id: string) => void;
	OnTriggerConflicts?: () => void;
	isDeleteDisabled?: boolean;
}

export const PanelSlotEventContainer = ({
	children,
	state,
	headerLeft,
	footer,
	footerLeft,
	customFooter,
	handleEdit,
	handleDelete,
	isDeleteDisabled,
	isSelectable = false,
	isCheckable = false,
	isSelected = false,
	isChecked = false,
	handleChecked,
	handleSelection,
	OnTriggerConflicts,
}: ISlotContainerProps) => {
	const { colors } = useColors();
	const { generateDuration } = useTimeFormats();
	const labels = { conflict: 'conflict' };

	const startTime = getCombinedDateTimeAsString(state.startDate ?? '', state.startTime ?? '');
	const endTime = getCombinedDateTimeAsString(state.endDate ?? '', state.endTime ?? '');

	const diff: string = generateDuration(Math.abs(dayjs(endTime).diff(startTime, 'minutes')));

	const Div = useCallback(({ children }: { children: ReactNode }) => {
		return <div className="headerWithCheckbox">{children}</div>;
	}, []);

	const HeaderContainer = isCheckable ? Div : Fragment;

	const handleClick = () => {
		if (isSelectable && handleSelection) {
			// do some logic here
			// and than it will work
			handleSelection(String(state.id));
		}
		if (isCheckable && handleChecked) {
			handleChecked(String(state.id));
		}
	};

	const ref = useRef<HTMLDivElement>(null);

	const isDateLineOverflow = ref?.current?.clientWidth !== ref?.current?.scrollWidth;

	const isFooterShowing: boolean = !!(footer && (handleDelete || handleEdit));

	return (
		<div data-aid="slotEvent" css={containerCss(colors, isSelected, isSelectable, isCheckable, !!customFooter || isFooterShowing)} onClick={handleClick}>
			<header className="slot-header">
				{isCheckable && (
					<div data-aid="slotEvent-checked">
						<Checkbox functionDisable checked={isChecked} />
					</div>
				)}
				<HeaderContainer>
					<div data-aid="slotEvent-headerCont" className="firstLine">
						<div data-aid="slotEvent-headerCont-state">
							{state.sport ? (
								<BnIcon className="main-icon" icon={sportsIcons[state.sport]} />
							) : state?.icon ? (
								<BnIcon className="main-icon" icon={state.icon} />
							) : (
								<BnIcon icon={sportsIcons[999]} />
							)}
							<Typography type={ETypography.body2} color={ETypographyColor.primary}>
								{state.title}
							</Typography>
							<Fragment>
								{state.hasConflicts && (
									<ConflictsIndication
										caption={labels.conflict}
										OnTriggerConflicts={OnTriggerConflicts}
										isSub={!OnTriggerConflicts}
									/>
								)}
							</Fragment>
						</div>
						<div data-aid="slotEvent-headerLeft">
							<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
								{headerLeft}
							</Typography>
						</div>
					</div>
					<div data-aid="slotEvent-startDate" ref={ref} className="secondLine">
						<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
							{dayjs(state.startDate).format(DateTimeFormats.DDD_MMM_DD)}
						</Typography>
						<Dot />
						<Typography data-aid="slotEvent-end" type={ETypography.caption} color={ETypographyColor.secondary}>
							{dayjs.utc(startTime).format(DateTimeFormats.H12_AMPM_UPPERCASE)}
							{' - '}
							{dayjs.utc(endTime).format(DateTimeFormats.H12_AMPM_UPPERCASE)}
						</Typography>
						<Typography className="last" type={ETypography.caption} color={ETypographyColor.secondary}>
							({diff})
						</Typography>
						{isDateLineOverflow && (
							<HideContainer>
								<Typography className="last" type={ETypography.caption} color={ETypographyColor.secondary}>
									...
								</Typography>
							</HideContainer>
						)}
					</div>
				</HeaderContainer>
			</header>

			{isValidElement(children) && <main>{children}</main>}
			{customFooter && <footer>{customFooter}</footer>}
			{isFooterShowing && (
				<footer data-aid="slotEvent-footer" className="slot-footer">
					<div>
						<Typography type={ETypography.caption} color={ETypographyColor.secondary}>
							{footerLeft}
						</Typography>
					</div>
					<div>
						{handleDelete && (
							<Button
								data-aid="button-panelSlotEvent-set"
								theme="basic"
								sizer="XS"
								onClick={e => {
									e.stopPropagation();
									handleDelete(state);
								}}
								disabled={isDeleteDisabled}
								type="button"
							>
								DELETE
							</Button>
						)}
						{handleEdit && (
							<Button
								data-aid="button-panelSlotEvent-sec"
								theme="secondary"
								sizer="XS"
								onClick={e => {
									e.stopPropagation();
									handleEdit(state);
								}}
								type="button"
							>
								Edit Event
							</Button>
						)}
					</div>
				</footer>
			)}
		</div>
	);
};

const DotSpecifiecCss = (colors: IColors) => css`
	${DotCss(colors)}
	width:3px;
	height: 3px;
	margin: 0 8px;
	background: ${colors.bg_text_secondary};
`;

const Dot = () => <span className="dot" css={DotSpecifiecCss} />;

const hiddenCss = (colors: IColors) => css`
	position: absolute;
	right: 16px;
	background: #fafcfd;
`;

const HideContainer = ({ children }: { children: ReactNode }) => {
	const { colors } = useColors();

	return <div css={hiddenCss(colors)}>{children}</div>;
};
