import * as yup from 'yup';
import { numberOrNull } from '@app/react/forms/activities/utils';

export const BASIC_NUMBER_SCHEMA = yup.number().transform(numberOrNull);

export const BASIC_INTEGER_SCHEMA = BASIC_NUMBER_SCHEMA.integer();

export const requiredIntegerWithMinAndMax = (min: number, max?: number): yup.NumberSchema => {
	let schema = yup.number().integer().min(min);

	if (max) {
		schema = schema.max(max);
	}

	return schema.required();
}


export const NULLABLE_INTEGER_SCHEMA = yup.number().transform(numberOrNull).integer().nullable();
