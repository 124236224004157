/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { useState, Fragment, useMemo, useLayoutEffect } from 'react';

import { useSlot } from '../../../hooks/useSlot';
import { GenericContainer } from '../GenericContainer';
import { MaintainanceList } from '../MaintenanceSlot';
import { ColorCode, IMaintSlot, ISlot, MaintenanceTimingEnum as MaintTime } from '../types';
import { EventSlotBody } from './EventSlotBody';
import { isDateBetweenDates } from '../../../calendar/utils/timeUtils';
import { END_OF_DAY, getCombinedDateTimeString, MIDNIGHT } from '../../../lib/timeUtils';
import { DateTimeFormats, TimeUnit, addToDateDays } from '@bondsports/date-time';
import { groupBy, sumBy } from 'lodash';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

function checkMaintenanceTimes(maintenance: IMaintSlot[], currentDate: string): boolean {
	const startOfDay: string = getCombinedDateTimeString(currentDate, MIDNIGHT);

	return maintenance.some(child => {
		const startDate: string = getCombinedDateTimeString(child.startDate, child.startTime);
		const endDate: string = getCombinedDateTimeString(child.endDate, child.endTime);

		// filtering maintenance slots that end at midnight of the current viewing date
		return endDate > startOfDay && isDateBetweenDates(currentDate, startDate, endDate, DateTimeFormats.YYYY_MM_DD);
	});
}

export const EventSlot = ({
	event: state,
	isDragging,
	isConflict = false,
	isInternal,
	colorCode,
	currentDate,
	horizontal,
}: {
	event: ISlot;
	isDragging?: boolean;
	isConflict?: boolean;
	isInternal: boolean;
	colorCode?: ColorCode;
	currentDate?: string;
	horizontal?: boolean;
}) => {
	const [isMaintenanceBefore, setIsMaintenanceBefore] = useState(false);
	const [isMaintenanceAfter, setIsMaintenanceAfter] = useState(false);
	const [isMaintenanceAtBeginning, setIsMaintenanceAtBeginning] = useState(false);
	const [isMaintenanceAtTheEnd, setIsMaintenanceAtTheEnd] = useState(false);

	const [maintenanceAfterHeight, setMaintenanceAfterHeight] = useState(0);
	const [maintenanceEndHeight, setMaintenanceEndHeight] = useState(0);

	const [responsiveGapFromTop, setResponsiveGapFromTop] = useState(0);

	const { isShort, isDraft } = useSlot({
		state,
		isConflict,
	});

	const setMaintenancePxHeight = (pxHeight: number, isAfter: boolean) => {
		isAfter ? setMaintenanceAfterHeight(pxHeight) : setMaintenanceEndHeight(pxHeight);
	};

	const getMaintenanceOfSameTiming = (connectedMaintenance: IMaintSlot[], maintenanceTiming: number) => {
		return connectedMaintenance?.filter(
			(maintenance: IMaintSlot) => Number(maintenance.maintenanceTiming) === maintenanceTiming
		);
	};

	const calcRelativeHeight = (parentTime: number, innerMaintenanceTime: number) => {
		return (innerMaintenanceTime / parentTime) * 100;
	};

	const calcDurationDiff = (endTime: string, startTime: string) => {
		return Math.abs(
			dayjs(endTime, DateTimeFormats.H24_WITH_SECONDS).diff(
				dayjs(startTime, DateTimeFormats.H24_WITH_SECONDS),
				TimeUnit.MINUTE
			)
		);
	};

	const start: string = state.overallStartTime ?? state.startTime;
	const end: string = state.overallEndTime ?? state.endTime;

	const parentEventDuration: number = calcDurationDiff(start, end);

	const calcMaintenanceHeight = (maintenance: IMaintSlot) => {
		if (!maintenance) {
			return 0;
		}

		const start: string = currentDate === maintenance?.startDate ? maintenance?.startTime : MIDNIGHT;
		const end: string = maintenance?.endDate > currentDate! ? END_OF_DAY : maintenance?.endTime;

		const duration: number = calcDurationDiff(end, start);
		const maintenanceSlotHeight: number = calcRelativeHeight(parentEventDuration, duration) || 0;

		return maintenanceSlotHeight;
	};

	const [maintenanceBefore, maintenanceAfter, maintenanceAtBeginning, maintenanceAtEnd] = useMemo(() => {
		const {
			[MaintTime.AFTER]: maintenanceAfter = [],
			[MaintTime.AT_THE_END]: maintenanceAtEnd = [],
			[MaintTime.BEFORE]: maintenanceBefore = [],
			[MaintTime.AT_THE_BEGINING]: maintenanceAtBeginning = [],
		} = groupBy(state.children, child => child.maintenanceTiming);

		return [maintenanceBefore, maintenanceAfter, maintenanceAtBeginning, maintenanceAtEnd, responsiveGapFromTop];
	}, [state.children]);

	useLayoutEffect(() => {
		if (maintenanceBefore?.length) {
			const hasMaintenanceBefore: boolean = checkMaintenanceTimes(maintenanceBefore, currentDate!);

			if (hasMaintenanceBefore) {
				setResponsiveGapFromTop(calcMaintenanceHeight(maintenanceBefore?.[0]));
			}

			setIsMaintenanceBefore(hasMaintenanceBefore);
		}

		if (maintenanceAfter?.length) {
			const hasMaintenanceAfter: boolean = checkMaintenanceTimes(maintenanceAfter, currentDate!);
			setIsMaintenanceAfter(hasMaintenanceAfter);
		}

		if (maintenanceAtBeginning?.length) {
			const hasMaintenanceAtTheBeginning: boolean = checkMaintenanceTimes(maintenanceAtBeginning, currentDate!);
			setIsMaintenanceAtBeginning(hasMaintenanceAtTheBeginning);
		}

		if (maintenanceAtEnd?.length) {
			const hasMaintenanceAtTheEnd: boolean = checkMaintenanceTimes(maintenanceAtEnd, currentDate!);
			setIsMaintenanceAtTheEnd(hasMaintenanceAtTheEnd);
		}
	}, [maintenanceBefore, maintenanceAfter, maintenanceAtBeginning, maintenanceAtEnd, currentDate]);

	const beforeRelativeSize: number = useMemo(() => {
		if (!horizontal || !maintenanceBefore?.length || !isMaintenanceBefore) {
			return 0;
		}

		// calculate the relative size of the before maintenance slots
		return sumBy(maintenanceBefore, maintenance => calcMaintenanceHeight(maintenance));
	}, [maintenanceBefore, horizontal, isMaintenanceBefore]);

	const afterRelativeSize: number = useMemo(() => {
		if (!horizontal || !maintenanceAfter?.length || !isMaintenanceAfter) {
			return 0;
		}

		// calculate the relative size of the after maintenance slots
		return sumBy(maintenanceAfter, maintenance => calcMaintenanceHeight(maintenance));
	}, [maintenanceAfter, horizontal, isMaintenanceAfter]);

	const horizontalWidth: number = useMemo(() => {
		// calculate the relative size of the parent slot subtracting the relative size of the maintenance slots
		// 100% - (beforeRelativeSize + afterRelativeSize)
		return Math.floor(100 - ((beforeRelativeSize ?? 0) + (afterRelativeSize ?? 0)));
	}, [beforeRelativeSize, afterRelativeSize]);

	return (
		<Fragment key={`EventSlot-${state.id}`}>
			{isMaintenanceBefore && (
				<MaintainanceList
					key={`Before-MaintenanceList-${state.id}`}
					maintSlots={maintenanceBefore}
					maintAtEndHeight={null}
					isTrailing={true}
					calcHeight={calcMaintenanceHeight}
					colorCode={colorCode}
					horizontal={horizontal}
				/>
			)}
			<GenericContainer
				isDraft={isDraft}
				isDaily
				isShort={isDragging ? false : isShort}
				isNesting={isMaintenanceAtBeginning}
				colorCode={colorCode}
				horizontal={horizontal}
				height={horizontal ? horizontalWidth : undefined}
			>
				<EventSlotBody
					event={state}
					isShort={isShort}
					isConflict={isConflict}
					isMaintAtBeginning={isMaintenanceAtBeginning}
					isMaintAtTheEnd={isMaintenanceAtTheEnd}
					maintsAtBeginning={maintenanceAtBeginning}
					maintsAtEnd={maintenanceAtEnd}
					isInternal={isInternal}
					colorCode={colorCode}
					calcHeight={calcMaintenanceHeight}
					gapFromTop={responsiveGapFromTop}
					endAndAfterOverallPxHeight={maintenanceAfterHeight + maintenanceEndHeight}
					maintEndHeight={maintenanceAfterHeight}
					getMaintRefHeight={setMaintenancePxHeight}
					horizontal={horizontal}
				/>
			</GenericContainer>
			{isMaintenanceAfter && (
				<MaintainanceList
					key={`After-MaintenanceList-${state.id}`}
					maintSlots={maintenanceAfter}
					maintAtEndHeight={{ height: maintenanceAfterHeight }}
					isTrailing={true}
					calcHeight={calcMaintenanceHeight}
					colorCode={colorCode}
					setHeightFromRef={setMaintenancePxHeight}
					horizontal={horizontal}
				/>
			)}
		</Fragment>
	);
};
