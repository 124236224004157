import { useCallback, useRef } from 'react';

export function useIntersectionObserver(callback: IntersectionObserverCallback) {
	const observer = useRef<IntersectionObserver | null>(null);

	const observe = useCallback(
		(node: Element) => {
			if (observer.current) {
				observer.current.disconnect();
			}

			observer.current = new IntersectionObserver(callback);

			if (node) {
				observer.current.observe(node);
			}
		},
		[callback]
	);

	return { observe };
}
