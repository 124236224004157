import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { ISlot, MaintenanceTimingEnum } from '../../organisms/bo_calendar_slot/types';
import {
	ECalendarMode,
	IEventsGroup,
	IOptions,
	IPartialRanges,
	ISlotEventsToSpaces,
	ISlotsEvents,
	ISlotsEventsAndEventsWithSpaces,
	TOnChangeInCalender,
} from '../types';
import {
	calculateMarginFromHours,
	calculateMarginFromMinutes,
	calculateMarginLeftFromHours,
	calculateMarginLeftFromMinutes,
	oneHourHeight,
	oneHourWidth,
	setNewHourHeight,
	setNewHourWidth,
} from '../utils/timeUtils';
import { usePrevious } from './usePrevious';
import { DateTimeFormats } from '@bondsports/date-time';
import { HasStartAndEndDates } from '@bondsports/types';
import {
	getCombinedDateTimeString,
	MIDNIGHT,
	calculateOverallStartTime,
	calculateOverallEndTime,
} from '../../lib/timeUtils';
import { cloneDeep, maxBy, minBy } from 'lodash';

dayjs.extend(isoWeek);

export type DateRange = {
	[key: number]: {
		startDate: string;
		endDate: string;
	};
};

const BEFORE_AND_AFTER_MAINTENANCE = [MaintenanceTimingEnum.AFTER, MaintenanceTimingEnum.BEFORE];

function updateChildrenStartAndEndTimes(children: ISlot[], delta: number) {
	for (const child of children ?? []) {
		// add the drag n' drop change delta minutes to the start date and time
		const childStartDate = dayjs(getCombinedDateTimeString(child.startDate, child.startTime)).add(delta, 'minutes');

		// add the drag n' drop change delta minutes to the end date and time
		const childEndDate = dayjs(getCombinedDateTimeString(child.endDate, child.endTime)).add(delta, 'minutes');

		child.startDate = childStartDate.format(DateTimeFormats.YYYY_MM_DD);
		child.startTime = childStartDate.format(DateTimeFormats.H24_WITH_SECONDS);

		child.endDate = childEndDate.format(DateTimeFormats.YYYY_MM_DD);
		child.endTime = childEndDate.format(DateTimeFormats.H24_WITH_SECONDS);
	}
}

export type MiddlewareContent = {
	datesRange: DateRange;
	updateDatesRange: (id: number, startDate: string, endDate: string, onChangeData?: any) => void;
	hasCollision: (event: ISlot, list: ISlot[]) => boolean;
	day: number;
	incrementDay: () => void;
	updateEventsToSpaceByDatesRange: (
		spaces: ISlotEventsToSpaces,
		onChange?: TOnChangeInCalender
	) => {
		[x: number]: ISlot[];
	};
	draggableDatesRange: DateRange;
	setDraggableRange: (id: number, startDate: string, endDate: string) => void;
	options: IOptions;
	setOptions: (options: IOptions) => void;
	partialRanges: IPartialRanges;
	setPartialRanges: (range: IPartialRanges) => void;
	getPartialEvents: (events: ISlot[], from: number, to: number) => ISlot[];
	getTopAndHeight: (
		startDate: number,
		endDate: number,
		horizontal?: boolean,
		debug?: boolean
	) => { top: number; height: number };
	getEventsGroups: (events: ISlot[], horizontal?: boolean) => IEventsGroup[];
	isResizing: boolean;
	setIsResizing: (isResizing: boolean) => void;
	initiateEventsToSpaces: (slots: ISlotsEventsAndEventsWithSpaces) => void;
	updateEventsData: () => void;
	handleDrop: (
		eventId: number | string,
		parentId: number | string,
		spaceId: number | string,
		newDate: string,
		differenceInMinutes: number,
		newEndDate?: string
	) => void;
	eventsToSpaces: ISlotEventsToSpaces;
	events: ISlotsEvents;
};

const MiddlewareContext = createContext<MiddlewareContent>({
	datesRange: {},
	updateDatesRange: (id: number, startDate: string, endDate: string, onChangeData?: any) => {},
	hasCollision: (event: any, list: any[]) => false,
	day: 1,
	incrementDay: () => {},
	updateEventsToSpaceByDatesRange: (spaces: ISlotEventsToSpaces, onChange?: TOnChangeInCalender) => ({}),
	draggableDatesRange: {},
	setDraggableRange: (id: number, startDate: string, endDate: string) => {},
	options: {},
	setOptions: (options: IOptions) => ({}),
	partialRanges: {
		vertical: {
			from: 0,
			to: 0,
		},
		horizontal: {
			from: 0,
			to: 0,
		},
	},
	setPartialRanges: (ranges: IPartialRanges) => ({}),
	getPartialEvents: (events: ISlot[], from: number, to: number) => [],
	getTopAndHeight: (startDate: number, endDate: number, horizontal?: boolean) => ({ top: 0, height: 0 }),
	getEventsGroups: (events: ISlot[], horizontal?: boolean) => [],
	isResizing: false,
	setIsResizing: (isResizing: boolean) => {},
	initiateEventsToSpaces: (slots: ISlotsEventsAndEventsWithSpaces) => {},
	updateEventsData: () => {},
	handleDrop: (
		eventId: number | string,
		parentId: number | string,
		spaceId: number | string,
		newDate: string,
		differenceInMinutes: number,
		newEndDate?: string
	) => {},
	eventsToSpaces: {},
	events: {},
});

export const MiddlewareContextProvider = ({
	children,
	onChange,
}: {
	children: ReactNode;
	onChange?: TOnChangeInCalender;
}) => {
	const [eventsToSpaces, setEventsToSpaces] = useState<ISlotEventsToSpaces>({});
	const [events, setEvents] = useState<ISlotsEvents>({});
	const initiateEventsToSpaces = useCallback((slots: ISlotsEventsAndEventsWithSpaces) => {
		setEventsToSpaces(slots.eventsToSpaces);
		setEvents(slots.events);
	}, []);

	const [options, setOptions] = useState<IOptions>({});
	const previousOptions = usePrevious(options);

	const currentDate = options?.date ?? '';

	const handleDrop = useCallback(
		(
			eventId: number | string,
			parentId: number | string,
			spaceId: number | string,
			newDate: string,
			differenceInMinutes: number,
			newEndDate?: string
		) => {
			const newEvents: ISlotEventsToSpaces = cloneDeep(eventsToSpaces);
			const fullEvents: ISlotsEvents = cloneDeep(events);

			const dataEvent = newEvents[spaceId]?.find((item: ISlot) => item.id === Number(eventId)) || fullEvents[eventId];

			let event = { ...dataEvent, spaceId };

			if (newDate) {
				const date: dayjs.Dayjs = dayjs(newDate);
				const requestedDate: number = date.get('date');
				const requestedMonth: number = date.get('month');
				const requestedYear: number = date.get('year');

				let startDate: string = dayjs(event.startDate)
					.set('date', requestedDate)
					.set('month', requestedMonth)
					.set('year', requestedYear)
					.format();

				let endDate: string = dayjs(event.endDate)
					.set('date', requestedDate)
					.set('month', requestedMonth)
					.set('year', requestedYear)
					.format();

				// Drag 'n' drop slot on daily view
				if (newEndDate) {
					startDate = newDate;
					endDate = newEndDate;
				}

				// formatting startDate to YYYY-MM-DD and startTime to HH:mm:ss
				const startTime: string = dayjs.utc(startDate).format(DateTimeFormats.H24_WITH_SECONDS);
				startDate = dayjs.utc(startDate).format(DateTimeFormats.YYYY_MM_DD);

				// formatting endDate to YYYY-MM-DD and endTime to HH:mm:ss
				const endTime: string = dayjs.utc(endDate).format(DateTimeFormats.H24_WITH_SECONDS);
				endDate = dayjs.utc(endDate).format(DateTimeFormats.YYYY_MM_DD);

				updateChildrenStartAndEndTimes(event.children, differenceInMinutes);

				// ignore maintenance slots at the beginning and end of a slot as they do not affect the slot overall time
				const children: ISlot[] =
					event.children?.filter((child: ISlot) =>
						BEFORE_AND_AFTER_MAINTENANCE.includes(Number(child.maintenanceTiming))
					) ?? [];

				// get the lowest startDate and the highest endDate from the slot and its children
				const minStartDate: string = minBy([startDate, ...children.map((child: ISlot) => child.startDate)])!;
				const maxEndDate: string = maxBy([endDate, ...children.map((child: ISlot) => child.endDate)])!;

				event = {
					...event,
					startDate,
					startTime,
					endDate,
					endTime,
					// calculating overallStartTime
					overallStartTime: calculateOverallStartTime(
						[
							getCombinedDateTimeString(startDate, startTime),
							...children.map((child: ISlot) => getCombinedDateTimeString(child.startDate, child.startTime)),
						],
						minStartDate,
						currentDate
					),
					// calculating overallEndTime
					overallEndTime: calculateOverallEndTime(
						[
							getCombinedDateTimeString(endDate, endTime),
							...children.map((child: ISlot) => getCombinedDateTimeString(child.endDate, child.endTime)),
						],
						maxEndDate,
						currentDate
					),
					children: event.children,
				};
			}

			// remove event from the previous space
			if (newEvents[parentId]) {
				newEvents[parentId] = newEvents[parentId].filter((item: ISlot) => Number(item.id) !== Number(eventId));
			}

			// add event to the new space
			if (newEvents[spaceId]) {
				newEvents[spaceId] = [...newEvents[spaceId], event];
			} else {
				newEvents[spaceId] = [event];
			}

			setEventsToSpaces(newEvents);

			// update the full events object
			fullEvents[eventId] = { ...event, spaceId };
			setEvents(fullEvents);
		},
		[events, eventsToSpaces, currentDate]
	);

	useEffect(() => {
		setNewHourHeight(options?.hourSize?.vertical);
		setNewHourWidth(options?.hourSize?.horizontal);
		setDatesRange({});
	}, [options?.hourSize?.vertical, options?.hourSize?.horizontal]);

	useEffect(() => {
		if (options && previousOptions && Object.keys(previousOptions).length > 0 && onChange) {
			const params = {} as {
				startDate?: string;
				endDate?: string;
			};
			const dayShift = options.isSundayFirstDay ? 1 : 0;

			if (options.mode === ECalendarMode.DAILY) {
				params.startDate = dayjs(options.date).format('DD/MM/YYYY');
				params.endDate = dayjs(options.date).format('DD/MM/YYYY');
			} else if (options.mode === ECalendarMode.WEEKLY) {
				params.startDate = dayjs(options.date)
					.isoWeekday(1 - dayShift)
					.format('DD/MM/YYYY');
				params.endDate = dayjs(options.date)
					.isoWeekday(7 - dayShift)
					.format('DD/MM/YYYY');
			} else if (options.mode === ECalendarMode.MONTHLY) {
				const firstDay = dayjs(options.date).date(1);
				let lastDay = dayjs(options.date).date(31);
				while (lastDay.format('MM') !== firstDay.format('MM')) lastDay = lastDay.subtract(1, 'day');

				if (options?.monthlySpecificMonth) {
					params.startDate = firstDay.format('DD/MM/YYYY');
					params.endDate = lastDay.format('DD/MM/YYYY');
				} else {
					params.startDate = dayjs(firstDay)
						.isoWeek(firstDay.isoWeek())
						.isoWeekday(1 - dayShift)
						.format('DD/MM/YYYY');
					params.endDate = dayjs(lastDay)
						.isoWeek(lastDay.isoWeek())
						.isoWeekday(7 - dayShift)
						.format('DD/MM/YYYY');
				}
			}

			onChange?.({
				type: 'NEW_MODE',
				data: {
					mode: options.mode,
					...params,
				},
			});
		}
	}, [options, previousOptions]);

	const [partialRanges, setPartialRanges] = useState<IPartialRanges>({
		vertical: {
			from: 0,
			to: 0,
		},
		horizontal: {
			from: 0,
			to: 0,
		},
	});

	const [datesRange, setDatesRange] = useState<DateRange>({});
	const [isResizing, setIsResizing] = useState(false);

	const updateDatesRange = useCallback(
		(id: number, startDate: string, endDate: string, onChangeData?: any) => {
			if (onChange) {
				onChange(onChangeData);
			}
			setDatesRange({ [id]: { startDate, endDate } });
		},
		[onChange]
	);

	const [draggableDatesRange, setDraggableDatesRange] = useState<DateRange>({});

	const setDraggableRange = useCallback((id: number, startDate: string, endDate: string) => {
		setDraggableDatesRange({ [id]: { startDate, endDate } });
	}, []);

	const hasCollision = (event: ISlot, slotsList: ISlot[] = []) => {
		if (!event) {
			return true;
		}

		if (slotsList?.length === 0 || !Array.isArray(slotsList)) {
			return false;
		}

		const other = slotsList.find(item => event.id !== item.id && isOverlapping(event, item));

		return Boolean(other);
	};

	const isOverlapping = (slot: ISlot, other: ISlot): boolean => {
		const { startDate: slotStart, endDate: slotEnd } = getCalculatedDateTime(slot);
		const { startDate: otherStart, endDate: otherEnd } = getCalculatedDateTime(other);

		return dayjs(slotStart).isBefore(otherEnd) && dayjs(slotEnd).isAfter(otherStart);
	};

	const getCalculatedDateTime = (slot: ISlot): HasStartAndEndDates => {
		const isStartDateBefore: boolean = slot.startDate < currentDate;

		const start: dayjs.Dayjs = dayjs(currentDate);
		const [startHours, startMinutes] = (isStartDateBefore ? MIDNIGHT : (slot.overallStartTime ?? slot.startTime))
			.split(':')
			.map(Number);

		const startDate: string = start
			.set('hour', startHours)
			.set('minute', startMinutes)
			.format(DateTimeFormats.YYYY_MM_DD_T_HH_MM_SS);

		const isEndDateAfter: boolean = slot.endDate > currentDate;

		const end: dayjs.Dayjs = isEndDateAfter ? dayjs(currentDate).add(1, 'day') : dayjs(currentDate);
		const [endHours, endMinutes] = (isEndDateAfter ? MIDNIGHT : (slot.overallEndTime ?? slot.endTime))
			.split(':')
			.map(Number);

		const endDate: string = end
			.set('hour', endHours)
			.set('minute', endMinutes)
			.format(DateTimeFormats.YYYY_MM_DD_T_HH_MM_SS);

		return { startDate, endDate };
	};

	/** Update events object after change datetime */
	const updateEventsToSpaceByDatesRange = useCallback(
		(prevSpaces: ISlotEventsToSpaces) => {
			const newSpaces = { ...prevSpaces };

			for (const eventId in datesRange) {
				for (const spaceId in newSpaces) {
					const events = newSpaces[spaceId];

					const others = events.filter(item => item.id !== Number(eventId));
					const changeItem = events.find(item => item.id === Number(eventId));

					if (changeItem) {
						const updatedItem = {
							...changeItem,
							startDate: datesRange[eventId].startDate,
							endDate: datesRange[eventId].endDate,
							startTime: dayjs.utc(datesRange[eventId].startDate).format('HH:mm:ss'),
							endTime: dayjs.utc(datesRange[eventId].endDate).format('HH:mm:ss'),
						};

						others.push(updatedItem);
					}

					newSpaces[spaceId] = others;
				}
			}

			return newSpaces;
		},
		[datesRange]
	);

	const [day, setDay] = useState(1);

	const incrementDay = useCallback(() => {
		setDay(currentDay => {
			onChange?.({
				type: 'SCROLL_TO_NEXT_DAY',
				data: {
					date: dayjs(options?.date).add(currentDay, 'day').format('DD/MM/YYYY'),
				},
			});

			return currentDay + 1;
		});
	}, [onChange]);

	useEffect(() => {
		if (options.infiniteScrolling && options.mode === ECalendarMode.DAILY) {
			setDay(1);
			incrementDay();
		} else setDay(1);
	}, [options.mode, options.view, options.date, options.infiniteScrolling]);

	const getPartialEvents = useCallback(
		(events: ISlot[], from: number, to: number) =>
			events.filter(event => {
				const date = dayjs(options?.date);

				const difference = dayjs(event.startDate).diff(date, 'hour');

				return difference >= from && difference <= to;
			}),
		[options?.date]
	);

	/** Calculating top/left position and height/width of events for daily mode */
	const getTopAndHeight = useCallback(
		(startDate: number, endDate: number, horizontal?: boolean, debug?: boolean) => {
			const hour = Number(dayjs(startDate).format('HH'));
			const minutes = Number(dayjs(startDate).format('mm'));

			const diff = dayjs(endDate).diff(dayjs(startDate), 'minutes');

			const h = horizontal ? calculateMarginLeftFromMinutes(diff) : calculateMarginFromMinutes(diff);
			const marginTop = horizontal ? calculateMarginLeftFromHours(hour) : calculateMarginFromHours(hour);
			const marginTopMin = horizontal ? calculateMarginLeftFromMinutes(minutes) : calculateMarginFromMinutes(minutes);

			const total = marginTop + marginTopMin;

			const today = dayjs(options?.date);
			const date = dayjs(startDate);

			const difference = date.diff(today, 'hour');
			const differenceAtDays = Math.abs(Math.floor(difference / 24));

			const size = horizontal ? oneHourWidth : oneHourHeight;

			let shift = size * 24 * differenceAtDays;
			if (difference < 0) shift *= -1;

			return { height: h || 0, top: total + shift || 0 + shift };
		},
		[options?.date]
	);

	const splitIntoGroups = (slots: ISlot[]): ISlot[][] => {
		const groups: ISlot[][] = slots.map(slot => [slot, ...slots.filter((other: ISlot) => hasCollision(slot, [other]))]);

		return mergeGroups(groups);
	};

	/**
	 * Merge groups with collision
	 * if two groups have at least one common event, merge them
	 */
	const mergeGroups = (groups: ISlot[][]): ISlot[][] => {
		const mergedGroups = groups.map(group => [...group]);

		for (let i = 0; i < mergedGroups.length; i++) {
			for (let j = i + 1; j < mergedGroups.length; j++) {
				if (mergedGroups[i]?.length && mergedGroups[j]?.length) {
					const hasOverlap = mergedGroups[i].some(slot =>
						mergedGroups[j].some(other => other.id === slot.id),
					);

					if (hasOverlap) {
						mergedGroups[i] = Array.from(new Set([...mergedGroups[i], ...mergedGroups[j]]));
						mergedGroups[j] = [];
					}
				}
			}
		}

		return mergedGroups.filter(group => group.length > 0);
	};

	/** Making events groups with collision */
	const getEventsGroups = (events: ISlot[], horizontal?: boolean): IEventsGroup[] => {
		const groups: ISlot[][] = splitIntoGroups(events);

		const result: IEventsGroup[] = [];

		groups.forEach(events => {
			if (events.length > 1) {
				const startDates: number[] = events.map(event => {
					const [hours, minutes] = event.startTime.split(':').map(Number);
					return dayjs(dayjs.utc(event.startDate).format('DD/MM/YYYY'), 'DD/MM/YYYY')
						.hour(hours)
						.minute(minutes)
						.toDate()
						.getTime();
				});

				const endDates: number[] = events.map(event => {
					const [hours, minutes] = event.endTime.split(':').map(Number);
					return dayjs(dayjs.utc(event.endDate).format('DD/MM/YYYY'), 'DD/MM/YYYY')
						.hour(hours)
						.minute(minutes)
						.toDate()
						.getTime();
				});

				let minStart: number = Math.min(...startDates);
				let maxEnd: number = Math.max(...endDates);

				const endOfDay: number = dayjs(options?.date).endOf('day').valueOf();
				if (maxEnd > endOfDay) {
					maxEnd = endOfDay;
				}

				const startOfDay: number = dayjs(options?.date).startOf('day').valueOf();
				if (minStart < startOfDay) {
					minStart = startOfDay;
				}

				const { top, height } = getTopAndHeight(minStart, maxEnd, horizontal);

				const groups =
					events.length > 2 ? getSubGroups(events) : [[events[0]], [events[1]]].sort((a, b) => a[0].id - b[0].id);

				result.push({
					items: events.sort((a, b) => a.id - b.id),
					groups,
					top,
					height,
				});
			} else if (events.length === 1) result.push({ items: events, item: events[0] });
		});

		return result;
	};

	/** Making groups inside group of events with collision */
	const getSubGroups = (events: ISlot[]) => {
		const groups = [] as ISlot[][];

		events.forEach(event => {
			let added = false;
			for (let i = 0; i < groups.length; i++) {
				if (!hasCollision(event, groups[i])) {
					groups[i].push(event);
					added = true;
					break;
				}
			}
			if (!added) groups.push([event]);
		});

		for (let i = 0; i < groups.length; i++) {
			groups[i] = groups[i].sort((a, b) => a.id - b.id);
		}

		return groups.sort((a, b) => a[0].id - b[0].id);
	};

	const updateEventsData = useCallback(() => {
		setEvents(prevEvents => {
			const newEvents = { ...prevEvents };

			for (const eventId in datesRange) {
				newEvents[eventId] = {
					...newEvents[eventId],
					startDate: datesRange[eventId].startDate,
					endDate: datesRange[eventId].endDate,
				};
			}

			return newEvents;
		});

		setEventsToSpaces(prevSpaces => updateEventsToSpaceByDatesRange(prevSpaces));
	}, [updateEventsToSpaceByDatesRange, options.date]);

	useEffect(() => {
		updateEventsData();
	}, [datesRange]);

	return (
		<MiddlewareContext.Provider
			value={{
				datesRange,
				updateDatesRange,
				hasCollision,
				day,
				incrementDay,
				updateEventsToSpaceByDatesRange,
				draggableDatesRange,
				setDraggableRange,
				options,
				setOptions,
				partialRanges,
				setPartialRanges,
				getPartialEvents,
				getTopAndHeight,
				getEventsGroups,
				isResizing,
				setIsResizing,
				initiateEventsToSpaces,
				updateEventsData,
				handleDrop,
				events,
				eventsToSpaces,
			}}
		>
			{children}
		</MiddlewareContext.Provider>
	);
};

export const useMiddlewareContext = () => useContext(MiddlewareContext);
