import { Router } from '@angular/router';
import { HashRouter, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Global } from '@emotion/react';
import { globalCss } from '@app/react/styles/global';
import muiTheme from '@app/react/styles/muiTheme';
import { CssBaseline, MuiThemeProvider, StylesProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { LoginPage } from '@app/react/components/authentication/LoginPage';

type Props = {
	router: Router;
};

export const AuthenticationPage: React.FC<Props> = ({ router }) => {
	useEffect(() => {
		if (!(window as any).ngRouter) {
			(window as any).ngRouter = router;
		}
	}, []);

	return (
		<StylesProvider injectFirst>
			<Global styles={globalCss} />
			<MuiThemeProvider theme={muiTheme}>
				<CssBaseline />
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<HashRouter>
						<Route path="/" exact render={() => <LoginPage />} />
					</HashRouter>
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		</StylesProvider>
	);
};
